#countdown {
    width: 250px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    display: inline-block;
    text-align: center;
    margin: auto;
    position: relative;
    top: 10%;
}
#countdown .box {
    padding: 10px;
    border-right: solid 1px rgba(255, 255, 255, 0.2);
}
#countdown .col-4:last-child .box {
    border-right-color: transparent;
}
#countdown .box p {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
}
#countdown .box .text {
    font-size: 12px;
    font-family: sans-serif;
}

@media (min-width: 768px) {
    .container {
        width: 1100px;
    }
    h1 {
        font-size: 58px;
    }
    #countdown {
        width: 350px;
        padding: 20px;
    }
}
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap');

.coming-soon {
    color: #fff;
    font-family: 'Roboto Condensed', sans-serif;
    background-image: url(https://images.unsplash.com/photo-1508389377389-b8221c0bcc9e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80);
    /* background-position: top top; */
    background-size: cover;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: 1000px;
}

.coming-soon-app {
    height: 1000px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background: rgb(49, 49, 177);
    background: linear-gradient(0deg, rgba(49, 49, 177, 1) 0%, rgba(49, 49, 177, 1) 50%, rgba(49, 49, 177, 0.6) 100%);
}

.col-4-time {
    width: 25%;
    float: left;
}
.col-8-time {
    padding: 20px;
    font-size: 27px;
    text-transform: uppercase;
}
.title-display-inline {
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    margin-bottom: 20px !important;
}

.description-display-inline {
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    min-height: 68px;
}

.description-display-inline-2 {
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    min-height: 35px;
}
.custome-scroll-bar {
    height: 67vh;
    background: #f5f5f5;
    overflow-y: scroll;
    overflow-x: hidden;
}
.custome-scroll-bar::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    background-color: #d62929;
}
@media (min-width: 768px) {
    .container {
        width: 1100px;
    }
    h1 {
        font-size: 58px;
    }
}

@media (max-width: 576px) {
    .h-md-100 {
        height: 100% !important;
    }
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-corect:before {
    background-color: rgb(95 167 12) !important;
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-danger:before {
    background-color: #dc3545 !important;
}

i.bx {
    color: aliceblue;
}

.dropdown-notifications .badge-notifications {
    top: -0.5rem;
}

#overlay {
    position: fixed; /* Sit on top of the page content */
    display: block; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    min-height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    z-index: 100000; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
}

div.is-disabled {
    pointer-events: none;
    opacity: 0.7;
}

.rounded-xl {
    border-radius: 1.2rem !important;
}

.rounded-xl-top {
    border-top-left-radius: 1.2rem !important;
    border-top-right-radius: 1.2rem !important;
}

.mh-mw-75 {
    min-height: 65% !important;
    min-width: 65% !important;
}

/* Scrollbar */

.scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

.scroll::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
}

.scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.5);
}
.progress-bar-none-transition {
    transition: none !important;
}

a.disabled-link {
    pointer-events: none !important;
}

.font-size-25pt {
    font-size: 1.42rem !important;
}

/*======================
    404 page
=======================*/

.page_404 {
    padding: 40px 0;
    background: #fff;
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
}

.contant_box_404 {
    margin-top: -50px;
}
