.text-nav:hover {
    background-color: #bbbbbb;
    opacity: 1;
}

.active.text-nav {
    background-color: #d6d6d6;
    opacity: 1;
}

.text-nav.active:hover {
    background-color: #bbbbbb;
    opacity: 1;
}

.svg {
    filter: invert(.6) sepia(2) saturate(5) hue-rotate(175deg);
}

.navbar-brand .svg,
.footer-left .svg {
    filter: none;
}

a.title:hover {
    color: rgba(2, 117, 216, 0.85);
}

.slide-footer {
    background-color: rgba(34, 35, 87, 0.7);
    padding: 8.5% 0%;
    height: 5.5rem;
    z-index: 100;
    bottom: 5.5rem;
}

a.slide-footer {
    color: white;
    font-size: 15px;
}

a.slide-footer:hover {
    color: white;
    text-decoration: none;
}

.embed-responsive {
    padding: 0;
    margin-bottom: 2rem;
}

span.dZkckO,
span.bBfHpH {
    top: calc(50% - 3.5rem)
}

@media (max-width: 992px) {

    span.dZkckO,
    span.bBfHpH {
        top: calc(50% - .5rem)
    }
}

/* ----Footer---- */

.footer-distributed {
    box-shadow: 0px -5px 20px 0px rgb(0 0 0 / 5%);
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    padding: 55px 50px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
    display: inline-block;
    vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left {
    width: 40%;
}

/* The company logo */

.footer-distributed h3 {
    color: rgba(57, 68, 77, 0.84);
    font: normal 36px 'Open Sans', cursive;
    margin: 0;
}

/* Footer links */

.footer-distributed .footer-links {
    color: rgba(0, 0, 0, 0.5);
    margin: 20px 0 12px;
    padding: 0;
}

.footer-distributed .footer-links a {
    display: inline-block;
    line-height: 1.8;
    font-weight: 400;
    text-decoration: none;
    color: inherit;
}

.footer-distributed .footer-company-name {
    color: #222;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center {
    width: 35%;
}

.footer-distributed .footer-center i {
    background-color: #33383b;
    color: #ffffff;
    font-size: 19px;
    width: 39px;
    height: 39px;
    border-radius: 50%;
    text-align: center;
    line-height: 41px;
    margin: 10px 15px;
    vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope {
    font-size: 18px;
    line-height: 38px;
}

.footer-distributed .footer-center p {
    display: inline-block;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    vertical-align: middle;
    margin: 0;
}

.footer-distributed .footer-center p span {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 2;
}

.footer-distributed .footer-center p a {
    color: rgba(2, 117, 216, 0.85);
    text-decoration: none;
    ;
}

.footer-distributed .footer-links a:not(:first-child):before {
    content: "|";
    font-weight: 300;
    font-size: 20px;
    left: 0;
    color: rgba(0, 0, 0, 0.5);
    display: inline-block;
    padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
    content: none;
}

/* Footer Right */

.footer-distributed .footer-right {
    width: 20%;
}

.footer-distributed .footer-company-about {
    line-height: 20px;
    color: #92999f;
    font-size: 13px;
    font-weight: normal;
    margin: 0;
}

.footer-distributed .footer-company-about span {
    display: block;
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    text-transform: uppercase;
}

.footer-distributed .footer-icons {
    margin-top: 25px;
}

.footer-distributed .footer-icons a {
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color: #33383b;
    border-radius: 2px;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 38px;
    margin-right: 3px;
    margin-bottom: 5px;
}

.search-input :focus {
    outline: none;
}

.search-box {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-height: 0;
}

.search-box .search-input {
    display: none !important;
    color: rgba(57, 68, 77, 0.84);
}

.show .search-box .search-input {
    max-height: 40px;
}

.show .search-box .search-input {
    display: block !important;
    transition: opacity 0.5s;
    height: 40px;
}

@media (max-width: 992px) {

    .footer-distributed {
        font: bold 14px sans-serif;
    }

    .footer-distributed .footer-left,
    .footer-distributed .footer-center,
    .footer-distributed .footer-right {
        align-items: center;
        width: 100%;
        margin-bottom: 40px;
        text-align: center;
    }

    .footer-distributed .footer-center i {
        margin: 10px 0px;
    }

    .footer-distributed .footer-center>div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100px;
    }
}

.modal-backdrop {
    position: inherit;
    display: none;
}

li.dropdown-item:first-child {
    margin-left: -13px
}

li.dropdown-item {
    transform: skew(-30deg, 0deg);
    transition: transform 0.1s;
}

li.dropdown-item>a {
    transform: skew(30deg, 0deg);
}