/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #222357;
    --primary-hover: #353687;
    --primary-background: #3F406D;
    --secondary: #6b757d;
    --success: #66bb6a;
    --info: #29b6f6;
    --warning: #ffa726;
    --danger: #f44336;
    --light: #f8f9fa;
    --dark: #39444d;
    --breakpoint-xs: 0;
    --breakpoint-xs-md: 374px;
    --breakpoint-xs-plus: 413px;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --breakpoint-xxl: 1366px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

*,
:after,
:before {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    font-family: Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--dark);
}

body {
    margin: 0;
    background-color: var(--white);
}

body {
    text-align: left;
}

[dir='rtl'] body {
    text-align: right;
}

[tabindex='-1']:focus:not(:focus-visible) {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
}

abbr[data-original-title],
abbr[title] {
    cursor: help;
    border-bottom: 0;
}

address {
    font-style: normal;
    line-height: inherit;
}

address {
    margin-bottom: 1rem;
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0;
}

dt {
    font-weight: 500;
}

dd {
    margin-bottom: 0.5rem;
}

dd {
    margin-left: 0;
}

[dir='rtl'] dd {
    margin-right: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

a {
    color: var(--primary);
    text-decoration: none;
}

a {
    background-color: transparent;
}

a:hover {
    color: var(--primary-hover);
    text-decoration: underline;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
}

code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    font-size: 1em;
}

pre {
    overflow: auto;
    -ms-overflow-style: scrollbar;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
}

img {
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

caption {
    color: #b8bdc2;
    caption-side: bottom;
}

caption {
    padding-top: 0.5rem 0.75rem;
    padding-bottom: 0.5rem 0.75rem;
}

caption {
    text-align: left;
}

[dir='rtl'] caption {
    text-align: right;
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent;
}

label {
    display: inline-block;
}

label {
    margin-bottom: 0.5rem;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

[role='button'] {
    cursor: pointer;
}

select {
    word-wrap: normal;
}

[type='button'],
[type='reset'],
[type='submit'],
button {
    -webkit-appearance: button;
}

[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}

[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type='checkbox'],
input[type='radio'] {
    box-sizing: border-box;
}

input[type='checkbox'],
input[type='radio'] {
    padding: 0;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

legend {
    padding: 0;
    margin-bottom: 0.5rem;
}

@media (max-width: 1200px) {
    legend {
        font-size: calc(1.275rem + 0.3vw);
    }
}

progress {
    vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
}

[type='search'] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
}

summary {
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Oswald, Helvetica Neue, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: var(--dark);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 1rem;
}

.h1,
h1 {
    font-size: 2.827rem;
}

@media (max-width: 1200px) {

    .h1,
    h1 {
        font-size: calc(1.4077rem + 1.8924vw);
    }
}

.h2,
h2 {
    font-size: 1.999rem;
}

@media (max-width: 1200px) {

    .h2,
    h2 {
        font-size: calc(1.3249rem + 0.8988vw);
    }
}

.h3,
h3 {
    font-size: 1.414rem;
}

@media (max-width: 1200px) {

    .h3,
    h3 {
        font-size: calc(1.2664rem + 0.1968vw);
    }
}

.h4,
h4 {
    font-size: 1.414rem;
}

@media (max-width: 1200px) {

    .h4,
    h4 {
        font-size: calc(1.2664rem + 0.1968vw);
    }
}

.h5,
h5 {
    font-size: 1rem;
}

.h6,
h6 {
    font-size: 0.9375rem;
}

.lead {
    font-size: 1.0546875rem;
    font-weight: 400;
}

.display-1 {
    font-size: 3.998rem;
    font-weight: 700;
    line-height: 1.5;
}

@media (max-width: 1200px) {
    .display-1 {
        font-size: calc(1.5248rem + 3.2976vw);
    }
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.5;
}

@media (max-width: 1200px) {
    .display-2 {
        font-size: calc(1.675rem + 5.1vw);
    }
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.5;
}

@media (max-width: 1200px) {
    .display-3 {
        font-size: calc(1.575rem + 3.9vw);
    }
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.5;
}

@media (max-width: 1200px) {
    .display-4 {
        font-size: calc(1.475rem + 2.7vw);
    }
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.small,
small {
    font-size: 0.75rem;
    font-weight: 400;
}

.mark,
mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

.list-unstyled {
    list-style: none;
}

.list-unstyled {
    padding-left: 0;
}

[dir='rtl'] .list-unstyled {
    padding-right: 0;
}

.list-inline {
    list-style: none;
}

.list-inline {
    padding-left: 0;
}

[dir='rtl'] .list-inline {
    padding-right: 0;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

[dir='rtl'] .list-inline-item:not(:last-child) {
    margin-left: 0.5rem;
}

.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.blockquote {
    font-size: 1.171875rem;
}

.blockquote {
    margin-bottom: 1rem;
}

.blockquote-footer {
    display: block;
    font-size: 0.75rem;
    color: #6c757d;
}

.blockquote-footer:before {
    content: '\2014\A0';
}

.img-fluid,
.img-thumbnail {
    max-width: 100%;
    height: auto;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #f5f6f7;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}

.figure {
    display: inline-block;
}

.figure-img {
    line-height: 1;
}

.figure-img {
    margin-bottom: 0.5rem;
}

.figure-caption {
    font-size: 90%;
    color: #6c757d;
}

code {
    font-size: 87.5%;
    color: var(--primary);
    word-wrap: break-word;
}

a>code {
    color: inherit;
}

kbd {
    font-size: 87.5%;
    color: #fff;
}

kbd {
    padding: 0.2rem 0.4rem;
    background-color: #212529;
    border-radius: 0.2rem;
    box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25);
}

kbd kbd {
    font-size: 100%;
    font-weight: 500;
}

kbd kbd {
    padding: 0;
    box-shadow: none;
}

pre {
    display: block;
    font-size: 87.5%;
    color: #212529;
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    width: 100%;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl,
[dir='rtl'] .container,
[dir='rtl'] .container-fluid,
[dir='rtl'] .container-lg,
[dir='rtl'] .container-md,
[dir='rtl'] .container-sm,
[dir='rtl'] .container-xl,
[dir='rtl'] .container-xxl {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {

    .container,
    .container-sm,
    .container-xs-md,
    .container-xs-plus {
        max-width: 520px;
    }
}

@media (min-width: 768px) {

    .container,
    .container-md,
    .container-sm,
    .container-xs-md,
    .container-xs-plus {
        max-width: 720px;
    }
}

@media (min-width: 992px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xs-md,
    .container-xs-plus {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xs-md,
    .container-xs-plus {
        max-width: 1120px;
    }
}

@media (min-width: 1366px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xs-md,
    .container-xs-plus,
    .container-xxl {
        max-width: 1144px;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.row,
[dir='rtl'] .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.no-gutters,
[dir='rtl'] .no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*='col-'],
[dir='rtl'] .no-gutters>.col,
[dir='rtl'] .no-gutters>[class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto,
.col-xs-md,
.col-xs-md-1,
.col-xs-md-2,
.col-xs-md-3,
.col-xs-md-4,
.col-xs-md-5,
.col-xs-md-6,
.col-xs-md-7,
.col-xs-md-8,
.col-xs-md-9,
.col-xs-md-10,
.col-xs-md-11,
.col-xs-md-12,
.col-xs-md-auto,
.col-xs-plus,
.col-xs-plus-1,
.col-xs-plus-2,
.col-xs-plus-3,
.col-xs-plus-4,
.col-xs-plus-5,
.col-xs-plus-6,
.col-xs-plus-7,
.col-xs-plus-8,
.col-xs-plus-9,
.col-xs-plus-10,
.col-xs-plus-11,
.col-xs-plus-12,
.col-xs-plus-auto,
.col-xxl,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl-auto {
    position: relative;
    width: 100%;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto,
.col-xs-md,
.col-xs-md-1,
.col-xs-md-2,
.col-xs-md-3,
.col-xs-md-4,
.col-xs-md-5,
.col-xs-md-6,
.col-xs-md-7,
.col-xs-md-8,
.col-xs-md-9,
.col-xs-md-10,
.col-xs-md-11,
.col-xs-md-12,
.col-xs-md-auto,
.col-xs-plus,
.col-xs-plus-1,
.col-xs-plus-2,
.col-xs-plus-3,
.col-xs-plus-4,
.col-xs-plus-5,
.col-xs-plus-6,
.col-xs-plus-7,
.col-xs-plus-8,
.col-xs-plus-9,
.col-xs-plus-10,
.col-xs-plus-11,
.col-xs-plus-12,
.col-xs-plus-auto,
.col-xxl,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl-auto,
[dir='rtl'] .col,
[dir='rtl'] .col-1,
[dir='rtl'] .col-2,
[dir='rtl'] .col-3,
[dir='rtl'] .col-4,
[dir='rtl'] .col-5,
[dir='rtl'] .col-6,
[dir='rtl'] .col-7,
[dir='rtl'] .col-8,
[dir='rtl'] .col-9,
[dir='rtl'] .col-10,
[dir='rtl'] .col-11,
[dir='rtl'] .col-12,
[dir='rtl'] .col-auto,
[dir='rtl'] .col-lg,
[dir='rtl'] .col-lg-1,
[dir='rtl'] .col-lg-2,
[dir='rtl'] .col-lg-3,
[dir='rtl'] .col-lg-4,
[dir='rtl'] .col-lg-5,
[dir='rtl'] .col-lg-6,
[dir='rtl'] .col-lg-7,
[dir='rtl'] .col-lg-8,
[dir='rtl'] .col-lg-9,
[dir='rtl'] .col-lg-10,
[dir='rtl'] .col-lg-11,
[dir='rtl'] .col-lg-12,
[dir='rtl'] .col-lg-auto,
[dir='rtl'] .col-md,
[dir='rtl'] .col-md-1,
[dir='rtl'] .col-md-2,
[dir='rtl'] .col-md-3,
[dir='rtl'] .col-md-4,
[dir='rtl'] .col-md-5,
[dir='rtl'] .col-md-6,
[dir='rtl'] .col-md-7,
[dir='rtl'] .col-md-8,
[dir='rtl'] .col-md-9,
[dir='rtl'] .col-md-10,
[dir='rtl'] .col-md-11,
[dir='rtl'] .col-md-12,
[dir='rtl'] .col-md-auto,
[dir='rtl'] .col-sm,
[dir='rtl'] .col-sm-1,
[dir='rtl'] .col-sm-2,
[dir='rtl'] .col-sm-3,
[dir='rtl'] .col-sm-4,
[dir='rtl'] .col-sm-5,
[dir='rtl'] .col-sm-6,
[dir='rtl'] .col-sm-7,
[dir='rtl'] .col-sm-8,
[dir='rtl'] .col-sm-9,
[dir='rtl'] .col-sm-10,
[dir='rtl'] .col-sm-11,
[dir='rtl'] .col-sm-12,
[dir='rtl'] .col-sm-auto,
[dir='rtl'] .col-xl,
[dir='rtl'] .col-xl-1,
[dir='rtl'] .col-xl-2,
[dir='rtl'] .col-xl-3,
[dir='rtl'] .col-xl-4,
[dir='rtl'] .col-xl-5,
[dir='rtl'] .col-xl-6,
[dir='rtl'] .col-xl-7,
[dir='rtl'] .col-xl-8,
[dir='rtl'] .col-xl-9,
[dir='rtl'] .col-xl-10,
[dir='rtl'] .col-xl-11,
[dir='rtl'] .col-xl-12,
[dir='rtl'] .col-xl-auto,
[dir='rtl'] .col-xs-md,
[dir='rtl'] .col-xs-md-1,
[dir='rtl'] .col-xs-md-2,
[dir='rtl'] .col-xs-md-3,
[dir='rtl'] .col-xs-md-4,
[dir='rtl'] .col-xs-md-5,
[dir='rtl'] .col-xs-md-6,
[dir='rtl'] .col-xs-md-7,
[dir='rtl'] .col-xs-md-8,
[dir='rtl'] .col-xs-md-9,
[dir='rtl'] .col-xs-md-10,
[dir='rtl'] .col-xs-md-11,
[dir='rtl'] .col-xs-md-12,
[dir='rtl'] .col-xs-md-auto,
[dir='rtl'] .col-xs-plus,
[dir='rtl'] .col-xs-plus-1,
[dir='rtl'] .col-xs-plus-2,
[dir='rtl'] .col-xs-plus-3,
[dir='rtl'] .col-xs-plus-4,
[dir='rtl'] .col-xs-plus-5,
[dir='rtl'] .col-xs-plus-6,
[dir='rtl'] .col-xs-plus-7,
[dir='rtl'] .col-xs-plus-8,
[dir='rtl'] .col-xs-plus-9,
[dir='rtl'] .col-xs-plus-10,
[dir='rtl'] .col-xs-plus-11,
[dir='rtl'] .col-xs-plus-12,
[dir='rtl'] .col-xs-plus-auto,
[dir='rtl'] .col-xxl,
[dir='rtl'] .col-xxl-1,
[dir='rtl'] .col-xxl-2,
[dir='rtl'] .col-xxl-3,
[dir='rtl'] .col-xxl-4,
[dir='rtl'] .col-xxl-5,
[dir='rtl'] .col-xxl-6,
[dir='rtl'] .col-xxl-7,
[dir='rtl'] .col-xxl-8,
[dir='rtl'] .col-xxl-9,
[dir='rtl'] .col-xxl-10,
[dir='rtl'] .col-xxl-11,
[dir='rtl'] .col-xxl-12,
[dir='rtl'] .col-xxl-auto {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.row-cols-1>* {
    flex: 0 0 100%;
    max-width: 100%;
}

.row-cols-2>* {
    flex: 0 0 50%;
    max-width: 50%;
}

.row-cols-3>* {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
}

.row-cols-4>* {
    flex: 0 0 25%;
    max-width: 25%;
}

.row-cols-5>* {
    flex: 0 0 20%;
    max-width: 20%;
}

.row-cols-6>* {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.col-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
}

.col-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
}

.col-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
}

.col-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
}

.col-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.order-first {
    order: -1;
}

.order-last {
    order: 13;
}

.order-0 {
    order: 0;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

.order-4 {
    order: 4;
}

.order-5 {
    order: 5;
}

.order-6 {
    order: 6;
}

.order-7 {
    order: 7;
}

.order-8 {
    order: 8;
}

.order-9 {
    order: 9;
}

.order-10 {
    order: 10;
}

.order-11 {
    order: 11;
}

.order-12 {
    order: 12;
}

.offset-1 {
    margin-left: 8.3333333333%;
}

[dir='rtl'] .offset-1 {
    margin-right: 8.3333333333%;
}

.offset-2 {
    margin-left: 16.6666666667%;
}

[dir='rtl'] .offset-2 {
    margin-right: 16.6666666667%;
}

.offset-3 {
    margin-left: 25%;
}

[dir='rtl'] .offset-3 {
    margin-right: 25%;
}

.offset-4 {
    margin-left: 33.3333333333%;
}

[dir='rtl'] .offset-4 {
    margin-right: 33.3333333333%;
}

.offset-5 {
    margin-left: 41.6666666667%;
}

[dir='rtl'] .offset-5 {
    margin-right: 41.6666666667%;
}

.offset-6 {
    margin-left: 50%;
}

[dir='rtl'] .offset-6 {
    margin-right: 50%;
}

.offset-7 {
    margin-left: 58.3333333333%;
}

[dir='rtl'] .offset-7 {
    margin-right: 58.3333333333%;
}

.offset-8 {
    margin-left: 66.6666666667%;
}

[dir='rtl'] .offset-8 {
    margin-right: 66.6666666667%;
}

.offset-9 {
    margin-left: 75%;
}

[dir='rtl'] .offset-9 {
    margin-right: 75%;
}

.offset-10 {
    margin-left: 83.3333333333%;
}

[dir='rtl'] .offset-10 {
    margin-right: 83.3333333333%;
}

.offset-11 {
    margin-left: 91.6666666667%;
}

[dir='rtl'] .offset-11 {
    margin-right: 91.6666666667%;
}

@media (min-width: 374px) {
    .col-xs-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-xs-md-1>* {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-xs-md-2>* {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-xs-md-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .row-cols-xs-md-4>* {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-xs-md-5>* {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-xs-md-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-xs-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-xs-md-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-xs-md-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-xs-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xs-md-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-xs-md-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-xs-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xs-md-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-xs-md-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-xs-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xs-md-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-xs-md-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-xs-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xs-md-first {
        order: -1;
    }

    .order-xs-md-last {
        order: 13;
    }

    .order-xs-md-0 {
        order: 0;
    }

    .order-xs-md-1 {
        order: 1;
    }

    .order-xs-md-2 {
        order: 2;
    }

    .order-xs-md-3 {
        order: 3;
    }

    .order-xs-md-4 {
        order: 4;
    }

    .order-xs-md-5 {
        order: 5;
    }

    .order-xs-md-6 {
        order: 6;
    }

    .order-xs-md-7 {
        order: 7;
    }

    .order-xs-md-8 {
        order: 8;
    }

    .order-xs-md-9 {
        order: 9;
    }

    .order-xs-md-10 {
        order: 10;
    }

    .order-xs-md-11 {
        order: 11;
    }

    .order-xs-md-12 {
        order: 12;
    }

    .offset-xs-md-0 {
        margin-left: 0;
    }

    [dir='rtl'] .offset-xs-md-0 {
        margin-right: 0;
    }

    .offset-xs-md-1 {
        margin-left: 8.3333333333%;
    }

    [dir='rtl'] .offset-xs-md-1 {
        margin-right: 8.3333333333%;
    }

    .offset-xs-md-2 {
        margin-left: 16.6666666667%;
    }

    [dir='rtl'] .offset-xs-md-2 {
        margin-right: 16.6666666667%;
    }

    .offset-xs-md-3 {
        margin-left: 25%;
    }

    [dir='rtl'] .offset-xs-md-3 {
        margin-right: 25%;
    }

    .offset-xs-md-4 {
        margin-left: 33.3333333333%;
    }

    [dir='rtl'] .offset-xs-md-4 {
        margin-right: 33.3333333333%;
    }

    .offset-xs-md-5 {
        margin-left: 41.6666666667%;
    }

    [dir='rtl'] .offset-xs-md-5 {
        margin-right: 41.6666666667%;
    }

    .offset-xs-md-6 {
        margin-left: 50%;
    }

    [dir='rtl'] .offset-xs-md-6 {
        margin-right: 50%;
    }

    .offset-xs-md-7 {
        margin-left: 58.3333333333%;
    }

    [dir='rtl'] .offset-xs-md-7 {
        margin-right: 58.3333333333%;
    }

    .offset-xs-md-8 {
        margin-left: 66.6666666667%;
    }

    [dir='rtl'] .offset-xs-md-8 {
        margin-right: 66.6666666667%;
    }

    .offset-xs-md-9 {
        margin-left: 75%;
    }

    [dir='rtl'] .offset-xs-md-9 {
        margin-right: 75%;
    }

    .offset-xs-md-10 {
        margin-left: 83.3333333333%;
    }

    [dir='rtl'] .offset-xs-md-10 {
        margin-right: 83.3333333333%;
    }

    .offset-xs-md-11 {
        margin-left: 91.6666666667%;
    }

    [dir='rtl'] .offset-xs-md-11 {
        margin-right: 91.6666666667%;
    }
}

@media (min-width: 413px) {
    .col-xs-plus {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-xs-plus-1>* {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-xs-plus-2>* {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-xs-plus-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .row-cols-xs-plus-4>* {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-xs-plus-5>* {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-xs-plus-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-xs-plus-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-xs-plus-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-xs-plus-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-xs-plus-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xs-plus-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-xs-plus-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-xs-plus-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xs-plus-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-xs-plus-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-xs-plus-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xs-plus-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-xs-plus-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-xs-plus-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xs-plus-first {
        order: -1;
    }

    .order-xs-plus-last {
        order: 13;
    }

    .order-xs-plus-0 {
        order: 0;
    }

    .order-xs-plus-1 {
        order: 1;
    }

    .order-xs-plus-2 {
        order: 2;
    }

    .order-xs-plus-3 {
        order: 3;
    }

    .order-xs-plus-4 {
        order: 4;
    }

    .order-xs-plus-5 {
        order: 5;
    }

    .order-xs-plus-6 {
        order: 6;
    }

    .order-xs-plus-7 {
        order: 7;
    }

    .order-xs-plus-8 {
        order: 8;
    }

    .order-xs-plus-9 {
        order: 9;
    }

    .order-xs-plus-10 {
        order: 10;
    }

    .order-xs-plus-11 {
        order: 11;
    }

    .order-xs-plus-12 {
        order: 12;
    }

    .offset-xs-plus-0 {
        margin-left: 0;
    }

    [dir='rtl'] .offset-xs-plus-0 {
        margin-right: 0;
    }

    .offset-xs-plus-1 {
        margin-left: 8.3333333333%;
    }

    [dir='rtl'] .offset-xs-plus-1 {
        margin-right: 8.3333333333%;
    }

    .offset-xs-plus-2 {
        margin-left: 16.6666666667%;
    }

    [dir='rtl'] .offset-xs-plus-2 {
        margin-right: 16.6666666667%;
    }

    .offset-xs-plus-3 {
        margin-left: 25%;
    }

    [dir='rtl'] .offset-xs-plus-3 {
        margin-right: 25%;
    }

    .offset-xs-plus-4 {
        margin-left: 33.3333333333%;
    }

    [dir='rtl'] .offset-xs-plus-4 {
        margin-right: 33.3333333333%;
    }

    .offset-xs-plus-5 {
        margin-left: 41.6666666667%;
    }

    [dir='rtl'] .offset-xs-plus-5 {
        margin-right: 41.6666666667%;
    }

    .offset-xs-plus-6 {
        margin-left: 50%;
    }

    [dir='rtl'] .offset-xs-plus-6 {
        margin-right: 50%;
    }

    .offset-xs-plus-7 {
        margin-left: 58.3333333333%;
    }

    [dir='rtl'] .offset-xs-plus-7 {
        margin-right: 58.3333333333%;
    }

    .offset-xs-plus-8 {
        margin-left: 66.6666666667%;
    }

    [dir='rtl'] .offset-xs-plus-8 {
        margin-right: 66.6666666667%;
    }

    .offset-xs-plus-9 {
        margin-left: 75%;
    }

    [dir='rtl'] .offset-xs-plus-9 {
        margin-right: 75%;
    }

    .offset-xs-plus-10 {
        margin-left: 83.3333333333%;
    }

    [dir='rtl'] .offset-xs-plus-10 {
        margin-right: 83.3333333333%;
    }

    .offset-xs-plus-11 {
        margin-left: 91.6666666667%;
    }

    [dir='rtl'] .offset-xs-plus-11 {
        margin-right: 91.6666666667%;
    }
}

@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-sm-1>* {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-sm-2>* {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-sm-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .row-cols-sm-4>* {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-sm-5>* {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-sm-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-sm-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-sm-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-sm-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-sm-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-sm-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-sm-first {
        order: -1;
    }

    .order-sm-last {
        order: 13;
    }

    .order-sm-0 {
        order: 0;
    }

    .order-sm-1 {
        order: 1;
    }

    .order-sm-2 {
        order: 2;
    }

    .order-sm-3 {
        order: 3;
    }

    .order-sm-4 {
        order: 4;
    }

    .order-sm-5 {
        order: 5;
    }

    .order-sm-6 {
        order: 6;
    }

    .order-sm-7 {
        order: 7;
    }

    .order-sm-8 {
        order: 8;
    }

    .order-sm-9 {
        order: 9;
    }

    .order-sm-10 {
        order: 10;
    }

    .order-sm-11 {
        order: 11;
    }

    .order-sm-12 {
        order: 12;
    }

    .offset-sm-0 {
        margin-left: 0;
    }

    [dir='rtl'] .offset-sm-0 {
        margin-right: 0;
    }

    .offset-sm-1 {
        margin-left: 8.3333333333%;
    }

    [dir='rtl'] .offset-sm-1 {
        margin-right: 8.3333333333%;
    }

    .offset-sm-2 {
        margin-left: 16.6666666667%;
    }

    [dir='rtl'] .offset-sm-2 {
        margin-right: 16.6666666667%;
    }

    .offset-sm-3 {
        margin-left: 25%;
    }

    [dir='rtl'] .offset-sm-3 {
        margin-right: 25%;
    }

    .offset-sm-4 {
        margin-left: 33.3333333333%;
    }

    [dir='rtl'] .offset-sm-4 {
        margin-right: 33.3333333333%;
    }

    .offset-sm-5 {
        margin-left: 41.6666666667%;
    }

    [dir='rtl'] .offset-sm-5 {
        margin-right: 41.6666666667%;
    }

    .offset-sm-6 {
        margin-left: 50%;
    }

    [dir='rtl'] .offset-sm-6 {
        margin-right: 50%;
    }

    .offset-sm-7 {
        margin-left: 58.3333333333%;
    }

    [dir='rtl'] .offset-sm-7 {
        margin-right: 58.3333333333%;
    }

    .offset-sm-8 {
        margin-left: 66.6666666667%;
    }

    [dir='rtl'] .offset-sm-8 {
        margin-right: 66.6666666667%;
    }

    .offset-sm-9 {
        margin-left: 75%;
    }

    [dir='rtl'] .offset-sm-9 {
        margin-right: 75%;
    }

    .offset-sm-10 {
        margin-left: 83.3333333333%;
    }

    [dir='rtl'] .offset-sm-10 {
        margin-right: 83.3333333333%;
    }

    .offset-sm-11 {
        margin-left: 91.6666666667%;
    }

    [dir='rtl'] .offset-sm-11 {
        margin-right: 91.6666666667%;
    }
}

@media (min-width: 768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-md-1>* {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-md-2>* {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-md-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .row-cols-md-4>* {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-md-5>* {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-md-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-md-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-md-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-md-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-md-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-md-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-md-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-md-first {
        order: -1;
    }

    .order-md-last {
        order: 13;
    }

    .order-md-0 {
        order: 0;
    }

    .order-md-1 {
        order: 1;
    }

    .order-md-2 {
        order: 2;
    }

    .order-md-3 {
        order: 3;
    }

    .order-md-4 {
        order: 4;
    }

    .order-md-5 {
        order: 5;
    }

    .order-md-6 {
        order: 6;
    }

    .order-md-7 {
        order: 7;
    }

    .order-md-8 {
        order: 8;
    }

    .order-md-9 {
        order: 9;
    }

    .order-md-10 {
        order: 10;
    }

    .order-md-11 {
        order: 11;
    }

    .order-md-12 {
        order: 12;
    }

    .offset-md-0 {
        margin-left: 0;
    }

    [dir='rtl'] .offset-md-0 {
        margin-right: 0;
    }

    .offset-md-1 {
        margin-left: 8.3333333333%;
    }

    [dir='rtl'] .offset-md-1 {
        margin-right: 8.3333333333%;
    }

    .offset-md-2 {
        margin-left: 16.6666666667%;
    }

    [dir='rtl'] .offset-md-2 {
        margin-right: 16.6666666667%;
    }

    .offset-md-3 {
        margin-left: 25%;
    }

    [dir='rtl'] .offset-md-3 {
        margin-right: 25%;
    }

    .offset-md-4 {
        margin-left: 33.3333333333%;
    }

    [dir='rtl'] .offset-md-4 {
        margin-right: 33.3333333333%;
    }

    .offset-md-5 {
        margin-left: 41.6666666667%;
    }

    [dir='rtl'] .offset-md-5 {
        margin-right: 41.6666666667%;
    }

    .offset-md-6 {
        margin-left: 50%;
    }

    [dir='rtl'] .offset-md-6 {
        margin-right: 50%;
    }

    .offset-md-7 {
        margin-left: 58.3333333333%;
    }

    [dir='rtl'] .offset-md-7 {
        margin-right: 58.3333333333%;
    }

    .offset-md-8 {
        margin-left: 66.6666666667%;
    }

    [dir='rtl'] .offset-md-8 {
        margin-right: 66.6666666667%;
    }

    .offset-md-9 {
        margin-left: 75%;
    }

    [dir='rtl'] .offset-md-9 {
        margin-right: 75%;
    }

    .offset-md-10 {
        margin-left: 83.3333333333%;
    }

    [dir='rtl'] .offset-md-10 {
        margin-right: 83.3333333333%;
    }

    .offset-md-11 {
        margin-left: 91.6666666667%;
    }

    [dir='rtl'] .offset-md-11 {
        margin-right: 91.6666666667%;
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-lg-1>* {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-lg-2>* {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-lg-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .row-cols-lg-4>* {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-lg-5>* {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-lg-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-lg-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-lg-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-lg-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-lg-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-lg-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-lg-first {
        order: -1;
    }

    .order-lg-last {
        order: 13;
    }

    .order-lg-0 {
        order: 0;
    }

    .order-lg-1 {
        order: 1;
    }

    .order-lg-2 {
        order: 2;
    }

    .order-lg-3 {
        order: 3;
    }

    .order-lg-4 {
        order: 4;
    }

    .order-lg-5 {
        order: 5;
    }

    .order-lg-6 {
        order: 6;
    }

    .order-lg-7 {
        order: 7;
    }

    .order-lg-8 {
        order: 8;
    }

    .order-lg-9 {
        order: 9;
    }

    .order-lg-10 {
        order: 10;
    }

    .order-lg-11 {
        order: 11;
    }

    .order-lg-12 {
        order: 12;
    }

    .offset-lg-0 {
        margin-left: 0;
    }

    [dir='rtl'] .offset-lg-0 {
        margin-right: 0;
    }

    .offset-lg-1 {
        margin-left: 8.3333333333%;
    }

    [dir='rtl'] .offset-lg-1 {
        margin-right: 8.3333333333%;
    }

    .offset-lg-2 {
        margin-left: 16.6666666667%;
    }

    [dir='rtl'] .offset-lg-2 {
        margin-right: 16.6666666667%;
    }

    .offset-lg-3 {
        margin-left: 25%;
    }

    [dir='rtl'] .offset-lg-3 {
        margin-right: 25%;
    }

    .offset-lg-4 {
        margin-left: 33.3333333333%;
    }

    [dir='rtl'] .offset-lg-4 {
        margin-right: 33.3333333333%;
    }

    .offset-lg-5 {
        margin-left: 41.6666666667%;
    }

    [dir='rtl'] .offset-lg-5 {
        margin-right: 41.6666666667%;
    }

    .offset-lg-6 {
        margin-left: 50%;
    }

    [dir='rtl'] .offset-lg-6 {
        margin-right: 50%;
    }

    .offset-lg-7 {
        margin-left: 58.3333333333%;
    }

    [dir='rtl'] .offset-lg-7 {
        margin-right: 58.3333333333%;
    }

    .offset-lg-8 {
        margin-left: 66.6666666667%;
    }

    [dir='rtl'] .offset-lg-8 {
        margin-right: 66.6666666667%;
    }

    .offset-lg-9 {
        margin-left: 75%;
    }

    [dir='rtl'] .offset-lg-9 {
        margin-right: 75%;
    }

    .offset-lg-10 {
        margin-left: 83.3333333333%;
    }

    [dir='rtl'] .offset-lg-10 {
        margin-right: 83.3333333333%;
    }

    .offset-lg-11 {
        margin-left: 91.6666666667%;
    }

    [dir='rtl'] .offset-lg-11 {
        margin-right: 91.6666666667%;
    }
}

@media (min-width: 1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-xl-1>* {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-xl-2>* {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-xl-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .row-cols-xl-4>* {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-xl-5>* {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-xl-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-xl-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-xl-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xl-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-xl-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xl-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-xl-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xl-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-xl-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xl-first {
        order: -1;
    }

    .order-xl-last {
        order: 13;
    }

    .order-xl-0 {
        order: 0;
    }

    .order-xl-1 {
        order: 1;
    }

    .order-xl-2 {
        order: 2;
    }

    .order-xl-3 {
        order: 3;
    }

    .order-xl-4 {
        order: 4;
    }

    .order-xl-5 {
        order: 5;
    }

    .order-xl-6 {
        order: 6;
    }

    .order-xl-7 {
        order: 7;
    }

    .order-xl-8 {
        order: 8;
    }

    .order-xl-9 {
        order: 9;
    }

    .order-xl-10 {
        order: 10;
    }

    .order-xl-11 {
        order: 11;
    }

    .order-xl-12 {
        order: 12;
    }

    .offset-xl-0 {
        margin-left: 0;
    }

    [dir='rtl'] .offset-xl-0 {
        margin-right: 0;
    }

    .offset-xl-1 {
        margin-left: 8.3333333333%;
    }

    [dir='rtl'] .offset-xl-1 {
        margin-right: 8.3333333333%;
    }

    .offset-xl-2 {
        margin-left: 16.6666666667%;
    }

    [dir='rtl'] .offset-xl-2 {
        margin-right: 16.6666666667%;
    }

    .offset-xl-3 {
        margin-left: 25%;
    }

    [dir='rtl'] .offset-xl-3 {
        margin-right: 25%;
    }

    .offset-xl-4 {
        margin-left: 33.3333333333%;
    }

    [dir='rtl'] .offset-xl-4 {
        margin-right: 33.3333333333%;
    }

    .offset-xl-5 {
        margin-left: 41.6666666667%;
    }

    [dir='rtl'] .offset-xl-5 {
        margin-right: 41.6666666667%;
    }

    .offset-xl-6 {
        margin-left: 50%;
    }

    [dir='rtl'] .offset-xl-6 {
        margin-right: 50%;
    }

    .offset-xl-7 {
        margin-left: 58.3333333333%;
    }

    [dir='rtl'] .offset-xl-7 {
        margin-right: 58.3333333333%;
    }

    .offset-xl-8 {
        margin-left: 66.6666666667%;
    }

    [dir='rtl'] .offset-xl-8 {
        margin-right: 66.6666666667%;
    }

    .offset-xl-9 {
        margin-left: 75%;
    }

    [dir='rtl'] .offset-xl-9 {
        margin-right: 75%;
    }

    .offset-xl-10 {
        margin-left: 83.3333333333%;
    }

    [dir='rtl'] .offset-xl-10 {
        margin-right: 83.3333333333%;
    }

    .offset-xl-11 {
        margin-left: 91.6666666667%;
    }

    [dir='rtl'] .offset-xl-11 {
        margin-right: 91.6666666667%;
    }
}

@media (min-width: 1366px) {
    .col-xxl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-xxl-1>* {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-xxl-2>* {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-xxl-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .row-cols-xxl-4>* {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-xxl-5>* {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-xxl-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-xxl-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-xxl-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-xxl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xxl-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-xxl-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-xxl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xxl-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-xxl-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-xxl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xxl-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-xxl-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-xxl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xxl-first {
        order: -1;
    }

    .order-xxl-last {
        order: 13;
    }

    .order-xxl-0 {
        order: 0;
    }

    .order-xxl-1 {
        order: 1;
    }

    .order-xxl-2 {
        order: 2;
    }

    .order-xxl-3 {
        order: 3;
    }

    .order-xxl-4 {
        order: 4;
    }

    .order-xxl-5 {
        order: 5;
    }

    .order-xxl-6 {
        order: 6;
    }

    .order-xxl-7 {
        order: 7;
    }

    .order-xxl-8 {
        order: 8;
    }

    .order-xxl-9 {
        order: 9;
    }

    .order-xxl-10 {
        order: 10;
    }

    .order-xxl-11 {
        order: 11;
    }

    .order-xxl-12 {
        order: 12;
    }

    .offset-xxl-0 {
        margin-left: 0;
    }

    [dir='rtl'] .offset-xxl-0 {
        margin-right: 0;
    }

    .offset-xxl-1 {
        margin-left: 8.3333333333%;
    }

    [dir='rtl'] .offset-xxl-1 {
        margin-right: 8.3333333333%;
    }

    .offset-xxl-2 {
        margin-left: 16.6666666667%;
    }

    [dir='rtl'] .offset-xxl-2 {
        margin-right: 16.6666666667%;
    }

    .offset-xxl-3 {
        margin-left: 25%;
    }

    [dir='rtl'] .offset-xxl-3 {
        margin-right: 25%;
    }

    .offset-xxl-4 {
        margin-left: 33.3333333333%;
    }

    [dir='rtl'] .offset-xxl-4 {
        margin-right: 33.3333333333%;
    }

    .offset-xxl-5 {
        margin-left: 41.6666666667%;
    }

    [dir='rtl'] .offset-xxl-5 {
        margin-right: 41.6666666667%;
    }

    .offset-xxl-6 {
        margin-left: 50%;
    }

    [dir='rtl'] .offset-xxl-6 {
        margin-right: 50%;
    }

    .offset-xxl-7 {
        margin-left: 58.3333333333%;
    }

    [dir='rtl'] .offset-xxl-7 {
        margin-right: 58.3333333333%;
    }

    .offset-xxl-8 {
        margin-left: 66.6666666667%;
    }

    [dir='rtl'] .offset-xxl-8 {
        margin-right: 66.6666666667%;
    }

    .offset-xxl-9 {
        margin-left: 75%;
    }

    [dir='rtl'] .offset-xxl-9 {
        margin-right: 75%;
    }

    .offset-xxl-10 {
        margin-left: 83.3333333333%;
    }

    [dir='rtl'] .offset-xxl-10 {
        margin-right: 83.3333333333%;
    }

    .offset-xxl-11 {
        margin-left: 91.6666666667%;
    }

    [dir='rtl'] .offset-xxl-11 {
        margin-right: 91.6666666667%;
    }
}

.table {
    width: 100%;
    color: #383b3d;
}

.table {
    margin-bottom: 1rem;
}

.table td,
.table th {
    vertical-align: top;
}

.table td,
.table th {
    padding: 0.5rem 0.75rem;
    border-top: 1px solid #f0f1f2;
}

.table thead th {
    vertical-align: bottom;
}

.table thead th {
    border-bottom: 2px solid #f0f1f2;
}

.table tbody+tbody {
    border-top: 2px solid #f0f1f2;
}

.table-sm td,
.table-sm th {
    padding: 0.3rem;
}

.table-bordered,
.table-bordered td,
.table-bordered th {
    border: 1px solid #f0f1f2;
}

.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 2px;
}

.table-borderless tbody+tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
    color: #383b3d;
}

.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary>td,
.table-primary>th {
    background-color: #c1e2fc;
}

.table-primary tbody+tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
    border-color: #8cc8f9;
}

.table-hover .table-primary:hover,
.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #a9d7fb;
}

.table-secondary,
.table-secondary>td,
.table-secondary>th {
    background-color: #d6d8db;
}

.table-secondary tbody+tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
    border-color: #b2b7bb;
}

.table-hover .table-secondary:hover,
.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #c8cbcf;
}

.table-success,
.table-success>td,
.table-success>th {
    background-color: #d4ecd5;
}

.table-success tbody+tbody,
.table-success td,
.table-success th,
.table-success thead th {
    border-color: #afdcb2;
}

.table-hover .table-success:hover,
.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #c2e4c4;
}

.table-info,
.table-info>td,
.table-info>th {
    background-color: #c3ebfc;
}

.table-info tbody+tbody,
.table-info td,
.table-info th,
.table-info thead th {
    border-color: #90d9fa;
}

.table-hover .table-info:hover,
.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #abe3fb;
}

.table-warning,
.table-warning>td,
.table-warning>th {
    background-color: #ffe6c2;
}

.table-warning tbody+tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
    border-color: #ffd18e;
}

.table-hover .table-warning:hover,
.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #ffdca9;
}

.table-danger,
.table-danger>td,
.table-danger>th {
    background-color: #fccac7;
}

.table-danger tbody+tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
    border-color: #f99d96;
}

.table-hover .table-danger:hover,
.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #fbb3af;
}

.table-light,
.table-light>td,
.table-light>th {
    background-color: #fdfdfe;
}

.table-light tbody+tbody,
.table-light td,
.table-light th,
.table-light thead th {
    border-color: #fbfcfc;
}

.table-hover .table-light:hover,
.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #ececf6;
}

.table-dark,
.table-dark>td,
.table-dark>th {
    background-color: #c8cbcd;
}

.table-dark tbody+tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #989ea2;
}

.table-hover .table-dark:hover,
.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #bbbec1;
}

.table-active,
.table-active>td,
.table-active>th,
.table-hover .table-active:hover,
.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
    color: #fff;
}

.table .thead-dark th {
    background-color: #343a40;
    border-color: #454d55;
}

.table .thead-light th {
    color: #b8bdc2;
}

.table .thead-light th {
    background-color: #f5f6f7;
    border-color: #f0f1f2;
}

.table-dark {
    color: #fff;
}

.table-dark {
    background-color: #343a40;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #454d55;
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: hsla(0, 0%, 100%, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
}

.table-dark.table-hover tbody tr:hover {
    background-color: hsla(0, 0%, 100%, 0.075);
}

@media (max-width: 373.98px) {
    .table-responsive-xs-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-xs-md>.table-bordered {
        border: 0;
    }
}

@media (max-width: 412.98px) {
    .table-responsive-xs-plus {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-xs-plus>.table-bordered {
        border: 0;
    }
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-sm>.table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-md>.table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-lg>.table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-xl>.table-bordered {
        border: 0;
    }
}

@media (max-width: 1365.98px) {
    .table-responsive-xxl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-xxl>.table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
    border: 0;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 1rem + 2px);
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control {
    padding: 0.5rem 0.75rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #f0f1f2;
    border-radius: 0.25rem;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none;
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:-moz-focusring {
    color: transparent;
}

.form-control:-moz-focusring {
    text-shadow: 0 0 0 #495057;
}

.form-control:focus {
    color: #495057;
    outline: 0;
}

.form-control:focus {
    background-color: #fff;
    border-color: #9acffa;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
}

.form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
}

input[type='date'].form-control,
input[type='datetime-local'].form-control,
input[type='month'].form-control,
input[type='time'].form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select.form-control:focus::-ms-value {
    color: #495057;
}

select.form-control:focus::-ms-value {
    background-color: #fff;
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}

.col-form-label {
    font-size: inherit;
    line-height: 1.5;
}

.col-form-label {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    margin-bottom: 0;
}

.col-form-label-lg {
    font-size: 1.171875rem;
    line-height: 1.5;
}

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
}

.col-form-label-sm {
    font-size: 0.8203125rem;
    line-height: 1.5;
}

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
}

.form-control-plaintext {
    display: block;
    width: 100%;
    font-size: 0.9375rem;
    line-height: 1.5;
    color: #383b3d;
}

.form-control-plaintext {
    padding: 0.5rem 0;
    margin-bottom: 0;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm,
[dir='rtl'] .form-control-plaintext.form-control-lg,
[dir='rtl'] .form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    font-size: 0.8203125rem;
    line-height: 1.5;
}

.form-control-sm {
    padding: 0.25rem 0.5rem;
    border-radius: 0.2rem;
}

.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    font-size: 1.171875rem;
    line-height: 1.5;
}

.form-control-lg {
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
}

select.form-control[multiple],
select.form-control[size],
textarea.form-control {
    height: auto;
}

.form-group {
    margin-bottom: 1rem;
}

.form-text {
    display: block;
}

.form-text {
    margin-top: 0.25rem;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
}

.form-row,
[dir='rtl'] .form-row {
    margin-right: -5px;
    margin-left: -5px;
}

.form-row>.col,
.form-row>[class*='col-'],
[dir='rtl'] .form-row>.col,
[dir='rtl'] .form-row>[class*='col-'] {
    padding-right: 5px;
    padding-left: 5px;
}

.form-check {
    position: relative;
    display: block;
}

.form-check {
    padding-left: 1.25rem;
}

[dir='rtl'] .form-check {
    padding-right: 1.25rem;
}

.form-check-input {
    position: absolute;
}

.form-check-input {
    margin-top: 0.3rem;
}

.form-check-input {
    margin-left: -1.25rem;
}

[dir='rtl'] .form-check-input {
    margin-right: -1.25rem;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    color: #b8bdc2;
}

.form-check-label {
    margin-bottom: 0;
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
}

.form-check-inline {
    padding-left: 0;
    margin-right: 0.75rem;
}

[dir='rtl'] .form-check-inline {
    padding-right: 0;
    margin-left: 0.75rem;
}

.form-check-inline .form-check-input {
    position: static;
}

.form-check-inline .form-check-input {
    margin-top: 0;
}

.form-check-inline .form-check-input {
    margin-right: 0.3125rem;
    margin-left: 0;
}

[dir='rtl'] .form-check-inline .form-check-input {
    margin-left: 0.3125rem;
    margin-right: 0;
}

.valid-feedback {
    display: none;
    width: 100%;
    font-size: 0.75rem;
    color: #66bb6a;
}

.valid-feedback {
    margin-top: 0.25rem;
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    font-size: 0.8203125rem;
    line-height: 1.5;
    color: #212529;
}

.valid-tooltip {
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    background-color: rgba(102, 187, 106, 0.9);
    border-radius: 0.25rem;
}

.valid-tooltip {
    left: 0;
}

[dir='rtl'] .valid-tooltip {
    right: 0;
}

.is-valid~.valid-feedback,
.is-valid~.valid-tooltip,
.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip {
    display: block;
}

.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: #66bb6a;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%2366BB6A' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}

.form-control.is-valid,
.was-validated .form-control:valid {
    padding-right: calc(1.5em + 1rem);
    background-position: right calc(0.375em + 0.25rem) center;
}

[dir='rtl'] .form-control.is-valid,
[dir='rtl'] .was-validated .form-control:valid {
    padding-left: calc(1.5em + 1rem);
    background-position: left calc(0.375em + 0.25rem) center;
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #66bb6a;
    box-shadow: 0 0 0 0.2rem rgba(102, 187, 106, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: calc(1.5em + 1rem);
    background-position: top calc(0.375em + 0.25rem) right calc(0.375em + 0.25rem);
}

[dir='rtl'] .was-validated textarea.form-control:valid,
[dir='rtl'] textarea.form-control.is-valid {
    padding-left: calc(1.5em + 1rem);
    background-position: top calc(0.375em + 0.25rem) left calc(0.375em + 0.25rem);
}

.custom-select.is-valid,
.was-validated .custom-select:valid {
    border-color: #66bb6a;
}

.custom-select.is-valid,
.was-validated .custom-select:valid {
    padding-right: calc(0.75em + 2.5rem);
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px,
        url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%2366BB6A' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E") #fff no-repeat center right 1.75rem / calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}

[dir='rtl'] .custom-select.is-valid,
[dir='rtl'] .was-validated .custom-select:valid {
    padding-left: calc(0.75em + 2.5rem);
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat left 0.75rem center/8px 10px,
        url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%2366BB6A' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E") #fff no-repeat center left 1.75rem / calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}

.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
    border-color: #66bb6a;
    box-shadow: 0 0 0 0.2rem rgba(102, 187, 106, 0.25);
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #66bb6a;
}

.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
    display: block;
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
    color: #66bb6a;
}

.custom-control-input.is-valid~.custom-control-label:before,
.was-validated .custom-control-input:valid~.custom-control-label:before {
    border-color: #66bb6a;
}

.custom-control-input.is-valid:checked~.custom-control-label:before,
.was-validated .custom-control-input:valid:checked~.custom-control-label:before {
    border-color: #89cb8c;
    background-color: #89cb8c;
}

.custom-control-input.is-valid:focus~.custom-control-label:before,
.was-validated .custom-control-input:valid:focus~.custom-control-label:before {
    box-shadow: 0 0 0 0.2rem rgba(102, 187, 106, 0.25);
}

.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label:before,
.custom-file-input.is-valid~.custom-file-label,
.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label:before,
.was-validated .custom-file-input:valid~.custom-file-label {
    border-color: #66bb6a;
}

.custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .custom-file-input:valid:focus~.custom-file-label {
    border-color: #66bb6a;
    box-shadow: 0 0 0 0.2rem rgba(102, 187, 106, 0.25);
}

.invalid-feedback {
    display: none;
    width: 100%;
    font-size: 0.75rem;
    color: #f44336;
}

.invalid-feedback {
    margin-top: 0.25rem;
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    font-size: 0.8203125rem;
    line-height: 1.5;
    color: #fff;
}

.invalid-tooltip {
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    background-color: rgba(244, 67, 54, 0.9);
    border-radius: 0.25rem;
}

.invalid-tooltip {
    left: 0;
}

[dir='rtl'] .invalid-tooltip {
    right: 0;
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
    display: block;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #f44336;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F44336'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23F44336' stroke='none'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    padding-right: calc(1.5em + 1rem);
    background-position: right calc(0.375em + 0.25rem) center;
}

[dir='rtl'] .form-control.is-invalid,
[dir='rtl'] .was-validated .form-control:invalid {
    padding-left: calc(1.5em + 1rem);
    background-position: left calc(0.375em + 0.25rem) center;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #f44336;
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 1rem);
    background-position: top calc(0.375em + 0.25rem) right calc(0.375em + 0.25rem);
}

[dir='rtl'] .was-validated textarea.form-control:invalid,
[dir='rtl'] textarea.form-control.is-invalid {
    padding-left: calc(1.5em + 1rem);
    background-position: top calc(0.375em + 0.25rem) left calc(0.375em + 0.25rem);
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
    border-color: #f44336;
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
    padding-right: calc(0.75em + 2.5rem);
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px,
        url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F44336'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23F44336' stroke='none'/%3E%3C/svg%3E") #fff no-repeat center right 1.75rem / calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}

[dir='rtl'] .custom-select.is-invalid,
[dir='rtl'] .was-validated .custom-select:invalid {
    padding-left: calc(0.75em + 2.5rem);
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat left 0.75rem center/8px 10px,
        url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F44336'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23F44336' stroke='none'/%3E%3C/svg%3E") #fff no-repeat center left 1.75rem / calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}

.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
    border-color: #f44336;
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: #f44336;
}

.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
    display: block;
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
    color: #f44336;
}

.custom-control-input.is-invalid~.custom-control-label:before,
.was-validated .custom-control-input:invalid~.custom-control-label:before {
    border-color: #f44336;
}

.custom-control-input.is-invalid:checked~.custom-control-label:before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label:before {
    border-color: #f77066;
    background-color: #f77066;
}

.custom-control-input.is-invalid:focus~.custom-control-label:before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label:before {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}

.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label:before,
.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label:before,
.was-validated .custom-file-input:invalid~.custom-file-label {
    border-color: #f44336;
}

.custom-file-input.is-invalid:focus~.custom-file-label,
.was-validated .custom-file-input:invalid:focus~.custom-file-label {
    border-color: #f44336;
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.form-inline .form-check {
    width: 100%;
}

@media (min-width: 576px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .form-inline label {
        margin-bottom: 0;
    }

    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
    }

    .form-inline .form-group {
        margin-bottom: 0;
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .form-inline .form-control-plaintext {
        display: inline-block;
    }

    .form-inline .custom-select,
    .form-inline .input-group {
        width: auto;
    }

    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
    }

    .form-inline .form-check {
        padding-left: 0;
    }

    [dir='rtl'] .form-inline .form-check {
        padding-right: 0;
    }

    .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
    }

    .form-inline .form-check-input {
        margin-top: 0;
    }

    .form-inline .form-check-input {
        margin-right: 0.25rem;
        margin-left: 0;
    }

    [dir='rtl'] .form-inline .form-check-input {
        margin-left: 0.25rem;
        margin-right: 0;
    }

    .form-inline .custom-control {
        align-items: center;
        justify-content: center;
    }

    .form-inline .custom-control-label {
        margin-bottom: 0;
    }
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #383b3d;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 0.9375rem;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

.btn {
    text-align: center;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
}

@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}

.btn:hover {
    color: #383b3d;
    text-decoration: none;
}

.btn.focus,
.btn:focus {
    outline: 0;
}

.btn.focus,
.btn:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
}

.btn.disabled,
.btn:disabled {
    opacity: 0.65;
}

.btn.disabled,
.btn:disabled {
    box-shadow: none;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

.btn-primary {
    color: #fff;
}

.btn-primary {
    background-color: var(--primary);
    border-color: var(--primary);
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-primary:hover {
    color: #fff;
}

.btn-primary:hover {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
}

.btn-primary.focus,
.btn-primary:focus {
    color: #fff;
}

.btn-primary.focus,
.btn-primary:focus {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 0 0.2rem rgba(66, 166, 245, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    background-color: var(--primary-hover);
    border-color: #0b75c9;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(66, 166, 245, 0.5);
}

.btn-secondary {
    color: #fff;
}

.btn-secondary {
    background-color: #6b757d;
    border-color: #6b757d;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-secondary:hover {
    color: #fff;
}

.btn-secondary:hover {
    background-color: #596268;
    border-color: #535b62;
}

.btn-secondary.focus,
.btn-secondary:focus {
    color: #fff;
}

.btn-secondary.focus,
.btn-secondary:focus {
    background-color: #596268;
    border-color: #535b62;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 0 0.2rem rgba(129, 138, 145, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    background-color: #6b757d;
    border-color: #6b757d;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    background-color: #535b62;
    border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(129, 138, 145, 0.5);
}

.btn-success {
    color: #212529;
}

.btn-success {
    background-color: #66bb6a;
    border-color: #66bb6a;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-success:hover {
    color: #fff;
}

.btn-success:hover {
    background-color: #4dae52;
    border-color: #49a54e;
}

.btn-success.focus,
.btn-success:focus {
    color: #fff;
}

.btn-success.focus,
.btn-success:focus {
    background-color: #4dae52;
    border-color: #49a54e;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 0 0.2rem rgba(92, 165, 96, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
    color: #212529;
}

.btn-success.disabled,
.btn-success:disabled {
    background-color: #66bb6a;
    border-color: #66bb6a;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
    background-color: #49a54e;
    border-color: #459c49;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(92, 165, 96, 0.5);
}

.btn-info {
    color: #fff;
}

.btn-info {
    background-color: #29b6f6;
    border-color: #29b6f6;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-info:hover {
    color: #fff;
}

.btn-info:hover {
    background-color: #0aa7ef;
    border-color: #0a9fe2;
}

.btn-info.focus,
.btn-info:focus {
    color: #fff;
}

.btn-info.focus,
.btn-info:focus {
    background-color: #0aa7ef;
    border-color: #0a9fe2;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 0 0.2rem rgba(73, 193, 247, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
}

.btn-info.disabled,
.btn-info:disabled {
    background-color: #29b6f6;
    border-color: #29b6f6;
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
    color: #fff;
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
    background-color: #0a9fe2;
    border-color: #0996d6;
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(73, 193, 247, 0.5);
}

.btn-warning {
    color: #212529;
}

.btn-warning {
    background-color: #ffa726;
    border-color: #ffa726;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-warning:hover {
    color: #212529;
}

.btn-warning:hover {
    background-color: #ff9700;
    border-color: #f29000;
}

.btn-warning.focus,
.btn-warning:focus {
    color: #212529;
}

.btn-warning.focus,
.btn-warning:focus {
    background-color: #ff9700;
    border-color: #f29000;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 0 0.2rem rgba(222, 148, 38, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #212529;
}

.btn-warning.disabled,
.btn-warning:disabled {
    background-color: #ffa726;
    border-color: #ffa726;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
    color: #212529;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
    background-color: #f29000;
    border-color: #e58800;
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(222, 148, 38, 0.5);
}

.btn-danger {
    color: #fff;
}

.btn-danger {
    background-color: #f44336;
    border-color: #f44336;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-danger:hover {
    color: #fff;
}

.btn-danger:hover {
    background-color: #f22112;
    border-color: #ea1c0d;
}

.btn-danger.focus,
.btn-danger:focus {
    color: #fff;
}

.btn-danger.focus,
.btn-danger:focus {
    background-color: #f22112;
    border-color: #ea1c0d;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 0 0.2rem rgba(246, 95, 84, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
}

.btn-danger.disabled,
.btn-danger:disabled {
    background-color: #f44336;
    border-color: #f44336;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
    background-color: #ea1c0d;
    border-color: #de1b0c;
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(246, 95, 84, 0.5);
}

.btn-light {
    color: #212529;
}

.btn-light {
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-light:hover {
    color: #212529;
}

.btn-light:hover {
    background-color: #e2e6ea;
    border-color: #dae0e5;
}

.btn-light.focus,
.btn-light:focus {
    color: #212529;
}

.btn-light.focus,
.btn-light:focus {
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
    color: #212529;
}

.btn-light.disabled,
.btn-light:disabled {
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
    color: #212529;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
    background-color: #dae0e5;
    border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
    color: #fff;
}

.btn-dark {
    background-color: #39444d;
    border-color: #39444d;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-dark:hover {
    color: #fff;
}

.btn-dark:hover {
    background-color: #293137;
    border-color: #232a30;
}

.btn-dark.focus,
.btn-dark:focus {
    color: #fff;
}

.btn-dark.focus,
.btn-dark:focus {
    background-color: #293137;
    border-color: #232a30;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 0 0.2rem rgba(87, 96, 104, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
}

.btn-dark.disabled,
.btn-dark:disabled {
    background-color: #39444d;
    border-color: #39444d;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
    background-color: #232a30;
    border-color: #1e2428;
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(87, 96, 104, 0.5);
}

.btn-outline-primary {
    color: var(--primary);
}

.btn-outline-primary {
    border-color: var(--primary);
}

.btn-outline-primary:hover {
    color: #fff;
}

.btn-outline-primary:hover {
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: var(--primary);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(33, 150, 243, 0.5);
}

.btn-outline-secondary {
    color: #6b757d;
}

.btn-outline-secondary {
    border-color: #6b757d;
}

.btn-outline-secondary:hover {
    color: #fff;
}

.btn-outline-secondary:hover {
    background-color: #6b757d;
    border-color: #6b757d;
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(107, 117, 125, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #6b757d;
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
    background-color: #6b757d;
    border-color: #6b757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(107, 117, 125, 0.5);
}

.btn-outline-success {
    color: #66bb6a;
}

.btn-outline-success {
    border-color: #66bb6a;
}

.btn-outline-success:hover {
    color: #212529;
}

.btn-outline-success:hover {
    background-color: #66bb6a;
    border-color: #66bb6a;
}

.btn-outline-success.focus,
.btn-outline-success:focus {
    box-shadow: 0 0 0 0.2rem rgba(102, 187, 106, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #66bb6a;
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
    color: #212529;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
    background-color: #66bb6a;
    border-color: #66bb6a;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(102, 187, 106, 0.5);
}

.btn-outline-info {
    color: #29b6f6;
}

.btn-outline-info {
    border-color: #29b6f6;
}

.btn-outline-info:hover {
    color: #fff;
}

.btn-outline-info:hover {
    background-color: #29b6f6;
    border-color: #29b6f6;
}

.btn-outline-info.focus,
.btn-outline-info:focus {
    box-shadow: 0 0 0 0.2rem rgba(41, 182, 246, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #29b6f6;
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
    background-color: #29b6f6;
    border-color: #29b6f6;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(41, 182, 246, 0.5);
}

.btn-outline-warning {
    color: #ffa726;
}

.btn-outline-warning {
    border-color: #ffa726;
}

.btn-outline-warning:hover {
    color: #212529;
}

.btn-outline-warning:hover {
    background-color: #ffa726;
    border-color: #ffa726;
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 167, 38, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #ffa726;
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #212529;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
    background-color: #ffa726;
    border-color: #ffa726;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 167, 38, 0.5);
}

.btn-outline-danger {
    color: #f44336;
}

.btn-outline-danger {
    border-color: #f44336;
}

.btn-outline-danger:hover {
    color: #fff;
}

.btn-outline-danger:hover {
    background-color: #f44336;
    border-color: #f44336;
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #f44336;
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
    background-color: #f44336;
    border-color: #f44336;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}

.btn-outline-light {
    color: #f8f9fa;
}

.btn-outline-light {
    border-color: #f8f9fa;
}

.btn-outline-light:hover {
    color: #212529;
}

.btn-outline-light:hover {
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light.focus,
.btn-outline-light:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f8f9fa;
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
    color: #212529;
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
    color: #39444d;
}

.btn-outline-dark {
    border-color: #39444d;
}

.btn-outline-dark:hover {
    color: #fff;
}

.btn-outline-dark:hover {
    background-color: #39444d;
    border-color: #39444d;
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 0.2rem rgba(57, 68, 77, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #39444d;
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
    background-color: #39444d;
    border-color: #39444d;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(57, 68, 77, 0.5);
}

.btn-link {
    font-weight: 400;
    color: var(--primary);
    text-decoration: none;
}

.btn-link:hover {
    color: var(--primary-hover);
}

.btn-link.focus,
.btn-link:focus,
.btn-link:hover {
    text-decoration: underline;
}

.btn-link.disabled,
.btn-link:disabled {
    color: #6c757d;
    pointer-events: none;
}

.btn-group-lg>.btn,
.btn-lg {
    font-size: 1.171875rem;
    line-height: 1.5;
}

.btn-group-lg>.btn,
.btn-lg {
    padding: 0.5rem 2rem;
    border-radius: 0.3rem;
}

.btn-group-sm>.btn,
.btn-sm {
    font-size: 0.8203125rem;
    line-height: 1.5;
}

.btn-group-sm>.btn,
.btn-sm {
    padding: 0.25rem 0.5rem;
    border-radius: 0.2rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block+.btn-block {
    margin-top: 0.5rem;
}

input[type='button'].btn-block,
input[type='reset'].btn-block,
input[type='submit'].btn-block {
    width: 100%;
}

.fade {
    transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
    .fade {
        transition: none;
    }
}

.fade:not(.show) {
    opacity: 0;
}

.collapse:not(.show) {
    display: none;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none;
    }
}

.dropdown,
.dropleft,
.dropright,
.dropup {
    position: relative;
}

.dropdown-toggle {
    white-space: nowrap;
}

.dropdown-toggle:after {
    display: inline-block;
    vertical-align: 0.255em;
    content: '';
}

.dropdown-toggle:after {
    border-top: 0.3em solid;
    border-bottom: 0;
}

.dropdown-toggle:after {
    margin-left: 0.255em;
}

.dropdown-toggle:after,
[dir='rtl'] .dropdown-toggle:after {
    border-right: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
}

[dir='rtl'] .dropdown-toggle:after {
    margin-right: 0.255em;
}

.dropdown-toggle:empty:after {
    margin-left: 0;
}

[dir='rtl'] .dropdown-toggle:empty:after {
    margin-right: 0;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    font-size: 0.9375rem;
    color: #383b3d;
    list-style: none;
}

.dropdown-menu {
    padding: 0.5rem 0;
    margin: 0.5rem 0 0;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #f0f1f2;
    border-radius: 0.1875rem;
    box-shadow: 0 5px 11px 0 rgba(56, 59, 61, 0.07);
}

.dropdown-menu {
    left: 0;
    float: left;
    text-align: left;
}

[dir='rtl'] .dropdown-menu {
    right: 0;
    float: right;
    text-align: right;
}

.dropdown-menu-left {
    right: auto;
    left: 0;
}

.dropdown-menu-right,
[dir='rtl'] .dropdown-menu-left {
    left: auto;
    right: 0;
}

[dir='rtl'] .dropdown-menu-right {
    left: 0;
    right: auto;
}

@media (min-width: 374px) {
    .dropdown-menu-xs-md-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-xs-md-right,
    [dir='rtl'] .dropdown-menu-xs-md-left {
        left: auto;
        right: 0;
    }

    [dir='rtl'] .dropdown-menu-xs-md-right {
        left: 0;
        right: auto;
    }
}

@media (min-width: 413px) {
    .dropdown-menu-xs-plus-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-xs-plus-right,
    [dir='rtl'] .dropdown-menu-xs-plus-left {
        left: auto;
        right: 0;
    }

    [dir='rtl'] .dropdown-menu-xs-plus-right {
        left: 0;
        right: auto;
    }
}

@media (min-width: 576px) {
    .dropdown-menu-sm-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-sm-right,
    [dir='rtl'] .dropdown-menu-sm-left {
        left: auto;
        right: 0;
    }

    [dir='rtl'] .dropdown-menu-sm-right {
        left: 0;
        right: auto;
    }
}

@media (min-width: 768px) {
    .dropdown-menu-md-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-md-right,
    [dir='rtl'] .dropdown-menu-md-left {
        left: auto;
        right: 0;
    }

    [dir='rtl'] .dropdown-menu-md-right {
        left: 0;
        right: auto;
    }
}

@media (min-width: 992px) {
    .dropdown-menu-lg-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-lg-right,
    [dir='rtl'] .dropdown-menu-lg-left {
        left: auto;
        right: 0;
    }

    [dir='rtl'] .dropdown-menu-lg-right {
        left: 0;
        right: auto;
    }
}

@media (min-width: 1200px) {
    .dropdown-menu-xl-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-xl-right,
    [dir='rtl'] .dropdown-menu-xl-left {
        left: auto;
        right: 0;
    }

    [dir='rtl'] .dropdown-menu-xl-right {
        left: 0;
        right: auto;
    }
}

@media (min-width: 1366px) {
    .dropdown-menu-xxl-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-xxl-right,
    [dir='rtl'] .dropdown-menu-xxl-left {
        left: auto;
        right: 0;
    }

    [dir='rtl'] .dropdown-menu-xxl-right {
        left: 0;
        right: auto;
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
}

.dropup .dropdown-menu {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.dropup .dropdown-toggle:after {
    display: inline-block;
    vertical-align: 0.255em;
    content: '';
}

.dropup .dropdown-toggle:after {
    border-top: 0;
    border-bottom: 0.3em solid;
}

.dropup .dropdown-toggle:after {
    margin-left: 0.255em;
    border-right: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
}

[dir='rtl'] .dropup .dropdown-toggle:after {
    margin-right: 0.255em;
    border-left: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty:after {
    margin-left: 0;
}

[dir='rtl'] .dropup .dropdown-toggle:empty:after {
    margin-right: 0;
}

.dropright .dropdown-menu {
    top: 0;
}

.dropright .dropdown-menu {
    margin-top: 0;
}

.dropright .dropdown-menu {
    right: auto;
    left: 100%;
    margin-left: 0.5rem;
}

[dir='rtl'] .dropright .dropdown-menu {
    left: auto;
    right: 100%;
    margin-right: 0.5rem;
}

.dropright .dropdown-toggle:after {
    display: inline-block;
    vertical-align: 0.255em;
    content: '';
}

.dropright .dropdown-toggle:after {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
}

.dropright .dropdown-toggle:after {
    margin-left: 0.255em;
    border-right: 0;
    border-left: 0.3em solid;
}

[dir='rtl'] .dropright .dropdown-toggle:after {
    margin-right: 0.255em;
    border-left: 0;
    border-right: 0.3em solid;
}

.dropright .dropdown-toggle:empty:after {
    margin-left: 0;
}

[dir='rtl'] .dropright .dropdown-toggle:empty:after {
    margin-right: 0;
}

.dropright .dropdown-toggle:after {
    vertical-align: 0;
}

.dropleft .dropdown-menu {
    top: 0;
}

.dropleft .dropdown-menu {
    margin-top: 0;
}

.dropleft .dropdown-menu {
    right: 100%;
    left: auto;
    margin-right: 0.5rem;
}

[dir='rtl'] .dropleft .dropdown-menu {
    left: 100%;
    right: auto;
    margin-left: 0.5rem;
}

.dropleft .dropdown-toggle:after {
    display: inline-block;
    vertical-align: 0.255em;
    content: '';
}

.dropleft .dropdown-toggle:after {
    margin-left: 0.255em;
}

[dir='rtl'] .dropleft .dropdown-toggle:after {
    margin-right: 0.255em;
}

.dropleft .dropdown-toggle:after {
    display: none;
}

.dropleft .dropdown-toggle:before {
    display: inline-block;
    vertical-align: 0.255em;
    content: '';
}

.dropleft .dropdown-toggle:before {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:before {
    margin-right: 0.255em;
    border-right: 0.3em solid;
}

[dir='rtl'] .dropleft .dropdown-toggle:before {
    margin-left: 0.255em;
    border-left: 0.3em solid;
}

.dropleft .dropdown-toggle:empty:after {
    margin-left: 0;
}

[dir='rtl'] .dropleft .dropdown-toggle:empty:after {
    margin-right: 0;
}

.dropleft .dropdown-toggle:before {
    vertical-align: 0;
}

.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='top'] {
    bottom: auto;
}

.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='top'] {
    right: auto;
}

[dir='rtl'] .dropdown-menu[x-placement^='bottom'],
[dir='rtl'] .dropdown-menu[x-placement^='left'],
[dir='rtl'] .dropdown-menu[x-placement^='right'],
[dir='rtl'] .dropdown-menu[x-placement^='top'] {
    left: auto;
}

.dropdown-divider {
    height: 0;
    overflow: hidden;
}

.dropdown-divider {
    margin: 0.5rem 0;
    border-top: 1px solid #e9ecef;
}

.dropdown-item {
    display: block;
    width: 100%;
    font-weight: 400;
    color: rgba(56, 59, 61, 0.7);
    white-space: nowrap;
}

.dropdown-item {
    padding: 0.25rem 1rem;
    clear: both;
    text-align: inherit;
    background-color: transparent;
    border: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: rgba(56, 59, 61, 0.9);
    text-decoration: none;
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: transparent;
}

.dropdown-item.active,
.dropdown-item:active {
    color: var(--primary);
    text-decoration: none;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #BBDEFB;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: rgba(56, 59, 61, 0.3);
    pointer-events: none;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    background-color: transparent;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-header {
    display: block;
    font-size: 0.8203125rem;
    color: #6c757d;
    white-space: nowrap;
}

.dropdown-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
}

.dropdown-item-text {
    display: block;
    color: rgba(56, 59, 61, 0.7);
}

.dropdown-item-text {
    padding: 0.25rem 1rem;
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    position: relative;
    flex: 1 1 auto;
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
    z-index: 1;
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.btn-toolbar .input-group {
    width: auto;
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
    margin-left: -1px;
}

[dir='rtl'] .btn-group>.btn-group:not(:first-child),
[dir='rtl'] .btn-group>.btn:not(:first-child) {
    margin-right: -1px;
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:not(:first-child),
[dir='rtl'] .btn-group>.btn-group:not(:last-child)>.btn,
[dir='rtl'] .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

[dir='rtl'] .btn-group>.btn-group:not(:first-child)>.btn,
[dir='rtl'] .btn-group>.btn:not(:first-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.dropdown-toggle-split,
[dir='rtl'] .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

.dropdown-toggle-split:after,
.dropright .dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after {
    margin-left: 0;
}

.dropleft .dropdown-toggle-split:before,
[dir='rtl'] .dropdown-toggle-split:after,
[dir='rtl'] .dropright .dropdown-toggle-split:after,
[dir='rtl'] .dropup .dropdown-toggle-split:after {
    margin-right: 0;
}

[dir='rtl'] .dropleft .dropdown-toggle-split:before {
    margin-left: 0;
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split,
[dir='rtl'] .btn-group-sm>.btn+.dropdown-toggle-split,
[dir='rtl'] .btn-sm+.dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split,
[dir='rtl'] .btn-group-lg>.btn+.dropdown-toggle-split,
[dir='rtl'] .btn-lg+.dropdown-toggle-split {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

.btn-group.show .dropdown-toggle {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none;
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%;
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child) {
    margin-top: -1px;
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
[dir='rtl'] .btn-group-vertical>.btn-group:not(:last-child)>.btn,
[dir='rtl'] .btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn:not(:first-child),
[dir='rtl'] .btn-group-vertical>.btn-group:not(:first-child)>.btn,
[dir='rtl'] .btn-group-vertical>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0;
}

.btn-group-toggle>.btn-group>.btn input[type='checkbox'],
.btn-group-toggle>.btn-group>.btn input[type='radio'],
.btn-group-toggle>.btn input[type='checkbox'],
.btn-group-toggle>.btn input[type='radio'] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control,
.input-group>.form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control,
.input-group>.form-control-plaintext {
    margin-bottom: 0;
}

.input-group>.custom-file+.custom-file,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.form-control,
.input-group>.custom-select+.custom-file,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.form-control,
.input-group>.form-control+.custom-file,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.form-control,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.form-control {
    margin-left: -1px;
}

[dir='rtl'] .input-group>.custom-file+.custom-file,
[dir='rtl'] .input-group>.custom-file+.custom-select,
[dir='rtl'] .input-group>.custom-file+.form-control,
[dir='rtl'] .input-group>.custom-select+.custom-file,
[dir='rtl'] .input-group>.custom-select+.custom-select,
[dir='rtl'] .input-group>.custom-select+.form-control,
[dir='rtl'] .input-group>.form-control+.custom-file,
[dir='rtl'] .input-group>.form-control+.custom-select,
[dir='rtl'] .input-group>.form-control+.form-control,
[dir='rtl'] .input-group>.form-control-plaintext+.custom-file,
[dir='rtl'] .input-group>.form-control-plaintext+.custom-select,
[dir='rtl'] .input-group>.form-control-plaintext+.form-control {
    margin-right: -1px;
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label,
.input-group>.custom-select:focus,
.input-group>.form-control:focus {
    z-index: 3;
}

.input-group>.custom-file .custom-file-input:focus {
    z-index: 4;
}

.input-group>.custom-select:not(:last-child),
.input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child),
[dir='rtl'] .input-group>.custom-select:not(:last-child),
[dir='rtl'] .input-group>.form-control:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

[dir='rtl'] .input-group>.custom-select:not(:first-child),
[dir='rtl'] .input-group>.form-control:not(:first-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.custom-file {
    display: flex;
    align-items: center;
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label:after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label,
[dir='rtl'] .input-group>.custom-file:not(:last-child) .custom-file-label,
[dir='rtl'] .input-group>.custom-file:not(:last-child) .custom-file-label:after {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

[dir='rtl'] .input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group-append,
.input-group-prepend {
    display: flex;
}

.input-group-append .btn,
.input-group-prepend .btn {
    position: relative;
    z-index: 2;
}

.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
    z-index: 3;
}

.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .input-group-text+.input-group-text,
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .input-group-text+.input-group-text {
    margin-left: -1px;
}

.input-group-prepend,
[dir='rtl'] .input-group-append .btn+.btn,
[dir='rtl'] .input-group-append .btn+.input-group-text,
[dir='rtl'] .input-group-append .input-group-text+.btn,
[dir='rtl'] .input-group-append .input-group-text+.input-group-text,
[dir='rtl'] .input-group-prepend .btn+.btn,
[dir='rtl'] .input-group-prepend .btn+.input-group-text,
[dir='rtl'] .input-group-prepend .input-group-text+.btn,
[dir='rtl'] .input-group-prepend .input-group-text+.input-group-text {
    margin-right: -1px;
}

.input-group-append,
[dir='rtl'] .input-group-prepend {
    margin-left: -1px;
}

[dir='rtl'] .input-group-append {
    margin-right: -1px;
}

.input-group-text {
    display: flex;
    align-items: center;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    white-space: nowrap;
}

.input-group-text {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    text-align: center;
    background-color: #fff;
    border: 1px solid #f0f1f2;
    border-radius: 0.25rem;
}

.input-group-text input[type='checkbox'],
.input-group-text input[type='radio'] {
    margin-top: 0;
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control:not(textarea) {
    height: calc(1.5em + 1rem + 2px);
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
    font-size: 1.171875rem;
    line-height: 1.5;
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control:not(textarea) {
    height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
    font-size: 0.8203125rem;
    line-height: 1.5;
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
    padding: 0.25rem 0.5rem;
    border-radius: 0.2rem;
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
    padding-right: 1.75rem;
}

[dir='rtl'] .input-group-lg>.custom-select,
[dir='rtl'] .input-group-sm>.custom-select {
    padding-left: 1.75rem;
}

.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
[dir='rtl'] .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
[dir='rtl'] .input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
[dir='rtl'] .input-group>.input-group-append:not(:last-child)>.btn,
[dir='rtl'] .input-group>.input-group-append:not(:last-child)>.input-group-text,
[dir='rtl'] .input-group>.input-group-prepend>.btn,
[dir='rtl'] .input-group>.input-group-prepend>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

[dir='rtl'] .input-group>.input-group-append>.btn,
[dir='rtl'] .input-group>.input-group-append>.input-group-text,
[dir='rtl'] .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
[dir='rtl'] .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
[dir='rtl'] .input-group>.input-group-prepend:not(:first-child)>.btn,
[dir='rtl'] .input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.40625rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}

.custom-control {
    padding-left: 1.5rem;
}

[dir='rtl'] .custom-control {
    padding-right: 1.5rem;
}

.custom-control-inline {
    display: inline-flex;
}

.custom-control-inline {
    margin-right: 1rem;
}

[dir='rtl'] .custom-control-inline {
    margin-left: 1rem;
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    width: 1rem;
    height: 1.203125rem;
    opacity: 0;
}

.custom-control-input {
    left: 0;
}

[dir='rtl'] .custom-control-input {
    right: 0;
}

.custom-control-input:checked~.custom-control-label:before {
    color: #fff;
}

.custom-control-input:checked~.custom-control-label:before {
    border-color: var(--primary);
    background-color: var(--primary);
}

.custom-control-input:focus~.custom-control-label:before {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
}

.custom-control-input:focus:not(:checked)~.custom-control-label:before {
    border-color: #9acffa;
}

.custom-control-input:not(:disabled):active~.custom-control-label:before {
    color: #fff;
}

.custom-control-input:not(:disabled):active~.custom-control-label:before {
    background-color: #cae6fc;
    border-color: #cae6fc;
}

.custom-control-input:disabled~.custom-control-label,
.custom-control-input[disabled]~.custom-control-label {
    color: #6c757d;
}

.custom-control-input:disabled~.custom-control-label:before,
.custom-control-input[disabled]~.custom-control-label:before {
    background-color: #e9ecef;
}

.custom-control-label {
    position: relative;
    vertical-align: top;
}

.custom-control-label {
    margin-bottom: 0;
}

.custom-control-label:before {
    position: absolute;
    top: 0.203125rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: '';
}

.custom-control-label:before {
    background-color: #fff;
    border: 1px solid #adb5bd;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.custom-control-label:before {
    left: -1.5rem;
}

[dir='rtl'] .custom-control-label:before {
    right: -1.5rem;
}

.custom-control-label:after {
    position: absolute;
    top: 0.203125rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: '';
}

.custom-control-label:after {
    background: no-repeat 50%/50% 50%;
}

.custom-control-label:after {
    left: -1.5rem;
}

[dir='rtl'] .custom-control-label:after {
    right: -1.5rem;
}

.custom-checkbox .custom-control-label:before {
    border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%23FFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label:before {
    border-color: var(--primary);
    background-color: var(--primary);
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4'%3E%3Cpath stroke='%23FFF' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: rgba(33, 150, 243, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label:before {
    background-color: rgba(33, 150, 243, 0.5);
}

.custom-radio .custom-control-label:before {
    border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23FFF'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: rgba(33, 150, 243, 0.5);
}

.custom-switch {
    padding-left: 2.25rem;
}

[dir='rtl'] .custom-switch {
    padding-right: 2.25rem;
}

.custom-switch .custom-control-label:before {
    width: 1.75rem;
    pointer-events: all;
}

.custom-switch .custom-control-label:before {
    border-radius: 0.5rem;
}

.custom-switch .custom-control-label:before {
    left: -2.25rem;
}

[dir='rtl'] .custom-switch .custom-control-label:before {
    right: -2.25rem;
}

.custom-switch .custom-control-label:after {
    top: calc(0.20313rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

.custom-switch .custom-control-label:after {
    background-color: #adb5bd;
    border-radius: 0.5rem;
}

.custom-switch .custom-control-label:after {
    left: calc(-2.25rem + 2px);
}

[dir='rtl'] .custom-switch .custom-control-label:after {
    right: calc(-2.25rem + 2px);
}

@media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label:after {
        transition: none;
    }
}

.custom-switch .custom-control-input:checked~.custom-control-label:after {
    background-color: #fff;
}

.custom-switch .custom-control-input:checked~.custom-control-label:after {
    transform: translateX(0.75rem);
}

[dir='rtl'] .custom-switch .custom-control-input:checked~.custom-control-label:after {
    transform: translateX(-0.75rem);
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: rgba(33, 150, 243, 0.5);
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 1rem + 2px);
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-select {
    border: 1px solid #f0f1f2;
    border-radius: 0.25rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.custom-select {
    padding: 0.5rem 1.75rem 0.5rem 0.75rem;
    background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px;
}

[dir='rtl'] .custom-select {
    padding: 0.5rem 0.75rem 0.5rem 1.75rem;
    background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat left 0.75rem center/8px 10px;
}

.custom-select:focus {
    outline: 0;
}

.custom-select:focus {
    border-color: #9acffa;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
}

.custom-select:focus::-ms-value {
    color: #495057;
}

.custom-select:focus::-ms-value {
    background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size='1']) {
    height: auto;
}

.custom-select[multiple],
.custom-select[size]:not([size='1']) {
    background-image: none;
}

.custom-select[multiple],
.custom-select[size]:not([size='1']) {
    padding-right: 0.75rem;
}

[dir='rtl'] .custom-select[multiple],
[dir='rtl'] .custom-select[size]:not([size='1']) {
    padding-left: 0.75rem;
}

.custom-select:disabled {
    color: #6c757d;
}

.custom-select:disabled {
    background-color: #e9ecef;
}

.custom-select::-ms-expand {
    display: none;
}

.custom-select:-moz-focusring {
    color: transparent;
}

.custom-select:-moz-focusring {
    text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    font-size: 0.8203125rem;
}

.custom-select-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.custom-select-sm {
    padding-left: 0.5rem;
}

[dir='rtl'] .custom-select-sm {
    padding-right: 0.5rem;
}

.custom-select-lg {
    height: calc(1.5em + 1rem + 2px);
    font-size: 1.171875rem;
}

.custom-select-lg {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.custom-select-lg {
    padding-left: 1rem;
}

[dir='rtl'] .custom-select-lg {
    padding-right: 1rem;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 1rem + 2px);
}

.custom-file {
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 1rem + 2px);
    opacity: 0;
}

.custom-file-input {
    margin: 0;
}

.custom-file-input:focus~.custom-file-label {
    border-color: #9acffa;
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
}

.custom-file-input:disabled~.custom-file-label,
.custom-file-input[disabled]~.custom-file-label {
    background-color: #e9ecef;
}

.custom-file-input:lang(en)~.custom-file-label:after {
    content: 'Browse';
}

.custom-file-input~.custom-file-label[data-browse]:after {
    content: attr(data-browse);
}

.custom-file-label {
    position: absolute;
    top: 0;
    z-index: 1;
    height: calc(1.5em + 1rem + 2px);
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}

.custom-file-label {
    padding: 0.5rem 0.75rem;
    background-color: #fff;
    border: 1px solid #f0f1f2;
    border-radius: 0.25rem;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.custom-file-label,
[dir='rtl'] .custom-file-label {
    right: 0;
    left: 0;
}

.custom-file-label:after {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 1rem);
    line-height: 1.5;
    color: #495057;
    content: 'Browse';
}

.custom-file-label:after {
    padding: 0.5rem 0.75rem;
    background-color: #fff;
}

.custom-file-label:after {
    right: 0;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0;
}

[dir='rtl'] .custom-file-label:after {
    left: 0;
    border-right: inherit;
    border-radius: 0.25rem 0 0 0.25rem;
}

.custom-range {
    width: 100%;
    height: 1.4rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-range {
    padding: 0;
    background-color: transparent;
}

.custom-range:focus {
    outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #f5f6f7, 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
}

.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #f5f6f7, 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
}

.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #f5f6f7, 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
}

.custom-range::-moz-focus-outer {
    border: 0;
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
}

.custom-range::-webkit-slider-thumb {
    margin-top: -0.25rem;
    background-color: var(--primary);
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none;
    }
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #cae6fc;
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
}

.custom-range::-webkit-slider-runnable-track {
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none;
}

.custom-range::-moz-range-thumb {
    background-color: var(--primary);
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none;
    }
}

.custom-range::-moz-range-thumb:active {
    background-color: #cae6fc;
}

.custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
}

.custom-range::-moz-range-track {
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

.custom-range::-ms-thumb {
    margin-top: 0;
    background-color: var(--primary);
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
}

.custom-range::-ms-thumb,
[dir='rtl'] .custom-range::-ms-thumb {
    margin-right: 0.2rem;
    margin-left: 0.2rem;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none;
    }
}

.custom-range::-ms-thumb:active {
    background-color: #cae6fc;
}

.custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
}

.custom-range::-ms-track {
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}

.custom-range::-ms-fill-lower,
.custom-range::-ms-fill-upper {
    background-color: #dee2e6;
    border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
    margin-right: 15px;
}

[dir='rtl'] .custom-range::-ms-fill-upper {
    margin-left: 15px;
}

.custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
    cursor: default;
}

.custom-range:disabled::-ms-thumb {
    background-color: #adb5bd;
}

.custom-control-label:before,
.custom-file-label,
.custom-select {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

    .custom-control-label:before,
    .custom-file-label,
    .custom-select {
        transition: none;
    }
}

.nav {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}

.nav {
    margin-bottom: 0;
}

.nav {
    padding-left: 0;
}

[dir='rtl'] .nav {
    padding-right: 0;
}

.nav-link {
    display: block;
}

.nav-link {
    padding: 0.5rem 1rem;
}

.nav-link:focus,
.nav-link:hover {
    text-decoration: none;
}

.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
}

.nav-link.disabled {
    cursor: default;
}

.nav-tabs {
    border-bottom: 1px solid #f0f1f2;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
}

.nav-tabs .nav-link,
[dir='rtl'] .nav-tabs .nav-link {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #f0f1f2;
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
}

.nav-tabs .nav-link.disabled {
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #495057;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: #fff;
    border-color: #f0f1f2;
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
}

.nav-tabs .dropdown-menu,
[dir='rtl'] .nav-tabs .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link {
    border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: var(--primary);
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
    flex: 1 1 auto;
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
    text-align: center;
}

.nav-justified .nav-item,
.nav-justified>.nav-link {
    flex-basis: 0;
    flex-grow: 1;
}

.nav-justified .nav-item,
.nav-justified>.nav-link {
    text-align: center;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.navbar {
    padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl,
.navbar .container-xxl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    font-size: 1.171875rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand {
    padding-top: 0.32421875rem;
    padding-bottom: 0.32421875rem;
}

.navbar-brand {
    margin-right: 1rem;
}

[dir='rtl'] .navbar-brand {
    margin-left: 1rem;
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    list-style: none;
}

.navbar-nav {
    margin-bottom: 0;
}

.navbar-nav {
    padding-left: 0;
}

[dir='rtl'] .navbar-nav {
    padding-right: 0;
}

.navbar-nav .nav-link,
[dir='rtl'] .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-nav .dropdown-menu {
    position: static;
}

.navbar-nav .dropdown-menu {
    float: none;
}

.navbar-text {
    display: inline-block;
}

.navbar-text {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

.navbar-toggler {
    font-size: 1.171875rem;
    line-height: 1;
}

.navbar-toggler {
    padding: 1rem 0;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: '';
}

.navbar-toggler-icon {
    background: no-repeat 50%;
    background-size: 100% 100%;
}

@media (max-width: 373.98px) {

    .navbar-expand-xs-md>.container,
    .navbar-expand-xs-md>.container-fluid,
    .navbar-expand-xs-md>.container-lg,
    .navbar-expand-xs-md>.container-md,
    .navbar-expand-xs-md>.container-sm,
    .navbar-expand-xs-md>.container-xl,
    .navbar-expand-xs-md>.container-xxl,
    [dir='rtl'] .navbar-expand-xs-md>.container,
    [dir='rtl'] .navbar-expand-xs-md>.container-fluid,
    [dir='rtl'] .navbar-expand-xs-md>.container-lg,
    [dir='rtl'] .navbar-expand-xs-md>.container-md,
    [dir='rtl'] .navbar-expand-xs-md>.container-sm,
    [dir='rtl'] .navbar-expand-xs-md>.container-xl,
    [dir='rtl'] .navbar-expand-xs-md>.container-xxl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 374px) {
    .navbar-expand-xs-md {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-xs-md .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-xs-md .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-xs-md .navbar-nav .nav-link,
    [dir='rtl'] .navbar-expand-xs-md .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
    }

    .navbar-expand-xs-md>.container,
    .navbar-expand-xs-md>.container-fluid,
    .navbar-expand-xs-md>.container-lg,
    .navbar-expand-xs-md>.container-md,
    .navbar-expand-xs-md>.container-sm,
    .navbar-expand-xs-md>.container-xl,
    .navbar-expand-xs-md>.container-xxl {
        flex-wrap: nowrap;
    }

    .navbar-expand-xs-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-xs-md .navbar-toggler {
        display: none;
    }
}

@media (max-width: 412.98px) {

    .navbar-expand-xs-plus>.container,
    .navbar-expand-xs-plus>.container-fluid,
    .navbar-expand-xs-plus>.container-lg,
    .navbar-expand-xs-plus>.container-md,
    .navbar-expand-xs-plus>.container-sm,
    .navbar-expand-xs-plus>.container-xl,
    .navbar-expand-xs-plus>.container-xxl,
    [dir='rtl'] .navbar-expand-xs-plus>.container,
    [dir='rtl'] .navbar-expand-xs-plus>.container-fluid,
    [dir='rtl'] .navbar-expand-xs-plus>.container-lg,
    [dir='rtl'] .navbar-expand-xs-plus>.container-md,
    [dir='rtl'] .navbar-expand-xs-plus>.container-sm,
    [dir='rtl'] .navbar-expand-xs-plus>.container-xl,
    [dir='rtl'] .navbar-expand-xs-plus>.container-xxl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 413px) {
    .navbar-expand-xs-plus {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-xs-plus .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-xs-plus .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-xs-plus .navbar-nav .nav-link,
    [dir='rtl'] .navbar-expand-xs-plus .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
    }

    .navbar-expand-xs-plus>.container,
    .navbar-expand-xs-plus>.container-fluid,
    .navbar-expand-xs-plus>.container-lg,
    .navbar-expand-xs-plus>.container-md,
    .navbar-expand-xs-plus>.container-sm,
    .navbar-expand-xs-plus>.container-xl,
    .navbar-expand-xs-plus>.container-xxl {
        flex-wrap: nowrap;
    }

    .navbar-expand-xs-plus .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-xs-plus .navbar-toggler {
        display: none;
    }
}

@media (max-width: 575.98px) {

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid,
    .navbar-expand-sm>.container-lg,
    .navbar-expand-sm>.container-md,
    .navbar-expand-sm>.container-sm,
    .navbar-expand-sm>.container-xl,
    .navbar-expand-sm>.container-xxl,
    [dir='rtl'] .navbar-expand-sm>.container,
    [dir='rtl'] .navbar-expand-sm>.container-fluid,
    [dir='rtl'] .navbar-expand-sm>.container-lg,
    [dir='rtl'] .navbar-expand-sm>.container-md,
    [dir='rtl'] .navbar-expand-sm>.container-sm,
    [dir='rtl'] .navbar-expand-sm>.container-xl,
    [dir='rtl'] .navbar-expand-sm>.container-xxl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-sm .navbar-nav .nav-link,
    [dir='rtl'] .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
    }

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid,
    .navbar-expand-sm>.container-lg,
    .navbar-expand-sm>.container-md,
    .navbar-expand-sm>.container-sm,
    .navbar-expand-sm>.container-xl,
    .navbar-expand-sm>.container-xxl {
        flex-wrap: nowrap;
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
}

@media (max-width: 767.98px) {

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid,
    .navbar-expand-md>.container-lg,
    .navbar-expand-md>.container-md,
    .navbar-expand-md>.container-sm,
    .navbar-expand-md>.container-xl,
    .navbar-expand-md>.container-xxl,
    [dir='rtl'] .navbar-expand-md>.container,
    [dir='rtl'] .navbar-expand-md>.container-fluid,
    [dir='rtl'] .navbar-expand-md>.container-lg,
    [dir='rtl'] .navbar-expand-md>.container-md,
    [dir='rtl'] .navbar-expand-md>.container-sm,
    [dir='rtl'] .navbar-expand-md>.container-xl,
    [dir='rtl'] .navbar-expand-md>.container-xxl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-md .navbar-nav .nav-link,
    [dir='rtl'] .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
    }

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid,
    .navbar-expand-md>.container-lg,
    .navbar-expand-md>.container-md,
    .navbar-expand-md>.container-sm,
    .navbar-expand-md>.container-xl,
    .navbar-expand-md>.container-xxl {
        flex-wrap: nowrap;
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-md .navbar-toggler {
        display: none;
    }
}

@media (max-width: 991.98px) {

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid,
    .navbar-expand-lg>.container-lg,
    .navbar-expand-lg>.container-md,
    .navbar-expand-lg>.container-sm,
    .navbar-expand-lg>.container-xl,
    .navbar-expand-lg>.container-xxl,
    [dir='rtl'] .navbar-expand-lg>.container,
    [dir='rtl'] .navbar-expand-lg>.container-fluid,
    [dir='rtl'] .navbar-expand-lg>.container-lg,
    [dir='rtl'] .navbar-expand-lg>.container-md,
    [dir='rtl'] .navbar-expand-lg>.container-sm,
    [dir='rtl'] .navbar-expand-lg>.container-xl,
    [dir='rtl'] .navbar-expand-lg>.container-xxl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-lg .navbar-nav .nav-link,
    [dir='rtl'] .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
    }

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid,
    .navbar-expand-lg>.container-lg,
    .navbar-expand-lg>.container-md,
    .navbar-expand-lg>.container-sm,
    .navbar-expand-lg>.container-xl,
    .navbar-expand-lg>.container-xxl {
        flex-wrap: nowrap;
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

@media (max-width: 1199.98px) {

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid,
    .navbar-expand-xl>.container-lg,
    .navbar-expand-xl>.container-md,
    .navbar-expand-xl>.container-sm,
    .navbar-expand-xl>.container-xl,
    .navbar-expand-xl>.container-xxl,
    [dir='rtl'] .navbar-expand-xl>.container,
    [dir='rtl'] .navbar-expand-xl>.container-fluid,
    [dir='rtl'] .navbar-expand-xl>.container-lg,
    [dir='rtl'] .navbar-expand-xl>.container-md,
    [dir='rtl'] .navbar-expand-xl>.container-sm,
    [dir='rtl'] .navbar-expand-xl>.container-xl,
    [dir='rtl'] .navbar-expand-xl>.container-xxl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-xl .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-xl .navbar-nav .nav-link,
    [dir='rtl'] .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
    }

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid,
    .navbar-expand-xl>.container-lg,
    .navbar-expand-xl>.container-md,
    .navbar-expand-xl>.container-sm,
    .navbar-expand-xl>.container-xl,
    .navbar-expand-xl>.container-xxl {
        flex-wrap: nowrap;
    }

    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
}

@media (max-width: 1365.98px) {

    .navbar-expand-xxl>.container,
    .navbar-expand-xxl>.container-fluid,
    .navbar-expand-xxl>.container-lg,
    .navbar-expand-xxl>.container-md,
    .navbar-expand-xxl>.container-sm,
    .navbar-expand-xxl>.container-xl,
    .navbar-expand-xxl>.container-xxl,
    [dir='rtl'] .navbar-expand-xxl>.container,
    [dir='rtl'] .navbar-expand-xxl>.container-fluid,
    [dir='rtl'] .navbar-expand-xxl>.container-lg,
    [dir='rtl'] .navbar-expand-xxl>.container-md,
    [dir='rtl'] .navbar-expand-xxl>.container-sm,
    [dir='rtl'] .navbar-expand-xxl>.container-xl,
    [dir='rtl'] .navbar-expand-xxl>.container-xxl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1366px) {
    .navbar-expand-xxl {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-xxl .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-xxl .navbar-nav .nav-link,
    [dir='rtl'] .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
    }

    .navbar-expand-xxl>.container,
    .navbar-expand-xxl>.container-fluid,
    .navbar-expand-xxl>.container-lg,
    .navbar-expand-xxl>.container-md,
    .navbar-expand-xxl>.container-sm,
    .navbar-expand-xxl>.container-xl,
    .navbar-expand-xxl>.container-xxl {
        flex-wrap: nowrap;
    }

    .navbar-expand-xxl .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-xxl .navbar-toggler {
        display: none;
    }
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-lg,
.navbar-expand>.container-md,
.navbar-expand>.container-sm,
.navbar-expand>.container-xl,
.navbar-expand>.container-xxl,
[dir='rtl'] .navbar-expand>.container,
[dir='rtl'] .navbar-expand>.container-fluid,
[dir='rtl'] .navbar-expand>.container-lg,
[dir='rtl'] .navbar-expand>.container-md,
[dir='rtl'] .navbar-expand>.container-sm,
[dir='rtl'] .navbar-expand>.container-xl,
[dir='rtl'] .navbar-expand>.container-xxl {
    padding-right: 0;
    padding-left: 0;
}

.navbar-expand .navbar-nav {
    flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-expand .navbar-nav .nav-link,
[dir='rtl'] .navbar-expand .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-lg,
.navbar-expand>.container-md,
.navbar-expand>.container-sm,
.navbar-expand>.container-xl,
.navbar-expand>.container-xxl {
    flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
}

.navbar-expand .navbar-toggler {
    display: none;
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: #8a9299;
}

.navbar-light .navbar-nav .nav-link {
    color: #b8c2cc;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(184, 194, 204, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(184, 194, 204, 0.3);
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: #8a9299;
}

.navbar-light .navbar-toggler {
    color: #b8c2cc;
}

.navbar-light .navbar-toggler {
    border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath stroke='%23B8C2CC' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
    color: #b8c2cc;
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: #8a9299;
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: hsla(0, 0%, 100%, 0.5);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: hsla(0, 0%, 100%, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: hsla(0, 0%, 100%, 0.25);
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
    color: #fff;
}

.navbar-dark .navbar-toggler {
    color: hsla(0, 0%, 100%, 0.5);
}

.navbar-dark .navbar-toggler {
    border-color: transparent;
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
    color: hsla(0, 0%, 100%, 0.5);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
    color: #fff;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
}

.card {
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #f0f1f2;
    border-radius: 0.1875rem;
}

.card>hr,
[dir='rtl'] .card>hr {
    margin-right: 0;
    margin-left: 0;
}

.card>.list-group {
    border-top: inherit;
    border-bottom: inherit;
}

.card>.list-group:first-child {
    border-top-width: 0;
}

.card>.list-group:first-child,
[dir='rtl'] .card>.list-group:first-child {
    border-top-left-radius: calc(0.1875rem - 1px);
    border-top-right-radius: calc(0.1875rem - 1px);
}

.card>.list-group:last-child {
    border-bottom-width: 0;
}

.card>.list-group:last-child,
[dir='rtl'] .card>.list-group:last-child {
    border-bottom-right-radius: calc(0.1875rem - 1px);
    border-bottom-left-radius: calc(0.1875rem - 1px);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
    border-top: 0;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
}

.card-body {
    padding: 1.25rem;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link+.card-link {
    margin-left: 1.25rem;
}

[dir='rtl'] .card-link+.card-link {
    margin-right: 1.25rem;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid #f0f1f2;
}

.card-header:first-child {
    border-radius: calc(0.1875rem - 1px) calc(0.1875rem - 1px) 0 0;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border-top: 1px solid #f0f1f2;
}

.card-footer:last-child {
    border-radius: 0 0 calc(0.1875rem - 1px) calc(0.1875rem - 1px);
}

.card-header-tabs {
    margin-bottom: -0.75rem;
    border-bottom: 0;
}

.card-header-pills,
.card-header-tabs,
[dir='rtl'] .card-header-pills,
[dir='rtl'] .card-header-tabs {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
}

.card-img-overlay {
    padding: 1.25rem;
    border-radius: calc(0.1875rem - 1px);
}

.card-img-overlay,
[dir='rtl'] .card-img-overlay {
    right: 0;
    left: 0;
}

.card-img,
.card-img-bottom,
.card-img-top {
    flex-shrink: 0;
    width: 100%;
}

.card-img,
.card-img-top,
[dir='rtl'] .card-img,
[dir='rtl'] .card-img-top {
    border-top-left-radius: calc(0.1875rem - 1px);
    border-top-right-radius: calc(0.1875rem - 1px);
}

.card-img,
.card-img-bottom,
[dir='rtl'] .card-img,
[dir='rtl'] .card-img-bottom {
    border-bottom-right-radius: calc(0.1875rem - 1px);
    border-bottom-left-radius: calc(0.1875rem - 1px);
}

.card-deck .card {
    margin-bottom: 0.625rem;
}

@media (min-width: 576px) {
    .card-deck {
        display: flex;
        flex-flow: row wrap;
    }

    .card-deck,
    [dir='rtl'] .card-deck {
        margin-right: -0.625rem;
        margin-left: -0.625rem;
    }

    .card-deck .card {
        flex: 1 0 0%;
    }

    .card-deck .card {
        margin-bottom: 0;
    }

    .card-deck .card,
    [dir='rtl'] .card-deck .card {
        margin-right: 0.625rem;
        margin-left: 0.625rem;
    }
}

.card-group>.card {
    margin-bottom: 0.625rem;
}

@media (min-width: 576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap;
    }

    .card-group>.card {
        flex: 1 0 0%;
    }

    .card-group>.card {
        margin-bottom: 0;
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0;
    }

    [dir='rtl'] .card-group>.card+.card {
        margin-right: 0;
        border-right: 0;
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    [dir='rtl'] .card-group>.card:not(:last-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .card-group>.card:not(:last-child) .card-header,
    .card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0;
    }

    [dir='rtl'] .card-group>.card:not(:last-child) .card-header,
    [dir='rtl'] .card-group>.card:not(:last-child) .card-img-top {
        border-top-left-radius: 0;
    }

    .card-group>.card:not(:last-child) .card-footer,
    .card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0;
    }

    [dir='rtl'] .card-group>.card:not(:last-child) .card-footer,
    [dir='rtl'] .card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-left-radius: 0;
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    [dir='rtl'] .card-group>.card:not(:first-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-group>.card:not(:first-child) .card-header,
    .card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0;
    }

    [dir='rtl'] .card-group>.card:not(:first-child) .card-header,
    [dir='rtl'] .card-group>.card:not(:first-child) .card-img-top {
        border-top-right-radius: 0;
    }

    .card-group>.card:not(:first-child) .card-footer,
    .card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0;
    }

    [dir='rtl'] .card-group>.card:not(:first-child) .card-footer,
    [dir='rtl'] .card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-right-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 1.5rem;
}

@media (min-width: 576px) {
    .card-columns {
        -moz-column-count: 3;
        column-count: 3;
        -moz-column-gap: 1.5rem;
        column-gap: 1.5rem;
        orphans: 1;
        widows: 1;
    }

    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.accordion {
    overflow-anchor: none;
}

.accordion>.card {
    overflow: hidden;
}

.accordion>.card:not(:last-of-type) {
    border-bottom: 0;
}

.accordion>.card:not(:last-of-type),
[dir='rtl'] .accordion>.card:not(:last-of-type) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion>.card:not(:first-of-type),
[dir='rtl'] .accordion>.card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion>.card>.card-header {
    border-radius: 0;
    margin-bottom: -1px;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}

.breadcrumb {
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    background-color: transparent;
    border-radius: 0.25rem;
}

.breadcrumb-item {
    display: flex;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 0.5rem;
}

[dir='rtl'] .breadcrumb-item+.breadcrumb-item {
    padding-right: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item:before {
    display: inline-block;
    color: #b8bdc2;
    content: '/';
}

.breadcrumb-item+.breadcrumb-item:before {
    padding-right: 0.5rem;
}

[dir='rtl'] .breadcrumb-item+.breadcrumb-item:before {
    padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item:hover:before {
    text-decoration: underline;
    text-decoration: none;
}

.breadcrumb-item.active {
    color: #b8bdc2;
}

.pagination {
    display: flex;
    list-style: none;
}

.pagination {
    border-radius: 0.25rem;
}

.pagination {
    padding-left: 0;
}

[dir='rtl'] .pagination {
    padding-right: 0;
}

.page-link {
    position: relative;
    display: block;
    line-height: 1.25;
    color: var(--primary);
}

.page-link {
    padding: 0.5rem 0.75rem;
    background-color: transparent;
    border: 1px solid #dee2e6;
}

.page-link {
    margin-left: -1px;
}

[dir='rtl'] .page-link {
    margin-right: -1px;
}

.page-link:hover {
    z-index: 2;
    color: var(--primary-hover);
    text-decoration: none;
}

.page-link:hover {
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.page-link:focus {
    z-index: 3;
    outline: 0;
}

.page-link:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

[dir='rtl'] .page-item:first-child .page-link {
    margin-right: 0;
}

.page-item:last-child .page-link,
[dir='rtl'] .page-item:first-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

[dir='rtl'] .page-item:last-child .page-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
}

.page-item.active .page-link {
    background-color: var(--primary);
    border-color: var(--primary);
}

.page-item.disabled .page-link {
    color: #cfcfcf;
    pointer-events: none;
}

.page-item.disabled .page-link {
    cursor: auto;
    background-color: transparent;
    border-color: #dee2e6;
}

.pagination-lg .page-link {
    font-size: 1.171875rem;
    line-height: 1.5;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link,
[dir='rtl'] .pagination-lg .page-item:first-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

[dir='rtl'] .pagination-lg .page-item:last-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.pagination-sm .page-link {
    font-size: 0.8203125rem;
    line-height: 1.5;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link,
[dir='rtl'] .pagination-sm .page-item:first-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}

[dir='rtl'] .pagination-sm .page-item:last-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}

.badge {
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;
    vertical-align: baseline;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

.badge {
    padding: 0.25em 0.4em;
    text-align: center;
    border-radius: 0.25rem;
}

@media (prefers-reduced-motion: reduce) {
    .badge {
        transition: none;
    }
}

a.badge:focus,
a.badge:hover {
    text-decoration: none;
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.badge-pill {
    border-radius: 10rem;
}

.badge-pill,
[dir='rtl'] .badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
}

.badge-primary {
    color: #fff;
}

.badge-primary {
    background-color: var(--primary);
}

a.badge-primary:focus,
a.badge-primary:hover {
    color: #fff;
}

a.badge-primary:focus,
a.badge-primary:hover {
    background-color: var(--primary-hover);
}

a.badge-primary.focus,
a.badge-primary:focus {
    outline: 0;
}

a.badge-primary.focus,
a.badge-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.5);
}

.badge-secondary {
    color: #fff;
}

.badge-secondary {
    background-color: #6b757d;
}

a.badge-secondary:focus,
a.badge-secondary:hover {
    color: #fff;
}

a.badge-secondary:focus,
a.badge-secondary:hover {
    background-color: #535b62;
}

a.badge-secondary.focus,
a.badge-secondary:focus {
    outline: 0;
}

a.badge-secondary.focus,
a.badge-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(107, 117, 125, 0.5);
}

.badge-success {
    color: #212529;
}

.badge-success {
    background-color: #66bb6a;
}

a.badge-success:focus,
a.badge-success:hover {
    color: #212529;
}

a.badge-success:focus,
a.badge-success:hover {
    background-color: #49a54e;
}

a.badge-success.focus,
a.badge-success:focus {
    outline: 0;
}

a.badge-success.focus,
a.badge-success:focus {
    box-shadow: 0 0 0 0.2rem rgba(102, 187, 106, 0.5);
}

.badge-info {
    color: #fff;
}

.badge-info {
    background-color: #29b6f6;
}

a.badge-info:focus,
a.badge-info:hover {
    color: #fff;
}

a.badge-info:focus,
a.badge-info:hover {
    background-color: #0a9fe2;
}

a.badge-info.focus,
a.badge-info:focus {
    outline: 0;
}

a.badge-info.focus,
a.badge-info:focus {
    box-shadow: 0 0 0 0.2rem rgba(41, 182, 246, 0.5);
}

.badge-warning {
    color: #212529;
}

.badge-warning {
    background-color: #ffa726;
}

a.badge-warning:focus,
a.badge-warning:hover {
    color: #212529;
}

a.badge-warning:focus,
a.badge-warning:hover {
    background-color: #f29000;
}

a.badge-warning.focus,
a.badge-warning:focus {
    outline: 0;
}

a.badge-warning.focus,
a.badge-warning:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 167, 38, 0.5);
}

.badge-danger {
    color: #fff;
}

.badge-danger {
    background-color: #f44336;
}

a.badge-danger:focus,
a.badge-danger:hover {
    color: #fff;
}

a.badge-danger:focus,
a.badge-danger:hover {
    background-color: #ea1c0d;
}

a.badge-danger.focus,
a.badge-danger:focus {
    outline: 0;
}

a.badge-danger.focus,
a.badge-danger:focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}

.badge-light {
    color: #212529;
}

.badge-light {
    background-color: #f8f9fa;
}

a.badge-light:focus,
a.badge-light:hover {
    color: #212529;
}

a.badge-light:focus,
a.badge-light:hover {
    background-color: #dae0e5;
}

a.badge-light.focus,
a.badge-light:focus {
    outline: 0;
}

a.badge-light.focus,
a.badge-light:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
    color: #fff;
}

.badge-dark {
    background-color: #39444d;
}

a.badge-dark:focus,
a.badge-dark:hover {
    color: #fff;
}

a.badge-dark:focus,
a.badge-dark:hover {
    background-color: #232a30;
}

a.badge-dark.focus,
a.badge-dark:focus {
    outline: 0;
}

a.badge-dark.focus,
a.badge-dark:focus {
    box-shadow: 0 0 0 0.2rem rgba(57, 68, 77, 0.5);
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #eff1f2;
    border-radius: 0.3rem;
}

@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem;
    }
}

.jumbotron-fluid {
    border-radius: 0;
}

.jumbotron-fluid,
[dir='rtl'] .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
}

.alert {
    position: relative;
}

.alert {
    padding: 0.75rem 1.25rem;
    margin-bottom: 1.5rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 500;
}

.alert-dismissible {
    padding-right: 3.90625rem;
}

[dir='rtl'] .alert-dismissible {
    padding-left: 3.90625rem;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    z-index: 2;
    color: inherit;
}

.alert-dismissible .close {
    padding: 0.75rem 1.25rem;
}

.alert-dismissible .close {
    right: 0;
}

[dir='rtl'] .alert-dismissible .close {
    left: 0;
}

.alert-primary {
    color: #114e7e;
}

.alert-primary {
    background-color: #d3eafd;
    border-color: #c1e2fc;
}

.alert-primary hr {
    border-top-color: #a9d7fb;
}

.alert-primary .alert-link {
    color: #0b3251;
}

.alert-secondary {
    color: #383d41;
}

.alert-secondary {
    background-color: #e1e3e5;
    border-color: #d6d8db;
}

.alert-secondary hr {
    border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
    color: #202326;
}

.alert-success {
    color: #356137;
}

.alert-success {
    background-color: #e0f1e1;
    border-color: #d4ecd5;
}

.alert-success hr {
    border-top-color: #c2e4c4;
}

.alert-success .alert-link {
    color: #234024;
}

.alert-info {
    color: #155f80;
}

.alert-info {
    background-color: #d4f0fd;
    border-color: #c3ebfc;
}

.alert-info hr {
    border-top-color: #abe3fb;
}

.alert-info .alert-link {
    color: #0e3e54;
}

.alert-warning {
    color: #855714;
}

.alert-warning {
    background-color: #ffedd4;
    border-color: #ffe6c2;
}

.alert-warning hr {
    border-top-color: #ffdca9;
}

.alert-warning .alert-link {
    color: #593a0d;
}

.alert-danger {
    color: #7f231c;
}

.alert-danger {
    background-color: #fdd9d7;
    border-color: #fccac7;
}

.alert-danger hr {
    border-top-color: #fbb3af;
}

.alert-danger .alert-link {
    color: #551713;
}

.alert-light {
    color: #818182;
}

.alert-light {
    background-color: #fefefe;
    border-color: #fdfdfe;
}

.alert-light hr {
    border-top-color: #ececf6;
}

.alert-light .alert-link {
    color: #686868;
}

.alert-dark {
    color: #1e2328;
}

.alert-dark {
    background-color: #d7dadb;
    border-color: #c8cbcd;
}

.alert-dark hr {
    border-top-color: #bbbec1;
}

.alert-dark .alert-link {
    color: #080a0b;
}

@-webkit-keyframes progress-bar-stripes-ltr {
    0% {
        background-position: 6px 0;
    }

    to {
        background-position: 0 0;
    }
}

@keyframes progress-bar-stripes-ltr {
    0% {
        background-position: 6px 0;
    }

    to {
        background-position: 0 0;
    }
}

@-webkit-keyframes progress-bar-stripes-rtl {
    0% {
        background-position: 6px 0;
    }

    to {
        background-position: 100% 0;
    }
}

@keyframes progress-bar-stripes-rtl {
    0% {
        background-position: 6px 0;
    }

    to {
        background-position: 100% 0;
    }
}

.progress {
    display: flex;
    height: 6px;
    overflow: hidden;
    line-height: 0;
    font-size: 0.703125rem;
}

.progress {
    background-color: #e9ecef;
    border-radius: 0.25rem;
    box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    white-space: nowrap;
    transition: width 0.6s ease;
}

.progress-bar {
    text-align: center;
    background-color: var(--primary);
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none;
    }
}

.progress-bar-striped {
    background-size: 6px 6px;
}

.progress-bar-striped {
    background-image: linear-gradient(45deg,
            hsla(0, 0%, 100%, 0.15) 25%,
            transparent 0,
            transparent 50%,
            hsla(0, 0%, 100%, 0.15) 0,
            hsla(0, 0%, 100%, 0.15) 75%,
            transparent 0,
            transparent);
}

[dir='rtl'] .progress-bar-striped {
    background-image: linear-gradient(-45deg,
            hsla(0, 0%, 100%, 0.15) 25%,
            transparent 0,
            transparent 50%,
            hsla(0, 0%, 100%, 0.15) 0,
            hsla(0, 0%, 100%, 0.15) 75%,
            transparent 0,
            transparent);
}

.progress-bar-animated {
    -webkit-animation: progress-bar-stripes-ltr 1s linear infinite;
    animation: progress-bar-stripes-ltr 1s linear infinite;
}

[dir='rtl'] .progress-bar-animated {
    -webkit-animation: progress-bar-stripes-rtl 1s linear infinite;
    animation: progress-bar-stripes-rtl 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {

    .progress-bar-animated,
    [dir='rtl'] .progress-bar-animated {
        -webkit-animation: none;
        animation: none;
    }
}

.media {
    display: flex;
    align-items: flex-start;
}

.media-body {
    flex: 1;
}

.list-group {
    display: flex;
    flex-direction: column;
}

.list-group {
    margin-bottom: 0;
    border-radius: 0;
}

.list-group {
    padding-left: 0;
}

[dir='rtl'] .list-group {
    padding-right: 0;
}

.list-group-item-action {
    width: 100%;
    color: #495057;
}

.list-group-item-action {
    text-align: inherit;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    z-index: 1;
    color: #495057;
    text-decoration: none;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    background-color: #f8f9fa;
}

.list-group-item-action:active {
    color: #383b3d;
}

.list-group-item-action:active {
    background-color: #e9ecef;
}

.list-group-item {
    position: relative;
    display: block;
}

.list-group-item {
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid #f0f1f2;
}

.list-group-item:first-child,
[dir='rtl'] .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.list-group-item:last-child,
[dir='rtl'] .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
}

.list-group-item.disabled,
.list-group-item:disabled {
    background-color: #fff;
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
}

.list-group-item.active {
    background-color: var(--primary);
    border-color: var(--primary);
}

.list-group-item+.list-group-item {
    border-top-width: 0;
}

.list-group-item+.list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px;
}

.list-group-horizontal {
    flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
}

[dir='rtl'] .list-group-horizontal>.list-group-item:first-child {
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
}

[dir='rtl'] .list-group-horizontal>.list-group-item:last-child {
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-top-width: 1px;
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-left-width: 0;
}

[dir='rtl'] .list-group-horizontal>.list-group-item+.list-group-item {
    border-right-width: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
}

[dir='rtl'] .list-group-horizontal>.list-group-item+.list-group-item.active {
    margin-right: -1px;
    border-right-width: 1px;
}

@media (min-width: 374px) {
    .list-group-horizontal-xs-md {
        flex-direction: row;
    }

    .list-group-horizontal-xs-md>.list-group-item:first-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
    }

    [dir='rtl'] .list-group-horizontal-xs-md>.list-group-item:first-child {
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
    }

    .list-group-horizontal-xs-md>.list-group-item:last-child {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    [dir='rtl'] .list-group-horizontal-xs-md>.list-group-item:last-child {
        border-top-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .list-group-horizontal-xs-md>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-xs-md>.list-group-item+.list-group-item {
        border-top-width: 1px;
    }

    .list-group-horizontal-xs-md>.list-group-item+.list-group-item {
        border-left-width: 0;
    }

    [dir='rtl'] .list-group-horizontal-xs-md>.list-group-item+.list-group-item {
        border-right-width: 0;
    }

    .list-group-horizontal-xs-md>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }

    [dir='rtl'] .list-group-horizontal-xs-md>.list-group-item+.list-group-item.active {
        margin-right: -1px;
        border-right-width: 1px;
    }
}

@media (min-width: 413px) {
    .list-group-horizontal-xs-plus {
        flex-direction: row;
    }

    .list-group-horizontal-xs-plus>.list-group-item:first-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
    }

    [dir='rtl'] .list-group-horizontal-xs-plus>.list-group-item:first-child {
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
    }

    .list-group-horizontal-xs-plus>.list-group-item:last-child {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    [dir='rtl'] .list-group-horizontal-xs-plus>.list-group-item:last-child {
        border-top-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .list-group-horizontal-xs-plus>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-xs-plus>.list-group-item+.list-group-item {
        border-top-width: 1px;
    }

    .list-group-horizontal-xs-plus>.list-group-item+.list-group-item {
        border-left-width: 0;
    }

    [dir='rtl'] .list-group-horizontal-xs-plus>.list-group-item+.list-group-item {
        border-right-width: 0;
    }

    .list-group-horizontal-xs-plus>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }

    [dir='rtl'] .list-group-horizontal-xs-plus>.list-group-item+.list-group-item.active {
        margin-right: -1px;
        border-right-width: 1px;
    }
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        flex-direction: row;
    }

    .list-group-horizontal-sm>.list-group-item:first-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
    }

    [dir='rtl'] .list-group-horizontal-sm>.list-group-item:first-child {
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
    }

    .list-group-horizontal-sm>.list-group-item:last-child {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    [dir='rtl'] .list-group-horizontal-sm>.list-group-item:last-child {
        border-top-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-top-width: 1px;
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-left-width: 0;
    }

    [dir='rtl'] .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-right-width: 0;
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }

    [dir='rtl'] .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        margin-right: -1px;
        border-right-width: 1px;
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        flex-direction: row;
    }

    .list-group-horizontal-md>.list-group-item:first-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
    }

    [dir='rtl'] .list-group-horizontal-md>.list-group-item:first-child {
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
    }

    .list-group-horizontal-md>.list-group-item:last-child {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    [dir='rtl'] .list-group-horizontal-md>.list-group-item:last-child {
        border-top-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-top-width: 1px;
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-left-width: 0;
    }

    [dir='rtl'] .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-right-width: 0;
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }

    [dir='rtl'] .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        margin-right: -1px;
        border-right-width: 1px;
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        flex-direction: row;
    }

    .list-group-horizontal-lg>.list-group-item:first-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
    }

    [dir='rtl'] .list-group-horizontal-lg>.list-group-item:first-child {
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
    }

    .list-group-horizontal-lg>.list-group-item:last-child {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    [dir='rtl'] .list-group-horizontal-lg>.list-group-item:last-child {
        border-top-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-top-width: 1px;
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-left-width: 0;
    }

    [dir='rtl'] .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-right-width: 0;
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }

    [dir='rtl'] .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        margin-right: -1px;
        border-right-width: 1px;
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        flex-direction: row;
    }

    .list-group-horizontal-xl>.list-group-item:first-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
    }

    [dir='rtl'] .list-group-horizontal-xl>.list-group-item:first-child {
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
    }

    .list-group-horizontal-xl>.list-group-item:last-child {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    [dir='rtl'] .list-group-horizontal-xl>.list-group-item:last-child {
        border-top-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-top-width: 1px;
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-left-width: 0;
    }

    [dir='rtl'] .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-right-width: 0;
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }

    [dir='rtl'] .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        margin-right: -1px;
        border-right-width: 1px;
    }
}

@media (min-width: 1366px) {
    .list-group-horizontal-xxl {
        flex-direction: row;
    }

    .list-group-horizontal-xxl>.list-group-item:first-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
    }

    [dir='rtl'] .list-group-horizontal-xxl>.list-group-item:first-child {
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
    }

    .list-group-horizontal-xxl>.list-group-item:last-child {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    [dir='rtl'] .list-group-horizontal-xxl>.list-group-item:last-child {
        border-top-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-top-width: 1px;
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-left-width: 0;
    }

    [dir='rtl'] .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-right-width: 0;
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }

    [dir='rtl'] .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        margin-right: -1px;
        border-right-width: 1px;
    }
}

.list-group-flush {
    border-radius: 0;
}

.list-group-flush>.list-group-item {
    border-width: 0 0 1px;
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0;
}

.list-group-item-primary {
    color: #114e7e;
}

.list-group-item-primary {
    background-color: #c1e2fc;
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    color: #114e7e;
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    background-color: #a9d7fb;
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
}

.list-group-item-primary.list-group-item-action.active {
    background-color: #114e7e;
    border-color: #114e7e;
}

.list-group-item-secondary {
    color: #383d41;
}

.list-group-item-secondary {
    background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    color: #383d41;
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
}

.list-group-item-secondary.list-group-item-action.active {
    background-color: #383d41;
    border-color: #383d41;
}

.list-group-item-success {
    color: #356137;
}

.list-group-item-success {
    background-color: #d4ecd5;
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    color: #356137;
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    background-color: #c2e4c4;
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
}

.list-group-item-success.list-group-item-action.active {
    background-color: #356137;
    border-color: #356137;
}

.list-group-item-info {
    color: #155f80;
}

.list-group-item-info {
    background-color: #c3ebfc;
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    color: #155f80;
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    background-color: #abe3fb;
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
}

.list-group-item-info.list-group-item-action.active {
    background-color: #155f80;
    border-color: #155f80;
}

.list-group-item-warning {
    color: #855714;
}

.list-group-item-warning {
    background-color: #ffe6c2;
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    color: #855714;
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    background-color: #ffdca9;
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
}

.list-group-item-warning.list-group-item-action.active {
    background-color: #855714;
    border-color: #855714;
}

.list-group-item-danger {
    color: #7f231c;
}

.list-group-item-danger {
    background-color: #fccac7;
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    color: #7f231c;
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    background-color: #fbb3af;
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
}

.list-group-item-danger.list-group-item-action.active {
    background-color: #7f231c;
    border-color: #7f231c;
}

.list-group-item-light {
    color: #818182;
}

.list-group-item-light {
    background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    color: #818182;
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
}

.list-group-item-light.list-group-item-action.active {
    background-color: #818182;
    border-color: #818182;
}

.list-group-item-dark {
    color: #1e2328;
}

.list-group-item-dark {
    background-color: #c8cbcd;
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    color: #1e2328;
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    background-color: #bbbec1;
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
}

.list-group-item-dark.list-group-item-action.active {
    background-color: #1e2328;
    border-color: #1e2328;
}

.close {
    font-size: 1.40625rem;
    font-weight: 500;
    line-height: 1;
    color: #000;
    opacity: 0.5;
}

.close {
    text-shadow: 0 1px 0 #fff;
}

.close {
    float: right;
}

[dir='rtl'] .close {
    float: left;
}

@media (max-width: 1200px) {
    .close {
        font-size: calc(1.26563rem + 0.1875vw);
    }
}

.close:hover {
    color: #000;
    text-decoration: none;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
    opacity: 0.75;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}

a.close.disabled {
    pointer-events: none;
}

.toast {
    flex-basis: 350px;
    max-width: 350px;
    font-size: 0.875rem;
    opacity: 0;
}

.toast {
    background-color: hsla(0, 0%, 100%, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
}

.toast:not(:last-child) {
    margin-bottom: 0.75rem;
}

.toast.showing {
    opacity: 1;
}

.toast.show {
    display: block;
    opacity: 1;
}

.toast.hide {
    display: none;
}

.toast-header {
    display: flex;
    align-items: center;
    color: #6c757d;
}

.toast-header {
    padding: 0.25rem 0.75rem;
    background-color: hsla(0, 0%, 100%, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-header,
[dir='rtl'] .toast-header {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
    padding: 0.75rem;
}

.modal-open {
    overflow: hidden;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.modal {
    left: 0;
}

[dir='rtl'] .modal {
    right: 0;
}

.modal-dialog {
    position: relative;
    width: auto;
    pointer-events: none;
}

.modal-dialog {
    margin: 0.5rem;
}

.modal.fade .modal-dialog {
    transition: transform 0.3s cubic-bezier(0.1, 0.3, 0.8, 1);
}

.modal.fade .modal-dialog {
    transform: translateY(-50px);
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}

.modal.show .modal-dialog {
    transform: none;
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02);
}

.modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
    flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.modal-dialog-centered:before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: '';
}

.modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable:before {
    content: none;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    outline: 0;
}

.modal-content {
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    box-shadow: 0 0.25rem 0.5rem rgba(56, 59, 61, 0.5);
}

.modal-backdrop {
    position: fixed;
    top: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
}

.modal-backdrop {
    background-color: #383b3d;
}

.modal-backdrop {
    left: 0;
}

[dir='rtl'] .modal-backdrop {
    right: 0;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.modal-header {
    padding: 1rem;
    border-bottom: 0 solid #f0f1f2;
}

.modal-header,
[dir='rtl'] .modal-header {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.modal-header .close {
    padding: 1rem;
}

.modal-header .close {
    margin: -1rem -1rem -1rem auto;
}

[dir='rtl'] .modal-header .close {
    margin: -1rem auto -1rem -1rem;
}

.modal-title {
    line-height: 1.5;
}

.modal-title {
    margin-bottom: 0;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
}

.modal-body {
    padding: 1rem;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}

.modal-footer {
    padding: 0.75rem;
    border-top: 0 solid #f0f1f2;
}

.modal-footer,
[dir='rtl'] .modal-footer {
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.modal-footer>* {
    margin: 0.25rem;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
    }

    .modal-dialog {
        margin: 1.75rem auto;
    }

    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem);
    }

    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem);
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }

    .modal-dialog-centered:before {
        height: calc(100vh - 3.5rem);
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
    }

    .modal-content {
        box-shadow: 0 0.5rem 1rem rgba(56, 59, 61, 0.5);
    }

    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {

    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-family: Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.8203125rem;
    word-wrap: break-word;
    opacity: 0;
}

.tooltip {
    margin: 0;
    text-align: start;
    text-shadow: none;
}

.tooltip {
    text-align: left;
}

[dir='rtl'] .tooltip {
    text-align: right;
}

.tooltip.show {
    opacity: 0.9;
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}

.tooltip .arrow:before {
    position: absolute;
    content: '';
}

.tooltip .arrow:before {
    border-color: transparent;
    border-style: solid;
}

.bs-tooltip-auto[x-placement^='top'],
.bs-tooltip-top {
    padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^='top'] .arrow,
.bs-tooltip-top .arrow {
    bottom: 0;
}

.bs-tooltip-auto[x-placement^='top'] .arrow:before,
.bs-tooltip-top .arrow:before {
    top: 0;
}

.bs-tooltip-auto[x-placement^='top'] .arrow:before,
.bs-tooltip-top .arrow:before {
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
}

.bs-tooltip-auto[x-placement^='right'],
.bs-tooltip-right {
    padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^='right'] .arrow,
.bs-tooltip-right .arrow {
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-auto[x-placement^='right'] .arrow,
.bs-tooltip-right .arrow {
    left: 0;
}

[dir='rtl'] .bs-tooltip-auto[x-placement^='right'] .arrow,
[dir='rtl'] .bs-tooltip-right .arrow {
    right: 0;
}

.bs-tooltip-auto[x-placement^='right'] .arrow:before,
.bs-tooltip-right .arrow:before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
}

[dir='rtl'] .bs-tooltip-auto[x-placement^='right'] .arrow:before,
[dir='rtl'] .bs-tooltip-right .arrow:before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
}

.bs-tooltip-auto[x-placement^='bottom'],
.bs-tooltip-bottom {
    padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow,
.bs-tooltip-bottom .arrow {
    top: 0;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow:before,
.bs-tooltip-bottom .arrow:before {
    bottom: 0;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow:before,
.bs-tooltip-bottom .arrow:before {
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
}

.bs-tooltip-auto[x-placement^='left'],
.bs-tooltip-left {
    padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^='left'] .arrow,
.bs-tooltip-left .arrow {
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-auto[x-placement^='left'] .arrow,
.bs-tooltip-left .arrow {
    right: 0;
}

[dir='rtl'] .bs-tooltip-auto[x-placement^='left'] .arrow,
[dir='rtl'] .bs-tooltip-left .arrow {
    left: 0;
}

.bs-tooltip-auto[x-placement^='left'] .arrow:before,
.bs-tooltip-left .arrow:before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
}

[dir='rtl'] .bs-tooltip-auto[x-placement^='left'] .arrow:before,
[dir='rtl'] .bs-tooltip-left .arrow:before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
}

.tooltip-inner {
    max-width: 200px;
    color: #fff;
}

.tooltip-inner {
    padding: 0.25rem 0.5rem;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem;
}

.popover {
    position: absolute;
    top: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.8203125rem;
    word-wrap: break-word;
}

.popover {
    text-align: start;
    text-shadow: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #f0f1f2;
    border-radius: 0.3rem;
    box-shadow: 0 2px 3px rgba(56, 59, 61, 0.05);
}

.popover {
    left: 0;
    text-align: left;
}

[dir='rtl'] .popover {
    right: 0;
    text-align: right;
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
}

.popover .arrow {
    margin: 0 0.3rem;
}

.popover .arrow:after,
.popover .arrow:before {
    position: absolute;
    display: block;
    content: '';
}

.popover .arrow:after,
.popover .arrow:before {
    border-color: transparent;
    border-style: solid;
}

.bs-popover-auto[x-placement^='top'],
.bs-popover-top {
    margin-bottom: 0.5rem;
}

.bs-popover-auto[x-placement^='top']>.arrow,
.bs-popover-top>.arrow {
    bottom: calc(-0.5rem - 1px);
}

.bs-popover-auto[x-placement^='top']>.arrow:before,
.bs-popover-top>.arrow:before {
    bottom: 0;
}

.bs-popover-auto[x-placement^='top']>.arrow:before,
.bs-popover-top>.arrow:before {
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #f0f1f2;
}

.bs-popover-auto[x-placement^='top']>.arrow:after,
.bs-popover-top>.arrow:after {
    bottom: 1px;
}

.bs-popover-auto[x-placement^='top']>.arrow:after,
.bs-popover-top>.arrow:after {
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
}

.bs-popover-auto[x-placement^='right'],
.bs-popover-right {
    margin-left: 0.5rem;
}

[dir='rtl'] .bs-popover-auto[x-placement^='right'],
[dir='rtl'] .bs-popover-right {
    margin-right: 0.5rem;
}

.bs-popover-auto[x-placement^='right']>.arrow,
.bs-popover-right>.arrow {
    width: 0.5rem;
    height: 1rem;
}

.bs-popover-auto[x-placement^='right']>.arrow,
.bs-popover-right>.arrow {
    margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^='right']>.arrow,
.bs-popover-right>.arrow {
    left: calc(-0.5rem - 1px);
}

[dir='rtl'] .bs-popover-auto[x-placement^='right']>.arrow,
[dir='rtl'] .bs-popover-right>.arrow {
    right: calc(-0.5rem - 1px);
}

.bs-popover-auto[x-placement^='right']>.arrow:before,
.bs-popover-right>.arrow:before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #f0f1f2;
}

[dir='rtl'] .bs-popover-auto[x-placement^='right']>.arrow:before,
[dir='rtl'] .bs-popover-right>.arrow:before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #f0f1f2;
}

.bs-popover-auto[x-placement^='right']>.arrow:after,
.bs-popover-right>.arrow:after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
}

[dir='rtl'] .bs-popover-auto[x-placement^='right']>.arrow:after,
[dir='rtl'] .bs-popover-right>.arrow:after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
}

.bs-popover-auto[x-placement^='bottom'],
.bs-popover-bottom {
    margin-top: 0.5rem;
}

.bs-popover-auto[x-placement^='bottom']>.arrow,
.bs-popover-bottom>.arrow {
    top: calc(-0.5rem - 1px);
}

.bs-popover-auto[x-placement^='bottom']>.arrow:before,
.bs-popover-bottom>.arrow:before {
    top: 0;
}

.bs-popover-auto[x-placement^='bottom']>.arrow:before,
.bs-popover-bottom>.arrow:before {
    border-width: 0 0.5rem 0.5rem;
    border-bottom-color: #f0f1f2;
}

.bs-popover-auto[x-placement^='bottom']>.arrow:after,
.bs-popover-bottom>.arrow:after {
    top: 1px;
}

.bs-popover-auto[x-placement^='bottom']>.arrow:after,
.bs-popover-bottom>.arrow:after {
    border-width: 0 0.5rem 0.5rem;
    border-bottom-color: #fff;
}

.bs-popover-auto[x-placement^='bottom'] .popover-header:before,
.bs-popover-bottom .popover-header:before {
    position: absolute;
    top: 0;
    display: block;
    width: 1rem;
    content: '';
}

.bs-popover-auto[x-placement^='bottom'] .popover-header:before,
.bs-popover-bottom .popover-header:before {
    border-bottom: 1px solid #f5f6f7;
}

.bs-popover-auto[x-placement^='bottom'] .popover-header:before,
.bs-popover-bottom .popover-header:before {
    left: 50%;
    margin-left: -0.5rem;
}

[dir='rtl'] .bs-popover-auto[x-placement^='bottom'] .popover-header:before,
[dir='rtl'] .bs-popover-bottom .popover-header:before {
    right: 50%;
    margin-right: -0.5rem;
}

.bs-popover-auto[x-placement^='left'],
.bs-popover-left {
    margin-right: 0.5rem;
}

[dir='rtl'] .bs-popover-auto[x-placement^='left'],
[dir='rtl'] .bs-popover-left {
    margin-left: 0.5rem;
}

.bs-popover-auto[x-placement^='left']>.arrow,
.bs-popover-left>.arrow {
    width: 0.5rem;
    height: 1rem;
}

.bs-popover-auto[x-placement^='left']>.arrow,
.bs-popover-left>.arrow {
    margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^='left']>.arrow,
.bs-popover-left>.arrow {
    right: calc(-0.5rem - 1px);
}

[dir='rtl'] .bs-popover-auto[x-placement^='left']>.arrow,
[dir='rtl'] .bs-popover-left>.arrow {
    left: calc(-0.5rem - 1px);
}

.bs-popover-auto[x-placement^='left']>.arrow:before,
.bs-popover-left>.arrow:before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #f0f1f2;
}

[dir='rtl'] .bs-popover-auto[x-placement^='left']>.arrow:before,
[dir='rtl'] .bs-popover-left>.arrow:before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #f0f1f2;
}

.bs-popover-auto[x-placement^='left']>.arrow:after,
.bs-popover-left>.arrow:after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
}

[dir='rtl'] .bs-popover-auto[x-placement^='left']>.arrow:after,
[dir='rtl'] .bs-popover-left>.arrow:after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
}

.popover-header {
    font-size: 0.9375rem;
    color: rgba(57, 68, 77, 0.84);
}

.popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    background-color: #f5f6f7;
    border-bottom: 1px solid #e7e9ec;
}

.popover-header,
[dir='rtl'] .popover-header {
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
    display: none;
}

.popover-body {
    color: #383b3d;
}

.popover-body {
    padding: 0.5rem 0.75rem;
}

.carousel {
    position: relative;
}

.carousel.pointer-event {
    touch-action: pan-y;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel-inner:after {
    display: block;
    content: '';
}

.carousel-inner:after {
    clear: both;
}

.carousel-item {
    position: relative;
    display: none;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
}

.carousel-item {
    float: left;
    margin-right: -100%;
}

[dir='rtl'] .carousel-item {
    float: right;
    margin-left: -100%;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-item {
        transition: none;
    }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block;
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
    transform: translateX(100%);
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right),
[dir='rtl'] .active.carousel-item-right,
[dir='rtl'] .carousel-item-next:not(.carousel-item-left) {
    transform: translateX(-100%);
}

[dir='rtl'] .active.carousel-item-left,
[dir='rtl'] .carousel-item-prev:not(.carousel-item-right) {
    transform: translateX(100%);
}

.carousel-fade .carousel-item {
    opacity: 0;
}

.carousel-fade .carousel-item {
    transition-property: opacity;
    transform: none;
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {

    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        transition: none;
    }
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    opacity: 0.5;
    transition: opacity 0.15s ease;
}

.carousel-control-next,
.carousel-control-prev {
    text-align: center;
}

@media (prefers-reduced-motion: reduce) {

    .carousel-control-next,
    .carousel-control-prev {
        transition: none;
    }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next,
[dir='rtl'] .carousel-control-prev {
    right: 0;
}

[dir='rtl'] .carousel-control-next {
    left: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' width='8' height='8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' width='8' height='8'%3E%3Cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
    position: absolute;
    bottom: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    list-style: none;
}

.carousel-indicators {
    right: 0;
    left: 0;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
}

[dir='rtl'] .carousel-indicators {
    left: 0;
    right: 0;
    padding-right: 0;
    margin-left: 15%;
    margin-right: 15%;
}

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    text-indent: -999px;
    opacity: 0.5;
    transition: opacity 0.6s ease;
}

.carousel-indicators li {
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}

.carousel-indicators li,
[dir='rtl'] .carousel-indicators li {
    margin-right: 3px;
    margin-left: 3px;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
        transition: none;
    }
}

.carousel-indicators .active {
    opacity: 1;
}

.carousel-caption {
    position: absolute;
    bottom: 20px;
    z-index: 10;
    color: #fff;
}

.carousel-caption {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
}

.carousel-caption,
[dir='rtl'] .carousel-caption {
    right: 15%;
    left: 15%;
}

@-webkit-keyframes spinner-border-ltr {
    to {
        transform: rotate(1turn);
    }
}

@keyframes spinner-border-ltr {
    to {
        transform: rotate(1turn);
    }
}

@-webkit-keyframes spinner-border-rtl {
    to {
        transform: rotate(-1turn);
    }
}

@keyframes spinner-border-rtl {
    to {
        transform: rotate(-1turn);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
}

.spinner-border {
    border: 0.25em solid;
    border-radius: 50%;
}

.spinner-border {
    border-right-color: transparent;
    -webkit-animation: spinner-border-ltr 0.75s linear infinite;
    animation: spinner-border-ltr 0.75s linear infinite;
}

[dir='rtl'] .spinner-border {
    border-left-color: transparent;
    -webkit-animation: spinner-border-rtl 0.75s linear infinite;
    animation: spinner-border-rtl 0.75s linear infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
}

.spinner-border-sm {
    border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
    0% {
        transform: scale(0);
    }

    50% {
        opacity: 1;
        transform: none;
    }
}

@keyframes spinner-grow {
    0% {
        transform: scale(0);
    }

    50% {
        opacity: 1;
        transform: none;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    opacity: 0;
}

.spinner-grow {
    background-color: currentColor;
    border-radius: 50%;
}

.spinner-grow,
[dir='rtl'] .spinner-grow {
    -webkit-animation: spinner-grow 0.75s linear infinite;
    animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.bg-primary {
    background-color: var(--primary) !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background-color: var(--primary-hover) !important;
}

.bg-secondary {
    background-color: #6b757d !important;
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
    background-color: #535b62 !important;
}

.bg-success {
    background-color: #66bb6a !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
    background-color: #49a54e !important;
}

.bg-info {
    background-color: #29b6f6 !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
    background-color: #0a9fe2 !important;
}

.bg-warning {
    background-color: #ffa726 !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
    background-color: #f29000 !important;
}

.bg-danger {
    background-color: #f44336 !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
    background-color: #ea1c0d !important;
}

.bg-light {
    background-color: #f8f9fa !important;
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
    background-color: #dae0e5 !important;
}

.bg-dark {
    background-color: #39444d !important;
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
    background-color: #232a30 !important;
}

.bg-dark-blue {
    background-color: var(--primary-background) !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.border-white {
    border-color: #fff !important;
}

.rounded-sm {
    border-radius: 0.2rem !important;
}

.rounded {
    border-radius: 0.25rem !important;
}

.rounded-top,
[dir='rtl'] .rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
}

.rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

[dir='rtl'] .rounded-right {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-bottom,
[dir='rtl'] .rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

[dir='rtl'] .rounded-left {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.rounded-lg {
    border-radius: 0.3rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.clearfix:after {
    display: block;
    content: '';
}

.clearfix:after {
    clear: both;
}

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

@media (min-width: 374px) {
    .d-xs-md-none {
        display: none !important;
    }

    .d-xs-md-inline {
        display: inline !important;
    }

    .d-xs-md-inline-block {
        display: inline-block !important;
    }

    .d-xs-md-block {
        display: block !important;
    }

    .d-xs-md-table {
        display: table !important;
    }

    .d-xs-md-table-row {
        display: table-row !important;
    }

    .d-xs-md-table-cell {
        display: table-cell !important;
    }

    .d-xs-md-flex {
        display: flex !important;
    }

    .d-xs-md-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 413px) {
    .d-xs-plus-none {
        display: none !important;
    }

    .d-xs-plus-inline {
        display: inline !important;
    }

    .d-xs-plus-inline-block {
        display: inline-block !important;
    }

    .d-xs-plus-block {
        display: block !important;
    }

    .d-xs-plus-table {
        display: table !important;
    }

    .d-xs-plus-table-row {
        display: table-row !important;
    }

    .d-xs-plus-table-cell {
        display: table-cell !important;
    }

    .d-xs-plus-flex {
        display: flex !important;
    }

    .d-xs-plus-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important;
    }

    .d-sm-inline {
        display: inline !important;
    }

    .d-sm-inline-block {
        display: inline-block !important;
    }

    .d-sm-block {
        display: block !important;
    }

    .d-sm-table {
        display: table !important;
    }

    .d-sm-table-row {
        display: table-row !important;
    }

    .d-sm-table-cell {
        display: table-cell !important;
    }

    .d-sm-flex {
        display: flex !important;
    }

    .d-sm-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }

    .d-md-inline {
        display: inline !important;
    }

    .d-md-inline-block {
        display: inline-block !important;
    }

    .d-md-block {
        display: block !important;
    }

    .d-md-table {
        display: table !important;
    }

    .d-md-table-row {
        display: table-row !important;
    }

    .d-md-table-cell {
        display: table-cell !important;
    }

    .d-md-flex {
        display: flex !important;
    }

    .d-md-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }

    .d-lg-inline {
        display: inline !important;
    }

    .d-lg-inline-block {
        display: inline-block !important;
    }

    .d-lg-block {
        display: block !important;
    }

    .d-lg-table {
        display: table !important;
    }

    .d-lg-table-row {
        display: table-row !important;
    }

    .d-lg-table-cell {
        display: table-cell !important;
    }

    .d-lg-flex {
        display: flex !important;
    }

    .d-lg-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important;
    }

    .d-xl-inline {
        display: inline !important;
    }

    .d-xl-inline-block {
        display: inline-block !important;
    }

    .d-xl-block {
        display: block !important;
    }

    .d-xl-table {
        display: table !important;
    }

    .d-xl-table-row {
        display: table-row !important;
    }

    .d-xl-table-cell {
        display: table-cell !important;
    }

    .d-xl-flex {
        display: flex !important;
    }

    .d-xl-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 1366px) {
    .d-xxl-none {
        display: none !important;
    }

    .d-xxl-inline {
        display: inline !important;
    }

    .d-xxl-inline-block {
        display: inline-block !important;
    }

    .d-xxl-block {
        display: block !important;
    }

    .d-xxl-table {
        display: table !important;
    }

    .d-xxl-table-row {
        display: table-row !important;
    }

    .d-xxl-table-cell {
        display: table-cell !important;
    }

    .d-xxl-flex {
        display: flex !important;
    }

    .d-xxl-inline-flex {
        display: inline-flex !important;
    }
}

@media print {
    .d-print-none {
        display: none !important;
    }

    .d-print-inline {
        display: inline !important;
    }

    .d-print-inline-block {
        display: inline-block !important;
    }

    .d-print-block {
        display: block !important;
    }

    .d-print-table {
        display: table !important;
    }

    .d-print-table-row {
        display: table-row !important;
    }

    .d-print-table-cell {
        display: table-cell !important;
    }

    .d-print-flex {
        display: flex !important;
    }

    .d-print-inline-flex {
        display: inline-flex !important;
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
}

.embed-responsive {
    padding: 0;
}

.embed-responsive:before {
    display: block;
    content: '';
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    border: 0;
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    left: 0;
}

[dir='rtl'] .embed-responsive .embed-responsive-item,
[dir='rtl'] .embed-responsive embed,
[dir='rtl'] .embed-responsive iframe,
[dir='rtl'] .embed-responsive object,
[dir='rtl'] .embed-responsive video {
    right: 0;
}

.embed-responsive-21by9:before {
    padding-top: 42.8571428571%;
}

.embed-responsive-16by9:before {
    padding-top: 56.25%;
}

.embed-responsive-4by3:before {
    padding-top: 75%;
}

.embed-responsive-1by1:before {
    padding-top: 100%;
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row-reverse {
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    flex-direction: column-reverse !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.flex-fill {
    flex: 1 1 auto !important;
}

.flex-grow-0 {
    flex-grow: 0 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    flex-shrink: 1 !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.align-content-start {
    align-content: flex-start !important;
}

.align-content-end {
    align-content: flex-end !important;
}

.align-content-center {
    align-content: center !important;
}

.align-content-between {
    align-content: space-between !important;
}

.align-content-around {
    align-content: space-around !important;
}

.align-content-stretch {
    align-content: stretch !important;
}

.align-self-auto {
    align-self: auto !important;
}

.align-self-start {
    align-self: flex-start !important;
}

.align-self-end {
    align-self: flex-end !important;
}

.align-self-center {
    align-self: center !important;
}

.align-self-baseline {
    align-self: baseline !important;
}

.align-self-stretch {
    align-self: stretch !important;
}

@media (min-width: 374px) {
    .flex-xs-md-row {
        flex-direction: row !important;
    }

    .flex-xs-md-column {
        flex-direction: column !important;
    }

    .flex-xs-md-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-xs-md-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-xs-md-wrap {
        flex-wrap: wrap !important;
    }

    .flex-xs-md-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-xs-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-xs-md-fill {
        flex: 1 1 auto !important;
    }

    .flex-xs-md-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-xs-md-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-xs-md-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-xs-md-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-xs-md-start {
        justify-content: flex-start !important;
    }

    .justify-content-xs-md-end {
        justify-content: flex-end !important;
    }

    .justify-content-xs-md-center {
        justify-content: center !important;
    }

    .justify-content-xs-md-between {
        justify-content: space-between !important;
    }

    .justify-content-xs-md-around {
        justify-content: space-around !important;
    }

    .align-items-xs-md-start {
        align-items: flex-start !important;
    }

    .align-items-xs-md-end {
        align-items: flex-end !important;
    }

    .align-items-xs-md-center {
        align-items: center !important;
    }

    .align-items-xs-md-baseline {
        align-items: baseline !important;
    }

    .align-items-xs-md-stretch {
        align-items: stretch !important;
    }

    .align-content-xs-md-start {
        align-content: flex-start !important;
    }

    .align-content-xs-md-end {
        align-content: flex-end !important;
    }

    .align-content-xs-md-center {
        align-content: center !important;
    }

    .align-content-xs-md-between {
        align-content: space-between !important;
    }

    .align-content-xs-md-around {
        align-content: space-around !important;
    }

    .align-content-xs-md-stretch {
        align-content: stretch !important;
    }

    .align-self-xs-md-auto {
        align-self: auto !important;
    }

    .align-self-xs-md-start {
        align-self: flex-start !important;
    }

    .align-self-xs-md-end {
        align-self: flex-end !important;
    }

    .align-self-xs-md-center {
        align-self: center !important;
    }

    .align-self-xs-md-baseline {
        align-self: baseline !important;
    }

    .align-self-xs-md-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 413px) {
    .flex-xs-plus-row {
        flex-direction: row !important;
    }

    .flex-xs-plus-column {
        flex-direction: column !important;
    }

    .flex-xs-plus-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-xs-plus-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-xs-plus-wrap {
        flex-wrap: wrap !important;
    }

    .flex-xs-plus-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-xs-plus-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-xs-plus-fill {
        flex: 1 1 auto !important;
    }

    .flex-xs-plus-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-xs-plus-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-xs-plus-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-xs-plus-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-xs-plus-start {
        justify-content: flex-start !important;
    }

    .justify-content-xs-plus-end {
        justify-content: flex-end !important;
    }

    .justify-content-xs-plus-center {
        justify-content: center !important;
    }

    .justify-content-xs-plus-between {
        justify-content: space-between !important;
    }

    .justify-content-xs-plus-around {
        justify-content: space-around !important;
    }

    .align-items-xs-plus-start {
        align-items: flex-start !important;
    }

    .align-items-xs-plus-end {
        align-items: flex-end !important;
    }

    .align-items-xs-plus-center {
        align-items: center !important;
    }

    .align-items-xs-plus-baseline {
        align-items: baseline !important;
    }

    .align-items-xs-plus-stretch {
        align-items: stretch !important;
    }

    .align-content-xs-plus-start {
        align-content: flex-start !important;
    }

    .align-content-xs-plus-end {
        align-content: flex-end !important;
    }

    .align-content-xs-plus-center {
        align-content: center !important;
    }

    .align-content-xs-plus-between {
        align-content: space-between !important;
    }

    .align-content-xs-plus-around {
        align-content: space-around !important;
    }

    .align-content-xs-plus-stretch {
        align-content: stretch !important;
    }

    .align-self-xs-plus-auto {
        align-self: auto !important;
    }

    .align-self-xs-plus-start {
        align-self: flex-start !important;
    }

    .align-self-xs-plus-end {
        align-self: flex-end !important;
    }

    .align-self-xs-plus-center {
        align-self: center !important;
    }

    .align-self-xs-plus-baseline {
        align-self: baseline !important;
    }

    .align-self-xs-plus-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 576px) {
    .flex-sm-row {
        flex-direction: row !important;
    }

    .flex-sm-column {
        flex-direction: column !important;
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important;
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-sm-fill {
        flex: 1 1 auto !important;
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-sm-start {
        justify-content: flex-start !important;
    }

    .justify-content-sm-end {
        justify-content: flex-end !important;
    }

    .justify-content-sm-center {
        justify-content: center !important;
    }

    .justify-content-sm-between {
        justify-content: space-between !important;
    }

    .justify-content-sm-around {
        justify-content: space-around !important;
    }

    .align-items-sm-start {
        align-items: flex-start !important;
    }

    .align-items-sm-end {
        align-items: flex-end !important;
    }

    .align-items-sm-center {
        align-items: center !important;
    }

    .align-items-sm-baseline {
        align-items: baseline !important;
    }

    .align-items-sm-stretch {
        align-items: stretch !important;
    }

    .align-content-sm-start {
        align-content: flex-start !important;
    }

    .align-content-sm-end {
        align-content: flex-end !important;
    }

    .align-content-sm-center {
        align-content: center !important;
    }

    .align-content-sm-between {
        align-content: space-between !important;
    }

    .align-content-sm-around {
        align-content: space-around !important;
    }

    .align-content-sm-stretch {
        align-content: stretch !important;
    }

    .align-self-sm-auto {
        align-self: auto !important;
    }

    .align-self-sm-start {
        align-self: flex-start !important;
    }

    .align-self-sm-end {
        align-self: flex-end !important;
    }

    .align-self-sm-center {
        align-self: center !important;
    }

    .align-self-sm-baseline {
        align-self: baseline !important;
    }

    .align-self-sm-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        flex-direction: row !important;
    }

    .flex-md-column {
        flex-direction: column !important;
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-md-wrap {
        flex-wrap: wrap !important;
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-md-fill {
        flex: 1 1 auto !important;
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-md-start {
        justify-content: flex-start !important;
    }

    .justify-content-md-end {
        justify-content: flex-end !important;
    }

    .justify-content-md-center {
        justify-content: center !important;
    }

    .justify-content-md-between {
        justify-content: space-between !important;
    }

    .justify-content-md-around {
        justify-content: space-around !important;
    }

    .align-items-md-start {
        align-items: flex-start !important;
    }

    .align-items-md-end {
        align-items: flex-end !important;
    }

    .align-items-md-center {
        align-items: center !important;
    }

    .align-items-md-baseline {
        align-items: baseline !important;
    }

    .align-items-md-stretch {
        align-items: stretch !important;
    }

    .align-content-md-start {
        align-content: flex-start !important;
    }

    .align-content-md-end {
        align-content: flex-end !important;
    }

    .align-content-md-center {
        align-content: center !important;
    }

    .align-content-md-between {
        align-content: space-between !important;
    }

    .align-content-md-around {
        align-content: space-around !important;
    }

    .align-content-md-stretch {
        align-content: stretch !important;
    }

    .align-self-md-auto {
        align-self: auto !important;
    }

    .align-self-md-start {
        align-self: flex-start !important;
    }

    .align-self-md-end {
        align-self: flex-end !important;
    }

    .align-self-md-center {
        align-self: center !important;
    }

    .align-self-md-baseline {
        align-self: baseline !important;
    }

    .align-self-md-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 992px) {
    .flex-lg-row {
        flex-direction: row !important;
    }

    .flex-lg-column {
        flex-direction: column !important;
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important;
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-lg-fill {
        flex: 1 1 auto !important;
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-lg-start {
        justify-content: flex-start !important;
    }

    .justify-content-lg-end {
        justify-content: flex-end !important;
    }

    .justify-content-lg-center {
        justify-content: center !important;
    }

    .justify-content-lg-between {
        justify-content: space-between !important;
    }

    .justify-content-lg-around {
        justify-content: space-around !important;
    }

    .align-items-lg-start {
        align-items: flex-start !important;
    }

    .align-items-lg-end {
        align-items: flex-end !important;
    }

    .align-items-lg-center {
        align-items: center !important;
    }

    .align-items-lg-baseline {
        align-items: baseline !important;
    }

    .align-items-lg-stretch {
        align-items: stretch !important;
    }

    .align-content-lg-start {
        align-content: flex-start !important;
    }

    .align-content-lg-end {
        align-content: flex-end !important;
    }

    .align-content-lg-center {
        align-content: center !important;
    }

    .align-content-lg-between {
        align-content: space-between !important;
    }

    .align-content-lg-around {
        align-content: space-around !important;
    }

    .align-content-lg-stretch {
        align-content: stretch !important;
    }

    .align-self-lg-auto {
        align-self: auto !important;
    }

    .align-self-lg-start {
        align-self: flex-start !important;
    }

    .align-self-lg-end {
        align-self: flex-end !important;
    }

    .align-self-lg-center {
        align-self: center !important;
    }

    .align-self-lg-baseline {
        align-self: baseline !important;
    }

    .align-self-lg-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 1200px) {
    .flex-xl-row {
        flex-direction: row !important;
    }

    .flex-xl-column {
        flex-direction: column !important;
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important;
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-xl-fill {
        flex: 1 1 auto !important;
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-xl-start {
        justify-content: flex-start !important;
    }

    .justify-content-xl-end {
        justify-content: flex-end !important;
    }

    .justify-content-xl-center {
        justify-content: center !important;
    }

    .justify-content-xl-between {
        justify-content: space-between !important;
    }

    .justify-content-xl-around {
        justify-content: space-around !important;
    }

    .align-items-xl-start {
        align-items: flex-start !important;
    }

    .align-items-xl-end {
        align-items: flex-end !important;
    }

    .align-items-xl-center {
        align-items: center !important;
    }

    .align-items-xl-baseline {
        align-items: baseline !important;
    }

    .align-items-xl-stretch {
        align-items: stretch !important;
    }

    .align-content-xl-start {
        align-content: flex-start !important;
    }

    .align-content-xl-end {
        align-content: flex-end !important;
    }

    .align-content-xl-center {
        align-content: center !important;
    }

    .align-content-xl-between {
        align-content: space-between !important;
    }

    .align-content-xl-around {
        align-content: space-around !important;
    }

    .align-content-xl-stretch {
        align-content: stretch !important;
    }

    .align-self-xl-auto {
        align-self: auto !important;
    }

    .align-self-xl-start {
        align-self: flex-start !important;
    }

    .align-self-xl-end {
        align-self: flex-end !important;
    }

    .align-self-xl-center {
        align-self: center !important;
    }

    .align-self-xl-baseline {
        align-self: baseline !important;
    }

    .align-self-xl-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 1366px) {
    .flex-xxl-row {
        flex-direction: row !important;
    }

    .flex-xxl-column {
        flex-direction: column !important;
    }

    .flex-xxl-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-xxl-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-xxl-wrap {
        flex-wrap: wrap !important;
    }

    .flex-xxl-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-xxl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-xxl-fill {
        flex: 1 1 auto !important;
    }

    .flex-xxl-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-xxl-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-xxl-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-xxl-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-xxl-start {
        justify-content: flex-start !important;
    }

    .justify-content-xxl-end {
        justify-content: flex-end !important;
    }

    .justify-content-xxl-center {
        justify-content: center !important;
    }

    .justify-content-xxl-between {
        justify-content: space-between !important;
    }

    .justify-content-xxl-around {
        justify-content: space-around !important;
    }

    .align-items-xxl-start {
        align-items: flex-start !important;
    }

    .align-items-xxl-end {
        align-items: flex-end !important;
    }

    .align-items-xxl-center {
        align-items: center !important;
    }

    .align-items-xxl-baseline {
        align-items: baseline !important;
    }

    .align-items-xxl-stretch {
        align-items: stretch !important;
    }

    .align-content-xxl-start {
        align-content: flex-start !important;
    }

    .align-content-xxl-end {
        align-content: flex-end !important;
    }

    .align-content-xxl-center {
        align-content: center !important;
    }

    .align-content-xxl-between {
        align-content: space-between !important;
    }

    .align-content-xxl-around {
        align-content: space-around !important;
    }

    .align-content-xxl-stretch {
        align-content: stretch !important;
    }

    .align-self-xxl-auto {
        align-self: auto !important;
    }

    .align-self-xxl-start {
        align-self: flex-start !important;
    }

    .align-self-xxl-end {
        align-self: flex-end !important;
    }

    .align-self-xxl-center {
        align-self: center !important;
    }

    .align-self-xxl-baseline {
        align-self: baseline !important;
    }

    .align-self-xxl-stretch {
        align-self: stretch !important;
    }
}

.float-left {
    float: left !important;
}

.float-right,
[dir='rtl'] .float-left {
    float: right !important;
}

[dir='rtl'] .float-right {
    float: left !important;
}

.float-none {
    float: none !important;
}

@media (min-width: 374px) {
    .float-xs-md-left {
        float: left !important;
    }

    .float-xs-md-right,
    [dir='rtl'] .float-xs-md-left {
        float: right !important;
    }

    [dir='rtl'] .float-xs-md-right {
        float: left !important;
    }

    .float-xs-md-none {
        float: none !important;
    }
}

@media (min-width: 413px) {
    .float-xs-plus-left {
        float: left !important;
    }

    .float-xs-plus-right,
    [dir='rtl'] .float-xs-plus-left {
        float: right !important;
    }

    [dir='rtl'] .float-xs-plus-right {
        float: left !important;
    }

    .float-xs-plus-none {
        float: none !important;
    }
}

@media (min-width: 576px) {
    .float-sm-left {
        float: left !important;
    }

    .float-sm-right,
    [dir='rtl'] .float-sm-left {
        float: right !important;
    }

    [dir='rtl'] .float-sm-right {
        float: left !important;
    }

    .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 768px) {
    .float-md-left {
        float: left !important;
    }

    .float-md-right,
    [dir='rtl'] .float-md-left {
        float: right !important;
    }

    [dir='rtl'] .float-md-right {
        float: left !important;
    }

    .float-md-none {
        float: none !important;
    }
}

@media (min-width: 992px) {
    .float-lg-left {
        float: left !important;
    }

    .float-lg-right,
    [dir='rtl'] .float-lg-left {
        float: right !important;
    }

    [dir='rtl'] .float-lg-right {
        float: left !important;
    }

    .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1200px) {
    .float-xl-left {
        float: left !important;
    }

    .float-xl-right,
    [dir='rtl'] .float-xl-left {
        float: right !important;
    }

    [dir='rtl'] .float-xl-right {
        float: left !important;
    }

    .float-xl-none {
        float: none !important;
    }
}

@media (min-width: 1366px) {
    .float-xxl-left {
        float: left !important;
    }

    .float-xxl-right,
    [dir='rtl'] .float-xxl-left {
        float: right !important;
    }

    [dir='rtl'] .float-xxl-right {
        float: left !important;
    }

    .float-xxl-none {
        float: none !important;
    }
}

.user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    -ms-user-select: all !important;
    user-select: all !important;
}

.user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;
}

.user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
}

.fixed-top {
    position: fixed;
    top: 0;
    z-index: 1030;
}

.fixed-top,
[dir='rtl'] .fixed-top {
    right: 0;
    left: 0;
}

.fixed-bottom {
    position: fixed;
    bottom: 0;
    z-index: 1030;
}

.fixed-bottom,
[dir='rtl'] .fixed-bottom {
    right: 0;
    left: 0;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
}

.sr-only {
    padding: 0;
    margin: -1px;
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
    box-shadow: none !important;
}

.mw-100 {
    max-width: 100% !important;
}

.mh-100 {
    max-height: 100% !important;
}

.min-vw-100 {
    min-width: 100vw !important;
}

.min-vh-100 {
    min-height: 100vh !important;
}

.vw-100 {
    width: 100vw !important;
}

.vh-100 {
    height: 100vh !important;
}

.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

[dir='rtl'] .mr-0,
[dir='rtl'] .mx-0 {
    margin-left: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

[dir='rtl'] .ml-0,
[dir='rtl'] .mx-0 {
    margin-right: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

[dir='rtl'] .mr-1,
[dir='rtl'] .mx-1 {
    margin-left: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

[dir='rtl'] .ml-1,
[dir='rtl'] .mx-1 {
    margin-right: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

[dir='rtl'] .mr-2,
[dir='rtl'] .mx-2 {
    margin-left: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

[dir='rtl'] .ml-2,
[dir='rtl'] .mx-2 {
    margin-right: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

[dir='rtl'] .mr-3,
[dir='rtl'] .mx-3 {
    margin-left: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

[dir='rtl'] .ml-3,
[dir='rtl'] .mx-3 {
    margin-right: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

[dir='rtl'] .mr-4,
[dir='rtl'] .mx-4 {
    margin-left: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

[dir='rtl'] .ml-4,
[dir='rtl'] .mx-4 {
    margin-right: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

[dir='rtl'] .mr-5,
[dir='rtl'] .mx-5 {
    margin-left: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

[dir='rtl'] .ml-5,
[dir='rtl'] .mx-5 {
    margin-right: 3rem !important;
}

.m-4pt {
    margin: 0.25rem !important;
}

.mt-4pt,
.my-4pt {
    margin-top: 0.25rem !important;
}

.mr-4pt,
.mx-4pt {
    margin-right: 0.25rem !important;
}

[dir='rtl'] .mr-4pt,
[dir='rtl'] .mx-4pt {
    margin-left: 0.25rem !important;
}

.mb-4pt,
.my-4pt {
    margin-bottom: 0.25rem !important;
}

.ml-4pt,
.mx-4pt {
    margin-left: 0.25rem !important;
}

[dir='rtl'] .ml-4pt,
[dir='rtl'] .mx-4pt {
    margin-right: 0.25rem !important;
}

.m-8pt {
    margin: 0.5rem !important;
}

.mt-8pt,
.my-8pt {
    margin-top: 0.5rem !important;
}

.mr-8pt,
.mx-8pt {
    margin-right: 0.5rem !important;
}

[dir='rtl'] .mr-8pt,
[dir='rtl'] .mx-8pt {
    margin-left: 0.5rem !important;
}

.mb-8pt,
.my-8pt {
    margin-bottom: 0.5rem !important;
}

.ml-8pt,
.mx-8pt {
    margin-left: 0.5rem !important;
}

[dir='rtl'] .ml-8pt,
[dir='rtl'] .mx-8pt {
    margin-right: 0.5rem !important;
}

.m-12pt {
    margin: 0.75rem !important;
}

.mt-12pt,
.my-12pt {
    margin-top: 0.75rem !important;
}

.mr-12pt,
.mx-12pt {
    margin-right: 0.75rem !important;
}

[dir='rtl'] .mr-12pt,
[dir='rtl'] .mx-12pt {
    margin-left: 0.75rem !important;
}

.mb-12pt,
.my-12pt {
    margin-bottom: 0.75rem !important;
}

.ml-12pt,
.mx-12pt {
    margin-left: 0.75rem !important;
}

[dir='rtl'] .ml-12pt,
[dir='rtl'] .mx-12pt {
    margin-right: 0.75rem !important;
}

.m-16pt {
    margin: 1rem !important;
}

.mt-16pt,
.my-16pt {
    margin-top: 1rem !important;
}

.mr-16pt,
.mx-16pt {
    margin-right: 1rem !important;
}

[dir='rtl'] .mr-16pt,
[dir='rtl'] .mx-16pt {
    margin-left: 1rem !important;
}

.mb-16pt,
.my-16pt {
    margin-bottom: 1rem !important;
}

.ml-16pt,
.mx-16pt {
    margin-left: 1rem !important;
}

[dir='rtl'] .ml-16pt,
[dir='rtl'] .mx-16pt {
    margin-right: 1rem !important;
}

.m-20pt {
    margin: 1.25rem !important;
}

.mt-20pt,
.my-20pt {
    margin-top: 1.25rem !important;
}

.mr-20pt,
.mx-20pt {
    margin-right: 1.25rem !important;
}

[dir='rtl'] .mr-20pt,
[dir='rtl'] .mx-20pt {
    margin-left: 1.25rem !important;
}

.mb-20pt,
.my-20pt {
    margin-bottom: 1.25rem !important;
}

.ml-20pt,
.mx-20pt {
    margin-left: 1.25rem !important;
}

[dir='rtl'] .ml-20pt,
[dir='rtl'] .mx-20pt {
    margin-right: 1.25rem !important;
}

.m-24pt {
    margin: 1.5rem !important;
}

.mt-24pt,
.my-24pt {
    margin-top: 1.5rem !important;
}

.mr-24pt,
.mx-24pt {
    margin-right: 1.5rem !important;
}

[dir='rtl'] .mr-24pt,
[dir='rtl'] .mx-24pt {
    margin-left: 1.5rem !important;
}

.mb-24pt,
.my-24pt {
    margin-bottom: 1.5rem !important;
}

.ml-24pt,
.mx-24pt {
    margin-left: 1.5rem !important;
}

[dir='rtl'] .ml-24pt,
[dir='rtl'] .mx-24pt {
    margin-right: 1.5rem !important;
}

.m-32pt {
    margin: 2rem !important;
}

.mt-32pt,
.my-32pt {
    margin-top: 2rem !important;
}

.mr-32pt,
.mx-32pt {
    margin-right: 2rem !important;
}

[dir='rtl'] .mr-32pt,
[dir='rtl'] .mx-32pt {
    margin-left: 2rem !important;
}

.mb-32pt,
.my-32pt {
    margin-bottom: 2rem !important;
}

.ml-32pt,
.mx-32pt {
    margin-left: 2rem !important;
}

[dir='rtl'] .ml-32pt,
[dir='rtl'] .mx-32pt {
    margin-right: 2rem !important;
}

.m-40pt {
    margin: 2.5rem !important;
}

.mt-40pt,
.my-40pt {
    margin-top: 2.5rem !important;
}

.mr-40pt,
.mx-40pt {
    margin-right: 2.5rem !important;
}

[dir='rtl'] .mr-40pt,
[dir='rtl'] .mx-40pt {
    margin-left: 2.5rem !important;
}

.mb-40pt,
.my-40pt {
    margin-bottom: 2.5rem !important;
}

.ml-40pt,
.mx-40pt {
    margin-left: 2.5rem !important;
}

[dir='rtl'] .ml-40pt,
[dir='rtl'] .mx-40pt {
    margin-right: 2.5rem !important;
}

.m-44pt {
    margin: 2.75rem !important;
}

.mt-44pt,
.my-44pt {
    margin-top: 2.75rem !important;
}

.mr-44pt,
.mx-44pt {
    margin-right: 2.75rem !important;
}

[dir='rtl'] .mr-44pt,
[dir='rtl'] .mx-44pt {
    margin-left: 2.75rem !important;
}

.mb-44pt,
.my-44pt {
    margin-bottom: 2.75rem !important;
}

.ml-44pt,
.mx-44pt {
    margin-left: 2.75rem !important;
}

[dir='rtl'] .ml-44pt,
[dir='rtl'] .mx-44pt {
    margin-right: 2.75rem !important;
}

.m-48pt {
    margin: 3rem !important;
}

.mt-48pt,
.my-48pt {
    margin-top: 3rem !important;
}

.mr-48pt,
.mx-48pt {
    margin-right: 3rem !important;
}

[dir='rtl'] .mr-48pt,
[dir='rtl'] .mx-48pt {
    margin-left: 3rem !important;
}

.mb-48pt,
.my-48pt {
    margin-bottom: 3rem !important;
}

.ml-48pt,
.mx-48pt {
    margin-left: 3rem !important;
}

[dir='rtl'] .ml-48pt,
[dir='rtl'] .mx-48pt {
    margin-right: 3rem !important;
}

.m-64pt {
    margin: 4rem !important;
}

.mt-64pt,
.my-64pt {
    margin-top: 4rem !important;
}

.mr-64pt,
.mx-64pt {
    margin-right: 4rem !important;
}

[dir='rtl'] .mr-64pt,
[dir='rtl'] .mx-64pt {
    margin-left: 4rem !important;
}

.mb-64pt,
.my-64pt {
    margin-bottom: 4rem !important;
}

.ml-64pt,
.mx-64pt {
    margin-left: 4rem !important;
}

[dir='rtl'] .ml-64pt,
[dir='rtl'] .mx-64pt {
    margin-right: 4rem !important;
}

.m-112pt {
    margin: 7rem !important;
}

.mt-112pt,
.my-112pt {
    margin-top: 7rem !important;
}

.mr-112pt,
.mx-112pt {
    margin-right: 7rem !important;
}

[dir='rtl'] .mr-112pt,
[dir='rtl'] .mx-112pt {
    margin-left: 7rem !important;
}

.mb-112pt,
.my-112pt {
    margin-bottom: 7rem !important;
}

.ml-112pt,
.mx-112pt {
    margin-left: 7rem !important;
}

[dir='rtl'] .ml-112pt,
[dir='rtl'] .mx-112pt {
    margin-right: 7rem !important;
}

.m-128pt {
    margin: 8rem !important;
}

.mt-128pt,
.my-128pt {
    margin-top: 8rem !important;
}

.mr-128pt,
.mx-128pt {
    margin-right: 8rem !important;
}

[dir='rtl'] .mr-128pt,
[dir='rtl'] .mx-128pt {
    margin-left: 8rem !important;
}

.mb-128pt,
.my-128pt {
    margin-bottom: 8rem !important;
}

.ml-128pt,
.mx-128pt {
    margin-left: 8rem !important;
}

[dir='rtl'] .ml-128pt,
[dir='rtl'] .mx-128pt {
    margin-right: 8rem !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

[dir='rtl'] .pr-0,
[dir='rtl'] .px-0 {
    padding-left: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

[dir='rtl'] .pl-0,
[dir='rtl'] .px-0 {
    padding-right: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

[dir='rtl'] .pr-1,
[dir='rtl'] .px-1 {
    padding-left: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

[dir='rtl'] .pl-1,
[dir='rtl'] .px-1 {
    padding-right: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

[dir='rtl'] .pr-2,
[dir='rtl'] .px-2 {
    padding-left: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

[dir='rtl'] .pl-2,
[dir='rtl'] .px-2 {
    padding-right: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

[dir='rtl'] .pr-3,
[dir='rtl'] .px-3 {
    padding-left: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

[dir='rtl'] .pl-3,
[dir='rtl'] .px-3 {
    padding-right: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

[dir='rtl'] .pr-4,
[dir='rtl'] .px-4 {
    padding-left: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

[dir='rtl'] .pl-4,
[dir='rtl'] .px-4 {
    padding-right: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

[dir='rtl'] .pr-5,
[dir='rtl'] .px-5 {
    padding-left: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

[dir='rtl'] .pl-5,
[dir='rtl'] .px-5 {
    padding-right: 3rem !important;
}

.p-4pt {
    padding: 0.25rem !important;
}

.pt-4pt,
.py-4pt {
    padding-top: 0.25rem !important;
}

.pr-4pt,
.px-4pt {
    padding-right: 0.25rem !important;
}

[dir='rtl'] .pr-4pt,
[dir='rtl'] .px-4pt {
    padding-left: 0.25rem !important;
}

.pb-4pt,
.py-4pt {
    padding-bottom: 0.25rem !important;
}

.pl-4pt,
.px-4pt {
    padding-left: 0.25rem !important;
}

[dir='rtl'] .pl-4pt,
[dir='rtl'] .px-4pt {
    padding-right: 0.25rem !important;
}

.p-8pt {
    padding: 0.5rem !important;
}

.pt-8pt,
.py-8pt {
    padding-top: 0.5rem !important;
}

.pr-8pt,
.px-8pt {
    padding-right: 0.5rem !important;
}

[dir='rtl'] .pr-8pt,
[dir='rtl'] .px-8pt {
    padding-left: 0.5rem !important;
}

.pb-8pt,
.py-8pt {
    padding-bottom: 0.5rem !important;
}

.pl-8pt,
.px-8pt {
    padding-left: 0.5rem !important;
}

[dir='rtl'] .pl-8pt,
[dir='rtl'] .px-8pt {
    padding-right: 0.5rem !important;
}

.p-12pt {
    padding: 0.75rem !important;
}

.pt-12pt,
.py-12pt {
    padding-top: 0.75rem !important;
}

.pr-12pt,
.px-12pt {
    padding-right: 0.75rem !important;
}

[dir='rtl'] .pr-12pt,
[dir='rtl'] .px-12pt {
    padding-left: 0.75rem !important;
}

.pb-12pt,
.py-12pt {
    padding-bottom: 0.75rem !important;
}

.pl-12pt,
.px-12pt {
    padding-left: 0.75rem !important;
}

[dir='rtl'] .pl-12pt,
[dir='rtl'] .px-12pt {
    padding-right: 0.75rem !important;
}

.p-16pt {
    padding: 1rem !important;
}

.pt-16pt,
.py-16pt {
    padding-top: 1rem !important;
}

.pr-16pt,
.px-16pt {
    padding-right: 1rem !important;
}

[dir='rtl'] .pr-16pt,
[dir='rtl'] .px-16pt {
    padding-left: 1rem !important;
}

.pb-16pt,
.py-16pt {
    padding-bottom: 1rem !important;
}

.pl-16pt,
.px-16pt {
    padding-left: 1rem !important;
}

[dir='rtl'] .pl-16pt,
[dir='rtl'] .px-16pt {
    padding-right: 1rem !important;
}

.p-20pt {
    padding: 1.25rem !important;
}

.pt-20pt,
.py-20pt {
    padding-top: 1.25rem !important;
}

.pr-20pt,
.px-20pt {
    padding-right: 1.25rem !important;
}

[dir='rtl'] .pr-20pt,
[dir='rtl'] .px-20pt {
    padding-left: 1.25rem !important;
}

.pb-20pt,
.py-20pt {
    padding-bottom: 1.25rem !important;
}

.pl-20pt,
.px-20pt {
    padding-left: 1.25rem !important;
}

[dir='rtl'] .pl-20pt,
[dir='rtl'] .px-20pt {
    padding-right: 1.25rem !important;
}

.p-24pt {
    padding: 1.5rem !important;
}

.pt-24pt,
.py-24pt {
    padding-top: 1.5rem !important;
}

.pr-24pt,
.px-24pt {
    padding-right: 1.5rem !important;
}

[dir='rtl'] .pr-24pt,
[dir='rtl'] .px-24pt {
    padding-left: 1.5rem !important;
}

.pb-24pt,
.py-24pt {
    padding-bottom: 1.5rem !important;
}

.pl-24pt,
.px-24pt {
    padding-left: 1.5rem !important;
}

[dir='rtl'] .pl-24pt,
[dir='rtl'] .px-24pt {
    padding-right: 1.5rem !important;
}

.p-32pt {
    padding: 2rem !important;
}

.pt-32pt,
.py-32pt {
    padding-top: 2rem !important;
}

.pr-32pt,
.px-32pt {
    padding-right: 2rem !important;
}

[dir='rtl'] .pr-32pt,
[dir='rtl'] .px-32pt {
    padding-left: 2rem !important;
}

.pb-32pt,
.py-32pt {
    padding-bottom: 2rem !important;
}

.pl-32pt,
.px-32pt {
    padding-left: 2rem !important;
}

[dir='rtl'] .pl-32pt,
[dir='rtl'] .px-32pt {
    padding-right: 2rem !important;
}

.p-40pt {
    padding: 2.5rem !important;
}

.pt-40pt,
.py-40pt {
    padding-top: 2.5rem !important;
}

.pr-40pt,
.px-40pt {
    padding-right: 2.5rem !important;
}

[dir='rtl'] .pr-40pt,
[dir='rtl'] .px-40pt {
    padding-left: 2.5rem !important;
}

.pb-40pt,
.py-40pt {
    padding-bottom: 2.5rem !important;
}

.pl-40pt,
.px-40pt {
    padding-left: 2.5rem !important;
}

[dir='rtl'] .pl-40pt,
[dir='rtl'] .px-40pt {
    padding-right: 2.5rem !important;
}

.p-44pt {
    padding: 2.75rem !important;
}

.pt-44pt,
.py-44pt {
    padding-top: 2.75rem !important;
}

.pr-44pt,
.px-44pt {
    padding-right: 2.75rem !important;
}

[dir='rtl'] .pr-44pt,
[dir='rtl'] .px-44pt {
    padding-left: 2.75rem !important;
}

.pb-44pt,
.py-44pt {
    padding-bottom: 2.75rem !important;
}

.pl-44pt,
.px-44pt {
    padding-left: 2.75rem !important;
}

[dir='rtl'] .pl-44pt,
[dir='rtl'] .px-44pt {
    padding-right: 2.75rem !important;
}

.p-48pt {
    padding: 3rem !important;
}

.pt-48pt,
.py-48pt {
    padding-top: 3rem !important;
}

.pr-48pt,
.px-48pt {
    padding-right: 3rem !important;
}

[dir='rtl'] .pr-48pt,
[dir='rtl'] .px-48pt {
    padding-left: 3rem !important;
}

.pb-48pt,
.py-48pt {
    padding-bottom: 3rem !important;
}

.pl-48pt,
.px-48pt {
    padding-left: 3rem !important;
}

[dir='rtl'] .pl-48pt,
[dir='rtl'] .px-48pt {
    padding-right: 3rem !important;
}

.p-64pt {
    padding: 4rem !important;
}

.pt-64pt,
.py-64pt {
    padding-top: 4rem !important;
}

.pr-64pt,
.px-64pt {
    padding-right: 4rem !important;
}

[dir='rtl'] .pr-64pt,
[dir='rtl'] .px-64pt {
    padding-left: 4rem !important;
}

.pb-64pt,
.py-64pt {
    padding-bottom: 4rem !important;
}

.pl-64pt,
.px-64pt {
    padding-left: 4rem !important;
}

[dir='rtl'] .pl-64pt,
[dir='rtl'] .px-64pt {
    padding-right: 4rem !important;
}

.p-112pt {
    padding: 7rem !important;
}

.pt-112pt,
.py-112pt {
    padding-top: 7rem !important;
}

.pr-112pt,
.px-112pt {
    padding-right: 7rem !important;
}

[dir='rtl'] .pr-112pt,
[dir='rtl'] .px-112pt {
    padding-left: 7rem !important;
}

.pb-112pt,
.py-112pt {
    padding-bottom: 7rem !important;
}

.pl-112pt,
.px-112pt {
    padding-left: 7rem !important;
}

[dir='rtl'] .pl-112pt,
[dir='rtl'] .px-112pt {
    padding-right: 7rem !important;
}

.p-128pt {
    padding: 8rem !important;
}

.pt-128pt,
.py-128pt {
    padding-top: 8rem !important;
}

.pr-128pt,
.px-128pt {
    padding-right: 8rem !important;
}

[dir='rtl'] .pr-128pt,
[dir='rtl'] .px-128pt {
    padding-left: 8rem !important;
}

.pb-128pt,
.py-128pt {
    padding-bottom: 8rem !important;
}

.pl-128pt,
.px-128pt {
    padding-left: 8rem !important;
}

[dir='rtl'] .pl-128pt,
[dir='rtl'] .px-128pt {
    padding-right: 8rem !important;
}

.m-n1 {
    margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
    margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
    margin-right: -0.25rem !important;
}

[dir='rtl'] .mr-n1,
[dir='rtl'] .mx-n1 {
    margin-left: -0.25rem !important;
}

.mb-n1,
.my-n1 {
    margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
    margin-left: -0.25rem !important;
}

[dir='rtl'] .ml-n1,
[dir='rtl'] .mx-n1 {
    margin-right: -0.25rem !important;
}

.m-n2 {
    margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
    margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
    margin-right: -0.5rem !important;
}

[dir='rtl'] .mr-n2,
[dir='rtl'] .mx-n2 {
    margin-left: -0.5rem !important;
}

.mb-n2,
.my-n2 {
    margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
    margin-left: -0.5rem !important;
}

[dir='rtl'] .ml-n2,
[dir='rtl'] .mx-n2 {
    margin-right: -0.5rem !important;
}

.m-n3 {
    margin: -1rem !important;
}

.mt-n3,
.my-n3 {
    margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
    margin-right: -1rem !important;
}

[dir='rtl'] .mr-n3,
[dir='rtl'] .mx-n3 {
    margin-left: -1rem !important;
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
    margin-left: -1rem !important;
}

[dir='rtl'] .ml-n3,
[dir='rtl'] .mx-n3 {
    margin-right: -1rem !important;
}

.m-n4 {
    margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
    margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
    margin-right: -1.5rem !important;
}

[dir='rtl'] .mr-n4,
[dir='rtl'] .mx-n4 {
    margin-left: -1.5rem !important;
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
    margin-left: -1.5rem !important;
}

[dir='rtl'] .ml-n4,
[dir='rtl'] .mx-n4 {
    margin-right: -1.5rem !important;
}

.m-n5 {
    margin: -3rem !important;
}

.mt-n5,
.my-n5 {
    margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
    margin-right: -3rem !important;
}

[dir='rtl'] .mr-n5,
[dir='rtl'] .mx-n5 {
    margin-left: -3rem !important;
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
    margin-left: -3rem !important;
}

[dir='rtl'] .ml-n5,
[dir='rtl'] .mx-n5 {
    margin-right: -3rem !important;
}

.m-n4pt {
    margin: -0.25rem !important;
}

.mt-n4pt,
.my-n4pt {
    margin-top: -0.25rem !important;
}

.mr-n4pt,
.mx-n4pt {
    margin-right: -0.25rem !important;
}

[dir='rtl'] .mr-n4pt,
[dir='rtl'] .mx-n4pt {
    margin-left: -0.25rem !important;
}

.mb-n4pt,
.my-n4pt {
    margin-bottom: -0.25rem !important;
}

.ml-n4pt,
.mx-n4pt {
    margin-left: -0.25rem !important;
}

[dir='rtl'] .ml-n4pt,
[dir='rtl'] .mx-n4pt {
    margin-right: -0.25rem !important;
}

.m-n8pt {
    margin: -0.5rem !important;
}

.mt-n8pt,
.my-n8pt {
    margin-top: -0.5rem !important;
}

.mr-n8pt,
.mx-n8pt {
    margin-right: -0.5rem !important;
}

[dir='rtl'] .mr-n8pt,
[dir='rtl'] .mx-n8pt {
    margin-left: -0.5rem !important;
}

.mb-n8pt,
.my-n8pt {
    margin-bottom: -0.5rem !important;
}

.ml-n8pt,
.mx-n8pt {
    margin-left: -0.5rem !important;
}

[dir='rtl'] .ml-n8pt,
[dir='rtl'] .mx-n8pt {
    margin-right: -0.5rem !important;
}

.m-n12pt {
    margin: -0.75rem !important;
}

.mt-n12pt,
.my-n12pt {
    margin-top: -0.75rem !important;
}

.mr-n12pt,
.mx-n12pt {
    margin-right: -0.75rem !important;
}

[dir='rtl'] .mr-n12pt,
[dir='rtl'] .mx-n12pt {
    margin-left: -0.75rem !important;
}

.mb-n12pt,
.my-n12pt {
    margin-bottom: -0.75rem !important;
}

.ml-n12pt,
.mx-n12pt {
    margin-left: -0.75rem !important;
}

[dir='rtl'] .ml-n12pt,
[dir='rtl'] .mx-n12pt {
    margin-right: -0.75rem !important;
}

.m-n16pt {
    margin: -1rem !important;
}

.mt-n16pt,
.my-n16pt {
    margin-top: -1rem !important;
}

.mr-n16pt,
.mx-n16pt {
    margin-right: -1rem !important;
}

[dir='rtl'] .mr-n16pt,
[dir='rtl'] .mx-n16pt {
    margin-left: -1rem !important;
}

.mb-n16pt,
.my-n16pt {
    margin-bottom: -1rem !important;
}

.ml-n16pt,
.mx-n16pt {
    margin-left: -1rem !important;
}

[dir='rtl'] .ml-n16pt,
[dir='rtl'] .mx-n16pt {
    margin-right: -1rem !important;
}

.m-n20pt {
    margin: -1.25rem !important;
}

.mt-n20pt,
.my-n20pt {
    margin-top: -1.25rem !important;
}

.mr-n20pt,
.mx-n20pt {
    margin-right: -1.25rem !important;
}

[dir='rtl'] .mr-n20pt,
[dir='rtl'] .mx-n20pt {
    margin-left: -1.25rem !important;
}

.mb-n20pt,
.my-n20pt {
    margin-bottom: -1.25rem !important;
}

.ml-n20pt,
.mx-n20pt {
    margin-left: -1.25rem !important;
}

[dir='rtl'] .ml-n20pt,
[dir='rtl'] .mx-n20pt {
    margin-right: -1.25rem !important;
}

.m-n24pt {
    margin: -1.5rem !important;
}

.mt-n24pt,
.my-n24pt {
    margin-top: -1.5rem !important;
}

.mr-n24pt,
.mx-n24pt {
    margin-right: -1.5rem !important;
}

[dir='rtl'] .mr-n24pt,
[dir='rtl'] .mx-n24pt {
    margin-left: -1.5rem !important;
}

.mb-n24pt,
.my-n24pt {
    margin-bottom: -1.5rem !important;
}

.ml-n24pt,
.mx-n24pt {
    margin-left: -1.5rem !important;
}

[dir='rtl'] .ml-n24pt,
[dir='rtl'] .mx-n24pt {
    margin-right: -1.5rem !important;
}

.m-n32pt {
    margin: -2rem !important;
}

.mt-n32pt,
.my-n32pt {
    margin-top: -2rem !important;
}

.mr-n32pt,
.mx-n32pt {
    margin-right: -2rem !important;
}

[dir='rtl'] .mr-n32pt,
[dir='rtl'] .mx-n32pt {
    margin-left: -2rem !important;
}

.mb-n32pt,
.my-n32pt {
    margin-bottom: -2rem !important;
}

.ml-n32pt,
.mx-n32pt {
    margin-left: -2rem !important;
}

[dir='rtl'] .ml-n32pt,
[dir='rtl'] .mx-n32pt {
    margin-right: -2rem !important;
}

.m-n40pt {
    margin: -2.5rem !important;
}

.mt-n40pt,
.my-n40pt {
    margin-top: -2.5rem !important;
}

.mr-n40pt,
.mx-n40pt {
    margin-right: -2.5rem !important;
}

[dir='rtl'] .mr-n40pt,
[dir='rtl'] .mx-n40pt {
    margin-left: -2.5rem !important;
}

.mb-n40pt,
.my-n40pt {
    margin-bottom: -2.5rem !important;
}

.ml-n40pt,
.mx-n40pt {
    margin-left: -2.5rem !important;
}

[dir='rtl'] .ml-n40pt,
[dir='rtl'] .mx-n40pt {
    margin-right: -2.5rem !important;
}

.m-n44pt {
    margin: -2.75rem !important;
}

.mt-n44pt,
.my-n44pt {
    margin-top: -2.75rem !important;
}

.mr-n44pt,
.mx-n44pt {
    margin-right: -2.75rem !important;
}

[dir='rtl'] .mr-n44pt,
[dir='rtl'] .mx-n44pt {
    margin-left: -2.75rem !important;
}

.mb-n44pt,
.my-n44pt {
    margin-bottom: -2.75rem !important;
}

.ml-n44pt,
.mx-n44pt {
    margin-left: -2.75rem !important;
}

[dir='rtl'] .ml-n44pt,
[dir='rtl'] .mx-n44pt {
    margin-right: -2.75rem !important;
}

.m-n48pt {
    margin: -3rem !important;
}

.mt-n48pt,
.my-n48pt {
    margin-top: -3rem !important;
}

.mr-n48pt,
.mx-n48pt {
    margin-right: -3rem !important;
}

[dir='rtl'] .mr-n48pt,
[dir='rtl'] .mx-n48pt {
    margin-left: -3rem !important;
}

.mb-n48pt,
.my-n48pt {
    margin-bottom: -3rem !important;
}

.ml-n48pt,
.mx-n48pt {
    margin-left: -3rem !important;
}

[dir='rtl'] .ml-n48pt,
[dir='rtl'] .mx-n48pt {
    margin-right: -3rem !important;
}

.m-n64pt {
    margin: -4rem !important;
}

.mt-n64pt,
.my-n64pt {
    margin-top: -4rem !important;
}

.mr-n64pt,
.mx-n64pt {
    margin-right: -4rem !important;
}

[dir='rtl'] .mr-n64pt,
[dir='rtl'] .mx-n64pt {
    margin-left: -4rem !important;
}

.mb-n64pt,
.my-n64pt {
    margin-bottom: -4rem !important;
}

.ml-n64pt,
.mx-n64pt {
    margin-left: -4rem !important;
}

[dir='rtl'] .ml-n64pt,
[dir='rtl'] .mx-n64pt {
    margin-right: -4rem !important;
}

.m-n112pt {
    margin: -7rem !important;
}

.mt-n112pt,
.my-n112pt {
    margin-top: -7rem !important;
}

.mr-n112pt,
.mx-n112pt {
    margin-right: -7rem !important;
}

[dir='rtl'] .mr-n112pt,
[dir='rtl'] .mx-n112pt {
    margin-left: -7rem !important;
}

.mb-n112pt,
.my-n112pt {
    margin-bottom: -7rem !important;
}

.ml-n112pt,
.mx-n112pt {
    margin-left: -7rem !important;
}

[dir='rtl'] .ml-n112pt,
[dir='rtl'] .mx-n112pt {
    margin-right: -7rem !important;
}

.m-n128pt {
    margin: -8rem !important;
}

.mt-n128pt,
.my-n128pt {
    margin-top: -8rem !important;
}

.mr-n128pt,
.mx-n128pt {
    margin-right: -8rem !important;
}

[dir='rtl'] .mr-n128pt,
[dir='rtl'] .mx-n128pt {
    margin-left: -8rem !important;
}

.mb-n128pt,
.my-n128pt {
    margin-bottom: -8rem !important;
}

.ml-n128pt,
.mx-n128pt {
    margin-left: -8rem !important;
}

[dir='rtl'] .ml-n128pt,
[dir='rtl'] .mx-n128pt {
    margin-right: -8rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

[dir='rtl'] .mr-auto,
[dir='rtl'] .mx-auto {
    margin-left: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

[dir='rtl'] .ml-auto,
[dir='rtl'] .mx-auto {
    margin-right: auto !important;
}

@media (min-width: 374px) {
    .m-xs-md-0 {
        margin: 0 !important;
    }

    .mt-xs-md-0,
    .my-xs-md-0 {
        margin-top: 0 !important;
    }

    .mr-xs-md-0,
    .mx-xs-md-0 {
        margin-right: 0 !important;
    }

    [dir='rtl'] .mr-xs-md-0,
    [dir='rtl'] .mx-xs-md-0 {
        margin-left: 0 !important;
    }

    .mb-xs-md-0,
    .my-xs-md-0 {
        margin-bottom: 0 !important;
    }

    .ml-xs-md-0,
    .mx-xs-md-0 {
        margin-left: 0 !important;
    }

    [dir='rtl'] .ml-xs-md-0,
    [dir='rtl'] .mx-xs-md-0 {
        margin-right: 0 !important;
    }

    .m-xs-md-1 {
        margin: 0.25rem !important;
    }

    .mt-xs-md-1,
    .my-xs-md-1 {
        margin-top: 0.25rem !important;
    }

    .mr-xs-md-1,
    .mx-xs-md-1 {
        margin-right: 0.25rem !important;
    }

    [dir='rtl'] .mr-xs-md-1,
    [dir='rtl'] .mx-xs-md-1 {
        margin-left: 0.25rem !important;
    }

    .mb-xs-md-1,
    .my-xs-md-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-xs-md-1,
    .mx-xs-md-1 {
        margin-left: 0.25rem !important;
    }

    [dir='rtl'] .ml-xs-md-1,
    [dir='rtl'] .mx-xs-md-1 {
        margin-right: 0.25rem !important;
    }

    .m-xs-md-2 {
        margin: 0.5rem !important;
    }

    .mt-xs-md-2,
    .my-xs-md-2 {
        margin-top: 0.5rem !important;
    }

    .mr-xs-md-2,
    .mx-xs-md-2 {
        margin-right: 0.5rem !important;
    }

    [dir='rtl'] .mr-xs-md-2,
    [dir='rtl'] .mx-xs-md-2 {
        margin-left: 0.5rem !important;
    }

    .mb-xs-md-2,
    .my-xs-md-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-xs-md-2,
    .mx-xs-md-2 {
        margin-left: 0.5rem !important;
    }

    [dir='rtl'] .ml-xs-md-2,
    [dir='rtl'] .mx-xs-md-2 {
        margin-right: 0.5rem !important;
    }

    .m-xs-md-3 {
        margin: 1rem !important;
    }

    .mt-xs-md-3,
    .my-xs-md-3 {
        margin-top: 1rem !important;
    }

    .mr-xs-md-3,
    .mx-xs-md-3 {
        margin-right: 1rem !important;
    }

    [dir='rtl'] .mr-xs-md-3,
    [dir='rtl'] .mx-xs-md-3 {
        margin-left: 1rem !important;
    }

    .mb-xs-md-3,
    .my-xs-md-3 {
        margin-bottom: 1rem !important;
    }

    .ml-xs-md-3,
    .mx-xs-md-3 {
        margin-left: 1rem !important;
    }

    [dir='rtl'] .ml-xs-md-3,
    [dir='rtl'] .mx-xs-md-3 {
        margin-right: 1rem !important;
    }

    .m-xs-md-4 {
        margin: 1.5rem !important;
    }

    .mt-xs-md-4,
    .my-xs-md-4 {
        margin-top: 1.5rem !important;
    }

    .mr-xs-md-4,
    .mx-xs-md-4 {
        margin-right: 1.5rem !important;
    }

    [dir='rtl'] .mr-xs-md-4,
    [dir='rtl'] .mx-xs-md-4 {
        margin-left: 1.5rem !important;
    }

    .mb-xs-md-4,
    .my-xs-md-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-xs-md-4,
    .mx-xs-md-4 {
        margin-left: 1.5rem !important;
    }

    [dir='rtl'] .ml-xs-md-4,
    [dir='rtl'] .mx-xs-md-4 {
        margin-right: 1.5rem !important;
    }

    .m-xs-md-5 {
        margin: 3rem !important;
    }

    .mt-xs-md-5,
    .my-xs-md-5 {
        margin-top: 3rem !important;
    }

    .mr-xs-md-5,
    .mx-xs-md-5 {
        margin-right: 3rem !important;
    }

    [dir='rtl'] .mr-xs-md-5,
    [dir='rtl'] .mx-xs-md-5 {
        margin-left: 3rem !important;
    }

    .mb-xs-md-5,
    .my-xs-md-5 {
        margin-bottom: 3rem !important;
    }

    .ml-xs-md-5,
    .mx-xs-md-5 {
        margin-left: 3rem !important;
    }

    [dir='rtl'] .ml-xs-md-5,
    [dir='rtl'] .mx-xs-md-5 {
        margin-right: 3rem !important;
    }

    .m-xs-md-4pt {
        margin: 0.25rem !important;
    }

    .mt-xs-md-4pt,
    .my-xs-md-4pt {
        margin-top: 0.25rem !important;
    }

    .mr-xs-md-4pt,
    .mx-xs-md-4pt {
        margin-right: 0.25rem !important;
    }

    [dir='rtl'] .mr-xs-md-4pt,
    [dir='rtl'] .mx-xs-md-4pt {
        margin-left: 0.25rem !important;
    }

    .mb-xs-md-4pt,
    .my-xs-md-4pt {
        margin-bottom: 0.25rem !important;
    }

    .ml-xs-md-4pt,
    .mx-xs-md-4pt {
        margin-left: 0.25rem !important;
    }

    [dir='rtl'] .ml-xs-md-4pt,
    [dir='rtl'] .mx-xs-md-4pt {
        margin-right: 0.25rem !important;
    }

    .m-xs-md-8pt {
        margin: 0.5rem !important;
    }

    .mt-xs-md-8pt,
    .my-xs-md-8pt {
        margin-top: 0.5rem !important;
    }

    .mr-xs-md-8pt,
    .mx-xs-md-8pt {
        margin-right: 0.5rem !important;
    }

    [dir='rtl'] .mr-xs-md-8pt,
    [dir='rtl'] .mx-xs-md-8pt {
        margin-left: 0.5rem !important;
    }

    .mb-xs-md-8pt,
    .my-xs-md-8pt {
        margin-bottom: 0.5rem !important;
    }

    .ml-xs-md-8pt,
    .mx-xs-md-8pt {
        margin-left: 0.5rem !important;
    }

    [dir='rtl'] .ml-xs-md-8pt,
    [dir='rtl'] .mx-xs-md-8pt {
        margin-right: 0.5rem !important;
    }

    .m-xs-md-12pt {
        margin: 0.75rem !important;
    }

    .mt-xs-md-12pt,
    .my-xs-md-12pt {
        margin-top: 0.75rem !important;
    }

    .mr-xs-md-12pt,
    .mx-xs-md-12pt {
        margin-right: 0.75rem !important;
    }

    [dir='rtl'] .mr-xs-md-12pt,
    [dir='rtl'] .mx-xs-md-12pt {
        margin-left: 0.75rem !important;
    }

    .mb-xs-md-12pt,
    .my-xs-md-12pt {
        margin-bottom: 0.75rem !important;
    }

    .ml-xs-md-12pt,
    .mx-xs-md-12pt {
        margin-left: 0.75rem !important;
    }

    [dir='rtl'] .ml-xs-md-12pt,
    [dir='rtl'] .mx-xs-md-12pt {
        margin-right: 0.75rem !important;
    }

    .m-xs-md-16pt {
        margin: 1rem !important;
    }

    .mt-xs-md-16pt,
    .my-xs-md-16pt {
        margin-top: 1rem !important;
    }

    .mr-xs-md-16pt,
    .mx-xs-md-16pt {
        margin-right: 1rem !important;
    }

    [dir='rtl'] .mr-xs-md-16pt,
    [dir='rtl'] .mx-xs-md-16pt {
        margin-left: 1rem !important;
    }

    .mb-xs-md-16pt,
    .my-xs-md-16pt {
        margin-bottom: 1rem !important;
    }

    .ml-xs-md-16pt,
    .mx-xs-md-16pt {
        margin-left: 1rem !important;
    }

    [dir='rtl'] .ml-xs-md-16pt,
    [dir='rtl'] .mx-xs-md-16pt {
        margin-right: 1rem !important;
    }

    .m-xs-md-20pt {
        margin: 1.25rem !important;
    }

    .mt-xs-md-20pt,
    .my-xs-md-20pt {
        margin-top: 1.25rem !important;
    }

    .mr-xs-md-20pt,
    .mx-xs-md-20pt {
        margin-right: 1.25rem !important;
    }

    [dir='rtl'] .mr-xs-md-20pt,
    [dir='rtl'] .mx-xs-md-20pt {
        margin-left: 1.25rem !important;
    }

    .mb-xs-md-20pt,
    .my-xs-md-20pt {
        margin-bottom: 1.25rem !important;
    }

    .ml-xs-md-20pt,
    .mx-xs-md-20pt {
        margin-left: 1.25rem !important;
    }

    [dir='rtl'] .ml-xs-md-20pt,
    [dir='rtl'] .mx-xs-md-20pt {
        margin-right: 1.25rem !important;
    }

    .m-xs-md-24pt {
        margin: 1.5rem !important;
    }

    .mt-xs-md-24pt,
    .my-xs-md-24pt {
        margin-top: 1.5rem !important;
    }

    .mr-xs-md-24pt,
    .mx-xs-md-24pt {
        margin-right: 1.5rem !important;
    }

    [dir='rtl'] .mr-xs-md-24pt,
    [dir='rtl'] .mx-xs-md-24pt {
        margin-left: 1.5rem !important;
    }

    .mb-xs-md-24pt,
    .my-xs-md-24pt {
        margin-bottom: 1.5rem !important;
    }

    .ml-xs-md-24pt,
    .mx-xs-md-24pt {
        margin-left: 1.5rem !important;
    }

    [dir='rtl'] .ml-xs-md-24pt,
    [dir='rtl'] .mx-xs-md-24pt {
        margin-right: 1.5rem !important;
    }

    .m-xs-md-32pt {
        margin: 2rem !important;
    }

    .mt-xs-md-32pt,
    .my-xs-md-32pt {
        margin-top: 2rem !important;
    }

    .mr-xs-md-32pt,
    .mx-xs-md-32pt {
        margin-right: 2rem !important;
    }

    [dir='rtl'] .mr-xs-md-32pt,
    [dir='rtl'] .mx-xs-md-32pt {
        margin-left: 2rem !important;
    }

    .mb-xs-md-32pt,
    .my-xs-md-32pt {
        margin-bottom: 2rem !important;
    }

    .ml-xs-md-32pt,
    .mx-xs-md-32pt {
        margin-left: 2rem !important;
    }

    [dir='rtl'] .ml-xs-md-32pt,
    [dir='rtl'] .mx-xs-md-32pt {
        margin-right: 2rem !important;
    }

    .m-xs-md-40pt {
        margin: 2.5rem !important;
    }

    .mt-xs-md-40pt,
    .my-xs-md-40pt {
        margin-top: 2.5rem !important;
    }

    .mr-xs-md-40pt,
    .mx-xs-md-40pt {
        margin-right: 2.5rem !important;
    }

    [dir='rtl'] .mr-xs-md-40pt,
    [dir='rtl'] .mx-xs-md-40pt {
        margin-left: 2.5rem !important;
    }

    .mb-xs-md-40pt,
    .my-xs-md-40pt {
        margin-bottom: 2.5rem !important;
    }

    .ml-xs-md-40pt,
    .mx-xs-md-40pt {
        margin-left: 2.5rem !important;
    }

    [dir='rtl'] .ml-xs-md-40pt,
    [dir='rtl'] .mx-xs-md-40pt {
        margin-right: 2.5rem !important;
    }

    .m-xs-md-44pt {
        margin: 2.75rem !important;
    }

    .mt-xs-md-44pt,
    .my-xs-md-44pt {
        margin-top: 2.75rem !important;
    }

    .mr-xs-md-44pt,
    .mx-xs-md-44pt {
        margin-right: 2.75rem !important;
    }

    [dir='rtl'] .mr-xs-md-44pt,
    [dir='rtl'] .mx-xs-md-44pt {
        margin-left: 2.75rem !important;
    }

    .mb-xs-md-44pt,
    .my-xs-md-44pt {
        margin-bottom: 2.75rem !important;
    }

    .ml-xs-md-44pt,
    .mx-xs-md-44pt {
        margin-left: 2.75rem !important;
    }

    [dir='rtl'] .ml-xs-md-44pt,
    [dir='rtl'] .mx-xs-md-44pt {
        margin-right: 2.75rem !important;
    }

    .m-xs-md-48pt {
        margin: 3rem !important;
    }

    .mt-xs-md-48pt,
    .my-xs-md-48pt {
        margin-top: 3rem !important;
    }

    .mr-xs-md-48pt,
    .mx-xs-md-48pt {
        margin-right: 3rem !important;
    }

    [dir='rtl'] .mr-xs-md-48pt,
    [dir='rtl'] .mx-xs-md-48pt {
        margin-left: 3rem !important;
    }

    .mb-xs-md-48pt,
    .my-xs-md-48pt {
        margin-bottom: 3rem !important;
    }

    .ml-xs-md-48pt,
    .mx-xs-md-48pt {
        margin-left: 3rem !important;
    }

    [dir='rtl'] .ml-xs-md-48pt,
    [dir='rtl'] .mx-xs-md-48pt {
        margin-right: 3rem !important;
    }

    .m-xs-md-64pt {
        margin: 4rem !important;
    }

    .mt-xs-md-64pt,
    .my-xs-md-64pt {
        margin-top: 4rem !important;
    }

    .mr-xs-md-64pt,
    .mx-xs-md-64pt {
        margin-right: 4rem !important;
    }

    [dir='rtl'] .mr-xs-md-64pt,
    [dir='rtl'] .mx-xs-md-64pt {
        margin-left: 4rem !important;
    }

    .mb-xs-md-64pt,
    .my-xs-md-64pt {
        margin-bottom: 4rem !important;
    }

    .ml-xs-md-64pt,
    .mx-xs-md-64pt {
        margin-left: 4rem !important;
    }

    [dir='rtl'] .ml-xs-md-64pt,
    [dir='rtl'] .mx-xs-md-64pt {
        margin-right: 4rem !important;
    }

    .m-xs-md-112pt {
        margin: 7rem !important;
    }

    .mt-xs-md-112pt,
    .my-xs-md-112pt {
        margin-top: 7rem !important;
    }

    .mr-xs-md-112pt,
    .mx-xs-md-112pt {
        margin-right: 7rem !important;
    }

    [dir='rtl'] .mr-xs-md-112pt,
    [dir='rtl'] .mx-xs-md-112pt {
        margin-left: 7rem !important;
    }

    .mb-xs-md-112pt,
    .my-xs-md-112pt {
        margin-bottom: 7rem !important;
    }

    .ml-xs-md-112pt,
    .mx-xs-md-112pt {
        margin-left: 7rem !important;
    }

    [dir='rtl'] .ml-xs-md-112pt,
    [dir='rtl'] .mx-xs-md-112pt {
        margin-right: 7rem !important;
    }

    .m-xs-md-128pt {
        margin: 8rem !important;
    }

    .mt-xs-md-128pt,
    .my-xs-md-128pt {
        margin-top: 8rem !important;
    }

    .mr-xs-md-128pt,
    .mx-xs-md-128pt {
        margin-right: 8rem !important;
    }

    [dir='rtl'] .mr-xs-md-128pt,
    [dir='rtl'] .mx-xs-md-128pt {
        margin-left: 8rem !important;
    }

    .mb-xs-md-128pt,
    .my-xs-md-128pt {
        margin-bottom: 8rem !important;
    }

    .ml-xs-md-128pt,
    .mx-xs-md-128pt {
        margin-left: 8rem !important;
    }

    [dir='rtl'] .ml-xs-md-128pt,
    [dir='rtl'] .mx-xs-md-128pt {
        margin-right: 8rem !important;
    }

    .p-xs-md-0 {
        padding: 0 !important;
    }

    .pt-xs-md-0,
    .py-xs-md-0 {
        padding-top: 0 !important;
    }

    .pr-xs-md-0,
    .px-xs-md-0 {
        padding-right: 0 !important;
    }

    [dir='rtl'] .pr-xs-md-0,
    [dir='rtl'] .px-xs-md-0 {
        padding-left: 0 !important;
    }

    .pb-xs-md-0,
    .py-xs-md-0 {
        padding-bottom: 0 !important;
    }

    .pl-xs-md-0,
    .px-xs-md-0 {
        padding-left: 0 !important;
    }

    [dir='rtl'] .pl-xs-md-0,
    [dir='rtl'] .px-xs-md-0 {
        padding-right: 0 !important;
    }

    .p-xs-md-1 {
        padding: 0.25rem !important;
    }

    .pt-xs-md-1,
    .py-xs-md-1 {
        padding-top: 0.25rem !important;
    }

    .pr-xs-md-1,
    .px-xs-md-1 {
        padding-right: 0.25rem !important;
    }

    [dir='rtl'] .pr-xs-md-1,
    [dir='rtl'] .px-xs-md-1 {
        padding-left: 0.25rem !important;
    }

    .pb-xs-md-1,
    .py-xs-md-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-xs-md-1,
    .px-xs-md-1 {
        padding-left: 0.25rem !important;
    }

    [dir='rtl'] .pl-xs-md-1,
    [dir='rtl'] .px-xs-md-1 {
        padding-right: 0.25rem !important;
    }

    .p-xs-md-2 {
        padding: 0.5rem !important;
    }

    .pt-xs-md-2,
    .py-xs-md-2 {
        padding-top: 0.5rem !important;
    }

    .pr-xs-md-2,
    .px-xs-md-2 {
        padding-right: 0.5rem !important;
    }

    [dir='rtl'] .pr-xs-md-2,
    [dir='rtl'] .px-xs-md-2 {
        padding-left: 0.5rem !important;
    }

    .pb-xs-md-2,
    .py-xs-md-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-xs-md-2,
    .px-xs-md-2 {
        padding-left: 0.5rem !important;
    }

    [dir='rtl'] .pl-xs-md-2,
    [dir='rtl'] .px-xs-md-2 {
        padding-right: 0.5rem !important;
    }

    .p-xs-md-3 {
        padding: 1rem !important;
    }

    .pt-xs-md-3,
    .py-xs-md-3 {
        padding-top: 1rem !important;
    }

    .pr-xs-md-3,
    .px-xs-md-3 {
        padding-right: 1rem !important;
    }

    [dir='rtl'] .pr-xs-md-3,
    [dir='rtl'] .px-xs-md-3 {
        padding-left: 1rem !important;
    }

    .pb-xs-md-3,
    .py-xs-md-3 {
        padding-bottom: 1rem !important;
    }

    .pl-xs-md-3,
    .px-xs-md-3 {
        padding-left: 1rem !important;
    }

    [dir='rtl'] .pl-xs-md-3,
    [dir='rtl'] .px-xs-md-3 {
        padding-right: 1rem !important;
    }

    .p-xs-md-4 {
        padding: 1.5rem !important;
    }

    .pt-xs-md-4,
    .py-xs-md-4 {
        padding-top: 1.5rem !important;
    }

    .pr-xs-md-4,
    .px-xs-md-4 {
        padding-right: 1.5rem !important;
    }

    [dir='rtl'] .pr-xs-md-4,
    [dir='rtl'] .px-xs-md-4 {
        padding-left: 1.5rem !important;
    }

    .pb-xs-md-4,
    .py-xs-md-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-xs-md-4,
    .px-xs-md-4 {
        padding-left: 1.5rem !important;
    }

    [dir='rtl'] .pl-xs-md-4,
    [dir='rtl'] .px-xs-md-4 {
        padding-right: 1.5rem !important;
    }

    .p-xs-md-5 {
        padding: 3rem !important;
    }

    .pt-xs-md-5,
    .py-xs-md-5 {
        padding-top: 3rem !important;
    }

    .pr-xs-md-5,
    .px-xs-md-5 {
        padding-right: 3rem !important;
    }

    [dir='rtl'] .pr-xs-md-5,
    [dir='rtl'] .px-xs-md-5 {
        padding-left: 3rem !important;
    }

    .pb-xs-md-5,
    .py-xs-md-5 {
        padding-bottom: 3rem !important;
    }

    .pl-xs-md-5,
    .px-xs-md-5 {
        padding-left: 3rem !important;
    }

    [dir='rtl'] .pl-xs-md-5,
    [dir='rtl'] .px-xs-md-5 {
        padding-right: 3rem !important;
    }

    .p-xs-md-4pt {
        padding: 0.25rem !important;
    }

    .pt-xs-md-4pt,
    .py-xs-md-4pt {
        padding-top: 0.25rem !important;
    }

    .pr-xs-md-4pt,
    .px-xs-md-4pt {
        padding-right: 0.25rem !important;
    }

    [dir='rtl'] .pr-xs-md-4pt,
    [dir='rtl'] .px-xs-md-4pt {
        padding-left: 0.25rem !important;
    }

    .pb-xs-md-4pt,
    .py-xs-md-4pt {
        padding-bottom: 0.25rem !important;
    }

    .pl-xs-md-4pt,
    .px-xs-md-4pt {
        padding-left: 0.25rem !important;
    }

    [dir='rtl'] .pl-xs-md-4pt,
    [dir='rtl'] .px-xs-md-4pt {
        padding-right: 0.25rem !important;
    }

    .p-xs-md-8pt {
        padding: 0.5rem !important;
    }

    .pt-xs-md-8pt,
    .py-xs-md-8pt {
        padding-top: 0.5rem !important;
    }

    .pr-xs-md-8pt,
    .px-xs-md-8pt {
        padding-right: 0.5rem !important;
    }

    [dir='rtl'] .pr-xs-md-8pt,
    [dir='rtl'] .px-xs-md-8pt {
        padding-left: 0.5rem !important;
    }

    .pb-xs-md-8pt,
    .py-xs-md-8pt {
        padding-bottom: 0.5rem !important;
    }

    .pl-xs-md-8pt,
    .px-xs-md-8pt {
        padding-left: 0.5rem !important;
    }

    [dir='rtl'] .pl-xs-md-8pt,
    [dir='rtl'] .px-xs-md-8pt {
        padding-right: 0.5rem !important;
    }

    .p-xs-md-12pt {
        padding: 0.75rem !important;
    }

    .pt-xs-md-12pt,
    .py-xs-md-12pt {
        padding-top: 0.75rem !important;
    }

    .pr-xs-md-12pt,
    .px-xs-md-12pt {
        padding-right: 0.75rem !important;
    }

    [dir='rtl'] .pr-xs-md-12pt,
    [dir='rtl'] .px-xs-md-12pt {
        padding-left: 0.75rem !important;
    }

    .pb-xs-md-12pt,
    .py-xs-md-12pt {
        padding-bottom: 0.75rem !important;
    }

    .pl-xs-md-12pt,
    .px-xs-md-12pt {
        padding-left: 0.75rem !important;
    }

    [dir='rtl'] .pl-xs-md-12pt,
    [dir='rtl'] .px-xs-md-12pt {
        padding-right: 0.75rem !important;
    }

    .p-xs-md-16pt {
        padding: 1rem !important;
    }

    .pt-xs-md-16pt,
    .py-xs-md-16pt {
        padding-top: 1rem !important;
    }

    .pr-xs-md-16pt,
    .px-xs-md-16pt {
        padding-right: 1rem !important;
    }

    [dir='rtl'] .pr-xs-md-16pt,
    [dir='rtl'] .px-xs-md-16pt {
        padding-left: 1rem !important;
    }

    .pb-xs-md-16pt,
    .py-xs-md-16pt {
        padding-bottom: 1rem !important;
    }

    .pl-xs-md-16pt,
    .px-xs-md-16pt {
        padding-left: 1rem !important;
    }

    [dir='rtl'] .pl-xs-md-16pt,
    [dir='rtl'] .px-xs-md-16pt {
        padding-right: 1rem !important;
    }

    .p-xs-md-20pt {
        padding: 1.25rem !important;
    }

    .pt-xs-md-20pt,
    .py-xs-md-20pt {
        padding-top: 1.25rem !important;
    }

    .pr-xs-md-20pt,
    .px-xs-md-20pt {
        padding-right: 1.25rem !important;
    }

    [dir='rtl'] .pr-xs-md-20pt,
    [dir='rtl'] .px-xs-md-20pt {
        padding-left: 1.25rem !important;
    }

    .pb-xs-md-20pt,
    .py-xs-md-20pt {
        padding-bottom: 1.25rem !important;
    }

    .pl-xs-md-20pt,
    .px-xs-md-20pt {
        padding-left: 1.25rem !important;
    }

    [dir='rtl'] .pl-xs-md-20pt,
    [dir='rtl'] .px-xs-md-20pt {
        padding-right: 1.25rem !important;
    }

    .p-xs-md-24pt {
        padding: 1.5rem !important;
    }

    .pt-xs-md-24pt,
    .py-xs-md-24pt {
        padding-top: 1.5rem !important;
    }

    .pr-xs-md-24pt,
    .px-xs-md-24pt {
        padding-right: 1.5rem !important;
    }

    [dir='rtl'] .pr-xs-md-24pt,
    [dir='rtl'] .px-xs-md-24pt {
        padding-left: 1.5rem !important;
    }

    .pb-xs-md-24pt,
    .py-xs-md-24pt {
        padding-bottom: 1.5rem !important;
    }

    .pl-xs-md-24pt,
    .px-xs-md-24pt {
        padding-left: 1.5rem !important;
    }

    [dir='rtl'] .pl-xs-md-24pt,
    [dir='rtl'] .px-xs-md-24pt {
        padding-right: 1.5rem !important;
    }

    .p-xs-md-32pt {
        padding: 2rem !important;
    }

    .pt-xs-md-32pt,
    .py-xs-md-32pt {
        padding-top: 2rem !important;
    }

    .pr-xs-md-32pt,
    .px-xs-md-32pt {
        padding-right: 2rem !important;
    }

    [dir='rtl'] .pr-xs-md-32pt,
    [dir='rtl'] .px-xs-md-32pt {
        padding-left: 2rem !important;
    }

    .pb-xs-md-32pt,
    .py-xs-md-32pt {
        padding-bottom: 2rem !important;
    }

    .pl-xs-md-32pt,
    .px-xs-md-32pt {
        padding-left: 2rem !important;
    }

    [dir='rtl'] .pl-xs-md-32pt,
    [dir='rtl'] .px-xs-md-32pt {
        padding-right: 2rem !important;
    }

    .p-xs-md-40pt {
        padding: 2.5rem !important;
    }

    .pt-xs-md-40pt,
    .py-xs-md-40pt {
        padding-top: 2.5rem !important;
    }

    .pr-xs-md-40pt,
    .px-xs-md-40pt {
        padding-right: 2.5rem !important;
    }

    [dir='rtl'] .pr-xs-md-40pt,
    [dir='rtl'] .px-xs-md-40pt {
        padding-left: 2.5rem !important;
    }

    .pb-xs-md-40pt,
    .py-xs-md-40pt {
        padding-bottom: 2.5rem !important;
    }

    .pl-xs-md-40pt,
    .px-xs-md-40pt {
        padding-left: 2.5rem !important;
    }

    [dir='rtl'] .pl-xs-md-40pt,
    [dir='rtl'] .px-xs-md-40pt {
        padding-right: 2.5rem !important;
    }

    .p-xs-md-44pt {
        padding: 2.75rem !important;
    }

    .pt-xs-md-44pt,
    .py-xs-md-44pt {
        padding-top: 2.75rem !important;
    }

    .pr-xs-md-44pt,
    .px-xs-md-44pt {
        padding-right: 2.75rem !important;
    }

    [dir='rtl'] .pr-xs-md-44pt,
    [dir='rtl'] .px-xs-md-44pt {
        padding-left: 2.75rem !important;
    }

    .pb-xs-md-44pt,
    .py-xs-md-44pt {
        padding-bottom: 2.75rem !important;
    }

    .pl-xs-md-44pt,
    .px-xs-md-44pt {
        padding-left: 2.75rem !important;
    }

    [dir='rtl'] .pl-xs-md-44pt,
    [dir='rtl'] .px-xs-md-44pt {
        padding-right: 2.75rem !important;
    }

    .p-xs-md-48pt {
        padding: 3rem !important;
    }

    .pt-xs-md-48pt,
    .py-xs-md-48pt {
        padding-top: 3rem !important;
    }

    .pr-xs-md-48pt,
    .px-xs-md-48pt {
        padding-right: 3rem !important;
    }

    [dir='rtl'] .pr-xs-md-48pt,
    [dir='rtl'] .px-xs-md-48pt {
        padding-left: 3rem !important;
    }

    .pb-xs-md-48pt,
    .py-xs-md-48pt {
        padding-bottom: 3rem !important;
    }

    .pl-xs-md-48pt,
    .px-xs-md-48pt {
        padding-left: 3rem !important;
    }

    [dir='rtl'] .pl-xs-md-48pt,
    [dir='rtl'] .px-xs-md-48pt {
        padding-right: 3rem !important;
    }

    .p-xs-md-64pt {
        padding: 4rem !important;
    }

    .pt-xs-md-64pt,
    .py-xs-md-64pt {
        padding-top: 4rem !important;
    }

    .pr-xs-md-64pt,
    .px-xs-md-64pt {
        padding-right: 4rem !important;
    }

    [dir='rtl'] .pr-xs-md-64pt,
    [dir='rtl'] .px-xs-md-64pt {
        padding-left: 4rem !important;
    }

    .pb-xs-md-64pt,
    .py-xs-md-64pt {
        padding-bottom: 4rem !important;
    }

    .pl-xs-md-64pt,
    .px-xs-md-64pt {
        padding-left: 4rem !important;
    }

    [dir='rtl'] .pl-xs-md-64pt,
    [dir='rtl'] .px-xs-md-64pt {
        padding-right: 4rem !important;
    }

    .p-xs-md-112pt {
        padding: 7rem !important;
    }

    .pt-xs-md-112pt,
    .py-xs-md-112pt {
        padding-top: 7rem !important;
    }

    .pr-xs-md-112pt,
    .px-xs-md-112pt {
        padding-right: 7rem !important;
    }

    [dir='rtl'] .pr-xs-md-112pt,
    [dir='rtl'] .px-xs-md-112pt {
        padding-left: 7rem !important;
    }

    .pb-xs-md-112pt,
    .py-xs-md-112pt {
        padding-bottom: 7rem !important;
    }

    .pl-xs-md-112pt,
    .px-xs-md-112pt {
        padding-left: 7rem !important;
    }

    [dir='rtl'] .pl-xs-md-112pt,
    [dir='rtl'] .px-xs-md-112pt {
        padding-right: 7rem !important;
    }

    .p-xs-md-128pt {
        padding: 8rem !important;
    }

    .pt-xs-md-128pt,
    .py-xs-md-128pt {
        padding-top: 8rem !important;
    }

    .pr-xs-md-128pt,
    .px-xs-md-128pt {
        padding-right: 8rem !important;
    }

    [dir='rtl'] .pr-xs-md-128pt,
    [dir='rtl'] .px-xs-md-128pt {
        padding-left: 8rem !important;
    }

    .pb-xs-md-128pt,
    .py-xs-md-128pt {
        padding-bottom: 8rem !important;
    }

    .pl-xs-md-128pt,
    .px-xs-md-128pt {
        padding-left: 8rem !important;
    }

    [dir='rtl'] .pl-xs-md-128pt,
    [dir='rtl'] .px-xs-md-128pt {
        padding-right: 8rem !important;
    }

    .m-xs-md-n1 {
        margin: -0.25rem !important;
    }

    .mt-xs-md-n1,
    .my-xs-md-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-xs-md-n1,
    .mx-xs-md-n1 {
        margin-right: -0.25rem !important;
    }

    [dir='rtl'] .mr-xs-md-n1,
    [dir='rtl'] .mx-xs-md-n1 {
        margin-left: -0.25rem !important;
    }

    .mb-xs-md-n1,
    .my-xs-md-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-xs-md-n1,
    .mx-xs-md-n1 {
        margin-left: -0.25rem !important;
    }

    [dir='rtl'] .ml-xs-md-n1,
    [dir='rtl'] .mx-xs-md-n1 {
        margin-right: -0.25rem !important;
    }

    .m-xs-md-n2 {
        margin: -0.5rem !important;
    }

    .mt-xs-md-n2,
    .my-xs-md-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-xs-md-n2,
    .mx-xs-md-n2 {
        margin-right: -0.5rem !important;
    }

    [dir='rtl'] .mr-xs-md-n2,
    [dir='rtl'] .mx-xs-md-n2 {
        margin-left: -0.5rem !important;
    }

    .mb-xs-md-n2,
    .my-xs-md-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-xs-md-n2,
    .mx-xs-md-n2 {
        margin-left: -0.5rem !important;
    }

    [dir='rtl'] .ml-xs-md-n2,
    [dir='rtl'] .mx-xs-md-n2 {
        margin-right: -0.5rem !important;
    }

    .m-xs-md-n3 {
        margin: -1rem !important;
    }

    .mt-xs-md-n3,
    .my-xs-md-n3 {
        margin-top: -1rem !important;
    }

    .mr-xs-md-n3,
    .mx-xs-md-n3 {
        margin-right: -1rem !important;
    }

    [dir='rtl'] .mr-xs-md-n3,
    [dir='rtl'] .mx-xs-md-n3 {
        margin-left: -1rem !important;
    }

    .mb-xs-md-n3,
    .my-xs-md-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-xs-md-n3,
    .mx-xs-md-n3 {
        margin-left: -1rem !important;
    }

    [dir='rtl'] .ml-xs-md-n3,
    [dir='rtl'] .mx-xs-md-n3 {
        margin-right: -1rem !important;
    }

    .m-xs-md-n4 {
        margin: -1.5rem !important;
    }

    .mt-xs-md-n4,
    .my-xs-md-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-xs-md-n4,
    .mx-xs-md-n4 {
        margin-right: -1.5rem !important;
    }

    [dir='rtl'] .mr-xs-md-n4,
    [dir='rtl'] .mx-xs-md-n4 {
        margin-left: -1.5rem !important;
    }

    .mb-xs-md-n4,
    .my-xs-md-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-xs-md-n4,
    .mx-xs-md-n4 {
        margin-left: -1.5rem !important;
    }

    [dir='rtl'] .ml-xs-md-n4,
    [dir='rtl'] .mx-xs-md-n4 {
        margin-right: -1.5rem !important;
    }

    .m-xs-md-n5 {
        margin: -3rem !important;
    }

    .mt-xs-md-n5,
    .my-xs-md-n5 {
        margin-top: -3rem !important;
    }

    .mr-xs-md-n5,
    .mx-xs-md-n5 {
        margin-right: -3rem !important;
    }

    [dir='rtl'] .mr-xs-md-n5,
    [dir='rtl'] .mx-xs-md-n5 {
        margin-left: -3rem !important;
    }

    .mb-xs-md-n5,
    .my-xs-md-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-xs-md-n5,
    .mx-xs-md-n5 {
        margin-left: -3rem !important;
    }

    [dir='rtl'] .ml-xs-md-n5,
    [dir='rtl'] .mx-xs-md-n5 {
        margin-right: -3rem !important;
    }

    .m-xs-md-n4pt {
        margin: -0.25rem !important;
    }

    .mt-xs-md-n4pt,
    .my-xs-md-n4pt {
        margin-top: -0.25rem !important;
    }

    .mr-xs-md-n4pt,
    .mx-xs-md-n4pt {
        margin-right: -0.25rem !important;
    }

    [dir='rtl'] .mr-xs-md-n4pt,
    [dir='rtl'] .mx-xs-md-n4pt {
        margin-left: -0.25rem !important;
    }

    .mb-xs-md-n4pt,
    .my-xs-md-n4pt {
        margin-bottom: -0.25rem !important;
    }

    .ml-xs-md-n4pt,
    .mx-xs-md-n4pt {
        margin-left: -0.25rem !important;
    }

    [dir='rtl'] .ml-xs-md-n4pt,
    [dir='rtl'] .mx-xs-md-n4pt {
        margin-right: -0.25rem !important;
    }

    .m-xs-md-n8pt {
        margin: -0.5rem !important;
    }

    .mt-xs-md-n8pt,
    .my-xs-md-n8pt {
        margin-top: -0.5rem !important;
    }

    .mr-xs-md-n8pt,
    .mx-xs-md-n8pt {
        margin-right: -0.5rem !important;
    }

    [dir='rtl'] .mr-xs-md-n8pt,
    [dir='rtl'] .mx-xs-md-n8pt {
        margin-left: -0.5rem !important;
    }

    .mb-xs-md-n8pt,
    .my-xs-md-n8pt {
        margin-bottom: -0.5rem !important;
    }

    .ml-xs-md-n8pt,
    .mx-xs-md-n8pt {
        margin-left: -0.5rem !important;
    }

    [dir='rtl'] .ml-xs-md-n8pt,
    [dir='rtl'] .mx-xs-md-n8pt {
        margin-right: -0.5rem !important;
    }

    .m-xs-md-n12pt {
        margin: -0.75rem !important;
    }

    .mt-xs-md-n12pt,
    .my-xs-md-n12pt {
        margin-top: -0.75rem !important;
    }

    .mr-xs-md-n12pt,
    .mx-xs-md-n12pt {
        margin-right: -0.75rem !important;
    }

    [dir='rtl'] .mr-xs-md-n12pt,
    [dir='rtl'] .mx-xs-md-n12pt {
        margin-left: -0.75rem !important;
    }

    .mb-xs-md-n12pt,
    .my-xs-md-n12pt {
        margin-bottom: -0.75rem !important;
    }

    .ml-xs-md-n12pt,
    .mx-xs-md-n12pt {
        margin-left: -0.75rem !important;
    }

    [dir='rtl'] .ml-xs-md-n12pt,
    [dir='rtl'] .mx-xs-md-n12pt {
        margin-right: -0.75rem !important;
    }

    .m-xs-md-n16pt {
        margin: -1rem !important;
    }

    .mt-xs-md-n16pt,
    .my-xs-md-n16pt {
        margin-top: -1rem !important;
    }

    .mr-xs-md-n16pt,
    .mx-xs-md-n16pt {
        margin-right: -1rem !important;
    }

    [dir='rtl'] .mr-xs-md-n16pt,
    [dir='rtl'] .mx-xs-md-n16pt {
        margin-left: -1rem !important;
    }

    .mb-xs-md-n16pt,
    .my-xs-md-n16pt {
        margin-bottom: -1rem !important;
    }

    .ml-xs-md-n16pt,
    .mx-xs-md-n16pt {
        margin-left: -1rem !important;
    }

    [dir='rtl'] .ml-xs-md-n16pt,
    [dir='rtl'] .mx-xs-md-n16pt {
        margin-right: -1rem !important;
    }

    .m-xs-md-n20pt {
        margin: -1.25rem !important;
    }

    .mt-xs-md-n20pt,
    .my-xs-md-n20pt {
        margin-top: -1.25rem !important;
    }

    .mr-xs-md-n20pt,
    .mx-xs-md-n20pt {
        margin-right: -1.25rem !important;
    }

    [dir='rtl'] .mr-xs-md-n20pt,
    [dir='rtl'] .mx-xs-md-n20pt {
        margin-left: -1.25rem !important;
    }

    .mb-xs-md-n20pt,
    .my-xs-md-n20pt {
        margin-bottom: -1.25rem !important;
    }

    .ml-xs-md-n20pt,
    .mx-xs-md-n20pt {
        margin-left: -1.25rem !important;
    }

    [dir='rtl'] .ml-xs-md-n20pt,
    [dir='rtl'] .mx-xs-md-n20pt {
        margin-right: -1.25rem !important;
    }

    .m-xs-md-n24pt {
        margin: -1.5rem !important;
    }

    .mt-xs-md-n24pt,
    .my-xs-md-n24pt {
        margin-top: -1.5rem !important;
    }

    .mr-xs-md-n24pt,
    .mx-xs-md-n24pt {
        margin-right: -1.5rem !important;
    }

    [dir='rtl'] .mr-xs-md-n24pt,
    [dir='rtl'] .mx-xs-md-n24pt {
        margin-left: -1.5rem !important;
    }

    .mb-xs-md-n24pt,
    .my-xs-md-n24pt {
        margin-bottom: -1.5rem !important;
    }

    .ml-xs-md-n24pt,
    .mx-xs-md-n24pt {
        margin-left: -1.5rem !important;
    }

    [dir='rtl'] .ml-xs-md-n24pt,
    [dir='rtl'] .mx-xs-md-n24pt {
        margin-right: -1.5rem !important;
    }

    .m-xs-md-n32pt {
        margin: -2rem !important;
    }

    .mt-xs-md-n32pt,
    .my-xs-md-n32pt {
        margin-top: -2rem !important;
    }

    .mr-xs-md-n32pt,
    .mx-xs-md-n32pt {
        margin-right: -2rem !important;
    }

    [dir='rtl'] .mr-xs-md-n32pt,
    [dir='rtl'] .mx-xs-md-n32pt {
        margin-left: -2rem !important;
    }

    .mb-xs-md-n32pt,
    .my-xs-md-n32pt {
        margin-bottom: -2rem !important;
    }

    .ml-xs-md-n32pt,
    .mx-xs-md-n32pt {
        margin-left: -2rem !important;
    }

    [dir='rtl'] .ml-xs-md-n32pt,
    [dir='rtl'] .mx-xs-md-n32pt {
        margin-right: -2rem !important;
    }

    .m-xs-md-n40pt {
        margin: -2.5rem !important;
    }

    .mt-xs-md-n40pt,
    .my-xs-md-n40pt {
        margin-top: -2.5rem !important;
    }

    .mr-xs-md-n40pt,
    .mx-xs-md-n40pt {
        margin-right: -2.5rem !important;
    }

    [dir='rtl'] .mr-xs-md-n40pt,
    [dir='rtl'] .mx-xs-md-n40pt {
        margin-left: -2.5rem !important;
    }

    .mb-xs-md-n40pt,
    .my-xs-md-n40pt {
        margin-bottom: -2.5rem !important;
    }

    .ml-xs-md-n40pt,
    .mx-xs-md-n40pt {
        margin-left: -2.5rem !important;
    }

    [dir='rtl'] .ml-xs-md-n40pt,
    [dir='rtl'] .mx-xs-md-n40pt {
        margin-right: -2.5rem !important;
    }

    .m-xs-md-n44pt {
        margin: -2.75rem !important;
    }

    .mt-xs-md-n44pt,
    .my-xs-md-n44pt {
        margin-top: -2.75rem !important;
    }

    .mr-xs-md-n44pt,
    .mx-xs-md-n44pt {
        margin-right: -2.75rem !important;
    }

    [dir='rtl'] .mr-xs-md-n44pt,
    [dir='rtl'] .mx-xs-md-n44pt {
        margin-left: -2.75rem !important;
    }

    .mb-xs-md-n44pt,
    .my-xs-md-n44pt {
        margin-bottom: -2.75rem !important;
    }

    .ml-xs-md-n44pt,
    .mx-xs-md-n44pt {
        margin-left: -2.75rem !important;
    }

    [dir='rtl'] .ml-xs-md-n44pt,
    [dir='rtl'] .mx-xs-md-n44pt {
        margin-right: -2.75rem !important;
    }

    .m-xs-md-n48pt {
        margin: -3rem !important;
    }

    .mt-xs-md-n48pt,
    .my-xs-md-n48pt {
        margin-top: -3rem !important;
    }

    .mr-xs-md-n48pt,
    .mx-xs-md-n48pt {
        margin-right: -3rem !important;
    }

    [dir='rtl'] .mr-xs-md-n48pt,
    [dir='rtl'] .mx-xs-md-n48pt {
        margin-left: -3rem !important;
    }

    .mb-xs-md-n48pt,
    .my-xs-md-n48pt {
        margin-bottom: -3rem !important;
    }

    .ml-xs-md-n48pt,
    .mx-xs-md-n48pt {
        margin-left: -3rem !important;
    }

    [dir='rtl'] .ml-xs-md-n48pt,
    [dir='rtl'] .mx-xs-md-n48pt {
        margin-right: -3rem !important;
    }

    .m-xs-md-n64pt {
        margin: -4rem !important;
    }

    .mt-xs-md-n64pt,
    .my-xs-md-n64pt {
        margin-top: -4rem !important;
    }

    .mr-xs-md-n64pt,
    .mx-xs-md-n64pt {
        margin-right: -4rem !important;
    }

    [dir='rtl'] .mr-xs-md-n64pt,
    [dir='rtl'] .mx-xs-md-n64pt {
        margin-left: -4rem !important;
    }

    .mb-xs-md-n64pt,
    .my-xs-md-n64pt {
        margin-bottom: -4rem !important;
    }

    .ml-xs-md-n64pt,
    .mx-xs-md-n64pt {
        margin-left: -4rem !important;
    }

    [dir='rtl'] .ml-xs-md-n64pt,
    [dir='rtl'] .mx-xs-md-n64pt {
        margin-right: -4rem !important;
    }

    .m-xs-md-n112pt {
        margin: -7rem !important;
    }

    .mt-xs-md-n112pt,
    .my-xs-md-n112pt {
        margin-top: -7rem !important;
    }

    .mr-xs-md-n112pt,
    .mx-xs-md-n112pt {
        margin-right: -7rem !important;
    }

    [dir='rtl'] .mr-xs-md-n112pt,
    [dir='rtl'] .mx-xs-md-n112pt {
        margin-left: -7rem !important;
    }

    .mb-xs-md-n112pt,
    .my-xs-md-n112pt {
        margin-bottom: -7rem !important;
    }

    .ml-xs-md-n112pt,
    .mx-xs-md-n112pt {
        margin-left: -7rem !important;
    }

    [dir='rtl'] .ml-xs-md-n112pt,
    [dir='rtl'] .mx-xs-md-n112pt {
        margin-right: -7rem !important;
    }

    .m-xs-md-n128pt {
        margin: -8rem !important;
    }

    .mt-xs-md-n128pt,
    .my-xs-md-n128pt {
        margin-top: -8rem !important;
    }

    .mr-xs-md-n128pt,
    .mx-xs-md-n128pt {
        margin-right: -8rem !important;
    }

    [dir='rtl'] .mr-xs-md-n128pt,
    [dir='rtl'] .mx-xs-md-n128pt {
        margin-left: -8rem !important;
    }

    .mb-xs-md-n128pt,
    .my-xs-md-n128pt {
        margin-bottom: -8rem !important;
    }

    .ml-xs-md-n128pt,
    .mx-xs-md-n128pt {
        margin-left: -8rem !important;
    }

    [dir='rtl'] .ml-xs-md-n128pt,
    [dir='rtl'] .mx-xs-md-n128pt {
        margin-right: -8rem !important;
    }

    .m-xs-md-auto {
        margin: auto !important;
    }

    .mt-xs-md-auto,
    .my-xs-md-auto {
        margin-top: auto !important;
    }

    .mr-xs-md-auto,
    .mx-xs-md-auto {
        margin-right: auto !important;
    }

    [dir='rtl'] .mr-xs-md-auto,
    [dir='rtl'] .mx-xs-md-auto {
        margin-left: auto !important;
    }

    .mb-xs-md-auto,
    .my-xs-md-auto {
        margin-bottom: auto !important;
    }

    .ml-xs-md-auto,
    .mx-xs-md-auto {
        margin-left: auto !important;
    }

    [dir='rtl'] .ml-xs-md-auto,
    [dir='rtl'] .mx-xs-md-auto {
        margin-right: auto !important;
    }
}

@media (min-width: 413px) {
    .m-xs-plus-0 {
        margin: 0 !important;
    }

    .mt-xs-plus-0,
    .my-xs-plus-0 {
        margin-top: 0 !important;
    }

    .mr-xs-plus-0,
    .mx-xs-plus-0 {
        margin-right: 0 !important;
    }

    [dir='rtl'] .mr-xs-plus-0,
    [dir='rtl'] .mx-xs-plus-0 {
        margin-left: 0 !important;
    }

    .mb-xs-plus-0,
    .my-xs-plus-0 {
        margin-bottom: 0 !important;
    }

    .ml-xs-plus-0,
    .mx-xs-plus-0 {
        margin-left: 0 !important;
    }

    [dir='rtl'] .ml-xs-plus-0,
    [dir='rtl'] .mx-xs-plus-0 {
        margin-right: 0 !important;
    }

    .m-xs-plus-1 {
        margin: 0.25rem !important;
    }

    .mt-xs-plus-1,
    .my-xs-plus-1 {
        margin-top: 0.25rem !important;
    }

    .mr-xs-plus-1,
    .mx-xs-plus-1 {
        margin-right: 0.25rem !important;
    }

    [dir='rtl'] .mr-xs-plus-1,
    [dir='rtl'] .mx-xs-plus-1 {
        margin-left: 0.25rem !important;
    }

    .mb-xs-plus-1,
    .my-xs-plus-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-xs-plus-1,
    .mx-xs-plus-1 {
        margin-left: 0.25rem !important;
    }

    [dir='rtl'] .ml-xs-plus-1,
    [dir='rtl'] .mx-xs-plus-1 {
        margin-right: 0.25rem !important;
    }

    .m-xs-plus-2 {
        margin: 0.5rem !important;
    }

    .mt-xs-plus-2,
    .my-xs-plus-2 {
        margin-top: 0.5rem !important;
    }

    .mr-xs-plus-2,
    .mx-xs-plus-2 {
        margin-right: 0.5rem !important;
    }

    [dir='rtl'] .mr-xs-plus-2,
    [dir='rtl'] .mx-xs-plus-2 {
        margin-left: 0.5rem !important;
    }

    .mb-xs-plus-2,
    .my-xs-plus-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-xs-plus-2,
    .mx-xs-plus-2 {
        margin-left: 0.5rem !important;
    }

    [dir='rtl'] .ml-xs-plus-2,
    [dir='rtl'] .mx-xs-plus-2 {
        margin-right: 0.5rem !important;
    }

    .m-xs-plus-3 {
        margin: 1rem !important;
    }

    .mt-xs-plus-3,
    .my-xs-plus-3 {
        margin-top: 1rem !important;
    }

    .mr-xs-plus-3,
    .mx-xs-plus-3 {
        margin-right: 1rem !important;
    }

    [dir='rtl'] .mr-xs-plus-3,
    [dir='rtl'] .mx-xs-plus-3 {
        margin-left: 1rem !important;
    }

    .mb-xs-plus-3,
    .my-xs-plus-3 {
        margin-bottom: 1rem !important;
    }

    .ml-xs-plus-3,
    .mx-xs-plus-3 {
        margin-left: 1rem !important;
    }

    [dir='rtl'] .ml-xs-plus-3,
    [dir='rtl'] .mx-xs-plus-3 {
        margin-right: 1rem !important;
    }

    .m-xs-plus-4 {
        margin: 1.5rem !important;
    }

    .mt-xs-plus-4,
    .my-xs-plus-4 {
        margin-top: 1.5rem !important;
    }

    .mr-xs-plus-4,
    .mx-xs-plus-4 {
        margin-right: 1.5rem !important;
    }

    [dir='rtl'] .mr-xs-plus-4,
    [dir='rtl'] .mx-xs-plus-4 {
        margin-left: 1.5rem !important;
    }

    .mb-xs-plus-4,
    .my-xs-plus-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-xs-plus-4,
    .mx-xs-plus-4 {
        margin-left: 1.5rem !important;
    }

    [dir='rtl'] .ml-xs-plus-4,
    [dir='rtl'] .mx-xs-plus-4 {
        margin-right: 1.5rem !important;
    }

    .m-xs-plus-5 {
        margin: 3rem !important;
    }

    .mt-xs-plus-5,
    .my-xs-plus-5 {
        margin-top: 3rem !important;
    }

    .mr-xs-plus-5,
    .mx-xs-plus-5 {
        margin-right: 3rem !important;
    }

    [dir='rtl'] .mr-xs-plus-5,
    [dir='rtl'] .mx-xs-plus-5 {
        margin-left: 3rem !important;
    }

    .mb-xs-plus-5,
    .my-xs-plus-5 {
        margin-bottom: 3rem !important;
    }

    .ml-xs-plus-5,
    .mx-xs-plus-5 {
        margin-left: 3rem !important;
    }

    [dir='rtl'] .ml-xs-plus-5,
    [dir='rtl'] .mx-xs-plus-5 {
        margin-right: 3rem !important;
    }

    .m-xs-plus-4pt {
        margin: 0.25rem !important;
    }

    .mt-xs-plus-4pt,
    .my-xs-plus-4pt {
        margin-top: 0.25rem !important;
    }

    .mr-xs-plus-4pt,
    .mx-xs-plus-4pt {
        margin-right: 0.25rem !important;
    }

    [dir='rtl'] .mr-xs-plus-4pt,
    [dir='rtl'] .mx-xs-plus-4pt {
        margin-left: 0.25rem !important;
    }

    .mb-xs-plus-4pt,
    .my-xs-plus-4pt {
        margin-bottom: 0.25rem !important;
    }

    .ml-xs-plus-4pt,
    .mx-xs-plus-4pt {
        margin-left: 0.25rem !important;
    }

    [dir='rtl'] .ml-xs-plus-4pt,
    [dir='rtl'] .mx-xs-plus-4pt {
        margin-right: 0.25rem !important;
    }

    .m-xs-plus-8pt {
        margin: 0.5rem !important;
    }

    .mt-xs-plus-8pt,
    .my-xs-plus-8pt {
        margin-top: 0.5rem !important;
    }

    .mr-xs-plus-8pt,
    .mx-xs-plus-8pt {
        margin-right: 0.5rem !important;
    }

    [dir='rtl'] .mr-xs-plus-8pt,
    [dir='rtl'] .mx-xs-plus-8pt {
        margin-left: 0.5rem !important;
    }

    .mb-xs-plus-8pt,
    .my-xs-plus-8pt {
        margin-bottom: 0.5rem !important;
    }

    .ml-xs-plus-8pt,
    .mx-xs-plus-8pt {
        margin-left: 0.5rem !important;
    }

    [dir='rtl'] .ml-xs-plus-8pt,
    [dir='rtl'] .mx-xs-plus-8pt {
        margin-right: 0.5rem !important;
    }

    .m-xs-plus-12pt {
        margin: 0.75rem !important;
    }

    .mt-xs-plus-12pt,
    .my-xs-plus-12pt {
        margin-top: 0.75rem !important;
    }

    .mr-xs-plus-12pt,
    .mx-xs-plus-12pt {
        margin-right: 0.75rem !important;
    }

    [dir='rtl'] .mr-xs-plus-12pt,
    [dir='rtl'] .mx-xs-plus-12pt {
        margin-left: 0.75rem !important;
    }

    .mb-xs-plus-12pt,
    .my-xs-plus-12pt {
        margin-bottom: 0.75rem !important;
    }

    .ml-xs-plus-12pt,
    .mx-xs-plus-12pt {
        margin-left: 0.75rem !important;
    }

    [dir='rtl'] .ml-xs-plus-12pt,
    [dir='rtl'] .mx-xs-plus-12pt {
        margin-right: 0.75rem !important;
    }

    .m-xs-plus-16pt {
        margin: 1rem !important;
    }

    .mt-xs-plus-16pt,
    .my-xs-plus-16pt {
        margin-top: 1rem !important;
    }

    .mr-xs-plus-16pt,
    .mx-xs-plus-16pt {
        margin-right: 1rem !important;
    }

    [dir='rtl'] .mr-xs-plus-16pt,
    [dir='rtl'] .mx-xs-plus-16pt {
        margin-left: 1rem !important;
    }

    .mb-xs-plus-16pt,
    .my-xs-plus-16pt {
        margin-bottom: 1rem !important;
    }

    .ml-xs-plus-16pt,
    .mx-xs-plus-16pt {
        margin-left: 1rem !important;
    }

    [dir='rtl'] .ml-xs-plus-16pt,
    [dir='rtl'] .mx-xs-plus-16pt {
        margin-right: 1rem !important;
    }

    .m-xs-plus-20pt {
        margin: 1.25rem !important;
    }

    .mt-xs-plus-20pt,
    .my-xs-plus-20pt {
        margin-top: 1.25rem !important;
    }

    .mr-xs-plus-20pt,
    .mx-xs-plus-20pt {
        margin-right: 1.25rem !important;
    }

    [dir='rtl'] .mr-xs-plus-20pt,
    [dir='rtl'] .mx-xs-plus-20pt {
        margin-left: 1.25rem !important;
    }

    .mb-xs-plus-20pt,
    .my-xs-plus-20pt {
        margin-bottom: 1.25rem !important;
    }

    .ml-xs-plus-20pt,
    .mx-xs-plus-20pt {
        margin-left: 1.25rem !important;
    }

    [dir='rtl'] .ml-xs-plus-20pt,
    [dir='rtl'] .mx-xs-plus-20pt {
        margin-right: 1.25rem !important;
    }

    .m-xs-plus-24pt {
        margin: 1.5rem !important;
    }

    .mt-xs-plus-24pt,
    .my-xs-plus-24pt {
        margin-top: 1.5rem !important;
    }

    .mr-xs-plus-24pt,
    .mx-xs-plus-24pt {
        margin-right: 1.5rem !important;
    }

    [dir='rtl'] .mr-xs-plus-24pt,
    [dir='rtl'] .mx-xs-plus-24pt {
        margin-left: 1.5rem !important;
    }

    .mb-xs-plus-24pt,
    .my-xs-plus-24pt {
        margin-bottom: 1.5rem !important;
    }

    .ml-xs-plus-24pt,
    .mx-xs-plus-24pt {
        margin-left: 1.5rem !important;
    }

    [dir='rtl'] .ml-xs-plus-24pt,
    [dir='rtl'] .mx-xs-plus-24pt {
        margin-right: 1.5rem !important;
    }

    .m-xs-plus-32pt {
        margin: 2rem !important;
    }

    .mt-xs-plus-32pt,
    .my-xs-plus-32pt {
        margin-top: 2rem !important;
    }

    .mr-xs-plus-32pt,
    .mx-xs-plus-32pt {
        margin-right: 2rem !important;
    }

    [dir='rtl'] .mr-xs-plus-32pt,
    [dir='rtl'] .mx-xs-plus-32pt {
        margin-left: 2rem !important;
    }

    .mb-xs-plus-32pt,
    .my-xs-plus-32pt {
        margin-bottom: 2rem !important;
    }

    .ml-xs-plus-32pt,
    .mx-xs-plus-32pt {
        margin-left: 2rem !important;
    }

    [dir='rtl'] .ml-xs-plus-32pt,
    [dir='rtl'] .mx-xs-plus-32pt {
        margin-right: 2rem !important;
    }

    .m-xs-plus-40pt {
        margin: 2.5rem !important;
    }

    .mt-xs-plus-40pt,
    .my-xs-plus-40pt {
        margin-top: 2.5rem !important;
    }

    .mr-xs-plus-40pt,
    .mx-xs-plus-40pt {
        margin-right: 2.5rem !important;
    }

    [dir='rtl'] .mr-xs-plus-40pt,
    [dir='rtl'] .mx-xs-plus-40pt {
        margin-left: 2.5rem !important;
    }

    .mb-xs-plus-40pt,
    .my-xs-plus-40pt {
        margin-bottom: 2.5rem !important;
    }

    .ml-xs-plus-40pt,
    .mx-xs-plus-40pt {
        margin-left: 2.5rem !important;
    }

    [dir='rtl'] .ml-xs-plus-40pt,
    [dir='rtl'] .mx-xs-plus-40pt {
        margin-right: 2.5rem !important;
    }

    .m-xs-plus-44pt {
        margin: 2.75rem !important;
    }

    .mt-xs-plus-44pt,
    .my-xs-plus-44pt {
        margin-top: 2.75rem !important;
    }

    .mr-xs-plus-44pt,
    .mx-xs-plus-44pt {
        margin-right: 2.75rem !important;
    }

    [dir='rtl'] .mr-xs-plus-44pt,
    [dir='rtl'] .mx-xs-plus-44pt {
        margin-left: 2.75rem !important;
    }

    .mb-xs-plus-44pt,
    .my-xs-plus-44pt {
        margin-bottom: 2.75rem !important;
    }

    .ml-xs-plus-44pt,
    .mx-xs-plus-44pt {
        margin-left: 2.75rem !important;
    }

    [dir='rtl'] .ml-xs-plus-44pt,
    [dir='rtl'] .mx-xs-plus-44pt {
        margin-right: 2.75rem !important;
    }

    .m-xs-plus-48pt {
        margin: 3rem !important;
    }

    .mt-xs-plus-48pt,
    .my-xs-plus-48pt {
        margin-top: 3rem !important;
    }

    .mr-xs-plus-48pt,
    .mx-xs-plus-48pt {
        margin-right: 3rem !important;
    }

    [dir='rtl'] .mr-xs-plus-48pt,
    [dir='rtl'] .mx-xs-plus-48pt {
        margin-left: 3rem !important;
    }

    .mb-xs-plus-48pt,
    .my-xs-plus-48pt {
        margin-bottom: 3rem !important;
    }

    .ml-xs-plus-48pt,
    .mx-xs-plus-48pt {
        margin-left: 3rem !important;
    }

    [dir='rtl'] .ml-xs-plus-48pt,
    [dir='rtl'] .mx-xs-plus-48pt {
        margin-right: 3rem !important;
    }

    .m-xs-plus-64pt {
        margin: 4rem !important;
    }

    .mt-xs-plus-64pt,
    .my-xs-plus-64pt {
        margin-top: 4rem !important;
    }

    .mr-xs-plus-64pt,
    .mx-xs-plus-64pt {
        margin-right: 4rem !important;
    }

    [dir='rtl'] .mr-xs-plus-64pt,
    [dir='rtl'] .mx-xs-plus-64pt {
        margin-left: 4rem !important;
    }

    .mb-xs-plus-64pt,
    .my-xs-plus-64pt {
        margin-bottom: 4rem !important;
    }

    .ml-xs-plus-64pt,
    .mx-xs-plus-64pt {
        margin-left: 4rem !important;
    }

    [dir='rtl'] .ml-xs-plus-64pt,
    [dir='rtl'] .mx-xs-plus-64pt {
        margin-right: 4rem !important;
    }

    .m-xs-plus-112pt {
        margin: 7rem !important;
    }

    .mt-xs-plus-112pt,
    .my-xs-plus-112pt {
        margin-top: 7rem !important;
    }

    .mr-xs-plus-112pt,
    .mx-xs-plus-112pt {
        margin-right: 7rem !important;
    }

    [dir='rtl'] .mr-xs-plus-112pt,
    [dir='rtl'] .mx-xs-plus-112pt {
        margin-left: 7rem !important;
    }

    .mb-xs-plus-112pt,
    .my-xs-plus-112pt {
        margin-bottom: 7rem !important;
    }

    .ml-xs-plus-112pt,
    .mx-xs-plus-112pt {
        margin-left: 7rem !important;
    }

    [dir='rtl'] .ml-xs-plus-112pt,
    [dir='rtl'] .mx-xs-plus-112pt {
        margin-right: 7rem !important;
    }

    .m-xs-plus-128pt {
        margin: 8rem !important;
    }

    .mt-xs-plus-128pt,
    .my-xs-plus-128pt {
        margin-top: 8rem !important;
    }

    .mr-xs-plus-128pt,
    .mx-xs-plus-128pt {
        margin-right: 8rem !important;
    }

    [dir='rtl'] .mr-xs-plus-128pt,
    [dir='rtl'] .mx-xs-plus-128pt {
        margin-left: 8rem !important;
    }

    .mb-xs-plus-128pt,
    .my-xs-plus-128pt {
        margin-bottom: 8rem !important;
    }

    .ml-xs-plus-128pt,
    .mx-xs-plus-128pt {
        margin-left: 8rem !important;
    }

    [dir='rtl'] .ml-xs-plus-128pt,
    [dir='rtl'] .mx-xs-plus-128pt {
        margin-right: 8rem !important;
    }

    .p-xs-plus-0 {
        padding: 0 !important;
    }

    .pt-xs-plus-0,
    .py-xs-plus-0 {
        padding-top: 0 !important;
    }

    .pr-xs-plus-0,
    .px-xs-plus-0 {
        padding-right: 0 !important;
    }

    [dir='rtl'] .pr-xs-plus-0,
    [dir='rtl'] .px-xs-plus-0 {
        padding-left: 0 !important;
    }

    .pb-xs-plus-0,
    .py-xs-plus-0 {
        padding-bottom: 0 !important;
    }

    .pl-xs-plus-0,
    .px-xs-plus-0 {
        padding-left: 0 !important;
    }

    [dir='rtl'] .pl-xs-plus-0,
    [dir='rtl'] .px-xs-plus-0 {
        padding-right: 0 !important;
    }

    .p-xs-plus-1 {
        padding: 0.25rem !important;
    }

    .pt-xs-plus-1,
    .py-xs-plus-1 {
        padding-top: 0.25rem !important;
    }

    .pr-xs-plus-1,
    .px-xs-plus-1 {
        padding-right: 0.25rem !important;
    }

    [dir='rtl'] .pr-xs-plus-1,
    [dir='rtl'] .px-xs-plus-1 {
        padding-left: 0.25rem !important;
    }

    .pb-xs-plus-1,
    .py-xs-plus-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-xs-plus-1,
    .px-xs-plus-1 {
        padding-left: 0.25rem !important;
    }

    [dir='rtl'] .pl-xs-plus-1,
    [dir='rtl'] .px-xs-plus-1 {
        padding-right: 0.25rem !important;
    }

    .p-xs-plus-2 {
        padding: 0.5rem !important;
    }

    .pt-xs-plus-2,
    .py-xs-plus-2 {
        padding-top: 0.5rem !important;
    }

    .pr-xs-plus-2,
    .px-xs-plus-2 {
        padding-right: 0.5rem !important;
    }

    [dir='rtl'] .pr-xs-plus-2,
    [dir='rtl'] .px-xs-plus-2 {
        padding-left: 0.5rem !important;
    }

    .pb-xs-plus-2,
    .py-xs-plus-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-xs-plus-2,
    .px-xs-plus-2 {
        padding-left: 0.5rem !important;
    }

    [dir='rtl'] .pl-xs-plus-2,
    [dir='rtl'] .px-xs-plus-2 {
        padding-right: 0.5rem !important;
    }

    .p-xs-plus-3 {
        padding: 1rem !important;
    }

    .pt-xs-plus-3,
    .py-xs-plus-3 {
        padding-top: 1rem !important;
    }

    .pr-xs-plus-3,
    .px-xs-plus-3 {
        padding-right: 1rem !important;
    }

    [dir='rtl'] .pr-xs-plus-3,
    [dir='rtl'] .px-xs-plus-3 {
        padding-left: 1rem !important;
    }

    .pb-xs-plus-3,
    .py-xs-plus-3 {
        padding-bottom: 1rem !important;
    }

    .pl-xs-plus-3,
    .px-xs-plus-3 {
        padding-left: 1rem !important;
    }

    [dir='rtl'] .pl-xs-plus-3,
    [dir='rtl'] .px-xs-plus-3 {
        padding-right: 1rem !important;
    }

    .p-xs-plus-4 {
        padding: 1.5rem !important;
    }

    .pt-xs-plus-4,
    .py-xs-plus-4 {
        padding-top: 1.5rem !important;
    }

    .pr-xs-plus-4,
    .px-xs-plus-4 {
        padding-right: 1.5rem !important;
    }

    [dir='rtl'] .pr-xs-plus-4,
    [dir='rtl'] .px-xs-plus-4 {
        padding-left: 1.5rem !important;
    }

    .pb-xs-plus-4,
    .py-xs-plus-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-xs-plus-4,
    .px-xs-plus-4 {
        padding-left: 1.5rem !important;
    }

    [dir='rtl'] .pl-xs-plus-4,
    [dir='rtl'] .px-xs-plus-4 {
        padding-right: 1.5rem !important;
    }

    .p-xs-plus-5 {
        padding: 3rem !important;
    }

    .pt-xs-plus-5,
    .py-xs-plus-5 {
        padding-top: 3rem !important;
    }

    .pr-xs-plus-5,
    .px-xs-plus-5 {
        padding-right: 3rem !important;
    }

    [dir='rtl'] .pr-xs-plus-5,
    [dir='rtl'] .px-xs-plus-5 {
        padding-left: 3rem !important;
    }

    .pb-xs-plus-5,
    .py-xs-plus-5 {
        padding-bottom: 3rem !important;
    }

    .pl-xs-plus-5,
    .px-xs-plus-5 {
        padding-left: 3rem !important;
    }

    [dir='rtl'] .pl-xs-plus-5,
    [dir='rtl'] .px-xs-plus-5 {
        padding-right: 3rem !important;
    }

    .p-xs-plus-4pt {
        padding: 0.25rem !important;
    }

    .pt-xs-plus-4pt,
    .py-xs-plus-4pt {
        padding-top: 0.25rem !important;
    }

    .pr-xs-plus-4pt,
    .px-xs-plus-4pt {
        padding-right: 0.25rem !important;
    }

    [dir='rtl'] .pr-xs-plus-4pt,
    [dir='rtl'] .px-xs-plus-4pt {
        padding-left: 0.25rem !important;
    }

    .pb-xs-plus-4pt,
    .py-xs-plus-4pt {
        padding-bottom: 0.25rem !important;
    }

    .pl-xs-plus-4pt,
    .px-xs-plus-4pt {
        padding-left: 0.25rem !important;
    }

    [dir='rtl'] .pl-xs-plus-4pt,
    [dir='rtl'] .px-xs-plus-4pt {
        padding-right: 0.25rem !important;
    }

    .p-xs-plus-8pt {
        padding: 0.5rem !important;
    }

    .pt-xs-plus-8pt,
    .py-xs-plus-8pt {
        padding-top: 0.5rem !important;
    }

    .pr-xs-plus-8pt,
    .px-xs-plus-8pt {
        padding-right: 0.5rem !important;
    }

    [dir='rtl'] .pr-xs-plus-8pt,
    [dir='rtl'] .px-xs-plus-8pt {
        padding-left: 0.5rem !important;
    }

    .pb-xs-plus-8pt,
    .py-xs-plus-8pt {
        padding-bottom: 0.5rem !important;
    }

    .pl-xs-plus-8pt,
    .px-xs-plus-8pt {
        padding-left: 0.5rem !important;
    }

    [dir='rtl'] .pl-xs-plus-8pt,
    [dir='rtl'] .px-xs-plus-8pt {
        padding-right: 0.5rem !important;
    }

    .p-xs-plus-12pt {
        padding: 0.75rem !important;
    }

    .pt-xs-plus-12pt,
    .py-xs-plus-12pt {
        padding-top: 0.75rem !important;
    }

    .pr-xs-plus-12pt,
    .px-xs-plus-12pt {
        padding-right: 0.75rem !important;
    }

    [dir='rtl'] .pr-xs-plus-12pt,
    [dir='rtl'] .px-xs-plus-12pt {
        padding-left: 0.75rem !important;
    }

    .pb-xs-plus-12pt,
    .py-xs-plus-12pt {
        padding-bottom: 0.75rem !important;
    }

    .pl-xs-plus-12pt,
    .px-xs-plus-12pt {
        padding-left: 0.75rem !important;
    }

    [dir='rtl'] .pl-xs-plus-12pt,
    [dir='rtl'] .px-xs-plus-12pt {
        padding-right: 0.75rem !important;
    }

    .p-xs-plus-16pt {
        padding: 1rem !important;
    }

    .pt-xs-plus-16pt,
    .py-xs-plus-16pt {
        padding-top: 1rem !important;
    }

    .pr-xs-plus-16pt,
    .px-xs-plus-16pt {
        padding-right: 1rem !important;
    }

    [dir='rtl'] .pr-xs-plus-16pt,
    [dir='rtl'] .px-xs-plus-16pt {
        padding-left: 1rem !important;
    }

    .pb-xs-plus-16pt,
    .py-xs-plus-16pt {
        padding-bottom: 1rem !important;
    }

    .pl-xs-plus-16pt,
    .px-xs-plus-16pt {
        padding-left: 1rem !important;
    }

    [dir='rtl'] .pl-xs-plus-16pt,
    [dir='rtl'] .px-xs-plus-16pt {
        padding-right: 1rem !important;
    }

    .p-xs-plus-20pt {
        padding: 1.25rem !important;
    }

    .pt-xs-plus-20pt,
    .py-xs-plus-20pt {
        padding-top: 1.25rem !important;
    }

    .pr-xs-plus-20pt,
    .px-xs-plus-20pt {
        padding-right: 1.25rem !important;
    }

    [dir='rtl'] .pr-xs-plus-20pt,
    [dir='rtl'] .px-xs-plus-20pt {
        padding-left: 1.25rem !important;
    }

    .pb-xs-plus-20pt,
    .py-xs-plus-20pt {
        padding-bottom: 1.25rem !important;
    }

    .pl-xs-plus-20pt,
    .px-xs-plus-20pt {
        padding-left: 1.25rem !important;
    }

    [dir='rtl'] .pl-xs-plus-20pt,
    [dir='rtl'] .px-xs-plus-20pt {
        padding-right: 1.25rem !important;
    }

    .p-xs-plus-24pt {
        padding: 1.5rem !important;
    }

    .pt-xs-plus-24pt,
    .py-xs-plus-24pt {
        padding-top: 1.5rem !important;
    }

    .pr-xs-plus-24pt,
    .px-xs-plus-24pt {
        padding-right: 1.5rem !important;
    }

    [dir='rtl'] .pr-xs-plus-24pt,
    [dir='rtl'] .px-xs-plus-24pt {
        padding-left: 1.5rem !important;
    }

    .pb-xs-plus-24pt,
    .py-xs-plus-24pt {
        padding-bottom: 1.5rem !important;
    }

    .pl-xs-plus-24pt,
    .px-xs-plus-24pt {
        padding-left: 1.5rem !important;
    }

    [dir='rtl'] .pl-xs-plus-24pt,
    [dir='rtl'] .px-xs-plus-24pt {
        padding-right: 1.5rem !important;
    }

    .p-xs-plus-32pt {
        padding: 2rem !important;
    }

    .pt-xs-plus-32pt,
    .py-xs-plus-32pt {
        padding-top: 2rem !important;
    }

    .pr-xs-plus-32pt,
    .px-xs-plus-32pt {
        padding-right: 2rem !important;
    }

    [dir='rtl'] .pr-xs-plus-32pt,
    [dir='rtl'] .px-xs-plus-32pt {
        padding-left: 2rem !important;
    }

    .pb-xs-plus-32pt,
    .py-xs-plus-32pt {
        padding-bottom: 2rem !important;
    }

    .pl-xs-plus-32pt,
    .px-xs-plus-32pt {
        padding-left: 2rem !important;
    }

    [dir='rtl'] .pl-xs-plus-32pt,
    [dir='rtl'] .px-xs-plus-32pt {
        padding-right: 2rem !important;
    }

    .p-xs-plus-40pt {
        padding: 2.5rem !important;
    }

    .pt-xs-plus-40pt,
    .py-xs-plus-40pt {
        padding-top: 2.5rem !important;
    }

    .pr-xs-plus-40pt,
    .px-xs-plus-40pt {
        padding-right: 2.5rem !important;
    }

    [dir='rtl'] .pr-xs-plus-40pt,
    [dir='rtl'] .px-xs-plus-40pt {
        padding-left: 2.5rem !important;
    }

    .pb-xs-plus-40pt,
    .py-xs-plus-40pt {
        padding-bottom: 2.5rem !important;
    }

    .pl-xs-plus-40pt,
    .px-xs-plus-40pt {
        padding-left: 2.5rem !important;
    }

    [dir='rtl'] .pl-xs-plus-40pt,
    [dir='rtl'] .px-xs-plus-40pt {
        padding-right: 2.5rem !important;
    }

    .p-xs-plus-44pt {
        padding: 2.75rem !important;
    }

    .pt-xs-plus-44pt,
    .py-xs-plus-44pt {
        padding-top: 2.75rem !important;
    }

    .pr-xs-plus-44pt,
    .px-xs-plus-44pt {
        padding-right: 2.75rem !important;
    }

    [dir='rtl'] .pr-xs-plus-44pt,
    [dir='rtl'] .px-xs-plus-44pt {
        padding-left: 2.75rem !important;
    }

    .pb-xs-plus-44pt,
    .py-xs-plus-44pt {
        padding-bottom: 2.75rem !important;
    }

    .pl-xs-plus-44pt,
    .px-xs-plus-44pt {
        padding-left: 2.75rem !important;
    }

    [dir='rtl'] .pl-xs-plus-44pt,
    [dir='rtl'] .px-xs-plus-44pt {
        padding-right: 2.75rem !important;
    }

    .p-xs-plus-48pt {
        padding: 3rem !important;
    }

    .pt-xs-plus-48pt,
    .py-xs-plus-48pt {
        padding-top: 3rem !important;
    }

    .pr-xs-plus-48pt,
    .px-xs-plus-48pt {
        padding-right: 3rem !important;
    }

    [dir='rtl'] .pr-xs-plus-48pt,
    [dir='rtl'] .px-xs-plus-48pt {
        padding-left: 3rem !important;
    }

    .pb-xs-plus-48pt,
    .py-xs-plus-48pt {
        padding-bottom: 3rem !important;
    }

    .pl-xs-plus-48pt,
    .px-xs-plus-48pt {
        padding-left: 3rem !important;
    }

    [dir='rtl'] .pl-xs-plus-48pt,
    [dir='rtl'] .px-xs-plus-48pt {
        padding-right: 3rem !important;
    }

    .p-xs-plus-64pt {
        padding: 4rem !important;
    }

    .pt-xs-plus-64pt,
    .py-xs-plus-64pt {
        padding-top: 4rem !important;
    }

    .pr-xs-plus-64pt,
    .px-xs-plus-64pt {
        padding-right: 4rem !important;
    }

    [dir='rtl'] .pr-xs-plus-64pt,
    [dir='rtl'] .px-xs-plus-64pt {
        padding-left: 4rem !important;
    }

    .pb-xs-plus-64pt,
    .py-xs-plus-64pt {
        padding-bottom: 4rem !important;
    }

    .pl-xs-plus-64pt,
    .px-xs-plus-64pt {
        padding-left: 4rem !important;
    }

    [dir='rtl'] .pl-xs-plus-64pt,
    [dir='rtl'] .px-xs-plus-64pt {
        padding-right: 4rem !important;
    }

    .p-xs-plus-112pt {
        padding: 7rem !important;
    }

    .pt-xs-plus-112pt,
    .py-xs-plus-112pt {
        padding-top: 7rem !important;
    }

    .pr-xs-plus-112pt,
    .px-xs-plus-112pt {
        padding-right: 7rem !important;
    }

    [dir='rtl'] .pr-xs-plus-112pt,
    [dir='rtl'] .px-xs-plus-112pt {
        padding-left: 7rem !important;
    }

    .pb-xs-plus-112pt,
    .py-xs-plus-112pt {
        padding-bottom: 7rem !important;
    }

    .pl-xs-plus-112pt,
    .px-xs-plus-112pt {
        padding-left: 7rem !important;
    }

    [dir='rtl'] .pl-xs-plus-112pt,
    [dir='rtl'] .px-xs-plus-112pt {
        padding-right: 7rem !important;
    }

    .p-xs-plus-128pt {
        padding: 8rem !important;
    }

    .pt-xs-plus-128pt,
    .py-xs-plus-128pt {
        padding-top: 8rem !important;
    }

    .pr-xs-plus-128pt,
    .px-xs-plus-128pt {
        padding-right: 8rem !important;
    }

    [dir='rtl'] .pr-xs-plus-128pt,
    [dir='rtl'] .px-xs-plus-128pt {
        padding-left: 8rem !important;
    }

    .pb-xs-plus-128pt,
    .py-xs-plus-128pt {
        padding-bottom: 8rem !important;
    }

    .pl-xs-plus-128pt,
    .px-xs-plus-128pt {
        padding-left: 8rem !important;
    }

    [dir='rtl'] .pl-xs-plus-128pt,
    [dir='rtl'] .px-xs-plus-128pt {
        padding-right: 8rem !important;
    }

    .m-xs-plus-n1 {
        margin: -0.25rem !important;
    }

    .mt-xs-plus-n1,
    .my-xs-plus-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-xs-plus-n1,
    .mx-xs-plus-n1 {
        margin-right: -0.25rem !important;
    }

    [dir='rtl'] .mr-xs-plus-n1,
    [dir='rtl'] .mx-xs-plus-n1 {
        margin-left: -0.25rem !important;
    }

    .mb-xs-plus-n1,
    .my-xs-plus-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-xs-plus-n1,
    .mx-xs-plus-n1 {
        margin-left: -0.25rem !important;
    }

    [dir='rtl'] .ml-xs-plus-n1,
    [dir='rtl'] .mx-xs-plus-n1 {
        margin-right: -0.25rem !important;
    }

    .m-xs-plus-n2 {
        margin: -0.5rem !important;
    }

    .mt-xs-plus-n2,
    .my-xs-plus-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-xs-plus-n2,
    .mx-xs-plus-n2 {
        margin-right: -0.5rem !important;
    }

    [dir='rtl'] .mr-xs-plus-n2,
    [dir='rtl'] .mx-xs-plus-n2 {
        margin-left: -0.5rem !important;
    }

    .mb-xs-plus-n2,
    .my-xs-plus-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-xs-plus-n2,
    .mx-xs-plus-n2 {
        margin-left: -0.5rem !important;
    }

    [dir='rtl'] .ml-xs-plus-n2,
    [dir='rtl'] .mx-xs-plus-n2 {
        margin-right: -0.5rem !important;
    }

    .m-xs-plus-n3 {
        margin: -1rem !important;
    }

    .mt-xs-plus-n3,
    .my-xs-plus-n3 {
        margin-top: -1rem !important;
    }

    .mr-xs-plus-n3,
    .mx-xs-plus-n3 {
        margin-right: -1rem !important;
    }

    [dir='rtl'] .mr-xs-plus-n3,
    [dir='rtl'] .mx-xs-plus-n3 {
        margin-left: -1rem !important;
    }

    .mb-xs-plus-n3,
    .my-xs-plus-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-xs-plus-n3,
    .mx-xs-plus-n3 {
        margin-left: -1rem !important;
    }

    [dir='rtl'] .ml-xs-plus-n3,
    [dir='rtl'] .mx-xs-plus-n3 {
        margin-right: -1rem !important;
    }

    .m-xs-plus-n4 {
        margin: -1.5rem !important;
    }

    .mt-xs-plus-n4,
    .my-xs-plus-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-xs-plus-n4,
    .mx-xs-plus-n4 {
        margin-right: -1.5rem !important;
    }

    [dir='rtl'] .mr-xs-plus-n4,
    [dir='rtl'] .mx-xs-plus-n4 {
        margin-left: -1.5rem !important;
    }

    .mb-xs-plus-n4,
    .my-xs-plus-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-xs-plus-n4,
    .mx-xs-plus-n4 {
        margin-left: -1.5rem !important;
    }

    [dir='rtl'] .ml-xs-plus-n4,
    [dir='rtl'] .mx-xs-plus-n4 {
        margin-right: -1.5rem !important;
    }

    .m-xs-plus-n5 {
        margin: -3rem !important;
    }

    .mt-xs-plus-n5,
    .my-xs-plus-n5 {
        margin-top: -3rem !important;
    }

    .mr-xs-plus-n5,
    .mx-xs-plus-n5 {
        margin-right: -3rem !important;
    }

    [dir='rtl'] .mr-xs-plus-n5,
    [dir='rtl'] .mx-xs-plus-n5 {
        margin-left: -3rem !important;
    }

    .mb-xs-plus-n5,
    .my-xs-plus-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-xs-plus-n5,
    .mx-xs-plus-n5 {
        margin-left: -3rem !important;
    }

    [dir='rtl'] .ml-xs-plus-n5,
    [dir='rtl'] .mx-xs-plus-n5 {
        margin-right: -3rem !important;
    }

    .m-xs-plus-n4pt {
        margin: -0.25rem !important;
    }

    .mt-xs-plus-n4pt,
    .my-xs-plus-n4pt {
        margin-top: -0.25rem !important;
    }

    .mr-xs-plus-n4pt,
    .mx-xs-plus-n4pt {
        margin-right: -0.25rem !important;
    }

    [dir='rtl'] .mr-xs-plus-n4pt,
    [dir='rtl'] .mx-xs-plus-n4pt {
        margin-left: -0.25rem !important;
    }

    .mb-xs-plus-n4pt,
    .my-xs-plus-n4pt {
        margin-bottom: -0.25rem !important;
    }

    .ml-xs-plus-n4pt,
    .mx-xs-plus-n4pt {
        margin-left: -0.25rem !important;
    }

    [dir='rtl'] .ml-xs-plus-n4pt,
    [dir='rtl'] .mx-xs-plus-n4pt {
        margin-right: -0.25rem !important;
    }

    .m-xs-plus-n8pt {
        margin: -0.5rem !important;
    }

    .mt-xs-plus-n8pt,
    .my-xs-plus-n8pt {
        margin-top: -0.5rem !important;
    }

    .mr-xs-plus-n8pt,
    .mx-xs-plus-n8pt {
        margin-right: -0.5rem !important;
    }

    [dir='rtl'] .mr-xs-plus-n8pt,
    [dir='rtl'] .mx-xs-plus-n8pt {
        margin-left: -0.5rem !important;
    }

    .mb-xs-plus-n8pt,
    .my-xs-plus-n8pt {
        margin-bottom: -0.5rem !important;
    }

    .ml-xs-plus-n8pt,
    .mx-xs-plus-n8pt {
        margin-left: -0.5rem !important;
    }

    [dir='rtl'] .ml-xs-plus-n8pt,
    [dir='rtl'] .mx-xs-plus-n8pt {
        margin-right: -0.5rem !important;
    }

    .m-xs-plus-n12pt {
        margin: -0.75rem !important;
    }

    .mt-xs-plus-n12pt,
    .my-xs-plus-n12pt {
        margin-top: -0.75rem !important;
    }

    .mr-xs-plus-n12pt,
    .mx-xs-plus-n12pt {
        margin-right: -0.75rem !important;
    }

    [dir='rtl'] .mr-xs-plus-n12pt,
    [dir='rtl'] .mx-xs-plus-n12pt {
        margin-left: -0.75rem !important;
    }

    .mb-xs-plus-n12pt,
    .my-xs-plus-n12pt {
        margin-bottom: -0.75rem !important;
    }

    .ml-xs-plus-n12pt,
    .mx-xs-plus-n12pt {
        margin-left: -0.75rem !important;
    }

    [dir='rtl'] .ml-xs-plus-n12pt,
    [dir='rtl'] .mx-xs-plus-n12pt {
        margin-right: -0.75rem !important;
    }

    .m-xs-plus-n16pt {
        margin: -1rem !important;
    }

    .mt-xs-plus-n16pt,
    .my-xs-plus-n16pt {
        margin-top: -1rem !important;
    }

    .mr-xs-plus-n16pt,
    .mx-xs-plus-n16pt {
        margin-right: -1rem !important;
    }

    [dir='rtl'] .mr-xs-plus-n16pt,
    [dir='rtl'] .mx-xs-plus-n16pt {
        margin-left: -1rem !important;
    }

    .mb-xs-plus-n16pt,
    .my-xs-plus-n16pt {
        margin-bottom: -1rem !important;
    }

    .ml-xs-plus-n16pt,
    .mx-xs-plus-n16pt {
        margin-left: -1rem !important;
    }

    [dir='rtl'] .ml-xs-plus-n16pt,
    [dir='rtl'] .mx-xs-plus-n16pt {
        margin-right: -1rem !important;
    }

    .m-xs-plus-n20pt {
        margin: -1.25rem !important;
    }

    .mt-xs-plus-n20pt,
    .my-xs-plus-n20pt {
        margin-top: -1.25rem !important;
    }

    .mr-xs-plus-n20pt,
    .mx-xs-plus-n20pt {
        margin-right: -1.25rem !important;
    }

    [dir='rtl'] .mr-xs-plus-n20pt,
    [dir='rtl'] .mx-xs-plus-n20pt {
        margin-left: -1.25rem !important;
    }

    .mb-xs-plus-n20pt,
    .my-xs-plus-n20pt {
        margin-bottom: -1.25rem !important;
    }

    .ml-xs-plus-n20pt,
    .mx-xs-plus-n20pt {
        margin-left: -1.25rem !important;
    }

    [dir='rtl'] .ml-xs-plus-n20pt,
    [dir='rtl'] .mx-xs-plus-n20pt {
        margin-right: -1.25rem !important;
    }

    .m-xs-plus-n24pt {
        margin: -1.5rem !important;
    }

    .mt-xs-plus-n24pt,
    .my-xs-plus-n24pt {
        margin-top: -1.5rem !important;
    }

    .mr-xs-plus-n24pt,
    .mx-xs-plus-n24pt {
        margin-right: -1.5rem !important;
    }

    [dir='rtl'] .mr-xs-plus-n24pt,
    [dir='rtl'] .mx-xs-plus-n24pt {
        margin-left: -1.5rem !important;
    }

    .mb-xs-plus-n24pt,
    .my-xs-plus-n24pt {
        margin-bottom: -1.5rem !important;
    }

    .ml-xs-plus-n24pt,
    .mx-xs-plus-n24pt {
        margin-left: -1.5rem !important;
    }

    [dir='rtl'] .ml-xs-plus-n24pt,
    [dir='rtl'] .mx-xs-plus-n24pt {
        margin-right: -1.5rem !important;
    }

    .m-xs-plus-n32pt {
        margin: -2rem !important;
    }

    .mt-xs-plus-n32pt,
    .my-xs-plus-n32pt {
        margin-top: -2rem !important;
    }

    .mr-xs-plus-n32pt,
    .mx-xs-plus-n32pt {
        margin-right: -2rem !important;
    }

    [dir='rtl'] .mr-xs-plus-n32pt,
    [dir='rtl'] .mx-xs-plus-n32pt {
        margin-left: -2rem !important;
    }

    .mb-xs-plus-n32pt,
    .my-xs-plus-n32pt {
        margin-bottom: -2rem !important;
    }

    .ml-xs-plus-n32pt,
    .mx-xs-plus-n32pt {
        margin-left: -2rem !important;
    }

    [dir='rtl'] .ml-xs-plus-n32pt,
    [dir='rtl'] .mx-xs-plus-n32pt {
        margin-right: -2rem !important;
    }

    .m-xs-plus-n40pt {
        margin: -2.5rem !important;
    }

    .mt-xs-plus-n40pt,
    .my-xs-plus-n40pt {
        margin-top: -2.5rem !important;
    }

    .mr-xs-plus-n40pt,
    .mx-xs-plus-n40pt {
        margin-right: -2.5rem !important;
    }

    [dir='rtl'] .mr-xs-plus-n40pt,
    [dir='rtl'] .mx-xs-plus-n40pt {
        margin-left: -2.5rem !important;
    }

    .mb-xs-plus-n40pt,
    .my-xs-plus-n40pt {
        margin-bottom: -2.5rem !important;
    }

    .ml-xs-plus-n40pt,
    .mx-xs-plus-n40pt {
        margin-left: -2.5rem !important;
    }

    [dir='rtl'] .ml-xs-plus-n40pt,
    [dir='rtl'] .mx-xs-plus-n40pt {
        margin-right: -2.5rem !important;
    }

    .m-xs-plus-n44pt {
        margin: -2.75rem !important;
    }

    .mt-xs-plus-n44pt,
    .my-xs-plus-n44pt {
        margin-top: -2.75rem !important;
    }

    .mr-xs-plus-n44pt,
    .mx-xs-plus-n44pt {
        margin-right: -2.75rem !important;
    }

    [dir='rtl'] .mr-xs-plus-n44pt,
    [dir='rtl'] .mx-xs-plus-n44pt {
        margin-left: -2.75rem !important;
    }

    .mb-xs-plus-n44pt,
    .my-xs-plus-n44pt {
        margin-bottom: -2.75rem !important;
    }

    .ml-xs-plus-n44pt,
    .mx-xs-plus-n44pt {
        margin-left: -2.75rem !important;
    }

    [dir='rtl'] .ml-xs-plus-n44pt,
    [dir='rtl'] .mx-xs-plus-n44pt {
        margin-right: -2.75rem !important;
    }

    .m-xs-plus-n48pt {
        margin: -3rem !important;
    }

    .mt-xs-plus-n48pt,
    .my-xs-plus-n48pt {
        margin-top: -3rem !important;
    }

    .mr-xs-plus-n48pt,
    .mx-xs-plus-n48pt {
        margin-right: -3rem !important;
    }

    [dir='rtl'] .mr-xs-plus-n48pt,
    [dir='rtl'] .mx-xs-plus-n48pt {
        margin-left: -3rem !important;
    }

    .mb-xs-plus-n48pt,
    .my-xs-plus-n48pt {
        margin-bottom: -3rem !important;
    }

    .ml-xs-plus-n48pt,
    .mx-xs-plus-n48pt {
        margin-left: -3rem !important;
    }

    [dir='rtl'] .ml-xs-plus-n48pt,
    [dir='rtl'] .mx-xs-plus-n48pt {
        margin-right: -3rem !important;
    }

    .m-xs-plus-n64pt {
        margin: -4rem !important;
    }

    .mt-xs-plus-n64pt,
    .my-xs-plus-n64pt {
        margin-top: -4rem !important;
    }

    .mr-xs-plus-n64pt,
    .mx-xs-plus-n64pt {
        margin-right: -4rem !important;
    }

    [dir='rtl'] .mr-xs-plus-n64pt,
    [dir='rtl'] .mx-xs-plus-n64pt {
        margin-left: -4rem !important;
    }

    .mb-xs-plus-n64pt,
    .my-xs-plus-n64pt {
        margin-bottom: -4rem !important;
    }

    .ml-xs-plus-n64pt,
    .mx-xs-plus-n64pt {
        margin-left: -4rem !important;
    }

    [dir='rtl'] .ml-xs-plus-n64pt,
    [dir='rtl'] .mx-xs-plus-n64pt {
        margin-right: -4rem !important;
    }

    .m-xs-plus-n112pt {
        margin: -7rem !important;
    }

    .mt-xs-plus-n112pt,
    .my-xs-plus-n112pt {
        margin-top: -7rem !important;
    }

    .mr-xs-plus-n112pt,
    .mx-xs-plus-n112pt {
        margin-right: -7rem !important;
    }

    [dir='rtl'] .mr-xs-plus-n112pt,
    [dir='rtl'] .mx-xs-plus-n112pt {
        margin-left: -7rem !important;
    }

    .mb-xs-plus-n112pt,
    .my-xs-plus-n112pt {
        margin-bottom: -7rem !important;
    }

    .ml-xs-plus-n112pt,
    .mx-xs-plus-n112pt {
        margin-left: -7rem !important;
    }

    [dir='rtl'] .ml-xs-plus-n112pt,
    [dir='rtl'] .mx-xs-plus-n112pt {
        margin-right: -7rem !important;
    }

    .m-xs-plus-n128pt {
        margin: -8rem !important;
    }

    .mt-xs-plus-n128pt,
    .my-xs-plus-n128pt {
        margin-top: -8rem !important;
    }

    .mr-xs-plus-n128pt,
    .mx-xs-plus-n128pt {
        margin-right: -8rem !important;
    }

    [dir='rtl'] .mr-xs-plus-n128pt,
    [dir='rtl'] .mx-xs-plus-n128pt {
        margin-left: -8rem !important;
    }

    .mb-xs-plus-n128pt,
    .my-xs-plus-n128pt {
        margin-bottom: -8rem !important;
    }

    .ml-xs-plus-n128pt,
    .mx-xs-plus-n128pt {
        margin-left: -8rem !important;
    }

    [dir='rtl'] .ml-xs-plus-n128pt,
    [dir='rtl'] .mx-xs-plus-n128pt {
        margin-right: -8rem !important;
    }

    .m-xs-plus-auto {
        margin: auto !important;
    }

    .mt-xs-plus-auto,
    .my-xs-plus-auto {
        margin-top: auto !important;
    }

    .mr-xs-plus-auto,
    .mx-xs-plus-auto {
        margin-right: auto !important;
    }

    [dir='rtl'] .mr-xs-plus-auto,
    [dir='rtl'] .mx-xs-plus-auto {
        margin-left: auto !important;
    }

    .mb-xs-plus-auto,
    .my-xs-plus-auto {
        margin-bottom: auto !important;
    }

    .ml-xs-plus-auto,
    .mx-xs-plus-auto {
        margin-left: auto !important;
    }

    [dir='rtl'] .ml-xs-plus-auto,
    [dir='rtl'] .mx-xs-plus-auto {
        margin-right: auto !important;
    }
}

@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important;
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important;
    }

    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }

    [dir='rtl'] .mr-sm-0,
    [dir='rtl'] .mx-sm-0 {
        margin-left: 0 !important;
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }

    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }

    [dir='rtl'] .ml-sm-0,
    [dir='rtl'] .mx-sm-0 {
        margin-right: 0 !important;
    }

    .m-sm-1 {
        margin: 0.25rem !important;
    }

    .mt-sm-1,
    .my-sm-1 {
        margin-top: 0.25rem !important;
    }

    .mr-sm-1,
    .mx-sm-1 {
        margin-right: 0.25rem !important;
    }

    [dir='rtl'] .mr-sm-1,
    [dir='rtl'] .mx-sm-1 {
        margin-left: 0.25rem !important;
    }

    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-sm-1,
    .mx-sm-1 {
        margin-left: 0.25rem !important;
    }

    [dir='rtl'] .ml-sm-1,
    [dir='rtl'] .mx-sm-1 {
        margin-right: 0.25rem !important;
    }

    .m-sm-2 {
        margin: 0.5rem !important;
    }

    .mt-sm-2,
    .my-sm-2 {
        margin-top: 0.5rem !important;
    }

    .mr-sm-2,
    .mx-sm-2 {
        margin-right: 0.5rem !important;
    }

    [dir='rtl'] .mr-sm-2,
    [dir='rtl'] .mx-sm-2 {
        margin-left: 0.5rem !important;
    }

    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-sm-2,
    .mx-sm-2 {
        margin-left: 0.5rem !important;
    }

    [dir='rtl'] .ml-sm-2,
    [dir='rtl'] .mx-sm-2 {
        margin-right: 0.5rem !important;
    }

    .m-sm-3 {
        margin: 1rem !important;
    }

    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important;
    }

    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important;
    }

    [dir='rtl'] .mr-sm-3,
    [dir='rtl'] .mx-sm-3 {
        margin-left: 1rem !important;
    }

    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important;
    }

    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important;
    }

    [dir='rtl'] .ml-sm-3,
    [dir='rtl'] .mx-sm-3 {
        margin-right: 1rem !important;
    }

    .m-sm-4 {
        margin: 1.5rem !important;
    }

    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important;
    }

    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important;
    }

    [dir='rtl'] .mr-sm-4,
    [dir='rtl'] .mx-sm-4 {
        margin-left: 1.5rem !important;
    }

    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important;
    }

    [dir='rtl'] .ml-sm-4,
    [dir='rtl'] .mx-sm-4 {
        margin-right: 1.5rem !important;
    }

    .m-sm-5 {
        margin: 3rem !important;
    }

    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important;
    }

    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important;
    }

    [dir='rtl'] .mr-sm-5,
    [dir='rtl'] .mx-sm-5 {
        margin-left: 3rem !important;
    }

    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important;
    }

    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important;
    }

    [dir='rtl'] .ml-sm-5,
    [dir='rtl'] .mx-sm-5 {
        margin-right: 3rem !important;
    }

    .m-sm-4pt {
        margin: 0.25rem !important;
    }

    .mt-sm-4pt,
    .my-sm-4pt {
        margin-top: 0.25rem !important;
    }

    .mr-sm-4pt,
    .mx-sm-4pt {
        margin-right: 0.25rem !important;
    }

    [dir='rtl'] .mr-sm-4pt,
    [dir='rtl'] .mx-sm-4pt {
        margin-left: 0.25rem !important;
    }

    .mb-sm-4pt,
    .my-sm-4pt {
        margin-bottom: 0.25rem !important;
    }

    .ml-sm-4pt,
    .mx-sm-4pt {
        margin-left: 0.25rem !important;
    }

    [dir='rtl'] .ml-sm-4pt,
    [dir='rtl'] .mx-sm-4pt {
        margin-right: 0.25rem !important;
    }

    .m-sm-8pt {
        margin: 0.5rem !important;
    }

    .mt-sm-8pt,
    .my-sm-8pt {
        margin-top: 0.5rem !important;
    }

    .mr-sm-8pt,
    .mx-sm-8pt {
        margin-right: 0.5rem !important;
    }

    [dir='rtl'] .mr-sm-8pt,
    [dir='rtl'] .mx-sm-8pt {
        margin-left: 0.5rem !important;
    }

    .mb-sm-8pt,
    .my-sm-8pt {
        margin-bottom: 0.5rem !important;
    }

    .ml-sm-8pt,
    .mx-sm-8pt {
        margin-left: 0.5rem !important;
    }

    [dir='rtl'] .ml-sm-8pt,
    [dir='rtl'] .mx-sm-8pt {
        margin-right: 0.5rem !important;
    }

    .m-sm-12pt {
        margin: 0.75rem !important;
    }

    .mt-sm-12pt,
    .my-sm-12pt {
        margin-top: 0.75rem !important;
    }

    .mr-sm-12pt,
    .mx-sm-12pt {
        margin-right: 0.75rem !important;
    }

    [dir='rtl'] .mr-sm-12pt,
    [dir='rtl'] .mx-sm-12pt {
        margin-left: 0.75rem !important;
    }

    .mb-sm-12pt,
    .my-sm-12pt {
        margin-bottom: 0.75rem !important;
    }

    .ml-sm-12pt,
    .mx-sm-12pt {
        margin-left: 0.75rem !important;
    }

    [dir='rtl'] .ml-sm-12pt,
    [dir='rtl'] .mx-sm-12pt {
        margin-right: 0.75rem !important;
    }

    .m-sm-16pt {
        margin: 1rem !important;
    }

    .mt-sm-16pt,
    .my-sm-16pt {
        margin-top: 1rem !important;
    }

    .mr-sm-16pt,
    .mx-sm-16pt {
        margin-right: 1rem !important;
    }

    [dir='rtl'] .mr-sm-16pt,
    [dir='rtl'] .mx-sm-16pt {
        margin-left: 1rem !important;
    }

    .mb-sm-16pt,
    .my-sm-16pt {
        margin-bottom: 1rem !important;
    }

    .ml-sm-16pt,
    .mx-sm-16pt {
        margin-left: 1rem !important;
    }

    [dir='rtl'] .ml-sm-16pt,
    [dir='rtl'] .mx-sm-16pt {
        margin-right: 1rem !important;
    }

    .m-sm-20pt {
        margin: 1.25rem !important;
    }

    .mt-sm-20pt,
    .my-sm-20pt {
        margin-top: 1.25rem !important;
    }

    .mr-sm-20pt,
    .mx-sm-20pt {
        margin-right: 1.25rem !important;
    }

    [dir='rtl'] .mr-sm-20pt,
    [dir='rtl'] .mx-sm-20pt {
        margin-left: 1.25rem !important;
    }

    .mb-sm-20pt,
    .my-sm-20pt {
        margin-bottom: 1.25rem !important;
    }

    .ml-sm-20pt,
    .mx-sm-20pt {
        margin-left: 1.25rem !important;
    }

    [dir='rtl'] .ml-sm-20pt,
    [dir='rtl'] .mx-sm-20pt {
        margin-right: 1.25rem !important;
    }

    .m-sm-24pt {
        margin: 1.5rem !important;
    }

    .mt-sm-24pt,
    .my-sm-24pt {
        margin-top: 1.5rem !important;
    }

    .mr-sm-24pt,
    .mx-sm-24pt {
        margin-right: 1.5rem !important;
    }

    [dir='rtl'] .mr-sm-24pt,
    [dir='rtl'] .mx-sm-24pt {
        margin-left: 1.5rem !important;
    }

    .mb-sm-24pt,
    .my-sm-24pt {
        margin-bottom: 1.5rem !important;
    }

    .ml-sm-24pt,
    .mx-sm-24pt {
        margin-left: 1.5rem !important;
    }

    [dir='rtl'] .ml-sm-24pt,
    [dir='rtl'] .mx-sm-24pt {
        margin-right: 1.5rem !important;
    }

    .m-sm-32pt {
        margin: 2rem !important;
    }

    .mt-sm-32pt,
    .my-sm-32pt {
        margin-top: 2rem !important;
    }

    .mr-sm-32pt,
    .mx-sm-32pt {
        margin-right: 2rem !important;
    }

    [dir='rtl'] .mr-sm-32pt,
    [dir='rtl'] .mx-sm-32pt {
        margin-left: 2rem !important;
    }

    .mb-sm-32pt,
    .my-sm-32pt {
        margin-bottom: 2rem !important;
    }

    .ml-sm-32pt,
    .mx-sm-32pt {
        margin-left: 2rem !important;
    }

    [dir='rtl'] .ml-sm-32pt,
    [dir='rtl'] .mx-sm-32pt {
        margin-right: 2rem !important;
    }

    .m-sm-40pt {
        margin: 2.5rem !important;
    }

    .mt-sm-40pt,
    .my-sm-40pt {
        margin-top: 2.5rem !important;
    }

    .mr-sm-40pt,
    .mx-sm-40pt {
        margin-right: 2.5rem !important;
    }

    [dir='rtl'] .mr-sm-40pt,
    [dir='rtl'] .mx-sm-40pt {
        margin-left: 2.5rem !important;
    }

    .mb-sm-40pt,
    .my-sm-40pt {
        margin-bottom: 2.5rem !important;
    }

    .ml-sm-40pt,
    .mx-sm-40pt {
        margin-left: 2.5rem !important;
    }

    [dir='rtl'] .ml-sm-40pt,
    [dir='rtl'] .mx-sm-40pt {
        margin-right: 2.5rem !important;
    }

    .m-sm-44pt {
        margin: 2.75rem !important;
    }

    .mt-sm-44pt,
    .my-sm-44pt {
        margin-top: 2.75rem !important;
    }

    .mr-sm-44pt,
    .mx-sm-44pt {
        margin-right: 2.75rem !important;
    }

    [dir='rtl'] .mr-sm-44pt,
    [dir='rtl'] .mx-sm-44pt {
        margin-left: 2.75rem !important;
    }

    .mb-sm-44pt,
    .my-sm-44pt {
        margin-bottom: 2.75rem !important;
    }

    .ml-sm-44pt,
    .mx-sm-44pt {
        margin-left: 2.75rem !important;
    }

    [dir='rtl'] .ml-sm-44pt,
    [dir='rtl'] .mx-sm-44pt {
        margin-right: 2.75rem !important;
    }

    .m-sm-48pt {
        margin: 3rem !important;
    }

    .mt-sm-48pt,
    .my-sm-48pt {
        margin-top: 3rem !important;
    }

    .mr-sm-48pt,
    .mx-sm-48pt {
        margin-right: 3rem !important;
    }

    [dir='rtl'] .mr-sm-48pt,
    [dir='rtl'] .mx-sm-48pt {
        margin-left: 3rem !important;
    }

    .mb-sm-48pt,
    .my-sm-48pt {
        margin-bottom: 3rem !important;
    }

    .ml-sm-48pt,
    .mx-sm-48pt {
        margin-left: 3rem !important;
    }

    [dir='rtl'] .ml-sm-48pt,
    [dir='rtl'] .mx-sm-48pt {
        margin-right: 3rem !important;
    }

    .m-sm-64pt {
        margin: 4rem !important;
    }

    .mt-sm-64pt,
    .my-sm-64pt {
        margin-top: 4rem !important;
    }

    .mr-sm-64pt,
    .mx-sm-64pt {
        margin-right: 4rem !important;
    }

    [dir='rtl'] .mr-sm-64pt,
    [dir='rtl'] .mx-sm-64pt {
        margin-left: 4rem !important;
    }

    .mb-sm-64pt,
    .my-sm-64pt {
        margin-bottom: 4rem !important;
    }

    .ml-sm-64pt,
    .mx-sm-64pt {
        margin-left: 4rem !important;
    }

    [dir='rtl'] .ml-sm-64pt,
    [dir='rtl'] .mx-sm-64pt {
        margin-right: 4rem !important;
    }

    .m-sm-112pt {
        margin: 7rem !important;
    }

    .mt-sm-112pt,
    .my-sm-112pt {
        margin-top: 7rem !important;
    }

    .mr-sm-112pt,
    .mx-sm-112pt {
        margin-right: 7rem !important;
    }

    [dir='rtl'] .mr-sm-112pt,
    [dir='rtl'] .mx-sm-112pt {
        margin-left: 7rem !important;
    }

    .mb-sm-112pt,
    .my-sm-112pt {
        margin-bottom: 7rem !important;
    }

    .ml-sm-112pt,
    .mx-sm-112pt {
        margin-left: 7rem !important;
    }

    [dir='rtl'] .ml-sm-112pt,
    [dir='rtl'] .mx-sm-112pt {
        margin-right: 7rem !important;
    }

    .m-sm-128pt {
        margin: 8rem !important;
    }

    .mt-sm-128pt,
    .my-sm-128pt {
        margin-top: 8rem !important;
    }

    .mr-sm-128pt,
    .mx-sm-128pt {
        margin-right: 8rem !important;
    }

    [dir='rtl'] .mr-sm-128pt,
    [dir='rtl'] .mx-sm-128pt {
        margin-left: 8rem !important;
    }

    .mb-sm-128pt,
    .my-sm-128pt {
        margin-bottom: 8rem !important;
    }

    .ml-sm-128pt,
    .mx-sm-128pt {
        margin-left: 8rem !important;
    }

    [dir='rtl'] .ml-sm-128pt,
    [dir='rtl'] .mx-sm-128pt {
        margin-right: 8rem !important;
    }

    .p-sm-0 {
        padding: 0 !important;
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important;
    }

    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }

    [dir='rtl'] .pr-sm-0,
    [dir='rtl'] .px-sm-0 {
        padding-left: 0 !important;
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important;
    }

    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }

    [dir='rtl'] .pl-sm-0,
    [dir='rtl'] .px-sm-0 {
        padding-right: 0 !important;
    }

    .p-sm-1 {
        padding: 0.25rem !important;
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: 0.25rem !important;
    }

    .pr-sm-1,
    .px-sm-1 {
        padding-right: 0.25rem !important;
    }

    [dir='rtl'] .pr-sm-1,
    [dir='rtl'] .px-sm-1 {
        padding-left: 0.25rem !important;
    }

    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-sm-1,
    .px-sm-1 {
        padding-left: 0.25rem !important;
    }

    [dir='rtl'] .pl-sm-1,
    [dir='rtl'] .px-sm-1 {
        padding-right: 0.25rem !important;
    }

    .p-sm-2 {
        padding: 0.5rem !important;
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: 0.5rem !important;
    }

    .pr-sm-2,
    .px-sm-2 {
        padding-right: 0.5rem !important;
    }

    [dir='rtl'] .pr-sm-2,
    [dir='rtl'] .px-sm-2 {
        padding-left: 0.5rem !important;
    }

    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-sm-2,
    .px-sm-2 {
        padding-left: 0.5rem !important;
    }

    [dir='rtl'] .pl-sm-2,
    [dir='rtl'] .px-sm-2 {
        padding-right: 0.5rem !important;
    }

    .p-sm-3 {
        padding: 1rem !important;
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important;
    }

    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important;
    }

    [dir='rtl'] .pr-sm-3,
    [dir='rtl'] .px-sm-3 {
        padding-left: 1rem !important;
    }

    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important;
    }

    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important;
    }

    [dir='rtl'] .pl-sm-3,
    [dir='rtl'] .px-sm-3 {
        padding-right: 1rem !important;
    }

    .p-sm-4 {
        padding: 1.5rem !important;
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important;
    }

    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important;
    }

    [dir='rtl'] .pr-sm-4,
    [dir='rtl'] .px-sm-4 {
        padding-left: 1.5rem !important;
    }

    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important;
    }

    [dir='rtl'] .pl-sm-4,
    [dir='rtl'] .px-sm-4 {
        padding-right: 1.5rem !important;
    }

    .p-sm-5 {
        padding: 3rem !important;
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important;
    }

    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important;
    }

    [dir='rtl'] .pr-sm-5,
    [dir='rtl'] .px-sm-5 {
        padding-left: 3rem !important;
    }

    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important;
    }

    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important;
    }

    [dir='rtl'] .pl-sm-5,
    [dir='rtl'] .px-sm-5 {
        padding-right: 3rem !important;
    }

    .p-sm-4pt {
        padding: 0.25rem !important;
    }

    .pt-sm-4pt,
    .py-sm-4pt {
        padding-top: 0.25rem !important;
    }

    .pr-sm-4pt,
    .px-sm-4pt {
        padding-right: 0.25rem !important;
    }

    [dir='rtl'] .pr-sm-4pt,
    [dir='rtl'] .px-sm-4pt {
        padding-left: 0.25rem !important;
    }

    .pb-sm-4pt,
    .py-sm-4pt {
        padding-bottom: 0.25rem !important;
    }

    .pl-sm-4pt,
    .px-sm-4pt {
        padding-left: 0.25rem !important;
    }

    [dir='rtl'] .pl-sm-4pt,
    [dir='rtl'] .px-sm-4pt {
        padding-right: 0.25rem !important;
    }

    .p-sm-8pt {
        padding: 0.5rem !important;
    }

    .pt-sm-8pt,
    .py-sm-8pt {
        padding-top: 0.5rem !important;
    }

    .pr-sm-8pt,
    .px-sm-8pt {
        padding-right: 0.5rem !important;
    }

    [dir='rtl'] .pr-sm-8pt,
    [dir='rtl'] .px-sm-8pt {
        padding-left: 0.5rem !important;
    }

    .pb-sm-8pt,
    .py-sm-8pt {
        padding-bottom: 0.5rem !important;
    }

    .pl-sm-8pt,
    .px-sm-8pt {
        padding-left: 0.5rem !important;
    }

    [dir='rtl'] .pl-sm-8pt,
    [dir='rtl'] .px-sm-8pt {
        padding-right: 0.5rem !important;
    }

    .p-sm-12pt {
        padding: 0.75rem !important;
    }

    .pt-sm-12pt,
    .py-sm-12pt {
        padding-top: 0.75rem !important;
    }

    .pr-sm-12pt,
    .px-sm-12pt {
        padding-right: 0.75rem !important;
    }

    [dir='rtl'] .pr-sm-12pt,
    [dir='rtl'] .px-sm-12pt {
        padding-left: 0.75rem !important;
    }

    .pb-sm-12pt,
    .py-sm-12pt {
        padding-bottom: 0.75rem !important;
    }

    .pl-sm-12pt,
    .px-sm-12pt {
        padding-left: 0.75rem !important;
    }

    [dir='rtl'] .pl-sm-12pt,
    [dir='rtl'] .px-sm-12pt {
        padding-right: 0.75rem !important;
    }

    .p-sm-16pt {
        padding: 1rem !important;
    }

    .pt-sm-16pt,
    .py-sm-16pt {
        padding-top: 1rem !important;
    }

    .pr-sm-16pt,
    .px-sm-16pt {
        padding-right: 1rem !important;
    }

    [dir='rtl'] .pr-sm-16pt,
    [dir='rtl'] .px-sm-16pt {
        padding-left: 1rem !important;
    }

    .pb-sm-16pt,
    .py-sm-16pt {
        padding-bottom: 1rem !important;
    }

    .pl-sm-16pt,
    .px-sm-16pt {
        padding-left: 1rem !important;
    }

    [dir='rtl'] .pl-sm-16pt,
    [dir='rtl'] .px-sm-16pt {
        padding-right: 1rem !important;
    }

    .p-sm-20pt {
        padding: 1.25rem !important;
    }

    .pt-sm-20pt,
    .py-sm-20pt {
        padding-top: 1.25rem !important;
    }

    .pr-sm-20pt,
    .px-sm-20pt {
        padding-right: 1.25rem !important;
    }

    [dir='rtl'] .pr-sm-20pt,
    [dir='rtl'] .px-sm-20pt {
        padding-left: 1.25rem !important;
    }

    .pb-sm-20pt,
    .py-sm-20pt {
        padding-bottom: 1.25rem !important;
    }

    .pl-sm-20pt,
    .px-sm-20pt {
        padding-left: 1.25rem !important;
    }

    [dir='rtl'] .pl-sm-20pt,
    [dir='rtl'] .px-sm-20pt {
        padding-right: 1.25rem !important;
    }

    .p-sm-24pt {
        padding: 1.5rem !important;
    }

    .pt-sm-24pt,
    .py-sm-24pt {
        padding-top: 1.5rem !important;
    }

    .pr-sm-24pt,
    .px-sm-24pt {
        padding-right: 1.5rem !important;
    }

    [dir='rtl'] .pr-sm-24pt,
    [dir='rtl'] .px-sm-24pt {
        padding-left: 1.5rem !important;
    }

    .pb-sm-24pt,
    .py-sm-24pt {
        padding-bottom: 1.5rem !important;
    }

    .pl-sm-24pt,
    .px-sm-24pt {
        padding-left: 1.5rem !important;
    }

    [dir='rtl'] .pl-sm-24pt,
    [dir='rtl'] .px-sm-24pt {
        padding-right: 1.5rem !important;
    }

    .p-sm-32pt {
        padding: 2rem !important;
    }

    .pt-sm-32pt,
    .py-sm-32pt {
        padding-top: 2rem !important;
    }

    .pr-sm-32pt,
    .px-sm-32pt {
        padding-right: 2rem !important;
    }

    [dir='rtl'] .pr-sm-32pt,
    [dir='rtl'] .px-sm-32pt {
        padding-left: 2rem !important;
    }

    .pb-sm-32pt,
    .py-sm-32pt {
        padding-bottom: 2rem !important;
    }

    .pl-sm-32pt,
    .px-sm-32pt {
        padding-left: 2rem !important;
    }

    [dir='rtl'] .pl-sm-32pt,
    [dir='rtl'] .px-sm-32pt {
        padding-right: 2rem !important;
    }

    .p-sm-40pt {
        padding: 2.5rem !important;
    }

    .pt-sm-40pt,
    .py-sm-40pt {
        padding-top: 2.5rem !important;
    }

    .pr-sm-40pt,
    .px-sm-40pt {
        padding-right: 2.5rem !important;
    }

    [dir='rtl'] .pr-sm-40pt,
    [dir='rtl'] .px-sm-40pt {
        padding-left: 2.5rem !important;
    }

    .pb-sm-40pt,
    .py-sm-40pt {
        padding-bottom: 2.5rem !important;
    }

    .pl-sm-40pt,
    .px-sm-40pt {
        padding-left: 2.5rem !important;
    }

    [dir='rtl'] .pl-sm-40pt,
    [dir='rtl'] .px-sm-40pt {
        padding-right: 2.5rem !important;
    }

    .p-sm-44pt {
        padding: 2.75rem !important;
    }

    .pt-sm-44pt,
    .py-sm-44pt {
        padding-top: 2.75rem !important;
    }

    .pr-sm-44pt,
    .px-sm-44pt {
        padding-right: 2.75rem !important;
    }

    [dir='rtl'] .pr-sm-44pt,
    [dir='rtl'] .px-sm-44pt {
        padding-left: 2.75rem !important;
    }

    .pb-sm-44pt,
    .py-sm-44pt {
        padding-bottom: 2.75rem !important;
    }

    .pl-sm-44pt,
    .px-sm-44pt {
        padding-left: 2.75rem !important;
    }

    [dir='rtl'] .pl-sm-44pt,
    [dir='rtl'] .px-sm-44pt {
        padding-right: 2.75rem !important;
    }

    .p-sm-48pt {
        padding: 3rem !important;
    }

    .pt-sm-48pt,
    .py-sm-48pt {
        padding-top: 3rem !important;
    }

    .pr-sm-48pt,
    .px-sm-48pt {
        padding-right: 3rem !important;
    }

    [dir='rtl'] .pr-sm-48pt,
    [dir='rtl'] .px-sm-48pt {
        padding-left: 3rem !important;
    }

    .pb-sm-48pt,
    .py-sm-48pt {
        padding-bottom: 3rem !important;
    }

    .pl-sm-48pt,
    .px-sm-48pt {
        padding-left: 3rem !important;
    }

    [dir='rtl'] .pl-sm-48pt,
    [dir='rtl'] .px-sm-48pt {
        padding-right: 3rem !important;
    }

    .p-sm-64pt {
        padding: 4rem !important;
    }

    .pt-sm-64pt,
    .py-sm-64pt {
        padding-top: 4rem !important;
    }

    .pr-sm-64pt,
    .px-sm-64pt {
        padding-right: 4rem !important;
    }

    [dir='rtl'] .pr-sm-64pt,
    [dir='rtl'] .px-sm-64pt {
        padding-left: 4rem !important;
    }

    .pb-sm-64pt,
    .py-sm-64pt {
        padding-bottom: 4rem !important;
    }

    .pl-sm-64pt,
    .px-sm-64pt {
        padding-left: 4rem !important;
    }

    [dir='rtl'] .pl-sm-64pt,
    [dir='rtl'] .px-sm-64pt {
        padding-right: 4rem !important;
    }

    .p-sm-112pt {
        padding: 7rem !important;
    }

    .pt-sm-112pt,
    .py-sm-112pt {
        padding-top: 7rem !important;
    }

    .pr-sm-112pt,
    .px-sm-112pt {
        padding-right: 7rem !important;
    }

    [dir='rtl'] .pr-sm-112pt,
    [dir='rtl'] .px-sm-112pt {
        padding-left: 7rem !important;
    }

    .pb-sm-112pt,
    .py-sm-112pt {
        padding-bottom: 7rem !important;
    }

    .pl-sm-112pt,
    .px-sm-112pt {
        padding-left: 7rem !important;
    }

    [dir='rtl'] .pl-sm-112pt,
    [dir='rtl'] .px-sm-112pt {
        padding-right: 7rem !important;
    }

    .p-sm-128pt {
        padding: 8rem !important;
    }

    .pt-sm-128pt,
    .py-sm-128pt {
        padding-top: 8rem !important;
    }

    .pr-sm-128pt,
    .px-sm-128pt {
        padding-right: 8rem !important;
    }

    [dir='rtl'] .pr-sm-128pt,
    [dir='rtl'] .px-sm-128pt {
        padding-left: 8rem !important;
    }

    .pb-sm-128pt,
    .py-sm-128pt {
        padding-bottom: 8rem !important;
    }

    .pl-sm-128pt,
    .px-sm-128pt {
        padding-left: 8rem !important;
    }

    [dir='rtl'] .pl-sm-128pt,
    [dir='rtl'] .px-sm-128pt {
        padding-right: 8rem !important;
    }

    .m-sm-n1 {
        margin: -0.25rem !important;
    }

    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -0.25rem !important;
    }

    [dir='rtl'] .mr-sm-n1,
    [dir='rtl'] .mx-sm-n1 {
        margin-left: -0.25rem !important;
    }

    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -0.25rem !important;
    }

    [dir='rtl'] .ml-sm-n1,
    [dir='rtl'] .mx-sm-n1 {
        margin-right: -0.25rem !important;
    }

    .m-sm-n2 {
        margin: -0.5rem !important;
    }

    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -0.5rem !important;
    }

    [dir='rtl'] .mr-sm-n2,
    [dir='rtl'] .mx-sm-n2 {
        margin-left: -0.5rem !important;
    }

    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -0.5rem !important;
    }

    [dir='rtl'] .ml-sm-n2,
    [dir='rtl'] .mx-sm-n2 {
        margin-right: -0.5rem !important;
    }

    .m-sm-n3 {
        margin: -1rem !important;
    }

    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem !important;
    }

    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem !important;
    }

    [dir='rtl'] .mr-sm-n3,
    [dir='rtl'] .mx-sm-n3 {
        margin-left: -1rem !important;
    }

    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem !important;
    }

    [dir='rtl'] .ml-sm-n3,
    [dir='rtl'] .mx-sm-n3 {
        margin-right: -1rem !important;
    }

    .m-sm-n4 {
        margin: -1.5rem !important;
    }

    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.5rem !important;
    }

    [dir='rtl'] .mr-sm-n4,
    [dir='rtl'] .mx-sm-n4 {
        margin-left: -1.5rem !important;
    }

    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.5rem !important;
    }

    [dir='rtl'] .ml-sm-n4,
    [dir='rtl'] .mx-sm-n4 {
        margin-right: -1.5rem !important;
    }

    .m-sm-n5 {
        margin: -3rem !important;
    }

    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -3rem !important;
    }

    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -3rem !important;
    }

    [dir='rtl'] .mr-sm-n5,
    [dir='rtl'] .mx-sm-n5 {
        margin-left: -3rem !important;
    }

    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -3rem !important;
    }

    [dir='rtl'] .ml-sm-n5,
    [dir='rtl'] .mx-sm-n5 {
        margin-right: -3rem !important;
    }

    .m-sm-n4pt {
        margin: -0.25rem !important;
    }

    .mt-sm-n4pt,
    .my-sm-n4pt {
        margin-top: -0.25rem !important;
    }

    .mr-sm-n4pt,
    .mx-sm-n4pt {
        margin-right: -0.25rem !important;
    }

    [dir='rtl'] .mr-sm-n4pt,
    [dir='rtl'] .mx-sm-n4pt {
        margin-left: -0.25rem !important;
    }

    .mb-sm-n4pt,
    .my-sm-n4pt {
        margin-bottom: -0.25rem !important;
    }

    .ml-sm-n4pt,
    .mx-sm-n4pt {
        margin-left: -0.25rem !important;
    }

    [dir='rtl'] .ml-sm-n4pt,
    [dir='rtl'] .mx-sm-n4pt {
        margin-right: -0.25rem !important;
    }

    .m-sm-n8pt {
        margin: -0.5rem !important;
    }

    .mt-sm-n8pt,
    .my-sm-n8pt {
        margin-top: -0.5rem !important;
    }

    .mr-sm-n8pt,
    .mx-sm-n8pt {
        margin-right: -0.5rem !important;
    }

    [dir='rtl'] .mr-sm-n8pt,
    [dir='rtl'] .mx-sm-n8pt {
        margin-left: -0.5rem !important;
    }

    .mb-sm-n8pt,
    .my-sm-n8pt {
        margin-bottom: -0.5rem !important;
    }

    .ml-sm-n8pt,
    .mx-sm-n8pt {
        margin-left: -0.5rem !important;
    }

    [dir='rtl'] .ml-sm-n8pt,
    [dir='rtl'] .mx-sm-n8pt {
        margin-right: -0.5rem !important;
    }

    .m-sm-n12pt {
        margin: -0.75rem !important;
    }

    .mt-sm-n12pt,
    .my-sm-n12pt {
        margin-top: -0.75rem !important;
    }

    .mr-sm-n12pt,
    .mx-sm-n12pt {
        margin-right: -0.75rem !important;
    }

    [dir='rtl'] .mr-sm-n12pt,
    [dir='rtl'] .mx-sm-n12pt {
        margin-left: -0.75rem !important;
    }

    .mb-sm-n12pt,
    .my-sm-n12pt {
        margin-bottom: -0.75rem !important;
    }

    .ml-sm-n12pt,
    .mx-sm-n12pt {
        margin-left: -0.75rem !important;
    }

    [dir='rtl'] .ml-sm-n12pt,
    [dir='rtl'] .mx-sm-n12pt {
        margin-right: -0.75rem !important;
    }

    .m-sm-n16pt {
        margin: -1rem !important;
    }

    .mt-sm-n16pt,
    .my-sm-n16pt {
        margin-top: -1rem !important;
    }

    .mr-sm-n16pt,
    .mx-sm-n16pt {
        margin-right: -1rem !important;
    }

    [dir='rtl'] .mr-sm-n16pt,
    [dir='rtl'] .mx-sm-n16pt {
        margin-left: -1rem !important;
    }

    .mb-sm-n16pt,
    .my-sm-n16pt {
        margin-bottom: -1rem !important;
    }

    .ml-sm-n16pt,
    .mx-sm-n16pt {
        margin-left: -1rem !important;
    }

    [dir='rtl'] .ml-sm-n16pt,
    [dir='rtl'] .mx-sm-n16pt {
        margin-right: -1rem !important;
    }

    .m-sm-n20pt {
        margin: -1.25rem !important;
    }

    .mt-sm-n20pt,
    .my-sm-n20pt {
        margin-top: -1.25rem !important;
    }

    .mr-sm-n20pt,
    .mx-sm-n20pt {
        margin-right: -1.25rem !important;
    }

    [dir='rtl'] .mr-sm-n20pt,
    [dir='rtl'] .mx-sm-n20pt {
        margin-left: -1.25rem !important;
    }

    .mb-sm-n20pt,
    .my-sm-n20pt {
        margin-bottom: -1.25rem !important;
    }

    .ml-sm-n20pt,
    .mx-sm-n20pt {
        margin-left: -1.25rem !important;
    }

    [dir='rtl'] .ml-sm-n20pt,
    [dir='rtl'] .mx-sm-n20pt {
        margin-right: -1.25rem !important;
    }

    .m-sm-n24pt {
        margin: -1.5rem !important;
    }

    .mt-sm-n24pt,
    .my-sm-n24pt {
        margin-top: -1.5rem !important;
    }

    .mr-sm-n24pt,
    .mx-sm-n24pt {
        margin-right: -1.5rem !important;
    }

    [dir='rtl'] .mr-sm-n24pt,
    [dir='rtl'] .mx-sm-n24pt {
        margin-left: -1.5rem !important;
    }

    .mb-sm-n24pt,
    .my-sm-n24pt {
        margin-bottom: -1.5rem !important;
    }

    .ml-sm-n24pt,
    .mx-sm-n24pt {
        margin-left: -1.5rem !important;
    }

    [dir='rtl'] .ml-sm-n24pt,
    [dir='rtl'] .mx-sm-n24pt {
        margin-right: -1.5rem !important;
    }

    .m-sm-n32pt {
        margin: -2rem !important;
    }

    .mt-sm-n32pt,
    .my-sm-n32pt {
        margin-top: -2rem !important;
    }

    .mr-sm-n32pt,
    .mx-sm-n32pt {
        margin-right: -2rem !important;
    }

    [dir='rtl'] .mr-sm-n32pt,
    [dir='rtl'] .mx-sm-n32pt {
        margin-left: -2rem !important;
    }

    .mb-sm-n32pt,
    .my-sm-n32pt {
        margin-bottom: -2rem !important;
    }

    .ml-sm-n32pt,
    .mx-sm-n32pt {
        margin-left: -2rem !important;
    }

    [dir='rtl'] .ml-sm-n32pt,
    [dir='rtl'] .mx-sm-n32pt {
        margin-right: -2rem !important;
    }

    .m-sm-n40pt {
        margin: -2.5rem !important;
    }

    .mt-sm-n40pt,
    .my-sm-n40pt {
        margin-top: -2.5rem !important;
    }

    .mr-sm-n40pt,
    .mx-sm-n40pt {
        margin-right: -2.5rem !important;
    }

    [dir='rtl'] .mr-sm-n40pt,
    [dir='rtl'] .mx-sm-n40pt {
        margin-left: -2.5rem !important;
    }

    .mb-sm-n40pt,
    .my-sm-n40pt {
        margin-bottom: -2.5rem !important;
    }

    .ml-sm-n40pt,
    .mx-sm-n40pt {
        margin-left: -2.5rem !important;
    }

    [dir='rtl'] .ml-sm-n40pt,
    [dir='rtl'] .mx-sm-n40pt {
        margin-right: -2.5rem !important;
    }

    .m-sm-n44pt {
        margin: -2.75rem !important;
    }

    .mt-sm-n44pt,
    .my-sm-n44pt {
        margin-top: -2.75rem !important;
    }

    .mr-sm-n44pt,
    .mx-sm-n44pt {
        margin-right: -2.75rem !important;
    }

    [dir='rtl'] .mr-sm-n44pt,
    [dir='rtl'] .mx-sm-n44pt {
        margin-left: -2.75rem !important;
    }

    .mb-sm-n44pt,
    .my-sm-n44pt {
        margin-bottom: -2.75rem !important;
    }

    .ml-sm-n44pt,
    .mx-sm-n44pt {
        margin-left: -2.75rem !important;
    }

    [dir='rtl'] .ml-sm-n44pt,
    [dir='rtl'] .mx-sm-n44pt {
        margin-right: -2.75rem !important;
    }

    .m-sm-n48pt {
        margin: -3rem !important;
    }

    .mt-sm-n48pt,
    .my-sm-n48pt {
        margin-top: -3rem !important;
    }

    .mr-sm-n48pt,
    .mx-sm-n48pt {
        margin-right: -3rem !important;
    }

    [dir='rtl'] .mr-sm-n48pt,
    [dir='rtl'] .mx-sm-n48pt {
        margin-left: -3rem !important;
    }

    .mb-sm-n48pt,
    .my-sm-n48pt {
        margin-bottom: -3rem !important;
    }

    .ml-sm-n48pt,
    .mx-sm-n48pt {
        margin-left: -3rem !important;
    }

    [dir='rtl'] .ml-sm-n48pt,
    [dir='rtl'] .mx-sm-n48pt {
        margin-right: -3rem !important;
    }

    .m-sm-n64pt {
        margin: -4rem !important;
    }

    .mt-sm-n64pt,
    .my-sm-n64pt {
        margin-top: -4rem !important;
    }

    .mr-sm-n64pt,
    .mx-sm-n64pt {
        margin-right: -4rem !important;
    }

    [dir='rtl'] .mr-sm-n64pt,
    [dir='rtl'] .mx-sm-n64pt {
        margin-left: -4rem !important;
    }

    .mb-sm-n64pt,
    .my-sm-n64pt {
        margin-bottom: -4rem !important;
    }

    .ml-sm-n64pt,
    .mx-sm-n64pt {
        margin-left: -4rem !important;
    }

    [dir='rtl'] .ml-sm-n64pt,
    [dir='rtl'] .mx-sm-n64pt {
        margin-right: -4rem !important;
    }

    .m-sm-n112pt {
        margin: -7rem !important;
    }

    .mt-sm-n112pt,
    .my-sm-n112pt {
        margin-top: -7rem !important;
    }

    .mr-sm-n112pt,
    .mx-sm-n112pt {
        margin-right: -7rem !important;
    }

    [dir='rtl'] .mr-sm-n112pt,
    [dir='rtl'] .mx-sm-n112pt {
        margin-left: -7rem !important;
    }

    .mb-sm-n112pt,
    .my-sm-n112pt {
        margin-bottom: -7rem !important;
    }

    .ml-sm-n112pt,
    .mx-sm-n112pt {
        margin-left: -7rem !important;
    }

    [dir='rtl'] .ml-sm-n112pt,
    [dir='rtl'] .mx-sm-n112pt {
        margin-right: -7rem !important;
    }

    .m-sm-n128pt {
        margin: -8rem !important;
    }

    .mt-sm-n128pt,
    .my-sm-n128pt {
        margin-top: -8rem !important;
    }

    .mr-sm-n128pt,
    .mx-sm-n128pt {
        margin-right: -8rem !important;
    }

    [dir='rtl'] .mr-sm-n128pt,
    [dir='rtl'] .mx-sm-n128pt {
        margin-left: -8rem !important;
    }

    .mb-sm-n128pt,
    .my-sm-n128pt {
        margin-bottom: -8rem !important;
    }

    .ml-sm-n128pt,
    .mx-sm-n128pt {
        margin-left: -8rem !important;
    }

    [dir='rtl'] .ml-sm-n128pt,
    [dir='rtl'] .mx-sm-n128pt {
        margin-right: -8rem !important;
    }

    .m-sm-auto {
        margin: auto !important;
    }

    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important;
    }

    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important;
    }

    [dir='rtl'] .mr-sm-auto,
    [dir='rtl'] .mx-sm-auto {
        margin-left: auto !important;
    }

    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important;
    }

    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important;
    }

    [dir='rtl'] .ml-sm-auto,
    [dir='rtl'] .mx-sm-auto {
        margin-right: auto !important;
    }
}

@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important;
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important;
    }

    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }

    [dir='rtl'] .mr-md-0,
    [dir='rtl'] .mx-md-0 {
        margin-left: 0 !important;
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important;
    }

    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }

    [dir='rtl'] .ml-md-0,
    [dir='rtl'] .mx-md-0 {
        margin-right: 0 !important;
    }

    .m-md-1 {
        margin: 0.25rem !important;
    }

    .mt-md-1,
    .my-md-1 {
        margin-top: 0.25rem !important;
    }

    .mr-md-1,
    .mx-md-1 {
        margin-right: 0.25rem !important;
    }

    [dir='rtl'] .mr-md-1,
    [dir='rtl'] .mx-md-1 {
        margin-left: 0.25rem !important;
    }

    .mb-md-1,
    .my-md-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-md-1,
    .mx-md-1 {
        margin-left: 0.25rem !important;
    }

    [dir='rtl'] .ml-md-1,
    [dir='rtl'] .mx-md-1 {
        margin-right: 0.25rem !important;
    }

    .m-md-2 {
        margin: 0.5rem !important;
    }

    .mt-md-2,
    .my-md-2 {
        margin-top: 0.5rem !important;
    }

    .mr-md-2,
    .mx-md-2 {
        margin-right: 0.5rem !important;
    }

    [dir='rtl'] .mr-md-2,
    [dir='rtl'] .mx-md-2 {
        margin-left: 0.5rem !important;
    }

    .mb-md-2,
    .my-md-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-md-2,
    .mx-md-2 {
        margin-left: 0.5rem !important;
    }

    [dir='rtl'] .ml-md-2,
    [dir='rtl'] .mx-md-2 {
        margin-right: 0.5rem !important;
    }

    .m-md-3 {
        margin: 1rem !important;
    }

    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important;
    }

    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important;
    }

    [dir='rtl'] .mr-md-3,
    [dir='rtl'] .mx-md-3 {
        margin-left: 1rem !important;
    }

    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important;
    }

    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important;
    }

    [dir='rtl'] .ml-md-3,
    [dir='rtl'] .mx-md-3 {
        margin-right: 1rem !important;
    }

    .m-md-4 {
        margin: 1.5rem !important;
    }

    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important;
    }

    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important;
    }

    [dir='rtl'] .mr-md-4,
    [dir='rtl'] .mx-md-4 {
        margin-left: 1.5rem !important;
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important;
    }

    [dir='rtl'] .ml-md-4,
    [dir='rtl'] .mx-md-4 {
        margin-right: 1.5rem !important;
    }

    .m-md-5 {
        margin: 3rem !important;
    }

    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important;
    }

    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem !important;
    }

    [dir='rtl'] .mr-md-5,
    [dir='rtl'] .mx-md-5 {
        margin-left: 3rem !important;
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important;
    }

    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem !important;
    }

    [dir='rtl'] .ml-md-5,
    [dir='rtl'] .mx-md-5 {
        margin-right: 3rem !important;
    }

    .m-md-4pt {
        margin: 0.25rem !important;
    }

    .mt-md-4pt,
    .my-md-4pt {
        margin-top: 0.25rem !important;
    }

    .mr-md-4pt,
    .mx-md-4pt {
        margin-right: 0.25rem !important;
    }

    [dir='rtl'] .mr-md-4pt,
    [dir='rtl'] .mx-md-4pt {
        margin-left: 0.25rem !important;
    }

    .mb-md-4pt,
    .my-md-4pt {
        margin-bottom: 0.25rem !important;
    }

    .ml-md-4pt,
    .mx-md-4pt {
        margin-left: 0.25rem !important;
    }

    [dir='rtl'] .ml-md-4pt,
    [dir='rtl'] .mx-md-4pt {
        margin-right: 0.25rem !important;
    }

    .m-md-8pt {
        margin: 0.5rem !important;
    }

    .mt-md-8pt,
    .my-md-8pt {
        margin-top: 0.5rem !important;
    }

    .mr-md-8pt,
    .mx-md-8pt {
        margin-right: 0.5rem !important;
    }

    [dir='rtl'] .mr-md-8pt,
    [dir='rtl'] .mx-md-8pt {
        margin-left: 0.5rem !important;
    }

    .mb-md-8pt,
    .my-md-8pt {
        margin-bottom: 0.5rem !important;
    }

    .ml-md-8pt,
    .mx-md-8pt {
        margin-left: 0.5rem !important;
    }

    [dir='rtl'] .ml-md-8pt,
    [dir='rtl'] .mx-md-8pt {
        margin-right: 0.5rem !important;
    }

    .m-md-12pt {
        margin: 0.75rem !important;
    }

    .mt-md-12pt,
    .my-md-12pt {
        margin-top: 0.75rem !important;
    }

    .mr-md-12pt,
    .mx-md-12pt {
        margin-right: 0.75rem !important;
    }

    [dir='rtl'] .mr-md-12pt,
    [dir='rtl'] .mx-md-12pt {
        margin-left: 0.75rem !important;
    }

    .mb-md-12pt,
    .my-md-12pt {
        margin-bottom: 0.75rem !important;
    }

    .ml-md-12pt,
    .mx-md-12pt {
        margin-left: 0.75rem !important;
    }

    [dir='rtl'] .ml-md-12pt,
    [dir='rtl'] .mx-md-12pt {
        margin-right: 0.75rem !important;
    }

    .m-md-16pt {
        margin: 1rem !important;
    }

    .mt-md-16pt,
    .my-md-16pt {
        margin-top: 1rem !important;
    }

    .mr-md-16pt,
    .mx-md-16pt {
        margin-right: 1rem !important;
    }

    [dir='rtl'] .mr-md-16pt,
    [dir='rtl'] .mx-md-16pt {
        margin-left: 1rem !important;
    }

    .mb-md-16pt,
    .my-md-16pt {
        margin-bottom: 1rem !important;
    }

    .ml-md-16pt,
    .mx-md-16pt {
        margin-left: 1rem !important;
    }

    [dir='rtl'] .ml-md-16pt,
    [dir='rtl'] .mx-md-16pt {
        margin-right: 1rem !important;
    }

    .m-md-20pt {
        margin: 1.25rem !important;
    }

    .mt-md-20pt,
    .my-md-20pt {
        margin-top: 1.25rem !important;
    }

    .mr-md-20pt,
    .mx-md-20pt {
        margin-right: 1.25rem !important;
    }

    [dir='rtl'] .mr-md-20pt,
    [dir='rtl'] .mx-md-20pt {
        margin-left: 1.25rem !important;
    }

    .mb-md-20pt,
    .my-md-20pt {
        margin-bottom: 1.25rem !important;
    }

    .ml-md-20pt,
    .mx-md-20pt {
        margin-left: 1.25rem !important;
    }

    [dir='rtl'] .ml-md-20pt,
    [dir='rtl'] .mx-md-20pt {
        margin-right: 1.25rem !important;
    }

    .m-md-24pt {
        margin: 1.5rem !important;
    }

    .mt-md-24pt,
    .my-md-24pt {
        margin-top: 1.5rem !important;
    }

    .mr-md-24pt,
    .mx-md-24pt {
        margin-right: 1.5rem !important;
    }

    [dir='rtl'] .mr-md-24pt,
    [dir='rtl'] .mx-md-24pt {
        margin-left: 1.5rem !important;
    }

    .mb-md-24pt,
    .my-md-24pt {
        margin-bottom: 1.5rem !important;
    }

    .ml-md-24pt,
    .mx-md-24pt {
        margin-left: 1.5rem !important;
    }

    [dir='rtl'] .ml-md-24pt,
    [dir='rtl'] .mx-md-24pt {
        margin-right: 1.5rem !important;
    }

    .m-md-32pt {
        margin: 2rem !important;
    }

    .mt-md-32pt,
    .my-md-32pt {
        margin-top: 2rem !important;
    }

    .mr-md-32pt,
    .mx-md-32pt {
        margin-right: 2rem !important;
    }

    [dir='rtl'] .mr-md-32pt,
    [dir='rtl'] .mx-md-32pt {
        margin-left: 2rem !important;
    }

    .mb-md-32pt,
    .my-md-32pt {
        margin-bottom: 2rem !important;
    }

    .ml-md-32pt,
    .mx-md-32pt {
        margin-left: 2rem !important;
    }

    [dir='rtl'] .ml-md-32pt,
    [dir='rtl'] .mx-md-32pt {
        margin-right: 2rem !important;
    }

    .m-md-40pt {
        margin: 2.5rem !important;
    }

    .mt-md-40pt,
    .my-md-40pt {
        margin-top: 2.5rem !important;
    }

    .mr-md-40pt,
    .mx-md-40pt {
        margin-right: 2.5rem !important;
    }

    [dir='rtl'] .mr-md-40pt,
    [dir='rtl'] .mx-md-40pt {
        margin-left: 2.5rem !important;
    }

    .mb-md-40pt,
    .my-md-40pt {
        margin-bottom: 2.5rem !important;
    }

    .ml-md-40pt,
    .mx-md-40pt {
        margin-left: 2.5rem !important;
    }

    [dir='rtl'] .ml-md-40pt,
    [dir='rtl'] .mx-md-40pt {
        margin-right: 2.5rem !important;
    }

    .m-md-44pt {
        margin: 2.75rem !important;
    }

    .mt-md-44pt,
    .my-md-44pt {
        margin-top: 2.75rem !important;
    }

    .mr-md-44pt,
    .mx-md-44pt {
        margin-right: 2.75rem !important;
    }

    [dir='rtl'] .mr-md-44pt,
    [dir='rtl'] .mx-md-44pt {
        margin-left: 2.75rem !important;
    }

    .mb-md-44pt,
    .my-md-44pt {
        margin-bottom: 2.75rem !important;
    }

    .ml-md-44pt,
    .mx-md-44pt {
        margin-left: 2.75rem !important;
    }

    [dir='rtl'] .ml-md-44pt,
    [dir='rtl'] .mx-md-44pt {
        margin-right: 2.75rem !important;
    }

    .m-md-48pt {
        margin: 3rem !important;
    }

    .mt-md-48pt,
    .my-md-48pt {
        margin-top: 3rem !important;
    }

    .mr-md-48pt,
    .mx-md-48pt {
        margin-right: 3rem !important;
    }

    [dir='rtl'] .mr-md-48pt,
    [dir='rtl'] .mx-md-48pt {
        margin-left: 3rem !important;
    }

    .mb-md-48pt,
    .my-md-48pt {
        margin-bottom: 3rem !important;
    }

    .ml-md-48pt,
    .mx-md-48pt {
        margin-left: 3rem !important;
    }

    [dir='rtl'] .ml-md-48pt,
    [dir='rtl'] .mx-md-48pt {
        margin-right: 3rem !important;
    }

    .m-md-64pt {
        margin: 4rem !important;
    }

    .mt-md-64pt,
    .my-md-64pt {
        margin-top: 4rem !important;
    }

    .mr-md-64pt,
    .mx-md-64pt {
        margin-right: 4rem !important;
    }

    [dir='rtl'] .mr-md-64pt,
    [dir='rtl'] .mx-md-64pt {
        margin-left: 4rem !important;
    }

    .mb-md-64pt,
    .my-md-64pt {
        margin-bottom: 4rem !important;
    }

    .ml-md-64pt,
    .mx-md-64pt {
        margin-left: 4rem !important;
    }

    [dir='rtl'] .ml-md-64pt,
    [dir='rtl'] .mx-md-64pt {
        margin-right: 4rem !important;
    }

    .m-md-112pt {
        margin: 7rem !important;
    }

    .mt-md-112pt,
    .my-md-112pt {
        margin-top: 7rem !important;
    }

    .mr-md-112pt,
    .mx-md-112pt {
        margin-right: 7rem !important;
    }

    [dir='rtl'] .mr-md-112pt,
    [dir='rtl'] .mx-md-112pt {
        margin-left: 7rem !important;
    }

    .mb-md-112pt,
    .my-md-112pt {
        margin-bottom: 7rem !important;
    }

    .ml-md-112pt,
    .mx-md-112pt {
        margin-left: 7rem !important;
    }

    [dir='rtl'] .ml-md-112pt,
    [dir='rtl'] .mx-md-112pt {
        margin-right: 7rem !important;
    }

    .m-md-128pt {
        margin: 8rem !important;
    }

    .mt-md-128pt,
    .my-md-128pt {
        margin-top: 8rem !important;
    }

    .mr-md-128pt,
    .mx-md-128pt {
        margin-right: 8rem !important;
    }

    [dir='rtl'] .mr-md-128pt,
    [dir='rtl'] .mx-md-128pt {
        margin-left: 8rem !important;
    }

    .mb-md-128pt,
    .my-md-128pt {
        margin-bottom: 8rem !important;
    }

    .ml-md-128pt,
    .mx-md-128pt {
        margin-left: 8rem !important;
    }

    [dir='rtl'] .ml-md-128pt,
    [dir='rtl'] .mx-md-128pt {
        margin-right: 8rem !important;
    }

    .p-md-0 {
        padding: 0 !important;
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important;
    }

    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }

    [dir='rtl'] .pr-md-0,
    [dir='rtl'] .px-md-0 {
        padding-left: 0 !important;
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }

    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }

    [dir='rtl'] .pl-md-0,
    [dir='rtl'] .px-md-0 {
        padding-right: 0 !important;
    }

    .p-md-1 {
        padding: 0.25rem !important;
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: 0.25rem !important;
    }

    .pr-md-1,
    .px-md-1 {
        padding-right: 0.25rem !important;
    }

    [dir='rtl'] .pr-md-1,
    [dir='rtl'] .px-md-1 {
        padding-left: 0.25rem !important;
    }

    .pb-md-1,
    .py-md-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-md-1,
    .px-md-1 {
        padding-left: 0.25rem !important;
    }

    [dir='rtl'] .pl-md-1,
    [dir='rtl'] .px-md-1 {
        padding-right: 0.25rem !important;
    }

    .p-md-2 {
        padding: 0.5rem !important;
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: 0.5rem !important;
    }

    .pr-md-2,
    .px-md-2 {
        padding-right: 0.5rem !important;
    }

    [dir='rtl'] .pr-md-2,
    [dir='rtl'] .px-md-2 {
        padding-left: 0.5rem !important;
    }

    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-md-2,
    .px-md-2 {
        padding-left: 0.5rem !important;
    }

    [dir='rtl'] .pl-md-2,
    [dir='rtl'] .px-md-2 {
        padding-right: 0.5rem !important;
    }

    .p-md-3 {
        padding: 1rem !important;
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important;
    }

    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important;
    }

    [dir='rtl'] .pr-md-3,
    [dir='rtl'] .px-md-3 {
        padding-left: 1rem !important;
    }

    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important;
    }

    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important;
    }

    [dir='rtl'] .pl-md-3,
    [dir='rtl'] .px-md-3 {
        padding-right: 1rem !important;
    }

    .p-md-4 {
        padding: 1.5rem !important;
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important;
    }

    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important;
    }

    [dir='rtl'] .pr-md-4,
    [dir='rtl'] .px-md-4 {
        padding-left: 1.5rem !important;
    }

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important;
    }

    [dir='rtl'] .pl-md-4,
    [dir='rtl'] .px-md-4 {
        padding-right: 1.5rem !important;
    }

    .p-md-5 {
        padding: 3rem !important;
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important;
    }

    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important;
    }

    [dir='rtl'] .pr-md-5,
    [dir='rtl'] .px-md-5 {
        padding-left: 3rem !important;
    }

    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important;
    }

    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important;
    }

    [dir='rtl'] .pl-md-5,
    [dir='rtl'] .px-md-5 {
        padding-right: 3rem !important;
    }

    .p-md-4pt {
        padding: 0.25rem !important;
    }

    .pt-md-4pt,
    .py-md-4pt {
        padding-top: 0.25rem !important;
    }

    .pr-md-4pt,
    .px-md-4pt {
        padding-right: 0.25rem !important;
    }

    [dir='rtl'] .pr-md-4pt,
    [dir='rtl'] .px-md-4pt {
        padding-left: 0.25rem !important;
    }

    .pb-md-4pt,
    .py-md-4pt {
        padding-bottom: 0.25rem !important;
    }

    .pl-md-4pt,
    .px-md-4pt {
        padding-left: 0.25rem !important;
    }

    [dir='rtl'] .pl-md-4pt,
    [dir='rtl'] .px-md-4pt {
        padding-right: 0.25rem !important;
    }

    .p-md-8pt {
        padding: 0.5rem !important;
    }

    .pt-md-8pt,
    .py-md-8pt {
        padding-top: 0.5rem !important;
    }

    .pr-md-8pt,
    .px-md-8pt {
        padding-right: 0.5rem !important;
    }

    [dir='rtl'] .pr-md-8pt,
    [dir='rtl'] .px-md-8pt {
        padding-left: 0.5rem !important;
    }

    .pb-md-8pt,
    .py-md-8pt {
        padding-bottom: 0.5rem !important;
    }

    .pl-md-8pt,
    .px-md-8pt {
        padding-left: 0.5rem !important;
    }

    [dir='rtl'] .pl-md-8pt,
    [dir='rtl'] .px-md-8pt {
        padding-right: 0.5rem !important;
    }

    .p-md-12pt {
        padding: 0.75rem !important;
    }

    .pt-md-12pt,
    .py-md-12pt {
        padding-top: 0.75rem !important;
    }

    .pr-md-12pt,
    .px-md-12pt {
        padding-right: 0.75rem !important;
    }

    [dir='rtl'] .pr-md-12pt,
    [dir='rtl'] .px-md-12pt {
        padding-left: 0.75rem !important;
    }

    .pb-md-12pt,
    .py-md-12pt {
        padding-bottom: 0.75rem !important;
    }

    .pl-md-12pt,
    .px-md-12pt {
        padding-left: 0.75rem !important;
    }

    [dir='rtl'] .pl-md-12pt,
    [dir='rtl'] .px-md-12pt {
        padding-right: 0.75rem !important;
    }

    .p-md-16pt {
        padding: 1rem !important;
    }

    .pt-md-16pt,
    .py-md-16pt {
        padding-top: 1rem !important;
    }

    .pr-md-16pt,
    .px-md-16pt {
        padding-right: 1rem !important;
    }

    [dir='rtl'] .pr-md-16pt,
    [dir='rtl'] .px-md-16pt {
        padding-left: 1rem !important;
    }

    .pb-md-16pt,
    .py-md-16pt {
        padding-bottom: 1rem !important;
    }

    .pl-md-16pt,
    .px-md-16pt {
        padding-left: 1rem !important;
    }

    [dir='rtl'] .pl-md-16pt,
    [dir='rtl'] .px-md-16pt {
        padding-right: 1rem !important;
    }

    .p-md-20pt {
        padding: 1.25rem !important;
    }

    .pt-md-20pt,
    .py-md-20pt {
        padding-top: 1.25rem !important;
    }

    .pr-md-20pt,
    .px-md-20pt {
        padding-right: 1.25rem !important;
    }

    [dir='rtl'] .pr-md-20pt,
    [dir='rtl'] .px-md-20pt {
        padding-left: 1.25rem !important;
    }

    .pb-md-20pt,
    .py-md-20pt {
        padding-bottom: 1.25rem !important;
    }

    .pl-md-20pt,
    .px-md-20pt {
        padding-left: 1.25rem !important;
    }

    [dir='rtl'] .pl-md-20pt,
    [dir='rtl'] .px-md-20pt {
        padding-right: 1.25rem !important;
    }

    .p-md-24pt {
        padding: 1.5rem !important;
    }

    .pt-md-24pt,
    .py-md-24pt {
        padding-top: 1.5rem !important;
    }

    .pr-md-24pt,
    .px-md-24pt {
        padding-right: 1.5rem !important;
    }

    [dir='rtl'] .pr-md-24pt,
    [dir='rtl'] .px-md-24pt {
        padding-left: 1.5rem !important;
    }

    .pb-md-24pt,
    .py-md-24pt {
        padding-bottom: 1.5rem !important;
    }

    .pl-md-24pt,
    .px-md-24pt {
        padding-left: 1.5rem !important;
    }

    [dir='rtl'] .pl-md-24pt,
    [dir='rtl'] .px-md-24pt {
        padding-right: 1.5rem !important;
    }

    .p-md-32pt {
        padding: 2rem !important;
    }

    .pt-md-32pt,
    .py-md-32pt {
        padding-top: 2rem !important;
    }

    .pr-md-32pt,
    .px-md-32pt {
        padding-right: 2rem !important;
    }

    [dir='rtl'] .pr-md-32pt,
    [dir='rtl'] .px-md-32pt {
        padding-left: 2rem !important;
    }

    .pb-md-32pt,
    .py-md-32pt {
        padding-bottom: 2rem !important;
    }

    .pl-md-32pt,
    .px-md-32pt {
        padding-left: 2rem !important;
    }

    [dir='rtl'] .pl-md-32pt,
    [dir='rtl'] .px-md-32pt {
        padding-right: 2rem !important;
    }

    .p-md-40pt {
        padding: 2.5rem !important;
    }

    .pt-md-40pt,
    .py-md-40pt {
        padding-top: 2.5rem !important;
    }

    .pr-md-40pt,
    .px-md-40pt {
        padding-right: 2.5rem !important;
    }

    [dir='rtl'] .pr-md-40pt,
    [dir='rtl'] .px-md-40pt {
        padding-left: 2.5rem !important;
    }

    .pb-md-40pt,
    .py-md-40pt {
        padding-bottom: 2.5rem !important;
    }

    .pl-md-40pt,
    .px-md-40pt {
        padding-left: 2.5rem !important;
    }

    [dir='rtl'] .pl-md-40pt,
    [dir='rtl'] .px-md-40pt {
        padding-right: 2.5rem !important;
    }

    .p-md-44pt {
        padding: 2.75rem !important;
    }

    .pt-md-44pt,
    .py-md-44pt {
        padding-top: 2.75rem !important;
    }

    .pr-md-44pt,
    .px-md-44pt {
        padding-right: 2.75rem !important;
    }

    [dir='rtl'] .pr-md-44pt,
    [dir='rtl'] .px-md-44pt {
        padding-left: 2.75rem !important;
    }

    .pb-md-44pt,
    .py-md-44pt {
        padding-bottom: 2.75rem !important;
    }

    .pl-md-44pt,
    .px-md-44pt {
        padding-left: 2.75rem !important;
    }

    [dir='rtl'] .pl-md-44pt,
    [dir='rtl'] .px-md-44pt {
        padding-right: 2.75rem !important;
    }

    .p-md-48pt {
        padding: 3rem !important;
    }

    .pt-md-48pt,
    .py-md-48pt {
        padding-top: 3rem !important;
    }

    .pr-md-48pt,
    .px-md-48pt {
        padding-right: 3rem !important;
    }

    [dir='rtl'] .pr-md-48pt,
    [dir='rtl'] .px-md-48pt {
        padding-left: 3rem !important;
    }

    .pb-md-48pt,
    .py-md-48pt {
        padding-bottom: 3rem !important;
    }

    .pl-md-48pt,
    .px-md-48pt {
        padding-left: 3rem !important;
    }

    [dir='rtl'] .pl-md-48pt,
    [dir='rtl'] .px-md-48pt {
        padding-right: 3rem !important;
    }

    .p-md-64pt {
        padding: 4rem !important;
    }

    .pt-md-64pt,
    .py-md-64pt {
        padding-top: 4rem !important;
    }

    .pr-md-64pt,
    .px-md-64pt {
        padding-right: 4rem !important;
    }

    [dir='rtl'] .pr-md-64pt,
    [dir='rtl'] .px-md-64pt {
        padding-left: 4rem !important;
    }

    .pb-md-64pt,
    .py-md-64pt {
        padding-bottom: 4rem !important;
    }

    .pl-md-64pt,
    .px-md-64pt {
        padding-left: 4rem !important;
    }

    [dir='rtl'] .pl-md-64pt,
    [dir='rtl'] .px-md-64pt {
        padding-right: 4rem !important;
    }

    .p-md-112pt {
        padding: 7rem !important;
    }

    .pt-md-112pt,
    .py-md-112pt {
        padding-top: 7rem !important;
    }

    .pr-md-112pt,
    .px-md-112pt {
        padding-right: 7rem !important;
    }

    [dir='rtl'] .pr-md-112pt,
    [dir='rtl'] .px-md-112pt {
        padding-left: 7rem !important;
    }

    .pb-md-112pt,
    .py-md-112pt {
        padding-bottom: 7rem !important;
    }

    .pl-md-112pt,
    .px-md-112pt {
        padding-left: 7rem !important;
    }

    [dir='rtl'] .pl-md-112pt,
    [dir='rtl'] .px-md-112pt {
        padding-right: 7rem !important;
    }

    .p-md-128pt {
        padding: 8rem !important;
    }

    .pt-md-128pt,
    .py-md-128pt {
        padding-top: 8rem !important;
    }

    .pr-md-128pt,
    .px-md-128pt {
        padding-right: 8rem !important;
    }

    [dir='rtl'] .pr-md-128pt,
    [dir='rtl'] .px-md-128pt {
        padding-left: 8rem !important;
    }

    .pb-md-128pt,
    .py-md-128pt {
        padding-bottom: 8rem !important;
    }

    .pl-md-128pt,
    .px-md-128pt {
        padding-left: 8rem !important;
    }

    [dir='rtl'] .pl-md-128pt,
    [dir='rtl'] .px-md-128pt {
        padding-right: 8rem !important;
    }

    .m-md-n1 {
        margin: -0.25rem !important;
    }

    .mt-md-n1,
    .my-md-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -0.25rem !important;
    }

    [dir='rtl'] .mr-md-n1,
    [dir='rtl'] .mx-md-n1 {
        margin-left: -0.25rem !important;
    }

    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -0.25rem !important;
    }

    [dir='rtl'] .ml-md-n1,
    [dir='rtl'] .mx-md-n1 {
        margin-right: -0.25rem !important;
    }

    .m-md-n2 {
        margin: -0.5rem !important;
    }

    .mt-md-n2,
    .my-md-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -0.5rem !important;
    }

    [dir='rtl'] .mr-md-n2,
    [dir='rtl'] .mx-md-n2 {
        margin-left: -0.5rem !important;
    }

    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -0.5rem !important;
    }

    [dir='rtl'] .ml-md-n2,
    [dir='rtl'] .mx-md-n2 {
        margin-right: -0.5rem !important;
    }

    .m-md-n3 {
        margin: -1rem !important;
    }

    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem !important;
    }

    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -1rem !important;
    }

    [dir='rtl'] .mr-md-n3,
    [dir='rtl'] .mx-md-n3 {
        margin-left: -1rem !important;
    }

    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -1rem !important;
    }

    [dir='rtl'] .ml-md-n3,
    [dir='rtl'] .mx-md-n3 {
        margin-right: -1rem !important;
    }

    .m-md-n4 {
        margin: -1.5rem !important;
    }

    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1.5rem !important;
    }

    [dir='rtl'] .mr-md-n4,
    [dir='rtl'] .mx-md-n4 {
        margin-left: -1.5rem !important;
    }

    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1.5rem !important;
    }

    [dir='rtl'] .ml-md-n4,
    [dir='rtl'] .mx-md-n4 {
        margin-right: -1.5rem !important;
    }

    .m-md-n5 {
        margin: -3rem !important;
    }

    .mt-md-n5,
    .my-md-n5 {
        margin-top: -3rem !important;
    }

    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -3rem !important;
    }

    [dir='rtl'] .mr-md-n5,
    [dir='rtl'] .mx-md-n5 {
        margin-left: -3rem !important;
    }

    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -3rem !important;
    }

    [dir='rtl'] .ml-md-n5,
    [dir='rtl'] .mx-md-n5 {
        margin-right: -3rem !important;
    }

    .m-md-n4pt {
        margin: -0.25rem !important;
    }

    .mt-md-n4pt,
    .my-md-n4pt {
        margin-top: -0.25rem !important;
    }

    .mr-md-n4pt,
    .mx-md-n4pt {
        margin-right: -0.25rem !important;
    }

    [dir='rtl'] .mr-md-n4pt,
    [dir='rtl'] .mx-md-n4pt {
        margin-left: -0.25rem !important;
    }

    .mb-md-n4pt,
    .my-md-n4pt {
        margin-bottom: -0.25rem !important;
    }

    .ml-md-n4pt,
    .mx-md-n4pt {
        margin-left: -0.25rem !important;
    }

    [dir='rtl'] .ml-md-n4pt,
    [dir='rtl'] .mx-md-n4pt {
        margin-right: -0.25rem !important;
    }

    .m-md-n8pt {
        margin: -0.5rem !important;
    }

    .mt-md-n8pt,
    .my-md-n8pt {
        margin-top: -0.5rem !important;
    }

    .mr-md-n8pt,
    .mx-md-n8pt {
        margin-right: -0.5rem !important;
    }

    [dir='rtl'] .mr-md-n8pt,
    [dir='rtl'] .mx-md-n8pt {
        margin-left: -0.5rem !important;
    }

    .mb-md-n8pt,
    .my-md-n8pt {
        margin-bottom: -0.5rem !important;
    }

    .ml-md-n8pt,
    .mx-md-n8pt {
        margin-left: -0.5rem !important;
    }

    [dir='rtl'] .ml-md-n8pt,
    [dir='rtl'] .mx-md-n8pt {
        margin-right: -0.5rem !important;
    }

    .m-md-n12pt {
        margin: -0.75rem !important;
    }

    .mt-md-n12pt,
    .my-md-n12pt {
        margin-top: -0.75rem !important;
    }

    .mr-md-n12pt,
    .mx-md-n12pt {
        margin-right: -0.75rem !important;
    }

    [dir='rtl'] .mr-md-n12pt,
    [dir='rtl'] .mx-md-n12pt {
        margin-left: -0.75rem !important;
    }

    .mb-md-n12pt,
    .my-md-n12pt {
        margin-bottom: -0.75rem !important;
    }

    .ml-md-n12pt,
    .mx-md-n12pt {
        margin-left: -0.75rem !important;
    }

    [dir='rtl'] .ml-md-n12pt,
    [dir='rtl'] .mx-md-n12pt {
        margin-right: -0.75rem !important;
    }

    .m-md-n16pt {
        margin: -1rem !important;
    }

    .mt-md-n16pt,
    .my-md-n16pt {
        margin-top: -1rem !important;
    }

    .mr-md-n16pt,
    .mx-md-n16pt {
        margin-right: -1rem !important;
    }

    [dir='rtl'] .mr-md-n16pt,
    [dir='rtl'] .mx-md-n16pt {
        margin-left: -1rem !important;
    }

    .mb-md-n16pt,
    .my-md-n16pt {
        margin-bottom: -1rem !important;
    }

    .ml-md-n16pt,
    .mx-md-n16pt {
        margin-left: -1rem !important;
    }

    [dir='rtl'] .ml-md-n16pt,
    [dir='rtl'] .mx-md-n16pt {
        margin-right: -1rem !important;
    }

    .m-md-n20pt {
        margin: -1.25rem !important;
    }

    .mt-md-n20pt,
    .my-md-n20pt {
        margin-top: -1.25rem !important;
    }

    .mr-md-n20pt,
    .mx-md-n20pt {
        margin-right: -1.25rem !important;
    }

    [dir='rtl'] .mr-md-n20pt,
    [dir='rtl'] .mx-md-n20pt {
        margin-left: -1.25rem !important;
    }

    .mb-md-n20pt,
    .my-md-n20pt {
        margin-bottom: -1.25rem !important;
    }

    .ml-md-n20pt,
    .mx-md-n20pt {
        margin-left: -1.25rem !important;
    }

    [dir='rtl'] .ml-md-n20pt,
    [dir='rtl'] .mx-md-n20pt {
        margin-right: -1.25rem !important;
    }

    .m-md-n24pt {
        margin: -1.5rem !important;
    }

    .mt-md-n24pt,
    .my-md-n24pt {
        margin-top: -1.5rem !important;
    }

    .mr-md-n24pt,
    .mx-md-n24pt {
        margin-right: -1.5rem !important;
    }

    [dir='rtl'] .mr-md-n24pt,
    [dir='rtl'] .mx-md-n24pt {
        margin-left: -1.5rem !important;
    }

    .mb-md-n24pt,
    .my-md-n24pt {
        margin-bottom: -1.5rem !important;
    }

    .ml-md-n24pt,
    .mx-md-n24pt {
        margin-left: -1.5rem !important;
    }

    [dir='rtl'] .ml-md-n24pt,
    [dir='rtl'] .mx-md-n24pt {
        margin-right: -1.5rem !important;
    }

    .m-md-n32pt {
        margin: -2rem !important;
    }

    .mt-md-n32pt,
    .my-md-n32pt {
        margin-top: -2rem !important;
    }

    .mr-md-n32pt,
    .mx-md-n32pt {
        margin-right: -2rem !important;
    }

    [dir='rtl'] .mr-md-n32pt,
    [dir='rtl'] .mx-md-n32pt {
        margin-left: -2rem !important;
    }

    .mb-md-n32pt,
    .my-md-n32pt {
        margin-bottom: -2rem !important;
    }

    .ml-md-n32pt,
    .mx-md-n32pt {
        margin-left: -2rem !important;
    }

    [dir='rtl'] .ml-md-n32pt,
    [dir='rtl'] .mx-md-n32pt {
        margin-right: -2rem !important;
    }

    .m-md-n40pt {
        margin: -2.5rem !important;
    }

    .mt-md-n40pt,
    .my-md-n40pt {
        margin-top: -2.5rem !important;
    }

    .mr-md-n40pt,
    .mx-md-n40pt {
        margin-right: -2.5rem !important;
    }

    [dir='rtl'] .mr-md-n40pt,
    [dir='rtl'] .mx-md-n40pt {
        margin-left: -2.5rem !important;
    }

    .mb-md-n40pt,
    .my-md-n40pt {
        margin-bottom: -2.5rem !important;
    }

    .ml-md-n40pt,
    .mx-md-n40pt {
        margin-left: -2.5rem !important;
    }

    [dir='rtl'] .ml-md-n40pt,
    [dir='rtl'] .mx-md-n40pt {
        margin-right: -2.5rem !important;
    }

    .m-md-n44pt {
        margin: -2.75rem !important;
    }

    .mt-md-n44pt,
    .my-md-n44pt {
        margin-top: -2.75rem !important;
    }

    .mr-md-n44pt,
    .mx-md-n44pt {
        margin-right: -2.75rem !important;
    }

    [dir='rtl'] .mr-md-n44pt,
    [dir='rtl'] .mx-md-n44pt {
        margin-left: -2.75rem !important;
    }

    .mb-md-n44pt,
    .my-md-n44pt {
        margin-bottom: -2.75rem !important;
    }

    .ml-md-n44pt,
    .mx-md-n44pt {
        margin-left: -2.75rem !important;
    }

    [dir='rtl'] .ml-md-n44pt,
    [dir='rtl'] .mx-md-n44pt {
        margin-right: -2.75rem !important;
    }

    .m-md-n48pt {
        margin: -3rem !important;
    }

    .mt-md-n48pt,
    .my-md-n48pt {
        margin-top: -3rem !important;
    }

    .mr-md-n48pt,
    .mx-md-n48pt {
        margin-right: -3rem !important;
    }

    [dir='rtl'] .mr-md-n48pt,
    [dir='rtl'] .mx-md-n48pt {
        margin-left: -3rem !important;
    }

    .mb-md-n48pt,
    .my-md-n48pt {
        margin-bottom: -3rem !important;
    }

    .ml-md-n48pt,
    .mx-md-n48pt {
        margin-left: -3rem !important;
    }

    [dir='rtl'] .ml-md-n48pt,
    [dir='rtl'] .mx-md-n48pt {
        margin-right: -3rem !important;
    }

    .m-md-n64pt {
        margin: -4rem !important;
    }

    .mt-md-n64pt,
    .my-md-n64pt {
        margin-top: -4rem !important;
    }

    .mr-md-n64pt,
    .mx-md-n64pt {
        margin-right: -4rem !important;
    }

    [dir='rtl'] .mr-md-n64pt,
    [dir='rtl'] .mx-md-n64pt {
        margin-left: -4rem !important;
    }

    .mb-md-n64pt,
    .my-md-n64pt {
        margin-bottom: -4rem !important;
    }

    .ml-md-n64pt,
    .mx-md-n64pt {
        margin-left: -4rem !important;
    }

    [dir='rtl'] .ml-md-n64pt,
    [dir='rtl'] .mx-md-n64pt {
        margin-right: -4rem !important;
    }

    .m-md-n112pt {
        margin: -7rem !important;
    }

    .mt-md-n112pt,
    .my-md-n112pt {
        margin-top: -7rem !important;
    }

    .mr-md-n112pt,
    .mx-md-n112pt {
        margin-right: -7rem !important;
    }

    [dir='rtl'] .mr-md-n112pt,
    [dir='rtl'] .mx-md-n112pt {
        margin-left: -7rem !important;
    }

    .mb-md-n112pt,
    .my-md-n112pt {
        margin-bottom: -7rem !important;
    }

    .ml-md-n112pt,
    .mx-md-n112pt {
        margin-left: -7rem !important;
    }

    [dir='rtl'] .ml-md-n112pt,
    [dir='rtl'] .mx-md-n112pt {
        margin-right: -7rem !important;
    }

    .m-md-n128pt {
        margin: -8rem !important;
    }

    .mt-md-n128pt,
    .my-md-n128pt {
        margin-top: -8rem !important;
    }

    .mr-md-n128pt,
    .mx-md-n128pt {
        margin-right: -8rem !important;
    }

    [dir='rtl'] .mr-md-n128pt,
    [dir='rtl'] .mx-md-n128pt {
        margin-left: -8rem !important;
    }

    .mb-md-n128pt,
    .my-md-n128pt {
        margin-bottom: -8rem !important;
    }

    .ml-md-n128pt,
    .mx-md-n128pt {
        margin-left: -8rem !important;
    }

    [dir='rtl'] .ml-md-n128pt,
    [dir='rtl'] .mx-md-n128pt {
        margin-right: -8rem !important;
    }

    .m-md-auto {
        margin: auto !important;
    }

    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important;
    }

    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important;
    }

    [dir='rtl'] .mr-md-auto,
    [dir='rtl'] .mx-md-auto {
        margin-left: auto !important;
    }

    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important;
    }

    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important;
    }

    [dir='rtl'] .ml-md-auto,
    [dir='rtl'] .mx-md-auto {
        margin-right: auto !important;
    }
}

@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important;
    }

    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important;
    }

    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important;
    }

    [dir='rtl'] .mr-lg-0,
    [dir='rtl'] .mx-lg-0 {
        margin-left: 0 !important;
    }

    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important;
    }

    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important;
    }

    [dir='rtl'] .ml-lg-0,
    [dir='rtl'] .mx-lg-0 {
        margin-right: 0 !important;
    }

    .m-lg-1 {
        margin: 0.25rem !important;
    }

    .mt-lg-1,
    .my-lg-1 {
        margin-top: 0.25rem !important;
    }

    .mr-lg-1,
    .mx-lg-1 {
        margin-right: 0.25rem !important;
    }

    [dir='rtl'] .mr-lg-1,
    [dir='rtl'] .mx-lg-1 {
        margin-left: 0.25rem !important;
    }

    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem !important;
    }

    [dir='rtl'] .ml-lg-1,
    [dir='rtl'] .mx-lg-1 {
        margin-right: 0.25rem !important;
    }

    .m-lg-2 {
        margin: 0.5rem !important;
    }

    .mt-lg-2,
    .my-lg-2 {
        margin-top: 0.5rem !important;
    }

    .mr-lg-2,
    .mx-lg-2 {
        margin-right: 0.5rem !important;
    }

    [dir='rtl'] .mr-lg-2,
    [dir='rtl'] .mx-lg-2 {
        margin-left: 0.5rem !important;
    }

    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-lg-2,
    .mx-lg-2 {
        margin-left: 0.5rem !important;
    }

    [dir='rtl'] .ml-lg-2,
    [dir='rtl'] .mx-lg-2 {
        margin-right: 0.5rem !important;
    }

    .m-lg-3 {
        margin: 1rem !important;
    }

    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important;
    }

    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important;
    }

    [dir='rtl'] .mr-lg-3,
    [dir='rtl'] .mx-lg-3 {
        margin-left: 1rem !important;
    }

    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important;
    }

    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important;
    }

    [dir='rtl'] .ml-lg-3,
    [dir='rtl'] .mx-lg-3 {
        margin-right: 1rem !important;
    }

    .m-lg-4 {
        margin: 1.5rem !important;
    }

    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important;
    }

    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important;
    }

    [dir='rtl'] .mr-lg-4,
    [dir='rtl'] .mx-lg-4 {
        margin-left: 1.5rem !important;
    }

    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important;
    }

    [dir='rtl'] .ml-lg-4,
    [dir='rtl'] .mx-lg-4 {
        margin-right: 1.5rem !important;
    }

    .m-lg-5 {
        margin: 3rem !important;
    }

    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important;
    }

    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important;
    }

    [dir='rtl'] .mr-lg-5,
    [dir='rtl'] .mx-lg-5 {
        margin-left: 3rem !important;
    }

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important;
    }

    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important;
    }

    [dir='rtl'] .ml-lg-5,
    [dir='rtl'] .mx-lg-5 {
        margin-right: 3rem !important;
    }

    .m-lg-4pt {
        margin: 0.25rem !important;
    }

    .mt-lg-4pt,
    .my-lg-4pt {
        margin-top: 0.25rem !important;
    }

    .mr-lg-4pt,
    .mx-lg-4pt {
        margin-right: 0.25rem !important;
    }

    [dir='rtl'] .mr-lg-4pt,
    [dir='rtl'] .mx-lg-4pt {
        margin-left: 0.25rem !important;
    }

    .mb-lg-4pt,
    .my-lg-4pt {
        margin-bottom: 0.25rem !important;
    }

    .ml-lg-4pt,
    .mx-lg-4pt {
        margin-left: 0.25rem !important;
    }

    [dir='rtl'] .ml-lg-4pt,
    [dir='rtl'] .mx-lg-4pt {
        margin-right: 0.25rem !important;
    }

    .m-lg-8pt {
        margin: 0.5rem !important;
    }

    .mt-lg-8pt,
    .my-lg-8pt {
        margin-top: 0.5rem !important;
    }

    .mr-lg-8pt,
    .mx-lg-8pt {
        margin-right: 0.5rem !important;
    }

    [dir='rtl'] .mr-lg-8pt,
    [dir='rtl'] .mx-lg-8pt {
        margin-left: 0.5rem !important;
    }

    .mb-lg-8pt,
    .my-lg-8pt {
        margin-bottom: 0.5rem !important;
    }

    .ml-lg-8pt,
    .mx-lg-8pt {
        margin-left: 0.5rem !important;
    }

    [dir='rtl'] .ml-lg-8pt,
    [dir='rtl'] .mx-lg-8pt {
        margin-right: 0.5rem !important;
    }

    .m-lg-12pt {
        margin: 0.75rem !important;
    }

    .mt-lg-12pt,
    .my-lg-12pt {
        margin-top: 0.75rem !important;
    }

    .mr-lg-12pt,
    .mx-lg-12pt {
        margin-right: 0.75rem !important;
    }

    [dir='rtl'] .mr-lg-12pt,
    [dir='rtl'] .mx-lg-12pt {
        margin-left: 0.75rem !important;
    }

    .mb-lg-12pt,
    .my-lg-12pt {
        margin-bottom: 0.75rem !important;
    }

    .ml-lg-12pt,
    .mx-lg-12pt {
        margin-left: 0.75rem !important;
    }

    [dir='rtl'] .ml-lg-12pt,
    [dir='rtl'] .mx-lg-12pt {
        margin-right: 0.75rem !important;
    }

    .m-lg-16pt {
        margin: 1rem !important;
    }

    .mt-lg-16pt,
    .my-lg-16pt {
        margin-top: 1rem !important;
    }

    .mr-lg-16pt,
    .mx-lg-16pt {
        margin-right: 1rem !important;
    }

    [dir='rtl'] .mr-lg-16pt,
    [dir='rtl'] .mx-lg-16pt {
        margin-left: 1rem !important;
    }

    .mb-lg-16pt,
    .my-lg-16pt {
        margin-bottom: 1rem !important;
    }

    .ml-lg-16pt,
    .mx-lg-16pt {
        margin-left: 1rem !important;
    }

    [dir='rtl'] .ml-lg-16pt,
    [dir='rtl'] .mx-lg-16pt {
        margin-right: 1rem !important;
    }

    .m-lg-20pt {
        margin: 1.25rem !important;
    }

    .mt-lg-20pt,
    .my-lg-20pt {
        margin-top: 1.25rem !important;
    }

    .mr-lg-20pt,
    .mx-lg-20pt {
        margin-right: 1.25rem !important;
    }

    [dir='rtl'] .mr-lg-20pt,
    [dir='rtl'] .mx-lg-20pt {
        margin-left: 1.25rem !important;
    }

    .mb-lg-20pt,
    .my-lg-20pt {
        margin-bottom: 1.25rem !important;
    }

    .ml-lg-20pt,
    .mx-lg-20pt {
        margin-left: 1.25rem !important;
    }

    [dir='rtl'] .ml-lg-20pt,
    [dir='rtl'] .mx-lg-20pt {
        margin-right: 1.25rem !important;
    }

    .m-lg-24pt {
        margin: 1.5rem !important;
    }

    .mt-lg-24pt,
    .my-lg-24pt {
        margin-top: 1.5rem !important;
    }

    .mr-lg-24pt,
    .mx-lg-24pt {
        margin-right: 1.5rem !important;
    }

    [dir='rtl'] .mr-lg-24pt,
    [dir='rtl'] .mx-lg-24pt {
        margin-left: 1.5rem !important;
    }

    .mb-lg-24pt,
    .my-lg-24pt {
        margin-bottom: 1.5rem !important;
    }

    .ml-lg-24pt,
    .mx-lg-24pt {
        margin-left: 1.5rem !important;
    }

    [dir='rtl'] .ml-lg-24pt,
    [dir='rtl'] .mx-lg-24pt {
        margin-right: 1.5rem !important;
    }

    .m-lg-32pt {
        margin: 2rem !important;
    }

    .mt-lg-32pt,
    .my-lg-32pt {
        margin-top: 2rem !important;
    }

    .mr-lg-32pt,
    .mx-lg-32pt {
        margin-right: 2rem !important;
    }

    [dir='rtl'] .mr-lg-32pt,
    [dir='rtl'] .mx-lg-32pt {
        margin-left: 2rem !important;
    }

    .mb-lg-32pt,
    .my-lg-32pt {
        margin-bottom: 2rem !important;
    }

    .ml-lg-32pt,
    .mx-lg-32pt {
        margin-left: 2rem !important;
    }

    [dir='rtl'] .ml-lg-32pt,
    [dir='rtl'] .mx-lg-32pt {
        margin-right: 2rem !important;
    }

    .m-lg-40pt {
        margin: 2.5rem !important;
    }

    .mt-lg-40pt,
    .my-lg-40pt {
        margin-top: 2.5rem !important;
    }

    .mr-lg-40pt,
    .mx-lg-40pt {
        margin-right: 2.5rem !important;
    }

    [dir='rtl'] .mr-lg-40pt,
    [dir='rtl'] .mx-lg-40pt {
        margin-left: 2.5rem !important;
    }

    .mb-lg-40pt,
    .my-lg-40pt {
        margin-bottom: 2.5rem !important;
    }

    .ml-lg-40pt,
    .mx-lg-40pt {
        margin-left: 2.5rem !important;
    }

    [dir='rtl'] .ml-lg-40pt,
    [dir='rtl'] .mx-lg-40pt {
        margin-right: 2.5rem !important;
    }

    .m-lg-44pt {
        margin: 2.75rem !important;
    }

    .mt-lg-44pt,
    .my-lg-44pt {
        margin-top: 2.75rem !important;
    }

    .mr-lg-44pt,
    .mx-lg-44pt {
        margin-right: 2.75rem !important;
    }

    [dir='rtl'] .mr-lg-44pt,
    [dir='rtl'] .mx-lg-44pt {
        margin-left: 2.75rem !important;
    }

    .mb-lg-44pt,
    .my-lg-44pt {
        margin-bottom: 2.75rem !important;
    }

    .ml-lg-44pt,
    .mx-lg-44pt {
        margin-left: 2.75rem !important;
    }

    [dir='rtl'] .ml-lg-44pt,
    [dir='rtl'] .mx-lg-44pt {
        margin-right: 2.75rem !important;
    }

    .m-lg-48pt {
        margin: 3rem !important;
    }

    .mt-lg-48pt,
    .my-lg-48pt {
        margin-top: 3rem !important;
    }

    .mr-lg-48pt,
    .mx-lg-48pt {
        margin-right: 3rem !important;
    }

    [dir='rtl'] .mr-lg-48pt,
    [dir='rtl'] .mx-lg-48pt {
        margin-left: 3rem !important;
    }

    .mb-lg-48pt,
    .my-lg-48pt {
        margin-bottom: 3rem !important;
    }

    .ml-lg-48pt,
    .mx-lg-48pt {
        margin-left: 3rem !important;
    }

    [dir='rtl'] .ml-lg-48pt,
    [dir='rtl'] .mx-lg-48pt {
        margin-right: 3rem !important;
    }

    .m-lg-64pt {
        margin: 4rem !important;
    }

    .mt-lg-64pt,
    .my-lg-64pt {
        margin-top: 4rem !important;
    }

    .mr-lg-64pt,
    .mx-lg-64pt {
        margin-right: 4rem !important;
    }

    [dir='rtl'] .mr-lg-64pt,
    [dir='rtl'] .mx-lg-64pt {
        margin-left: 4rem !important;
    }

    .mb-lg-64pt,
    .my-lg-64pt {
        margin-bottom: 4rem !important;
    }

    .ml-lg-64pt,
    .mx-lg-64pt {
        margin-left: 4rem !important;
    }

    [dir='rtl'] .ml-lg-64pt,
    [dir='rtl'] .mx-lg-64pt {
        margin-right: 4rem !important;
    }

    .m-lg-112pt {
        margin: 7rem !important;
    }

    .mt-lg-112pt,
    .my-lg-112pt {
        margin-top: 7rem !important;
    }

    .mr-lg-112pt,
    .mx-lg-112pt {
        margin-right: 7rem !important;
    }

    [dir='rtl'] .mr-lg-112pt,
    [dir='rtl'] .mx-lg-112pt {
        margin-left: 7rem !important;
    }

    .mb-lg-112pt,
    .my-lg-112pt {
        margin-bottom: 7rem !important;
    }

    .ml-lg-112pt,
    .mx-lg-112pt {
        margin-left: 7rem !important;
    }

    [dir='rtl'] .ml-lg-112pt,
    [dir='rtl'] .mx-lg-112pt {
        margin-right: 7rem !important;
    }

    .m-lg-128pt {
        margin: 8rem !important;
    }

    .mt-lg-128pt,
    .my-lg-128pt {
        margin-top: 8rem !important;
    }

    .mr-lg-128pt,
    .mx-lg-128pt {
        margin-right: 8rem !important;
    }

    [dir='rtl'] .mr-lg-128pt,
    [dir='rtl'] .mx-lg-128pt {
        margin-left: 8rem !important;
    }

    .mb-lg-128pt,
    .my-lg-128pt {
        margin-bottom: 8rem !important;
    }

    .ml-lg-128pt,
    .mx-lg-128pt {
        margin-left: 8rem !important;
    }

    [dir='rtl'] .ml-lg-128pt,
    [dir='rtl'] .mx-lg-128pt {
        margin-right: 8rem !important;
    }

    .p-lg-0 {
        padding: 0 !important;
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important;
    }

    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }

    [dir='rtl'] .pr-lg-0,
    [dir='rtl'] .px-lg-0 {
        padding-left: 0 !important;
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important;
    }

    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }

    [dir='rtl'] .pl-lg-0,
    [dir='rtl'] .px-lg-0 {
        padding-right: 0 !important;
    }

    .p-lg-1 {
        padding: 0.25rem !important;
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: 0.25rem !important;
    }

    .pr-lg-1,
    .px-lg-1 {
        padding-right: 0.25rem !important;
    }

    [dir='rtl'] .pr-lg-1,
    [dir='rtl'] .px-lg-1 {
        padding-left: 0.25rem !important;
    }

    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-lg-1,
    .px-lg-1 {
        padding-left: 0.25rem !important;
    }

    [dir='rtl'] .pl-lg-1,
    [dir='rtl'] .px-lg-1 {
        padding-right: 0.25rem !important;
    }

    .p-lg-2 {
        padding: 0.5rem !important;
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: 0.5rem !important;
    }

    .pr-lg-2,
    .px-lg-2 {
        padding-right: 0.5rem !important;
    }

    [dir='rtl'] .pr-lg-2,
    [dir='rtl'] .px-lg-2 {
        padding-left: 0.5rem !important;
    }

    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-lg-2,
    .px-lg-2 {
        padding-left: 0.5rem !important;
    }

    [dir='rtl'] .pl-lg-2,
    [dir='rtl'] .px-lg-2 {
        padding-right: 0.5rem !important;
    }

    .p-lg-3 {
        padding: 1rem !important;
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important;
    }

    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important;
    }

    [dir='rtl'] .pr-lg-3,
    [dir='rtl'] .px-lg-3 {
        padding-left: 1rem !important;
    }

    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important;
    }

    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important;
    }

    [dir='rtl'] .pl-lg-3,
    [dir='rtl'] .px-lg-3 {
        padding-right: 1rem !important;
    }

    .p-lg-4 {
        padding: 1.5rem !important;
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important;
    }

    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important;
    }

    [dir='rtl'] .pr-lg-4,
    [dir='rtl'] .px-lg-4 {
        padding-left: 1.5rem !important;
    }

    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important;
    }

    [dir='rtl'] .pl-lg-4,
    [dir='rtl'] .px-lg-4 {
        padding-right: 1.5rem !important;
    }

    .p-lg-5 {
        padding: 3rem !important;
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important;
    }

    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important;
    }

    [dir='rtl'] .pr-lg-5,
    [dir='rtl'] .px-lg-5 {
        padding-left: 3rem !important;
    }

    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important;
    }

    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important;
    }

    [dir='rtl'] .pl-lg-5,
    [dir='rtl'] .px-lg-5 {
        padding-right: 3rem !important;
    }

    .p-lg-4pt {
        padding: 0.25rem !important;
    }

    .pt-lg-4pt,
    .py-lg-4pt {
        padding-top: 0.25rem !important;
    }

    .pr-lg-4pt,
    .px-lg-4pt {
        padding-right: 0.25rem !important;
    }

    [dir='rtl'] .pr-lg-4pt,
    [dir='rtl'] .px-lg-4pt {
        padding-left: 0.25rem !important;
    }

    .pb-lg-4pt,
    .py-lg-4pt {
        padding-bottom: 0.25rem !important;
    }

    .pl-lg-4pt,
    .px-lg-4pt {
        padding-left: 0.25rem !important;
    }

    [dir='rtl'] .pl-lg-4pt,
    [dir='rtl'] .px-lg-4pt {
        padding-right: 0.25rem !important;
    }

    .p-lg-8pt {
        padding: 0.5rem !important;
    }

    .pt-lg-8pt,
    .py-lg-8pt {
        padding-top: 0.5rem !important;
    }

    .pr-lg-8pt,
    .px-lg-8pt {
        padding-right: 0.5rem !important;
    }

    [dir='rtl'] .pr-lg-8pt,
    [dir='rtl'] .px-lg-8pt {
        padding-left: 0.5rem !important;
    }

    .pb-lg-8pt,
    .py-lg-8pt {
        padding-bottom: 0.5rem !important;
    }

    .pl-lg-8pt,
    .px-lg-8pt {
        padding-left: 0.5rem !important;
    }

    [dir='rtl'] .pl-lg-8pt,
    [dir='rtl'] .px-lg-8pt {
        padding-right: 0.5rem !important;
    }

    .p-lg-12pt {
        padding: 0.75rem !important;
    }

    .pt-lg-12pt,
    .py-lg-12pt {
        padding-top: 0.75rem !important;
    }

    .pr-lg-12pt,
    .px-lg-12pt {
        padding-right: 0.75rem !important;
    }

    [dir='rtl'] .pr-lg-12pt,
    [dir='rtl'] .px-lg-12pt {
        padding-left: 0.75rem !important;
    }

    .pb-lg-12pt,
    .py-lg-12pt {
        padding-bottom: 0.75rem !important;
    }

    .pl-lg-12pt,
    .px-lg-12pt {
        padding-left: 0.75rem !important;
    }

    [dir='rtl'] .pl-lg-12pt,
    [dir='rtl'] .px-lg-12pt {
        padding-right: 0.75rem !important;
    }

    .p-lg-16pt {
        padding: 1rem !important;
    }

    .pt-lg-16pt,
    .py-lg-16pt {
        padding-top: 1rem !important;
    }

    .pr-lg-16pt,
    .px-lg-16pt {
        padding-right: 1rem !important;
    }

    [dir='rtl'] .pr-lg-16pt,
    [dir='rtl'] .px-lg-16pt {
        padding-left: 1rem !important;
    }

    .pb-lg-16pt,
    .py-lg-16pt {
        padding-bottom: 1rem !important;
    }

    .pl-lg-16pt,
    .px-lg-16pt {
        padding-left: 1rem !important;
    }

    [dir='rtl'] .pl-lg-16pt,
    [dir='rtl'] .px-lg-16pt {
        padding-right: 1rem !important;
    }

    .p-lg-20pt {
        padding: 1.25rem !important;
    }

    .pt-lg-20pt,
    .py-lg-20pt {
        padding-top: 1.25rem !important;
    }

    .pr-lg-20pt,
    .px-lg-20pt {
        padding-right: 1.25rem !important;
    }

    [dir='rtl'] .pr-lg-20pt,
    [dir='rtl'] .px-lg-20pt {
        padding-left: 1.25rem !important;
    }

    .pb-lg-20pt,
    .py-lg-20pt {
        padding-bottom: 1.25rem !important;
    }

    .pl-lg-20pt,
    .px-lg-20pt {
        padding-left: 1.25rem !important;
    }

    [dir='rtl'] .pl-lg-20pt,
    [dir='rtl'] .px-lg-20pt {
        padding-right: 1.25rem !important;
    }

    .p-lg-24pt {
        padding: 1.5rem !important;
    }

    .pt-lg-24pt,
    .py-lg-24pt {
        padding-top: 1.5rem !important;
    }

    .pr-lg-24pt,
    .px-lg-24pt {
        padding-right: 1.5rem !important;
    }

    [dir='rtl'] .pr-lg-24pt,
    [dir='rtl'] .px-lg-24pt {
        padding-left: 1.5rem !important;
    }

    .pb-lg-24pt,
    .py-lg-24pt {
        padding-bottom: 1.5rem !important;
    }

    .pl-lg-24pt,
    .px-lg-24pt {
        padding-left: 1.5rem !important;
    }

    [dir='rtl'] .pl-lg-24pt,
    [dir='rtl'] .px-lg-24pt {
        padding-right: 1.5rem !important;
    }

    .p-lg-32pt {
        padding: 2rem !important;
    }

    .pt-lg-32pt,
    .py-lg-32pt {
        padding-top: 2rem !important;
    }

    .pr-lg-32pt,
    .px-lg-32pt {
        padding-right: 2rem !important;
    }

    [dir='rtl'] .pr-lg-32pt,
    [dir='rtl'] .px-lg-32pt {
        padding-left: 2rem !important;
    }

    .pb-lg-32pt,
    .py-lg-32pt {
        padding-bottom: 2rem !important;
    }

    .pl-lg-32pt,
    .px-lg-32pt {
        padding-left: 2rem !important;
    }

    [dir='rtl'] .pl-lg-32pt,
    [dir='rtl'] .px-lg-32pt {
        padding-right: 2rem !important;
    }

    .p-lg-40pt {
        padding: 2.5rem !important;
    }

    .pt-lg-40pt,
    .py-lg-40pt {
        padding-top: 2.5rem !important;
    }

    .pr-lg-40pt,
    .px-lg-40pt {
        padding-right: 2.5rem !important;
    }

    [dir='rtl'] .pr-lg-40pt,
    [dir='rtl'] .px-lg-40pt {
        padding-left: 2.5rem !important;
    }

    .pb-lg-40pt,
    .py-lg-40pt {
        padding-bottom: 2.5rem !important;
    }

    .pl-lg-40pt,
    .px-lg-40pt {
        padding-left: 2.5rem !important;
    }

    [dir='rtl'] .pl-lg-40pt,
    [dir='rtl'] .px-lg-40pt {
        padding-right: 2.5rem !important;
    }

    .p-lg-44pt {
        padding: 2.75rem !important;
    }

    .pt-lg-44pt,
    .py-lg-44pt {
        padding-top: 2.75rem !important;
    }

    .pr-lg-44pt,
    .px-lg-44pt {
        padding-right: 2.75rem !important;
    }

    [dir='rtl'] .pr-lg-44pt,
    [dir='rtl'] .px-lg-44pt {
        padding-left: 2.75rem !important;
    }

    .pb-lg-44pt,
    .py-lg-44pt {
        padding-bottom: 2.75rem !important;
    }

    .pl-lg-44pt,
    .px-lg-44pt {
        padding-left: 2.75rem !important;
    }

    [dir='rtl'] .pl-lg-44pt,
    [dir='rtl'] .px-lg-44pt {
        padding-right: 2.75rem !important;
    }

    .p-lg-48pt {
        padding: 3rem !important;
    }

    .pt-lg-48pt,
    .py-lg-48pt {
        padding-top: 3rem !important;
    }

    .pr-lg-48pt,
    .px-lg-48pt {
        padding-right: 3rem !important;
    }

    [dir='rtl'] .pr-lg-48pt,
    [dir='rtl'] .px-lg-48pt {
        padding-left: 3rem !important;
    }

    .pb-lg-48pt,
    .py-lg-48pt {
        padding-bottom: 3rem !important;
    }

    .pl-lg-48pt,
    .px-lg-48pt {
        padding-left: 3rem !important;
    }

    [dir='rtl'] .pl-lg-48pt,
    [dir='rtl'] .px-lg-48pt {
        padding-right: 3rem !important;
    }

    .p-lg-64pt {
        padding: 4rem !important;
    }

    .pt-lg-64pt,
    .py-lg-64pt {
        padding-top: 4rem !important;
    }

    .pr-lg-64pt,
    .px-lg-64pt {
        padding-right: 4rem !important;
    }

    [dir='rtl'] .pr-lg-64pt,
    [dir='rtl'] .px-lg-64pt {
        padding-left: 4rem !important;
    }

    .pb-lg-64pt,
    .py-lg-64pt {
        padding-bottom: 4rem !important;
    }

    .pl-lg-64pt,
    .px-lg-64pt {
        padding-left: 4rem !important;
    }

    [dir='rtl'] .pl-lg-64pt,
    [dir='rtl'] .px-lg-64pt {
        padding-right: 4rem !important;
    }

    .p-lg-112pt {
        padding: 7rem !important;
    }

    .pt-lg-112pt,
    .py-lg-112pt {
        padding-top: 7rem !important;
    }

    .pr-lg-112pt,
    .px-lg-112pt {
        padding-right: 7rem !important;
    }

    [dir='rtl'] .pr-lg-112pt,
    [dir='rtl'] .px-lg-112pt {
        padding-left: 7rem !important;
    }

    .pb-lg-112pt,
    .py-lg-112pt {
        padding-bottom: 7rem !important;
    }

    .pl-lg-112pt,
    .px-lg-112pt {
        padding-left: 7rem !important;
    }

    [dir='rtl'] .pl-lg-112pt,
    [dir='rtl'] .px-lg-112pt {
        padding-right: 7rem !important;
    }

    .p-lg-128pt {
        padding: 8rem !important;
    }

    .pt-lg-128pt,
    .py-lg-128pt {
        padding-top: 8rem !important;
    }

    .pr-lg-128pt,
    .px-lg-128pt {
        padding-right: 8rem !important;
    }

    [dir='rtl'] .pr-lg-128pt,
    [dir='rtl'] .px-lg-128pt {
        padding-left: 8rem !important;
    }

    .pb-lg-128pt,
    .py-lg-128pt {
        padding-bottom: 8rem !important;
    }

    .pl-lg-128pt,
    .px-lg-128pt {
        padding-left: 8rem !important;
    }

    [dir='rtl'] .pl-lg-128pt,
    [dir='rtl'] .px-lg-128pt {
        padding-right: 8rem !important;
    }

    .m-lg-n1 {
        margin: -0.25rem !important;
    }

    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -0.25rem !important;
    }

    [dir='rtl'] .mr-lg-n1,
    [dir='rtl'] .mx-lg-n1 {
        margin-left: -0.25rem !important;
    }

    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -0.25rem !important;
    }

    [dir='rtl'] .ml-lg-n1,
    [dir='rtl'] .mx-lg-n1 {
        margin-right: -0.25rem !important;
    }

    .m-lg-n2 {
        margin: -0.5rem !important;
    }

    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -0.5rem !important;
    }

    [dir='rtl'] .mr-lg-n2,
    [dir='rtl'] .mx-lg-n2 {
        margin-left: -0.5rem !important;
    }

    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -0.5rem !important;
    }

    [dir='rtl'] .ml-lg-n2,
    [dir='rtl'] .mx-lg-n2 {
        margin-right: -0.5rem !important;
    }

    .m-lg-n3 {
        margin: -1rem !important;
    }

    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem !important;
    }

    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem !important;
    }

    [dir='rtl'] .mr-lg-n3,
    [dir='rtl'] .mx-lg-n3 {
        margin-left: -1rem !important;
    }

    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem !important;
    }

    [dir='rtl'] .ml-lg-n3,
    [dir='rtl'] .mx-lg-n3 {
        margin-right: -1rem !important;
    }

    .m-lg-n4 {
        margin: -1.5rem !important;
    }

    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.5rem !important;
    }

    [dir='rtl'] .mr-lg-n4,
    [dir='rtl'] .mx-lg-n4 {
        margin-left: -1.5rem !important;
    }

    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.5rem !important;
    }

    [dir='rtl'] .ml-lg-n4,
    [dir='rtl'] .mx-lg-n4 {
        margin-right: -1.5rem !important;
    }

    .m-lg-n5 {
        margin: -3rem !important;
    }

    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -3rem !important;
    }

    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -3rem !important;
    }

    [dir='rtl'] .mr-lg-n5,
    [dir='rtl'] .mx-lg-n5 {
        margin-left: -3rem !important;
    }

    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -3rem !important;
    }

    [dir='rtl'] .ml-lg-n5,
    [dir='rtl'] .mx-lg-n5 {
        margin-right: -3rem !important;
    }

    .m-lg-n4pt {
        margin: -0.25rem !important;
    }

    .mt-lg-n4pt,
    .my-lg-n4pt {
        margin-top: -0.25rem !important;
    }

    .mr-lg-n4pt,
    .mx-lg-n4pt {
        margin-right: -0.25rem !important;
    }

    [dir='rtl'] .mr-lg-n4pt,
    [dir='rtl'] .mx-lg-n4pt {
        margin-left: -0.25rem !important;
    }

    .mb-lg-n4pt,
    .my-lg-n4pt {
        margin-bottom: -0.25rem !important;
    }

    .ml-lg-n4pt,
    .mx-lg-n4pt {
        margin-left: -0.25rem !important;
    }

    [dir='rtl'] .ml-lg-n4pt,
    [dir='rtl'] .mx-lg-n4pt {
        margin-right: -0.25rem !important;
    }

    .m-lg-n8pt {
        margin: -0.5rem !important;
    }

    .mt-lg-n8pt,
    .my-lg-n8pt {
        margin-top: -0.5rem !important;
    }

    .mr-lg-n8pt,
    .mx-lg-n8pt {
        margin-right: -0.5rem !important;
    }

    [dir='rtl'] .mr-lg-n8pt,
    [dir='rtl'] .mx-lg-n8pt {
        margin-left: -0.5rem !important;
    }

    .mb-lg-n8pt,
    .my-lg-n8pt {
        margin-bottom: -0.5rem !important;
    }

    .ml-lg-n8pt,
    .mx-lg-n8pt {
        margin-left: -0.5rem !important;
    }

    [dir='rtl'] .ml-lg-n8pt,
    [dir='rtl'] .mx-lg-n8pt {
        margin-right: -0.5rem !important;
    }

    .m-lg-n12pt {
        margin: -0.75rem !important;
    }

    .mt-lg-n12pt,
    .my-lg-n12pt {
        margin-top: -0.75rem !important;
    }

    .mr-lg-n12pt,
    .mx-lg-n12pt {
        margin-right: -0.75rem !important;
    }

    [dir='rtl'] .mr-lg-n12pt,
    [dir='rtl'] .mx-lg-n12pt {
        margin-left: -0.75rem !important;
    }

    .mb-lg-n12pt,
    .my-lg-n12pt {
        margin-bottom: -0.75rem !important;
    }

    .ml-lg-n12pt,
    .mx-lg-n12pt {
        margin-left: -0.75rem !important;
    }

    [dir='rtl'] .ml-lg-n12pt,
    [dir='rtl'] .mx-lg-n12pt {
        margin-right: -0.75rem !important;
    }

    .m-lg-n16pt {
        margin: -1rem !important;
    }

    .mt-lg-n16pt,
    .my-lg-n16pt {
        margin-top: -1rem !important;
    }

    .mr-lg-n16pt,
    .mx-lg-n16pt {
        margin-right: -1rem !important;
    }

    [dir='rtl'] .mr-lg-n16pt,
    [dir='rtl'] .mx-lg-n16pt {
        margin-left: -1rem !important;
    }

    .mb-lg-n16pt,
    .my-lg-n16pt {
        margin-bottom: -1rem !important;
    }

    .ml-lg-n16pt,
    .mx-lg-n16pt {
        margin-left: -1rem !important;
    }

    [dir='rtl'] .ml-lg-n16pt,
    [dir='rtl'] .mx-lg-n16pt {
        margin-right: -1rem !important;
    }

    .m-lg-n20pt {
        margin: -1.25rem !important;
    }

    .mt-lg-n20pt,
    .my-lg-n20pt {
        margin-top: -1.25rem !important;
    }

    .mr-lg-n20pt,
    .mx-lg-n20pt {
        margin-right: -1.25rem !important;
    }

    [dir='rtl'] .mr-lg-n20pt,
    [dir='rtl'] .mx-lg-n20pt {
        margin-left: -1.25rem !important;
    }

    .mb-lg-n20pt,
    .my-lg-n20pt {
        margin-bottom: -1.25rem !important;
    }

    .ml-lg-n20pt,
    .mx-lg-n20pt {
        margin-left: -1.25rem !important;
    }

    [dir='rtl'] .ml-lg-n20pt,
    [dir='rtl'] .mx-lg-n20pt {
        margin-right: -1.25rem !important;
    }

    .m-lg-n24pt {
        margin: -1.5rem !important;
    }

    .mt-lg-n24pt,
    .my-lg-n24pt {
        margin-top: -1.5rem !important;
    }

    .mr-lg-n24pt,
    .mx-lg-n24pt {
        margin-right: -1.5rem !important;
    }

    [dir='rtl'] .mr-lg-n24pt,
    [dir='rtl'] .mx-lg-n24pt {
        margin-left: -1.5rem !important;
    }

    .mb-lg-n24pt,
    .my-lg-n24pt {
        margin-bottom: -1.5rem !important;
    }

    .ml-lg-n24pt,
    .mx-lg-n24pt {
        margin-left: -1.5rem !important;
    }

    [dir='rtl'] .ml-lg-n24pt,
    [dir='rtl'] .mx-lg-n24pt {
        margin-right: -1.5rem !important;
    }

    .m-lg-n32pt {
        margin: -2rem !important;
    }

    .mt-lg-n32pt,
    .my-lg-n32pt {
        margin-top: -2rem !important;
    }

    .mr-lg-n32pt,
    .mx-lg-n32pt {
        margin-right: -2rem !important;
    }

    [dir='rtl'] .mr-lg-n32pt,
    [dir='rtl'] .mx-lg-n32pt {
        margin-left: -2rem !important;
    }

    .mb-lg-n32pt,
    .my-lg-n32pt {
        margin-bottom: -2rem !important;
    }

    .ml-lg-n32pt,
    .mx-lg-n32pt {
        margin-left: -2rem !important;
    }

    [dir='rtl'] .ml-lg-n32pt,
    [dir='rtl'] .mx-lg-n32pt {
        margin-right: -2rem !important;
    }

    .m-lg-n40pt {
        margin: -2.5rem !important;
    }

    .mt-lg-n40pt,
    .my-lg-n40pt {
        margin-top: -2.5rem !important;
    }

    .mr-lg-n40pt,
    .mx-lg-n40pt {
        margin-right: -2.5rem !important;
    }

    [dir='rtl'] .mr-lg-n40pt,
    [dir='rtl'] .mx-lg-n40pt {
        margin-left: -2.5rem !important;
    }

    .mb-lg-n40pt,
    .my-lg-n40pt {
        margin-bottom: -2.5rem !important;
    }

    .ml-lg-n40pt,
    .mx-lg-n40pt {
        margin-left: -2.5rem !important;
    }

    [dir='rtl'] .ml-lg-n40pt,
    [dir='rtl'] .mx-lg-n40pt {
        margin-right: -2.5rem !important;
    }

    .m-lg-n44pt {
        margin: -2.75rem !important;
    }

    .mt-lg-n44pt,
    .my-lg-n44pt {
        margin-top: -2.75rem !important;
    }

    .mr-lg-n44pt,
    .mx-lg-n44pt {
        margin-right: -2.75rem !important;
    }

    [dir='rtl'] .mr-lg-n44pt,
    [dir='rtl'] .mx-lg-n44pt {
        margin-left: -2.75rem !important;
    }

    .mb-lg-n44pt,
    .my-lg-n44pt {
        margin-bottom: -2.75rem !important;
    }

    .ml-lg-n44pt,
    .mx-lg-n44pt {
        margin-left: -2.75rem !important;
    }

    [dir='rtl'] .ml-lg-n44pt,
    [dir='rtl'] .mx-lg-n44pt {
        margin-right: -2.75rem !important;
    }

    .m-lg-n48pt {
        margin: -3rem !important;
    }

    .mt-lg-n48pt,
    .my-lg-n48pt {
        margin-top: -3rem !important;
    }

    .mr-lg-n48pt,
    .mx-lg-n48pt {
        margin-right: -3rem !important;
    }

    [dir='rtl'] .mr-lg-n48pt,
    [dir='rtl'] .mx-lg-n48pt {
        margin-left: -3rem !important;
    }

    .mb-lg-n48pt,
    .my-lg-n48pt {
        margin-bottom: -3rem !important;
    }

    .ml-lg-n48pt,
    .mx-lg-n48pt {
        margin-left: -3rem !important;
    }

    [dir='rtl'] .ml-lg-n48pt,
    [dir='rtl'] .mx-lg-n48pt {
        margin-right: -3rem !important;
    }

    .m-lg-n64pt {
        margin: -4rem !important;
    }

    .mt-lg-n64pt,
    .my-lg-n64pt {
        margin-top: -4rem !important;
    }

    .mr-lg-n64pt,
    .mx-lg-n64pt {
        margin-right: -4rem !important;
    }

    [dir='rtl'] .mr-lg-n64pt,
    [dir='rtl'] .mx-lg-n64pt {
        margin-left: -4rem !important;
    }

    .mb-lg-n64pt,
    .my-lg-n64pt {
        margin-bottom: -4rem !important;
    }

    .ml-lg-n64pt,
    .mx-lg-n64pt {
        margin-left: -4rem !important;
    }

    [dir='rtl'] .ml-lg-n64pt,
    [dir='rtl'] .mx-lg-n64pt {
        margin-right: -4rem !important;
    }

    .m-lg-n112pt {
        margin: -7rem !important;
    }

    .mt-lg-n112pt,
    .my-lg-n112pt {
        margin-top: -7rem !important;
    }

    .mr-lg-n112pt,
    .mx-lg-n112pt {
        margin-right: -7rem !important;
    }

    [dir='rtl'] .mr-lg-n112pt,
    [dir='rtl'] .mx-lg-n112pt {
        margin-left: -7rem !important;
    }

    .mb-lg-n112pt,
    .my-lg-n112pt {
        margin-bottom: -7rem !important;
    }

    .ml-lg-n112pt,
    .mx-lg-n112pt {
        margin-left: -7rem !important;
    }

    [dir='rtl'] .ml-lg-n112pt,
    [dir='rtl'] .mx-lg-n112pt {
        margin-right: -7rem !important;
    }

    .m-lg-n128pt {
        margin: -8rem !important;
    }

    .mt-lg-n128pt,
    .my-lg-n128pt {
        margin-top: -8rem !important;
    }

    .mr-lg-n128pt,
    .mx-lg-n128pt {
        margin-right: -8rem !important;
    }

    [dir='rtl'] .mr-lg-n128pt,
    [dir='rtl'] .mx-lg-n128pt {
        margin-left: -8rem !important;
    }

    .mb-lg-n128pt,
    .my-lg-n128pt {
        margin-bottom: -8rem !important;
    }

    .ml-lg-n128pt,
    .mx-lg-n128pt {
        margin-left: -8rem !important;
    }

    [dir='rtl'] .ml-lg-n128pt,
    [dir='rtl'] .mx-lg-n128pt {
        margin-right: -8rem !important;
    }

    .m-lg-auto {
        margin: auto !important;
    }

    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important;
    }

    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important;
    }

    [dir='rtl'] .mr-lg-auto,
    [dir='rtl'] .mx-lg-auto {
        margin-left: auto !important;
    }

    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important;
    }

    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important;
    }

    [dir='rtl'] .ml-lg-auto,
    [dir='rtl'] .mx-lg-auto {
        margin-right: auto !important;
    }
}

@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important;
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important;
    }

    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }

    [dir='rtl'] .mr-xl-0,
    [dir='rtl'] .mx-xl-0 {
        margin-left: 0 !important;
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important;
    }

    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }

    [dir='rtl'] .ml-xl-0,
    [dir='rtl'] .mx-xl-0 {
        margin-right: 0 !important;
    }

    .m-xl-1 {
        margin: 0.25rem !important;
    }

    .mt-xl-1,
    .my-xl-1 {
        margin-top: 0.25rem !important;
    }

    .mr-xl-1,
    .mx-xl-1 {
        margin-right: 0.25rem !important;
    }

    [dir='rtl'] .mr-xl-1,
    [dir='rtl'] .mx-xl-1 {
        margin-left: 0.25rem !important;
    }

    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-xl-1,
    .mx-xl-1 {
        margin-left: 0.25rem !important;
    }

    [dir='rtl'] .ml-xl-1,
    [dir='rtl'] .mx-xl-1 {
        margin-right: 0.25rem !important;
    }

    .m-xl-2 {
        margin: 0.5rem !important;
    }

    .mt-xl-2,
    .my-xl-2 {
        margin-top: 0.5rem !important;
    }

    .mr-xl-2,
    .mx-xl-2 {
        margin-right: 0.5rem !important;
    }

    [dir='rtl'] .mr-xl-2,
    [dir='rtl'] .mx-xl-2 {
        margin-left: 0.5rem !important;
    }

    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-xl-2,
    .mx-xl-2 {
        margin-left: 0.5rem !important;
    }

    [dir='rtl'] .ml-xl-2,
    [dir='rtl'] .mx-xl-2 {
        margin-right: 0.5rem !important;
    }

    .m-xl-3 {
        margin: 1rem !important;
    }

    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important;
    }

    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important;
    }

    [dir='rtl'] .mr-xl-3,
    [dir='rtl'] .mx-xl-3 {
        margin-left: 1rem !important;
    }

    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important;
    }

    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important;
    }

    [dir='rtl'] .ml-xl-3,
    [dir='rtl'] .mx-xl-3 {
        margin-right: 1rem !important;
    }

    .m-xl-4 {
        margin: 1.5rem !important;
    }

    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important;
    }

    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important;
    }

    [dir='rtl'] .mr-xl-4,
    [dir='rtl'] .mx-xl-4 {
        margin-left: 1.5rem !important;
    }

    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important;
    }

    [dir='rtl'] .ml-xl-4,
    [dir='rtl'] .mx-xl-4 {
        margin-right: 1.5rem !important;
    }

    .m-xl-5 {
        margin: 3rem !important;
    }

    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important;
    }

    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important;
    }

    [dir='rtl'] .mr-xl-5,
    [dir='rtl'] .mx-xl-5 {
        margin-left: 3rem !important;
    }

    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important;
    }

    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important;
    }

    [dir='rtl'] .ml-xl-5,
    [dir='rtl'] .mx-xl-5 {
        margin-right: 3rem !important;
    }

    .m-xl-4pt {
        margin: 0.25rem !important;
    }

    .mt-xl-4pt,
    .my-xl-4pt {
        margin-top: 0.25rem !important;
    }

    .mr-xl-4pt,
    .mx-xl-4pt {
        margin-right: 0.25rem !important;
    }

    [dir='rtl'] .mr-xl-4pt,
    [dir='rtl'] .mx-xl-4pt {
        margin-left: 0.25rem !important;
    }

    .mb-xl-4pt,
    .my-xl-4pt {
        margin-bottom: 0.25rem !important;
    }

    .ml-xl-4pt,
    .mx-xl-4pt {
        margin-left: 0.25rem !important;
    }

    [dir='rtl'] .ml-xl-4pt,
    [dir='rtl'] .mx-xl-4pt {
        margin-right: 0.25rem !important;
    }

    .m-xl-8pt {
        margin: 0.5rem !important;
    }

    .mt-xl-8pt,
    .my-xl-8pt {
        margin-top: 0.5rem !important;
    }

    .mr-xl-8pt,
    .mx-xl-8pt {
        margin-right: 0.5rem !important;
    }

    [dir='rtl'] .mr-xl-8pt,
    [dir='rtl'] .mx-xl-8pt {
        margin-left: 0.5rem !important;
    }

    .mb-xl-8pt,
    .my-xl-8pt {
        margin-bottom: 0.5rem !important;
    }

    .ml-xl-8pt,
    .mx-xl-8pt {
        margin-left: 0.5rem !important;
    }

    [dir='rtl'] .ml-xl-8pt,
    [dir='rtl'] .mx-xl-8pt {
        margin-right: 0.5rem !important;
    }

    .m-xl-12pt {
        margin: 0.75rem !important;
    }

    .mt-xl-12pt,
    .my-xl-12pt {
        margin-top: 0.75rem !important;
    }

    .mr-xl-12pt,
    .mx-xl-12pt {
        margin-right: 0.75rem !important;
    }

    [dir='rtl'] .mr-xl-12pt,
    [dir='rtl'] .mx-xl-12pt {
        margin-left: 0.75rem !important;
    }

    .mb-xl-12pt,
    .my-xl-12pt {
        margin-bottom: 0.75rem !important;
    }

    .ml-xl-12pt,
    .mx-xl-12pt {
        margin-left: 0.75rem !important;
    }

    [dir='rtl'] .ml-xl-12pt,
    [dir='rtl'] .mx-xl-12pt {
        margin-right: 0.75rem !important;
    }

    .m-xl-16pt {
        margin: 1rem !important;
    }

    .mt-xl-16pt,
    .my-xl-16pt {
        margin-top: 1rem !important;
    }

    .mr-xl-16pt,
    .mx-xl-16pt {
        margin-right: 1rem !important;
    }

    [dir='rtl'] .mr-xl-16pt,
    [dir='rtl'] .mx-xl-16pt {
        margin-left: 1rem !important;
    }

    .mb-xl-16pt,
    .my-xl-16pt {
        margin-bottom: 1rem !important;
    }

    .ml-xl-16pt,
    .mx-xl-16pt {
        margin-left: 1rem !important;
    }

    [dir='rtl'] .ml-xl-16pt,
    [dir='rtl'] .mx-xl-16pt {
        margin-right: 1rem !important;
    }

    .m-xl-20pt {
        margin: 1.25rem !important;
    }

    .mt-xl-20pt,
    .my-xl-20pt {
        margin-top: 1.25rem !important;
    }

    .mr-xl-20pt,
    .mx-xl-20pt {
        margin-right: 1.25rem !important;
    }

    [dir='rtl'] .mr-xl-20pt,
    [dir='rtl'] .mx-xl-20pt {
        margin-left: 1.25rem !important;
    }

    .mb-xl-20pt,
    .my-xl-20pt {
        margin-bottom: 1.25rem !important;
    }

    .ml-xl-20pt,
    .mx-xl-20pt {
        margin-left: 1.25rem !important;
    }

    [dir='rtl'] .ml-xl-20pt,
    [dir='rtl'] .mx-xl-20pt {
        margin-right: 1.25rem !important;
    }

    .m-xl-24pt {
        margin: 1.5rem !important;
    }

    .mt-xl-24pt,
    .my-xl-24pt {
        margin-top: 1.5rem !important;
    }

    .mr-xl-24pt,
    .mx-xl-24pt {
        margin-right: 1.5rem !important;
    }

    [dir='rtl'] .mr-xl-24pt,
    [dir='rtl'] .mx-xl-24pt {
        margin-left: 1.5rem !important;
    }

    .mb-xl-24pt,
    .my-xl-24pt {
        margin-bottom: 1.5rem !important;
    }

    .ml-xl-24pt,
    .mx-xl-24pt {
        margin-left: 1.5rem !important;
    }

    [dir='rtl'] .ml-xl-24pt,
    [dir='rtl'] .mx-xl-24pt {
        margin-right: 1.5rem !important;
    }

    .m-xl-32pt {
        margin: 2rem !important;
    }

    .mt-xl-32pt,
    .my-xl-32pt {
        margin-top: 2rem !important;
    }

    .mr-xl-32pt,
    .mx-xl-32pt {
        margin-right: 2rem !important;
    }

    [dir='rtl'] .mr-xl-32pt,
    [dir='rtl'] .mx-xl-32pt {
        margin-left: 2rem !important;
    }

    .mb-xl-32pt,
    .my-xl-32pt {
        margin-bottom: 2rem !important;
    }

    .ml-xl-32pt,
    .mx-xl-32pt {
        margin-left: 2rem !important;
    }

    [dir='rtl'] .ml-xl-32pt,
    [dir='rtl'] .mx-xl-32pt {
        margin-right: 2rem !important;
    }

    .m-xl-40pt {
        margin: 2.5rem !important;
    }

    .mt-xl-40pt,
    .my-xl-40pt {
        margin-top: 2.5rem !important;
    }

    .mr-xl-40pt,
    .mx-xl-40pt {
        margin-right: 2.5rem !important;
    }

    [dir='rtl'] .mr-xl-40pt,
    [dir='rtl'] .mx-xl-40pt {
        margin-left: 2.5rem !important;
    }

    .mb-xl-40pt,
    .my-xl-40pt {
        margin-bottom: 2.5rem !important;
    }

    .ml-xl-40pt,
    .mx-xl-40pt {
        margin-left: 2.5rem !important;
    }

    [dir='rtl'] .ml-xl-40pt,
    [dir='rtl'] .mx-xl-40pt {
        margin-right: 2.5rem !important;
    }

    .m-xl-44pt {
        margin: 2.75rem !important;
    }

    .mt-xl-44pt,
    .my-xl-44pt {
        margin-top: 2.75rem !important;
    }

    .mr-xl-44pt,
    .mx-xl-44pt {
        margin-right: 2.75rem !important;
    }

    [dir='rtl'] .mr-xl-44pt,
    [dir='rtl'] .mx-xl-44pt {
        margin-left: 2.75rem !important;
    }

    .mb-xl-44pt,
    .my-xl-44pt {
        margin-bottom: 2.75rem !important;
    }

    .ml-xl-44pt,
    .mx-xl-44pt {
        margin-left: 2.75rem !important;
    }

    [dir='rtl'] .ml-xl-44pt,
    [dir='rtl'] .mx-xl-44pt {
        margin-right: 2.75rem !important;
    }

    .m-xl-48pt {
        margin: 3rem !important;
    }

    .mt-xl-48pt,
    .my-xl-48pt {
        margin-top: 3rem !important;
    }

    .mr-xl-48pt,
    .mx-xl-48pt {
        margin-right: 3rem !important;
    }

    [dir='rtl'] .mr-xl-48pt,
    [dir='rtl'] .mx-xl-48pt {
        margin-left: 3rem !important;
    }

    .mb-xl-48pt,
    .my-xl-48pt {
        margin-bottom: 3rem !important;
    }

    .ml-xl-48pt,
    .mx-xl-48pt {
        margin-left: 3rem !important;
    }

    [dir='rtl'] .ml-xl-48pt,
    [dir='rtl'] .mx-xl-48pt {
        margin-right: 3rem !important;
    }

    .m-xl-64pt {
        margin: 4rem !important;
    }

    .mt-xl-64pt,
    .my-xl-64pt {
        margin-top: 4rem !important;
    }

    .mr-xl-64pt,
    .mx-xl-64pt {
        margin-right: 4rem !important;
    }

    [dir='rtl'] .mr-xl-64pt,
    [dir='rtl'] .mx-xl-64pt {
        margin-left: 4rem !important;
    }

    .mb-xl-64pt,
    .my-xl-64pt {
        margin-bottom: 4rem !important;
    }

    .ml-xl-64pt,
    .mx-xl-64pt {
        margin-left: 4rem !important;
    }

    [dir='rtl'] .ml-xl-64pt,
    [dir='rtl'] .mx-xl-64pt {
        margin-right: 4rem !important;
    }

    .m-xl-112pt {
        margin: 7rem !important;
    }

    .mt-xl-112pt,
    .my-xl-112pt {
        margin-top: 7rem !important;
    }

    .mr-xl-112pt,
    .mx-xl-112pt {
        margin-right: 7rem !important;
    }

    [dir='rtl'] .mr-xl-112pt,
    [dir='rtl'] .mx-xl-112pt {
        margin-left: 7rem !important;
    }

    .mb-xl-112pt,
    .my-xl-112pt {
        margin-bottom: 7rem !important;
    }

    .ml-xl-112pt,
    .mx-xl-112pt {
        margin-left: 7rem !important;
    }

    [dir='rtl'] .ml-xl-112pt,
    [dir='rtl'] .mx-xl-112pt {
        margin-right: 7rem !important;
    }

    .m-xl-128pt {
        margin: 8rem !important;
    }

    .mt-xl-128pt,
    .my-xl-128pt {
        margin-top: 8rem !important;
    }

    .mr-xl-128pt,
    .mx-xl-128pt {
        margin-right: 8rem !important;
    }

    [dir='rtl'] .mr-xl-128pt,
    [dir='rtl'] .mx-xl-128pt {
        margin-left: 8rem !important;
    }

    .mb-xl-128pt,
    .my-xl-128pt {
        margin-bottom: 8rem !important;
    }

    .ml-xl-128pt,
    .mx-xl-128pt {
        margin-left: 8rem !important;
    }

    [dir='rtl'] .ml-xl-128pt,
    [dir='rtl'] .mx-xl-128pt {
        margin-right: 8rem !important;
    }

    .p-xl-0 {
        padding: 0 !important;
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important;
    }

    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }

    [dir='rtl'] .pr-xl-0,
    [dir='rtl'] .px-xl-0 {
        padding-left: 0 !important;
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important;
    }

    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }

    [dir='rtl'] .pl-xl-0,
    [dir='rtl'] .px-xl-0 {
        padding-right: 0 !important;
    }

    .p-xl-1 {
        padding: 0.25rem !important;
    }

    .pt-xl-1,
    .py-xl-1 {
        padding-top: 0.25rem !important;
    }

    .pr-xl-1,
    .px-xl-1 {
        padding-right: 0.25rem !important;
    }

    [dir='rtl'] .pr-xl-1,
    [dir='rtl'] .px-xl-1 {
        padding-left: 0.25rem !important;
    }

    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-xl-1,
    .px-xl-1 {
        padding-left: 0.25rem !important;
    }

    [dir='rtl'] .pl-xl-1,
    [dir='rtl'] .px-xl-1 {
        padding-right: 0.25rem !important;
    }

    .p-xl-2 {
        padding: 0.5rem !important;
    }

    .pt-xl-2,
    .py-xl-2 {
        padding-top: 0.5rem !important;
    }

    .pr-xl-2,
    .px-xl-2 {
        padding-right: 0.5rem !important;
    }

    [dir='rtl'] .pr-xl-2,
    [dir='rtl'] .px-xl-2 {
        padding-left: 0.5rem !important;
    }

    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-xl-2,
    .px-xl-2 {
        padding-left: 0.5rem !important;
    }

    [dir='rtl'] .pl-xl-2,
    [dir='rtl'] .px-xl-2 {
        padding-right: 0.5rem !important;
    }

    .p-xl-3 {
        padding: 1rem !important;
    }

    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important;
    }

    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important;
    }

    [dir='rtl'] .pr-xl-3,
    [dir='rtl'] .px-xl-3 {
        padding-left: 1rem !important;
    }

    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important;
    }

    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important;
    }

    [dir='rtl'] .pl-xl-3,
    [dir='rtl'] .px-xl-3 {
        padding-right: 1rem !important;
    }

    .p-xl-4 {
        padding: 1.5rem !important;
    }

    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important;
    }

    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important;
    }

    [dir='rtl'] .pr-xl-4,
    [dir='rtl'] .px-xl-4 {
        padding-left: 1.5rem !important;
    }

    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important;
    }

    [dir='rtl'] .pl-xl-4,
    [dir='rtl'] .px-xl-4 {
        padding-right: 1.5rem !important;
    }

    .p-xl-5 {
        padding: 3rem !important;
    }

    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important;
    }

    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important;
    }

    [dir='rtl'] .pr-xl-5,
    [dir='rtl'] .px-xl-5 {
        padding-left: 3rem !important;
    }

    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important;
    }

    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important;
    }

    [dir='rtl'] .pl-xl-5,
    [dir='rtl'] .px-xl-5 {
        padding-right: 3rem !important;
    }

    .p-xl-4pt {
        padding: 0.25rem !important;
    }

    .pt-xl-4pt,
    .py-xl-4pt {
        padding-top: 0.25rem !important;
    }

    .pr-xl-4pt,
    .px-xl-4pt {
        padding-right: 0.25rem !important;
    }

    [dir='rtl'] .pr-xl-4pt,
    [dir='rtl'] .px-xl-4pt {
        padding-left: 0.25rem !important;
    }

    .pb-xl-4pt,
    .py-xl-4pt {
        padding-bottom: 0.25rem !important;
    }

    .pl-xl-4pt,
    .px-xl-4pt {
        padding-left: 0.25rem !important;
    }

    [dir='rtl'] .pl-xl-4pt,
    [dir='rtl'] .px-xl-4pt {
        padding-right: 0.25rem !important;
    }

    .p-xl-8pt {
        padding: 0.5rem !important;
    }

    .pt-xl-8pt,
    .py-xl-8pt {
        padding-top: 0.5rem !important;
    }

    .pr-xl-8pt,
    .px-xl-8pt {
        padding-right: 0.5rem !important;
    }

    [dir='rtl'] .pr-xl-8pt,
    [dir='rtl'] .px-xl-8pt {
        padding-left: 0.5rem !important;
    }

    .pb-xl-8pt,
    .py-xl-8pt {
        padding-bottom: 0.5rem !important;
    }

    .pl-xl-8pt,
    .px-xl-8pt {
        padding-left: 0.5rem !important;
    }

    [dir='rtl'] .pl-xl-8pt,
    [dir='rtl'] .px-xl-8pt {
        padding-right: 0.5rem !important;
    }

    .p-xl-12pt {
        padding: 0.75rem !important;
    }

    .pt-xl-12pt,
    .py-xl-12pt {
        padding-top: 0.75rem !important;
    }

    .pr-xl-12pt,
    .px-xl-12pt {
        padding-right: 0.75rem !important;
    }

    [dir='rtl'] .pr-xl-12pt,
    [dir='rtl'] .px-xl-12pt {
        padding-left: 0.75rem !important;
    }

    .pb-xl-12pt,
    .py-xl-12pt {
        padding-bottom: 0.75rem !important;
    }

    .pl-xl-12pt,
    .px-xl-12pt {
        padding-left: 0.75rem !important;
    }

    [dir='rtl'] .pl-xl-12pt,
    [dir='rtl'] .px-xl-12pt {
        padding-right: 0.75rem !important;
    }

    .p-xl-16pt {
        padding: 1rem !important;
    }

    .pt-xl-16pt,
    .py-xl-16pt {
        padding-top: 1rem !important;
    }

    .pr-xl-16pt,
    .px-xl-16pt {
        padding-right: 1rem !important;
    }

    [dir='rtl'] .pr-xl-16pt,
    [dir='rtl'] .px-xl-16pt {
        padding-left: 1rem !important;
    }

    .pb-xl-16pt,
    .py-xl-16pt {
        padding-bottom: 1rem !important;
    }

    .pl-xl-16pt,
    .px-xl-16pt {
        padding-left: 1rem !important;
    }

    [dir='rtl'] .pl-xl-16pt,
    [dir='rtl'] .px-xl-16pt {
        padding-right: 1rem !important;
    }

    .p-xl-20pt {
        padding: 1.25rem !important;
    }

    .pt-xl-20pt,
    .py-xl-20pt {
        padding-top: 1.25rem !important;
    }

    .pr-xl-20pt,
    .px-xl-20pt {
        padding-right: 1.25rem !important;
    }

    [dir='rtl'] .pr-xl-20pt,
    [dir='rtl'] .px-xl-20pt {
        padding-left: 1.25rem !important;
    }

    .pb-xl-20pt,
    .py-xl-20pt {
        padding-bottom: 1.25rem !important;
    }

    .pl-xl-20pt,
    .px-xl-20pt {
        padding-left: 1.25rem !important;
    }

    [dir='rtl'] .pl-xl-20pt,
    [dir='rtl'] .px-xl-20pt {
        padding-right: 1.25rem !important;
    }

    .p-xl-24pt {
        padding: 1.5rem !important;
    }

    .pt-xl-24pt,
    .py-xl-24pt {
        padding-top: 1.5rem !important;
    }

    .pr-xl-24pt,
    .px-xl-24pt {
        padding-right: 1.5rem !important;
    }

    [dir='rtl'] .pr-xl-24pt,
    [dir='rtl'] .px-xl-24pt {
        padding-left: 1.5rem !important;
    }

    .pb-xl-24pt,
    .py-xl-24pt {
        padding-bottom: 1.5rem !important;
    }

    .pl-xl-24pt,
    .px-xl-24pt {
        padding-left: 1.5rem !important;
    }

    [dir='rtl'] .pl-xl-24pt,
    [dir='rtl'] .px-xl-24pt {
        padding-right: 1.5rem !important;
    }

    .p-xl-32pt {
        padding: 2rem !important;
    }

    .pt-xl-32pt,
    .py-xl-32pt {
        padding-top: 2rem !important;
    }

    .pr-xl-32pt,
    .px-xl-32pt {
        padding-right: 2rem !important;
    }

    [dir='rtl'] .pr-xl-32pt,
    [dir='rtl'] .px-xl-32pt {
        padding-left: 2rem !important;
    }

    .pb-xl-32pt,
    .py-xl-32pt {
        padding-bottom: 2rem !important;
    }

    .pl-xl-32pt,
    .px-xl-32pt {
        padding-left: 2rem !important;
    }

    [dir='rtl'] .pl-xl-32pt,
    [dir='rtl'] .px-xl-32pt {
        padding-right: 2rem !important;
    }

    .p-xl-40pt {
        padding: 2.5rem !important;
    }

    .pt-xl-40pt,
    .py-xl-40pt {
        padding-top: 2.5rem !important;
    }

    .pr-xl-40pt,
    .px-xl-40pt {
        padding-right: 2.5rem !important;
    }

    [dir='rtl'] .pr-xl-40pt,
    [dir='rtl'] .px-xl-40pt {
        padding-left: 2.5rem !important;
    }

    .pb-xl-40pt,
    .py-xl-40pt {
        padding-bottom: 2.5rem !important;
    }

    .pl-xl-40pt,
    .px-xl-40pt {
        padding-left: 2.5rem !important;
    }

    [dir='rtl'] .pl-xl-40pt,
    [dir='rtl'] .px-xl-40pt {
        padding-right: 2.5rem !important;
    }

    .p-xl-44pt {
        padding: 2.75rem !important;
    }

    .pt-xl-44pt,
    .py-xl-44pt {
        padding-top: 2.75rem !important;
    }

    .pr-xl-44pt,
    .px-xl-44pt {
        padding-right: 2.75rem !important;
    }

    [dir='rtl'] .pr-xl-44pt,
    [dir='rtl'] .px-xl-44pt {
        padding-left: 2.75rem !important;
    }

    .pb-xl-44pt,
    .py-xl-44pt {
        padding-bottom: 2.75rem !important;
    }

    .pl-xl-44pt,
    .px-xl-44pt {
        padding-left: 2.75rem !important;
    }

    [dir='rtl'] .pl-xl-44pt,
    [dir='rtl'] .px-xl-44pt {
        padding-right: 2.75rem !important;
    }

    .p-xl-48pt {
        padding: 3rem !important;
    }

    .pt-xl-48pt,
    .py-xl-48pt {
        padding-top: 3rem !important;
    }

    .pr-xl-48pt,
    .px-xl-48pt {
        padding-right: 3rem !important;
    }

    [dir='rtl'] .pr-xl-48pt,
    [dir='rtl'] .px-xl-48pt {
        padding-left: 3rem !important;
    }

    .pb-xl-48pt,
    .py-xl-48pt {
        padding-bottom: 3rem !important;
    }

    .pl-xl-48pt,
    .px-xl-48pt {
        padding-left: 3rem !important;
    }

    [dir='rtl'] .pl-xl-48pt,
    [dir='rtl'] .px-xl-48pt {
        padding-right: 3rem !important;
    }

    .p-xl-64pt {
        padding: 4rem !important;
    }

    .pt-xl-64pt,
    .py-xl-64pt {
        padding-top: 4rem !important;
    }

    .pr-xl-64pt,
    .px-xl-64pt {
        padding-right: 4rem !important;
    }

    [dir='rtl'] .pr-xl-64pt,
    [dir='rtl'] .px-xl-64pt {
        padding-left: 4rem !important;
    }

    .pb-xl-64pt,
    .py-xl-64pt {
        padding-bottom: 4rem !important;
    }

    .pl-xl-64pt,
    .px-xl-64pt {
        padding-left: 4rem !important;
    }

    [dir='rtl'] .pl-xl-64pt,
    [dir='rtl'] .px-xl-64pt {
        padding-right: 4rem !important;
    }

    .p-xl-112pt {
        padding: 7rem !important;
    }

    .pt-xl-112pt,
    .py-xl-112pt {
        padding-top: 7rem !important;
    }

    .pr-xl-112pt,
    .px-xl-112pt {
        padding-right: 7rem !important;
    }

    [dir='rtl'] .pr-xl-112pt,
    [dir='rtl'] .px-xl-112pt {
        padding-left: 7rem !important;
    }

    .pb-xl-112pt,
    .py-xl-112pt {
        padding-bottom: 7rem !important;
    }

    .pl-xl-112pt,
    .px-xl-112pt {
        padding-left: 7rem !important;
    }

    [dir='rtl'] .pl-xl-112pt,
    [dir='rtl'] .px-xl-112pt {
        padding-right: 7rem !important;
    }

    .p-xl-128pt {
        padding: 8rem !important;
    }

    .pt-xl-128pt,
    .py-xl-128pt {
        padding-top: 8rem !important;
    }

    .pr-xl-128pt,
    .px-xl-128pt {
        padding-right: 8rem !important;
    }

    [dir='rtl'] .pr-xl-128pt,
    [dir='rtl'] .px-xl-128pt {
        padding-left: 8rem !important;
    }

    .pb-xl-128pt,
    .py-xl-128pt {
        padding-bottom: 8rem !important;
    }

    .pl-xl-128pt,
    .px-xl-128pt {
        padding-left: 8rem !important;
    }

    [dir='rtl'] .pl-xl-128pt,
    [dir='rtl'] .px-xl-128pt {
        padding-right: 8rem !important;
    }

    .m-xl-n1 {
        margin: -0.25rem !important;
    }

    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -0.25rem !important;
    }

    [dir='rtl'] .mr-xl-n1,
    [dir='rtl'] .mx-xl-n1 {
        margin-left: -0.25rem !important;
    }

    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -0.25rem !important;
    }

    [dir='rtl'] .ml-xl-n1,
    [dir='rtl'] .mx-xl-n1 {
        margin-right: -0.25rem !important;
    }

    .m-xl-n2 {
        margin: -0.5rem !important;
    }

    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -0.5rem !important;
    }

    [dir='rtl'] .mr-xl-n2,
    [dir='rtl'] .mx-xl-n2 {
        margin-left: -0.5rem !important;
    }

    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -0.5rem !important;
    }

    [dir='rtl'] .ml-xl-n2,
    [dir='rtl'] .mx-xl-n2 {
        margin-right: -0.5rem !important;
    }

    .m-xl-n3 {
        margin: -1rem !important;
    }

    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem !important;
    }

    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem !important;
    }

    [dir='rtl'] .mr-xl-n3,
    [dir='rtl'] .mx-xl-n3 {
        margin-left: -1rem !important;
    }

    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem !important;
    }

    [dir='rtl'] .ml-xl-n3,
    [dir='rtl'] .mx-xl-n3 {
        margin-right: -1rem !important;
    }

    .m-xl-n4 {
        margin: -1.5rem !important;
    }

    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.5rem !important;
    }

    [dir='rtl'] .mr-xl-n4,
    [dir='rtl'] .mx-xl-n4 {
        margin-left: -1.5rem !important;
    }

    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.5rem !important;
    }

    [dir='rtl'] .ml-xl-n4,
    [dir='rtl'] .mx-xl-n4 {
        margin-right: -1.5rem !important;
    }

    .m-xl-n5 {
        margin: -3rem !important;
    }

    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -3rem !important;
    }

    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -3rem !important;
    }

    [dir='rtl'] .mr-xl-n5,
    [dir='rtl'] .mx-xl-n5 {
        margin-left: -3rem !important;
    }

    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -3rem !important;
    }

    [dir='rtl'] .ml-xl-n5,
    [dir='rtl'] .mx-xl-n5 {
        margin-right: -3rem !important;
    }

    .m-xl-n4pt {
        margin: -0.25rem !important;
    }

    .mt-xl-n4pt,
    .my-xl-n4pt {
        margin-top: -0.25rem !important;
    }

    .mr-xl-n4pt,
    .mx-xl-n4pt {
        margin-right: -0.25rem !important;
    }

    [dir='rtl'] .mr-xl-n4pt,
    [dir='rtl'] .mx-xl-n4pt {
        margin-left: -0.25rem !important;
    }

    .mb-xl-n4pt,
    .my-xl-n4pt {
        margin-bottom: -0.25rem !important;
    }

    .ml-xl-n4pt,
    .mx-xl-n4pt {
        margin-left: -0.25rem !important;
    }

    [dir='rtl'] .ml-xl-n4pt,
    [dir='rtl'] .mx-xl-n4pt {
        margin-right: -0.25rem !important;
    }

    .m-xl-n8pt {
        margin: -0.5rem !important;
    }

    .mt-xl-n8pt,
    .my-xl-n8pt {
        margin-top: -0.5rem !important;
    }

    .mr-xl-n8pt,
    .mx-xl-n8pt {
        margin-right: -0.5rem !important;
    }

    [dir='rtl'] .mr-xl-n8pt,
    [dir='rtl'] .mx-xl-n8pt {
        margin-left: -0.5rem !important;
    }

    .mb-xl-n8pt,
    .my-xl-n8pt {
        margin-bottom: -0.5rem !important;
    }

    .ml-xl-n8pt,
    .mx-xl-n8pt {
        margin-left: -0.5rem !important;
    }

    [dir='rtl'] .ml-xl-n8pt,
    [dir='rtl'] .mx-xl-n8pt {
        margin-right: -0.5rem !important;
    }

    .m-xl-n12pt {
        margin: -0.75rem !important;
    }

    .mt-xl-n12pt,
    .my-xl-n12pt {
        margin-top: -0.75rem !important;
    }

    .mr-xl-n12pt,
    .mx-xl-n12pt {
        margin-right: -0.75rem !important;
    }

    [dir='rtl'] .mr-xl-n12pt,
    [dir='rtl'] .mx-xl-n12pt {
        margin-left: -0.75rem !important;
    }

    .mb-xl-n12pt,
    .my-xl-n12pt {
        margin-bottom: -0.75rem !important;
    }

    .ml-xl-n12pt,
    .mx-xl-n12pt {
        margin-left: -0.75rem !important;
    }

    [dir='rtl'] .ml-xl-n12pt,
    [dir='rtl'] .mx-xl-n12pt {
        margin-right: -0.75rem !important;
    }

    .m-xl-n16pt {
        margin: -1rem !important;
    }

    .mt-xl-n16pt,
    .my-xl-n16pt {
        margin-top: -1rem !important;
    }

    .mr-xl-n16pt,
    .mx-xl-n16pt {
        margin-right: -1rem !important;
    }

    [dir='rtl'] .mr-xl-n16pt,
    [dir='rtl'] .mx-xl-n16pt {
        margin-left: -1rem !important;
    }

    .mb-xl-n16pt,
    .my-xl-n16pt {
        margin-bottom: -1rem !important;
    }

    .ml-xl-n16pt,
    .mx-xl-n16pt {
        margin-left: -1rem !important;
    }

    [dir='rtl'] .ml-xl-n16pt,
    [dir='rtl'] .mx-xl-n16pt {
        margin-right: -1rem !important;
    }

    .m-xl-n20pt {
        margin: -1.25rem !important;
    }

    .mt-xl-n20pt,
    .my-xl-n20pt {
        margin-top: -1.25rem !important;
    }

    .mr-xl-n20pt,
    .mx-xl-n20pt {
        margin-right: -1.25rem !important;
    }

    [dir='rtl'] .mr-xl-n20pt,
    [dir='rtl'] .mx-xl-n20pt {
        margin-left: -1.25rem !important;
    }

    .mb-xl-n20pt,
    .my-xl-n20pt {
        margin-bottom: -1.25rem !important;
    }

    .ml-xl-n20pt,
    .mx-xl-n20pt {
        margin-left: -1.25rem !important;
    }

    [dir='rtl'] .ml-xl-n20pt,
    [dir='rtl'] .mx-xl-n20pt {
        margin-right: -1.25rem !important;
    }

    .m-xl-n24pt {
        margin: -1.5rem !important;
    }

    .mt-xl-n24pt,
    .my-xl-n24pt {
        margin-top: -1.5rem !important;
    }

    .mr-xl-n24pt,
    .mx-xl-n24pt {
        margin-right: -1.5rem !important;
    }

    [dir='rtl'] .mr-xl-n24pt,
    [dir='rtl'] .mx-xl-n24pt {
        margin-left: -1.5rem !important;
    }

    .mb-xl-n24pt,
    .my-xl-n24pt {
        margin-bottom: -1.5rem !important;
    }

    .ml-xl-n24pt,
    .mx-xl-n24pt {
        margin-left: -1.5rem !important;
    }

    [dir='rtl'] .ml-xl-n24pt,
    [dir='rtl'] .mx-xl-n24pt {
        margin-right: -1.5rem !important;
    }

    .m-xl-n32pt {
        margin: -2rem !important;
    }

    .mt-xl-n32pt,
    .my-xl-n32pt {
        margin-top: -2rem !important;
    }

    .mr-xl-n32pt,
    .mx-xl-n32pt {
        margin-right: -2rem !important;
    }

    [dir='rtl'] .mr-xl-n32pt,
    [dir='rtl'] .mx-xl-n32pt {
        margin-left: -2rem !important;
    }

    .mb-xl-n32pt,
    .my-xl-n32pt {
        margin-bottom: -2rem !important;
    }

    .ml-xl-n32pt,
    .mx-xl-n32pt {
        margin-left: -2rem !important;
    }

    [dir='rtl'] .ml-xl-n32pt,
    [dir='rtl'] .mx-xl-n32pt {
        margin-right: -2rem !important;
    }

    .m-xl-n40pt {
        margin: -2.5rem !important;
    }

    .mt-xl-n40pt,
    .my-xl-n40pt {
        margin-top: -2.5rem !important;
    }

    .mr-xl-n40pt,
    .mx-xl-n40pt {
        margin-right: -2.5rem !important;
    }

    [dir='rtl'] .mr-xl-n40pt,
    [dir='rtl'] .mx-xl-n40pt {
        margin-left: -2.5rem !important;
    }

    .mb-xl-n40pt,
    .my-xl-n40pt {
        margin-bottom: -2.5rem !important;
    }

    .ml-xl-n40pt,
    .mx-xl-n40pt {
        margin-left: -2.5rem !important;
    }

    [dir='rtl'] .ml-xl-n40pt,
    [dir='rtl'] .mx-xl-n40pt {
        margin-right: -2.5rem !important;
    }

    .m-xl-n44pt {
        margin: -2.75rem !important;
    }

    .mt-xl-n44pt,
    .my-xl-n44pt {
        margin-top: -2.75rem !important;
    }

    .mr-xl-n44pt,
    .mx-xl-n44pt {
        margin-right: -2.75rem !important;
    }

    [dir='rtl'] .mr-xl-n44pt,
    [dir='rtl'] .mx-xl-n44pt {
        margin-left: -2.75rem !important;
    }

    .mb-xl-n44pt,
    .my-xl-n44pt {
        margin-bottom: -2.75rem !important;
    }

    .ml-xl-n44pt,
    .mx-xl-n44pt {
        margin-left: -2.75rem !important;
    }

    [dir='rtl'] .ml-xl-n44pt,
    [dir='rtl'] .mx-xl-n44pt {
        margin-right: -2.75rem !important;
    }

    .m-xl-n48pt {
        margin: -3rem !important;
    }

    .mt-xl-n48pt,
    .my-xl-n48pt {
        margin-top: -3rem !important;
    }

    .mr-xl-n48pt,
    .mx-xl-n48pt {
        margin-right: -3rem !important;
    }

    [dir='rtl'] .mr-xl-n48pt,
    [dir='rtl'] .mx-xl-n48pt {
        margin-left: -3rem !important;
    }

    .mb-xl-n48pt,
    .my-xl-n48pt {
        margin-bottom: -3rem !important;
    }

    .ml-xl-n48pt,
    .mx-xl-n48pt {
        margin-left: -3rem !important;
    }

    [dir='rtl'] .ml-xl-n48pt,
    [dir='rtl'] .mx-xl-n48pt {
        margin-right: -3rem !important;
    }

    .m-xl-n64pt {
        margin: -4rem !important;
    }

    .mt-xl-n64pt,
    .my-xl-n64pt {
        margin-top: -4rem !important;
    }

    .mr-xl-n64pt,
    .mx-xl-n64pt {
        margin-right: -4rem !important;
    }

    [dir='rtl'] .mr-xl-n64pt,
    [dir='rtl'] .mx-xl-n64pt {
        margin-left: -4rem !important;
    }

    .mb-xl-n64pt,
    .my-xl-n64pt {
        margin-bottom: -4rem !important;
    }

    .ml-xl-n64pt,
    .mx-xl-n64pt {
        margin-left: -4rem !important;
    }

    [dir='rtl'] .ml-xl-n64pt,
    [dir='rtl'] .mx-xl-n64pt {
        margin-right: -4rem !important;
    }

    .m-xl-n112pt {
        margin: -7rem !important;
    }

    .mt-xl-n112pt,
    .my-xl-n112pt {
        margin-top: -7rem !important;
    }

    .mr-xl-n112pt,
    .mx-xl-n112pt {
        margin-right: -7rem !important;
    }

    [dir='rtl'] .mr-xl-n112pt,
    [dir='rtl'] .mx-xl-n112pt {
        margin-left: -7rem !important;
    }

    .mb-xl-n112pt,
    .my-xl-n112pt {
        margin-bottom: -7rem !important;
    }

    .ml-xl-n112pt,
    .mx-xl-n112pt {
        margin-left: -7rem !important;
    }

    [dir='rtl'] .ml-xl-n112pt,
    [dir='rtl'] .mx-xl-n112pt {
        margin-right: -7rem !important;
    }

    .m-xl-n128pt {
        margin: -8rem !important;
    }

    .mt-xl-n128pt,
    .my-xl-n128pt {
        margin-top: -8rem !important;
    }

    .mr-xl-n128pt,
    .mx-xl-n128pt {
        margin-right: -8rem !important;
    }

    [dir='rtl'] .mr-xl-n128pt,
    [dir='rtl'] .mx-xl-n128pt {
        margin-left: -8rem !important;
    }

    .mb-xl-n128pt,
    .my-xl-n128pt {
        margin-bottom: -8rem !important;
    }

    .ml-xl-n128pt,
    .mx-xl-n128pt {
        margin-left: -8rem !important;
    }

    [dir='rtl'] .ml-xl-n128pt,
    [dir='rtl'] .mx-xl-n128pt {
        margin-right: -8rem !important;
    }

    .m-xl-auto {
        margin: auto !important;
    }

    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important;
    }

    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important;
    }

    [dir='rtl'] .mr-xl-auto,
    [dir='rtl'] .mx-xl-auto {
        margin-left: auto !important;
    }

    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important;
    }

    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important;
    }

    [dir='rtl'] .ml-xl-auto,
    [dir='rtl'] .mx-xl-auto {
        margin-right: auto !important;
    }
}

@media (min-width: 1366px) {
    .m-xxl-0 {
        margin: 0 !important;
    }

    .mt-xxl-0,
    .my-xxl-0 {
        margin-top: 0 !important;
    }

    .mr-xxl-0,
    .mx-xxl-0 {
        margin-right: 0 !important;
    }

    [dir='rtl'] .mr-xxl-0,
    [dir='rtl'] .mx-xxl-0 {
        margin-left: 0 !important;
    }

    .mb-xxl-0,
    .my-xxl-0 {
        margin-bottom: 0 !important;
    }

    .ml-xxl-0,
    .mx-xxl-0 {
        margin-left: 0 !important;
    }

    [dir='rtl'] .ml-xxl-0,
    [dir='rtl'] .mx-xxl-0 {
        margin-right: 0 !important;
    }

    .m-xxl-1 {
        margin: 0.25rem !important;
    }

    .mt-xxl-1,
    .my-xxl-1 {
        margin-top: 0.25rem !important;
    }

    .mr-xxl-1,
    .mx-xxl-1 {
        margin-right: 0.25rem !important;
    }

    [dir='rtl'] .mr-xxl-1,
    [dir='rtl'] .mx-xxl-1 {
        margin-left: 0.25rem !important;
    }

    .mb-xxl-1,
    .my-xxl-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-xxl-1,
    .mx-xxl-1 {
        margin-left: 0.25rem !important;
    }

    [dir='rtl'] .ml-xxl-1,
    [dir='rtl'] .mx-xxl-1 {
        margin-right: 0.25rem !important;
    }

    .m-xxl-2 {
        margin: 0.5rem !important;
    }

    .mt-xxl-2,
    .my-xxl-2 {
        margin-top: 0.5rem !important;
    }

    .mr-xxl-2,
    .mx-xxl-2 {
        margin-right: 0.5rem !important;
    }

    [dir='rtl'] .mr-xxl-2,
    [dir='rtl'] .mx-xxl-2 {
        margin-left: 0.5rem !important;
    }

    .mb-xxl-2,
    .my-xxl-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-xxl-2,
    .mx-xxl-2 {
        margin-left: 0.5rem !important;
    }

    [dir='rtl'] .ml-xxl-2,
    [dir='rtl'] .mx-xxl-2 {
        margin-right: 0.5rem !important;
    }

    .m-xxl-3 {
        margin: 1rem !important;
    }

    .mt-xxl-3,
    .my-xxl-3 {
        margin-top: 1rem !important;
    }

    .mr-xxl-3,
    .mx-xxl-3 {
        margin-right: 1rem !important;
    }

    [dir='rtl'] .mr-xxl-3,
    [dir='rtl'] .mx-xxl-3 {
        margin-left: 1rem !important;
    }

    .mb-xxl-3,
    .my-xxl-3 {
        margin-bottom: 1rem !important;
    }

    .ml-xxl-3,
    .mx-xxl-3 {
        margin-left: 1rem !important;
    }

    [dir='rtl'] .ml-xxl-3,
    [dir='rtl'] .mx-xxl-3 {
        margin-right: 1rem !important;
    }

    .m-xxl-4 {
        margin: 1.5rem !important;
    }

    .mt-xxl-4,
    .my-xxl-4 {
        margin-top: 1.5rem !important;
    }

    .mr-xxl-4,
    .mx-xxl-4 {
        margin-right: 1.5rem !important;
    }

    [dir='rtl'] .mr-xxl-4,
    [dir='rtl'] .mx-xxl-4 {
        margin-left: 1.5rem !important;
    }

    .mb-xxl-4,
    .my-xxl-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-xxl-4,
    .mx-xxl-4 {
        margin-left: 1.5rem !important;
    }

    [dir='rtl'] .ml-xxl-4,
    [dir='rtl'] .mx-xxl-4 {
        margin-right: 1.5rem !important;
    }

    .m-xxl-5 {
        margin: 3rem !important;
    }

    .mt-xxl-5,
    .my-xxl-5 {
        margin-top: 3rem !important;
    }

    .mr-xxl-5,
    .mx-xxl-5 {
        margin-right: 3rem !important;
    }

    [dir='rtl'] .mr-xxl-5,
    [dir='rtl'] .mx-xxl-5 {
        margin-left: 3rem !important;
    }

    .mb-xxl-5,
    .my-xxl-5 {
        margin-bottom: 3rem !important;
    }

    .ml-xxl-5,
    .mx-xxl-5 {
        margin-left: 3rem !important;
    }

    [dir='rtl'] .ml-xxl-5,
    [dir='rtl'] .mx-xxl-5 {
        margin-right: 3rem !important;
    }

    .m-xxl-4pt {
        margin: 0.25rem !important;
    }

    .mt-xxl-4pt,
    .my-xxl-4pt {
        margin-top: 0.25rem !important;
    }

    .mr-xxl-4pt,
    .mx-xxl-4pt {
        margin-right: 0.25rem !important;
    }

    [dir='rtl'] .mr-xxl-4pt,
    [dir='rtl'] .mx-xxl-4pt {
        margin-left: 0.25rem !important;
    }

    .mb-xxl-4pt,
    .my-xxl-4pt {
        margin-bottom: 0.25rem !important;
    }

    .ml-xxl-4pt,
    .mx-xxl-4pt {
        margin-left: 0.25rem !important;
    }

    [dir='rtl'] .ml-xxl-4pt,
    [dir='rtl'] .mx-xxl-4pt {
        margin-right: 0.25rem !important;
    }

    .m-xxl-8pt {
        margin: 0.5rem !important;
    }

    .mt-xxl-8pt,
    .my-xxl-8pt {
        margin-top: 0.5rem !important;
    }

    .mr-xxl-8pt,
    .mx-xxl-8pt {
        margin-right: 0.5rem !important;
    }

    [dir='rtl'] .mr-xxl-8pt,
    [dir='rtl'] .mx-xxl-8pt {
        margin-left: 0.5rem !important;
    }

    .mb-xxl-8pt,
    .my-xxl-8pt {
        margin-bottom: 0.5rem !important;
    }

    .ml-xxl-8pt,
    .mx-xxl-8pt {
        margin-left: 0.5rem !important;
    }

    [dir='rtl'] .ml-xxl-8pt,
    [dir='rtl'] .mx-xxl-8pt {
        margin-right: 0.5rem !important;
    }

    .m-xxl-12pt {
        margin: 0.75rem !important;
    }

    .mt-xxl-12pt,
    .my-xxl-12pt {
        margin-top: 0.75rem !important;
    }

    .mr-xxl-12pt,
    .mx-xxl-12pt {
        margin-right: 0.75rem !important;
    }

    [dir='rtl'] .mr-xxl-12pt,
    [dir='rtl'] .mx-xxl-12pt {
        margin-left: 0.75rem !important;
    }

    .mb-xxl-12pt,
    .my-xxl-12pt {
        margin-bottom: 0.75rem !important;
    }

    .ml-xxl-12pt,
    .mx-xxl-12pt {
        margin-left: 0.75rem !important;
    }

    [dir='rtl'] .ml-xxl-12pt,
    [dir='rtl'] .mx-xxl-12pt {
        margin-right: 0.75rem !important;
    }

    .m-xxl-16pt {
        margin: 1rem !important;
    }

    .mt-xxl-16pt,
    .my-xxl-16pt {
        margin-top: 1rem !important;
    }

    .mr-xxl-16pt,
    .mx-xxl-16pt {
        margin-right: 1rem !important;
    }

    [dir='rtl'] .mr-xxl-16pt,
    [dir='rtl'] .mx-xxl-16pt {
        margin-left: 1rem !important;
    }

    .mb-xxl-16pt,
    .my-xxl-16pt {
        margin-bottom: 1rem !important;
    }

    .ml-xxl-16pt,
    .mx-xxl-16pt {
        margin-left: 1rem !important;
    }

    [dir='rtl'] .ml-xxl-16pt,
    [dir='rtl'] .mx-xxl-16pt {
        margin-right: 1rem !important;
    }

    .m-xxl-20pt {
        margin: 1.25rem !important;
    }

    .mt-xxl-20pt,
    .my-xxl-20pt {
        margin-top: 1.25rem !important;
    }

    .mr-xxl-20pt,
    .mx-xxl-20pt {
        margin-right: 1.25rem !important;
    }

    [dir='rtl'] .mr-xxl-20pt,
    [dir='rtl'] .mx-xxl-20pt {
        margin-left: 1.25rem !important;
    }

    .mb-xxl-20pt,
    .my-xxl-20pt {
        margin-bottom: 1.25rem !important;
    }

    .ml-xxl-20pt,
    .mx-xxl-20pt {
        margin-left: 1.25rem !important;
    }

    [dir='rtl'] .ml-xxl-20pt,
    [dir='rtl'] .mx-xxl-20pt {
        margin-right: 1.25rem !important;
    }

    .m-xxl-24pt {
        margin: 1.5rem !important;
    }

    .mt-xxl-24pt,
    .my-xxl-24pt {
        margin-top: 1.5rem !important;
    }

    .mr-xxl-24pt,
    .mx-xxl-24pt {
        margin-right: 1.5rem !important;
    }

    [dir='rtl'] .mr-xxl-24pt,
    [dir='rtl'] .mx-xxl-24pt {
        margin-left: 1.5rem !important;
    }

    .mb-xxl-24pt,
    .my-xxl-24pt {
        margin-bottom: 1.5rem !important;
    }

    .ml-xxl-24pt,
    .mx-xxl-24pt {
        margin-left: 1.5rem !important;
    }

    [dir='rtl'] .ml-xxl-24pt,
    [dir='rtl'] .mx-xxl-24pt {
        margin-right: 1.5rem !important;
    }

    .m-xxl-32pt {
        margin: 2rem !important;
    }

    .mt-xxl-32pt,
    .my-xxl-32pt {
        margin-top: 2rem !important;
    }

    .mr-xxl-32pt,
    .mx-xxl-32pt {
        margin-right: 2rem !important;
    }

    [dir='rtl'] .mr-xxl-32pt,
    [dir='rtl'] .mx-xxl-32pt {
        margin-left: 2rem !important;
    }

    .mb-xxl-32pt,
    .my-xxl-32pt {
        margin-bottom: 2rem !important;
    }

    .ml-xxl-32pt,
    .mx-xxl-32pt {
        margin-left: 2rem !important;
    }

    [dir='rtl'] .ml-xxl-32pt,
    [dir='rtl'] .mx-xxl-32pt {
        margin-right: 2rem !important;
    }

    .m-xxl-40pt {
        margin: 2.5rem !important;
    }

    .mt-xxl-40pt,
    .my-xxl-40pt {
        margin-top: 2.5rem !important;
    }

    .mr-xxl-40pt,
    .mx-xxl-40pt {
        margin-right: 2.5rem !important;
    }

    [dir='rtl'] .mr-xxl-40pt,
    [dir='rtl'] .mx-xxl-40pt {
        margin-left: 2.5rem !important;
    }

    .mb-xxl-40pt,
    .my-xxl-40pt {
        margin-bottom: 2.5rem !important;
    }

    .ml-xxl-40pt,
    .mx-xxl-40pt {
        margin-left: 2.5rem !important;
    }

    [dir='rtl'] .ml-xxl-40pt,
    [dir='rtl'] .mx-xxl-40pt {
        margin-right: 2.5rem !important;
    }

    .m-xxl-44pt {
        margin: 2.75rem !important;
    }

    .mt-xxl-44pt,
    .my-xxl-44pt {
        margin-top: 2.75rem !important;
    }

    .mr-xxl-44pt,
    .mx-xxl-44pt {
        margin-right: 2.75rem !important;
    }

    [dir='rtl'] .mr-xxl-44pt,
    [dir='rtl'] .mx-xxl-44pt {
        margin-left: 2.75rem !important;
    }

    .mb-xxl-44pt,
    .my-xxl-44pt {
        margin-bottom: 2.75rem !important;
    }

    .ml-xxl-44pt,
    .mx-xxl-44pt {
        margin-left: 2.75rem !important;
    }

    [dir='rtl'] .ml-xxl-44pt,
    [dir='rtl'] .mx-xxl-44pt {
        margin-right: 2.75rem !important;
    }

    .m-xxl-48pt {
        margin: 3rem !important;
    }

    .mt-xxl-48pt,
    .my-xxl-48pt {
        margin-top: 3rem !important;
    }

    .mr-xxl-48pt,
    .mx-xxl-48pt {
        margin-right: 3rem !important;
    }

    [dir='rtl'] .mr-xxl-48pt,
    [dir='rtl'] .mx-xxl-48pt {
        margin-left: 3rem !important;
    }

    .mb-xxl-48pt,
    .my-xxl-48pt {
        margin-bottom: 3rem !important;
    }

    .ml-xxl-48pt,
    .mx-xxl-48pt {
        margin-left: 3rem !important;
    }

    [dir='rtl'] .ml-xxl-48pt,
    [dir='rtl'] .mx-xxl-48pt {
        margin-right: 3rem !important;
    }

    .m-xxl-64pt {
        margin: 4rem !important;
    }

    .mt-xxl-64pt,
    .my-xxl-64pt {
        margin-top: 4rem !important;
    }

    .mr-xxl-64pt,
    .mx-xxl-64pt {
        margin-right: 4rem !important;
    }

    [dir='rtl'] .mr-xxl-64pt,
    [dir='rtl'] .mx-xxl-64pt {
        margin-left: 4rem !important;
    }

    .mb-xxl-64pt,
    .my-xxl-64pt {
        margin-bottom: 4rem !important;
    }

    .ml-xxl-64pt,
    .mx-xxl-64pt {
        margin-left: 4rem !important;
    }

    [dir='rtl'] .ml-xxl-64pt,
    [dir='rtl'] .mx-xxl-64pt {
        margin-right: 4rem !important;
    }

    .m-xxl-112pt {
        margin: 7rem !important;
    }

    .mt-xxl-112pt,
    .my-xxl-112pt {
        margin-top: 7rem !important;
    }

    .mr-xxl-112pt,
    .mx-xxl-112pt {
        margin-right: 7rem !important;
    }

    [dir='rtl'] .mr-xxl-112pt,
    [dir='rtl'] .mx-xxl-112pt {
        margin-left: 7rem !important;
    }

    .mb-xxl-112pt,
    .my-xxl-112pt {
        margin-bottom: 7rem !important;
    }

    .ml-xxl-112pt,
    .mx-xxl-112pt {
        margin-left: 7rem !important;
    }

    [dir='rtl'] .ml-xxl-112pt,
    [dir='rtl'] .mx-xxl-112pt {
        margin-right: 7rem !important;
    }

    .m-xxl-128pt {
        margin: 8rem !important;
    }

    .mt-xxl-128pt,
    .my-xxl-128pt {
        margin-top: 8rem !important;
    }

    .mr-xxl-128pt,
    .mx-xxl-128pt {
        margin-right: 8rem !important;
    }

    [dir='rtl'] .mr-xxl-128pt,
    [dir='rtl'] .mx-xxl-128pt {
        margin-left: 8rem !important;
    }

    .mb-xxl-128pt,
    .my-xxl-128pt {
        margin-bottom: 8rem !important;
    }

    .ml-xxl-128pt,
    .mx-xxl-128pt {
        margin-left: 8rem !important;
    }

    [dir='rtl'] .ml-xxl-128pt,
    [dir='rtl'] .mx-xxl-128pt {
        margin-right: 8rem !important;
    }

    .p-xxl-0 {
        padding: 0 !important;
    }

    .pt-xxl-0,
    .py-xxl-0 {
        padding-top: 0 !important;
    }

    .pr-xxl-0,
    .px-xxl-0 {
        padding-right: 0 !important;
    }

    [dir='rtl'] .pr-xxl-0,
    [dir='rtl'] .px-xxl-0 {
        padding-left: 0 !important;
    }

    .pb-xxl-0,
    .py-xxl-0 {
        padding-bottom: 0 !important;
    }

    .pl-xxl-0,
    .px-xxl-0 {
        padding-left: 0 !important;
    }

    [dir='rtl'] .pl-xxl-0,
    [dir='rtl'] .px-xxl-0 {
        padding-right: 0 !important;
    }

    .p-xxl-1 {
        padding: 0.25rem !important;
    }

    .pt-xxl-1,
    .py-xxl-1 {
        padding-top: 0.25rem !important;
    }

    .pr-xxl-1,
    .px-xxl-1 {
        padding-right: 0.25rem !important;
    }

    [dir='rtl'] .pr-xxl-1,
    [dir='rtl'] .px-xxl-1 {
        padding-left: 0.25rem !important;
    }

    .pb-xxl-1,
    .py-xxl-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-xxl-1,
    .px-xxl-1 {
        padding-left: 0.25rem !important;
    }

    [dir='rtl'] .pl-xxl-1,
    [dir='rtl'] .px-xxl-1 {
        padding-right: 0.25rem !important;
    }

    .p-xxl-2 {
        padding: 0.5rem !important;
    }

    .pt-xxl-2,
    .py-xxl-2 {
        padding-top: 0.5rem !important;
    }

    .pr-xxl-2,
    .px-xxl-2 {
        padding-right: 0.5rem !important;
    }

    [dir='rtl'] .pr-xxl-2,
    [dir='rtl'] .px-xxl-2 {
        padding-left: 0.5rem !important;
    }

    .pb-xxl-2,
    .py-xxl-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-xxl-2,
    .px-xxl-2 {
        padding-left: 0.5rem !important;
    }

    [dir='rtl'] .pl-xxl-2,
    [dir='rtl'] .px-xxl-2 {
        padding-right: 0.5rem !important;
    }

    .p-xxl-3 {
        padding: 1rem !important;
    }

    .pt-xxl-3,
    .py-xxl-3 {
        padding-top: 1rem !important;
    }

    .pr-xxl-3,
    .px-xxl-3 {
        padding-right: 1rem !important;
    }

    [dir='rtl'] .pr-xxl-3,
    [dir='rtl'] .px-xxl-3 {
        padding-left: 1rem !important;
    }

    .pb-xxl-3,
    .py-xxl-3 {
        padding-bottom: 1rem !important;
    }

    .pl-xxl-3,
    .px-xxl-3 {
        padding-left: 1rem !important;
    }

    [dir='rtl'] .pl-xxl-3,
    [dir='rtl'] .px-xxl-3 {
        padding-right: 1rem !important;
    }

    .p-xxl-4 {
        padding: 1.5rem !important;
    }

    .pt-xxl-4,
    .py-xxl-4 {
        padding-top: 1.5rem !important;
    }

    .pr-xxl-4,
    .px-xxl-4 {
        padding-right: 1.5rem !important;
    }

    [dir='rtl'] .pr-xxl-4,
    [dir='rtl'] .px-xxl-4 {
        padding-left: 1.5rem !important;
    }

    .pb-xxl-4,
    .py-xxl-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-xxl-4,
    .px-xxl-4 {
        padding-left: 1.5rem !important;
    }

    [dir='rtl'] .pl-xxl-4,
    [dir='rtl'] .px-xxl-4 {
        padding-right: 1.5rem !important;
    }

    .p-xxl-5 {
        padding: 3rem !important;
    }

    .pt-xxl-5,
    .py-xxl-5 {
        padding-top: 3rem !important;
    }

    .pr-xxl-5,
    .px-xxl-5 {
        padding-right: 3rem !important;
    }

    [dir='rtl'] .pr-xxl-5,
    [dir='rtl'] .px-xxl-5 {
        padding-left: 3rem !important;
    }

    .pb-xxl-5,
    .py-xxl-5 {
        padding-bottom: 3rem !important;
    }

    .pl-xxl-5,
    .px-xxl-5 {
        padding-left: 3rem !important;
    }

    [dir='rtl'] .pl-xxl-5,
    [dir='rtl'] .px-xxl-5 {
        padding-right: 3rem !important;
    }

    .p-xxl-4pt {
        padding: 0.25rem !important;
    }

    .pt-xxl-4pt,
    .py-xxl-4pt {
        padding-top: 0.25rem !important;
    }

    .pr-xxl-4pt,
    .px-xxl-4pt {
        padding-right: 0.25rem !important;
    }

    [dir='rtl'] .pr-xxl-4pt,
    [dir='rtl'] .px-xxl-4pt {
        padding-left: 0.25rem !important;
    }

    .pb-xxl-4pt,
    .py-xxl-4pt {
        padding-bottom: 0.25rem !important;
    }

    .pl-xxl-4pt,
    .px-xxl-4pt {
        padding-left: 0.25rem !important;
    }

    [dir='rtl'] .pl-xxl-4pt,
    [dir='rtl'] .px-xxl-4pt {
        padding-right: 0.25rem !important;
    }

    .p-xxl-8pt {
        padding: 0.5rem !important;
    }

    .pt-xxl-8pt,
    .py-xxl-8pt {
        padding-top: 0.5rem !important;
    }

    .pr-xxl-8pt,
    .px-xxl-8pt {
        padding-right: 0.5rem !important;
    }

    [dir='rtl'] .pr-xxl-8pt,
    [dir='rtl'] .px-xxl-8pt {
        padding-left: 0.5rem !important;
    }

    .pb-xxl-8pt,
    .py-xxl-8pt {
        padding-bottom: 0.5rem !important;
    }

    .pl-xxl-8pt,
    .px-xxl-8pt {
        padding-left: 0.5rem !important;
    }

    [dir='rtl'] .pl-xxl-8pt,
    [dir='rtl'] .px-xxl-8pt {
        padding-right: 0.5rem !important;
    }

    .p-xxl-12pt {
        padding: 0.75rem !important;
    }

    .pt-xxl-12pt,
    .py-xxl-12pt {
        padding-top: 0.75rem !important;
    }

    .pr-xxl-12pt,
    .px-xxl-12pt {
        padding-right: 0.75rem !important;
    }

    [dir='rtl'] .pr-xxl-12pt,
    [dir='rtl'] .px-xxl-12pt {
        padding-left: 0.75rem !important;
    }

    .pb-xxl-12pt,
    .py-xxl-12pt {
        padding-bottom: 0.75rem !important;
    }

    .pl-xxl-12pt,
    .px-xxl-12pt {
        padding-left: 0.75rem !important;
    }

    [dir='rtl'] .pl-xxl-12pt,
    [dir='rtl'] .px-xxl-12pt {
        padding-right: 0.75rem !important;
    }

    .p-xxl-16pt {
        padding: 1rem !important;
    }

    .pt-xxl-16pt,
    .py-xxl-16pt {
        padding-top: 1rem !important;
    }

    .pr-xxl-16pt,
    .px-xxl-16pt {
        padding-right: 1rem !important;
    }

    [dir='rtl'] .pr-xxl-16pt,
    [dir='rtl'] .px-xxl-16pt {
        padding-left: 1rem !important;
    }

    .pb-xxl-16pt,
    .py-xxl-16pt {
        padding-bottom: 1rem !important;
    }

    .pl-xxl-16pt,
    .px-xxl-16pt {
        padding-left: 1rem !important;
    }

    [dir='rtl'] .pl-xxl-16pt,
    [dir='rtl'] .px-xxl-16pt {
        padding-right: 1rem !important;
    }

    .p-xxl-20pt {
        padding: 1.25rem !important;
    }

    .pt-xxl-20pt,
    .py-xxl-20pt {
        padding-top: 1.25rem !important;
    }

    .pr-xxl-20pt,
    .px-xxl-20pt {
        padding-right: 1.25rem !important;
    }

    [dir='rtl'] .pr-xxl-20pt,
    [dir='rtl'] .px-xxl-20pt {
        padding-left: 1.25rem !important;
    }

    .pb-xxl-20pt,
    .py-xxl-20pt {
        padding-bottom: 1.25rem !important;
    }

    .pl-xxl-20pt,
    .px-xxl-20pt {
        padding-left: 1.25rem !important;
    }

    [dir='rtl'] .pl-xxl-20pt,
    [dir='rtl'] .px-xxl-20pt {
        padding-right: 1.25rem !important;
    }

    .p-xxl-24pt {
        padding: 1.5rem !important;
    }

    .pt-xxl-24pt,
    .py-xxl-24pt {
        padding-top: 1.5rem !important;
    }

    .pr-xxl-24pt,
    .px-xxl-24pt {
        padding-right: 1.5rem !important;
    }

    [dir='rtl'] .pr-xxl-24pt,
    [dir='rtl'] .px-xxl-24pt {
        padding-left: 1.5rem !important;
    }

    .pb-xxl-24pt,
    .py-xxl-24pt {
        padding-bottom: 1.5rem !important;
    }

    .pl-xxl-24pt,
    .px-xxl-24pt {
        padding-left: 1.5rem !important;
    }

    [dir='rtl'] .pl-xxl-24pt,
    [dir='rtl'] .px-xxl-24pt {
        padding-right: 1.5rem !important;
    }

    .p-xxl-32pt {
        padding: 2rem !important;
    }

    .pt-xxl-32pt,
    .py-xxl-32pt {
        padding-top: 2rem !important;
    }

    .pr-xxl-32pt,
    .px-xxl-32pt {
        padding-right: 2rem !important;
    }

    [dir='rtl'] .pr-xxl-32pt,
    [dir='rtl'] .px-xxl-32pt {
        padding-left: 2rem !important;
    }

    .pb-xxl-32pt,
    .py-xxl-32pt {
        padding-bottom: 2rem !important;
    }

    .pl-xxl-32pt,
    .px-xxl-32pt {
        padding-left: 2rem !important;
    }

    [dir='rtl'] .pl-xxl-32pt,
    [dir='rtl'] .px-xxl-32pt {
        padding-right: 2rem !important;
    }

    .p-xxl-40pt {
        padding: 2.5rem !important;
    }

    .pt-xxl-40pt,
    .py-xxl-40pt {
        padding-top: 2.5rem !important;
    }

    .pr-xxl-40pt,
    .px-xxl-40pt {
        padding-right: 2.5rem !important;
    }

    [dir='rtl'] .pr-xxl-40pt,
    [dir='rtl'] .px-xxl-40pt {
        padding-left: 2.5rem !important;
    }

    .pb-xxl-40pt,
    .py-xxl-40pt {
        padding-bottom: 2.5rem !important;
    }

    .pl-xxl-40pt,
    .px-xxl-40pt {
        padding-left: 2.5rem !important;
    }

    [dir='rtl'] .pl-xxl-40pt,
    [dir='rtl'] .px-xxl-40pt {
        padding-right: 2.5rem !important;
    }

    .p-xxl-44pt {
        padding: 2.75rem !important;
    }

    .pt-xxl-44pt,
    .py-xxl-44pt {
        padding-top: 2.75rem !important;
    }

    .pr-xxl-44pt,
    .px-xxl-44pt {
        padding-right: 2.75rem !important;
    }

    [dir='rtl'] .pr-xxl-44pt,
    [dir='rtl'] .px-xxl-44pt {
        padding-left: 2.75rem !important;
    }

    .pb-xxl-44pt,
    .py-xxl-44pt {
        padding-bottom: 2.75rem !important;
    }

    .pl-xxl-44pt,
    .px-xxl-44pt {
        padding-left: 2.75rem !important;
    }

    [dir='rtl'] .pl-xxl-44pt,
    [dir='rtl'] .px-xxl-44pt {
        padding-right: 2.75rem !important;
    }

    .p-xxl-48pt {
        padding: 3rem !important;
    }

    .pt-xxl-48pt,
    .py-xxl-48pt {
        padding-top: 3rem !important;
    }

    .pr-xxl-48pt,
    .px-xxl-48pt {
        padding-right: 3rem !important;
    }

    [dir='rtl'] .pr-xxl-48pt,
    [dir='rtl'] .px-xxl-48pt {
        padding-left: 3rem !important;
    }

    .pb-xxl-48pt,
    .py-xxl-48pt {
        padding-bottom: 3rem !important;
    }

    .pl-xxl-48pt,
    .px-xxl-48pt {
        padding-left: 3rem !important;
    }

    [dir='rtl'] .pl-xxl-48pt,
    [dir='rtl'] .px-xxl-48pt {
        padding-right: 3rem !important;
    }

    .p-xxl-64pt {
        padding: 4rem !important;
    }

    .pt-xxl-64pt,
    .py-xxl-64pt {
        padding-top: 4rem !important;
    }

    .pr-xxl-64pt,
    .px-xxl-64pt {
        padding-right: 4rem !important;
    }

    [dir='rtl'] .pr-xxl-64pt,
    [dir='rtl'] .px-xxl-64pt {
        padding-left: 4rem !important;
    }

    .pb-xxl-64pt,
    .py-xxl-64pt {
        padding-bottom: 4rem !important;
    }

    .pl-xxl-64pt,
    .px-xxl-64pt {
        padding-left: 4rem !important;
    }

    [dir='rtl'] .pl-xxl-64pt,
    [dir='rtl'] .px-xxl-64pt {
        padding-right: 4rem !important;
    }

    .p-xxl-112pt {
        padding: 7rem !important;
    }

    .pt-xxl-112pt,
    .py-xxl-112pt {
        padding-top: 7rem !important;
    }

    .pr-xxl-112pt,
    .px-xxl-112pt {
        padding-right: 7rem !important;
    }

    [dir='rtl'] .pr-xxl-112pt,
    [dir='rtl'] .px-xxl-112pt {
        padding-left: 7rem !important;
    }

    .pb-xxl-112pt,
    .py-xxl-112pt {
        padding-bottom: 7rem !important;
    }

    .pl-xxl-112pt,
    .px-xxl-112pt {
        padding-left: 7rem !important;
    }

    [dir='rtl'] .pl-xxl-112pt,
    [dir='rtl'] .px-xxl-112pt {
        padding-right: 7rem !important;
    }

    .p-xxl-128pt {
        padding: 8rem !important;
    }

    .pt-xxl-128pt,
    .py-xxl-128pt {
        padding-top: 8rem !important;
    }

    .pr-xxl-128pt,
    .px-xxl-128pt {
        padding-right: 8rem !important;
    }

    [dir='rtl'] .pr-xxl-128pt,
    [dir='rtl'] .px-xxl-128pt {
        padding-left: 8rem !important;
    }

    .pb-xxl-128pt,
    .py-xxl-128pt {
        padding-bottom: 8rem !important;
    }

    .pl-xxl-128pt,
    .px-xxl-128pt {
        padding-left: 8rem !important;
    }

    [dir='rtl'] .pl-xxl-128pt,
    [dir='rtl'] .px-xxl-128pt {
        padding-right: 8rem !important;
    }

    .m-xxl-n1 {
        margin: -0.25rem !important;
    }

    .mt-xxl-n1,
    .my-xxl-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-xxl-n1,
    .mx-xxl-n1 {
        margin-right: -0.25rem !important;
    }

    [dir='rtl'] .mr-xxl-n1,
    [dir='rtl'] .mx-xxl-n1 {
        margin-left: -0.25rem !important;
    }

    .mb-xxl-n1,
    .my-xxl-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-xxl-n1,
    .mx-xxl-n1 {
        margin-left: -0.25rem !important;
    }

    [dir='rtl'] .ml-xxl-n1,
    [dir='rtl'] .mx-xxl-n1 {
        margin-right: -0.25rem !important;
    }

    .m-xxl-n2 {
        margin: -0.5rem !important;
    }

    .mt-xxl-n2,
    .my-xxl-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-xxl-n2,
    .mx-xxl-n2 {
        margin-right: -0.5rem !important;
    }

    [dir='rtl'] .mr-xxl-n2,
    [dir='rtl'] .mx-xxl-n2 {
        margin-left: -0.5rem !important;
    }

    .mb-xxl-n2,
    .my-xxl-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-xxl-n2,
    .mx-xxl-n2 {
        margin-left: -0.5rem !important;
    }

    [dir='rtl'] .ml-xxl-n2,
    [dir='rtl'] .mx-xxl-n2 {
        margin-right: -0.5rem !important;
    }

    .m-xxl-n3 {
        margin: -1rem !important;
    }

    .mt-xxl-n3,
    .my-xxl-n3 {
        margin-top: -1rem !important;
    }

    .mr-xxl-n3,
    .mx-xxl-n3 {
        margin-right: -1rem !important;
    }

    [dir='rtl'] .mr-xxl-n3,
    [dir='rtl'] .mx-xxl-n3 {
        margin-left: -1rem !important;
    }

    .mb-xxl-n3,
    .my-xxl-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-xxl-n3,
    .mx-xxl-n3 {
        margin-left: -1rem !important;
    }

    [dir='rtl'] .ml-xxl-n3,
    [dir='rtl'] .mx-xxl-n3 {
        margin-right: -1rem !important;
    }

    .m-xxl-n4 {
        margin: -1.5rem !important;
    }

    .mt-xxl-n4,
    .my-xxl-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-xxl-n4,
    .mx-xxl-n4 {
        margin-right: -1.5rem !important;
    }

    [dir='rtl'] .mr-xxl-n4,
    [dir='rtl'] .mx-xxl-n4 {
        margin-left: -1.5rem !important;
    }

    .mb-xxl-n4,
    .my-xxl-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-xxl-n4,
    .mx-xxl-n4 {
        margin-left: -1.5rem !important;
    }

    [dir='rtl'] .ml-xxl-n4,
    [dir='rtl'] .mx-xxl-n4 {
        margin-right: -1.5rem !important;
    }

    .m-xxl-n5 {
        margin: -3rem !important;
    }

    .mt-xxl-n5,
    .my-xxl-n5 {
        margin-top: -3rem !important;
    }

    .mr-xxl-n5,
    .mx-xxl-n5 {
        margin-right: -3rem !important;
    }

    [dir='rtl'] .mr-xxl-n5,
    [dir='rtl'] .mx-xxl-n5 {
        margin-left: -3rem !important;
    }

    .mb-xxl-n5,
    .my-xxl-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-xxl-n5,
    .mx-xxl-n5 {
        margin-left: -3rem !important;
    }

    [dir='rtl'] .ml-xxl-n5,
    [dir='rtl'] .mx-xxl-n5 {
        margin-right: -3rem !important;
    }

    .m-xxl-n4pt {
        margin: -0.25rem !important;
    }

    .mt-xxl-n4pt,
    .my-xxl-n4pt {
        margin-top: -0.25rem !important;
    }

    .mr-xxl-n4pt,
    .mx-xxl-n4pt {
        margin-right: -0.25rem !important;
    }

    [dir='rtl'] .mr-xxl-n4pt,
    [dir='rtl'] .mx-xxl-n4pt {
        margin-left: -0.25rem !important;
    }

    .mb-xxl-n4pt,
    .my-xxl-n4pt {
        margin-bottom: -0.25rem !important;
    }

    .ml-xxl-n4pt,
    .mx-xxl-n4pt {
        margin-left: -0.25rem !important;
    }

    [dir='rtl'] .ml-xxl-n4pt,
    [dir='rtl'] .mx-xxl-n4pt {
        margin-right: -0.25rem !important;
    }

    .m-xxl-n8pt {
        margin: -0.5rem !important;
    }

    .mt-xxl-n8pt,
    .my-xxl-n8pt {
        margin-top: -0.5rem !important;
    }

    .mr-xxl-n8pt,
    .mx-xxl-n8pt {
        margin-right: -0.5rem !important;
    }

    [dir='rtl'] .mr-xxl-n8pt,
    [dir='rtl'] .mx-xxl-n8pt {
        margin-left: -0.5rem !important;
    }

    .mb-xxl-n8pt,
    .my-xxl-n8pt {
        margin-bottom: -0.5rem !important;
    }

    .ml-xxl-n8pt,
    .mx-xxl-n8pt {
        margin-left: -0.5rem !important;
    }

    [dir='rtl'] .ml-xxl-n8pt,
    [dir='rtl'] .mx-xxl-n8pt {
        margin-right: -0.5rem !important;
    }

    .m-xxl-n12pt {
        margin: -0.75rem !important;
    }

    .mt-xxl-n12pt,
    .my-xxl-n12pt {
        margin-top: -0.75rem !important;
    }

    .mr-xxl-n12pt,
    .mx-xxl-n12pt {
        margin-right: -0.75rem !important;
    }

    [dir='rtl'] .mr-xxl-n12pt,
    [dir='rtl'] .mx-xxl-n12pt {
        margin-left: -0.75rem !important;
    }

    .mb-xxl-n12pt,
    .my-xxl-n12pt {
        margin-bottom: -0.75rem !important;
    }

    .ml-xxl-n12pt,
    .mx-xxl-n12pt {
        margin-left: -0.75rem !important;
    }

    [dir='rtl'] .ml-xxl-n12pt,
    [dir='rtl'] .mx-xxl-n12pt {
        margin-right: -0.75rem !important;
    }

    .m-xxl-n16pt {
        margin: -1rem !important;
    }

    .mt-xxl-n16pt,
    .my-xxl-n16pt {
        margin-top: -1rem !important;
    }

    .mr-xxl-n16pt,
    .mx-xxl-n16pt {
        margin-right: -1rem !important;
    }

    [dir='rtl'] .mr-xxl-n16pt,
    [dir='rtl'] .mx-xxl-n16pt {
        margin-left: -1rem !important;
    }

    .mb-xxl-n16pt,
    .my-xxl-n16pt {
        margin-bottom: -1rem !important;
    }

    .ml-xxl-n16pt,
    .mx-xxl-n16pt {
        margin-left: -1rem !important;
    }

    [dir='rtl'] .ml-xxl-n16pt,
    [dir='rtl'] .mx-xxl-n16pt {
        margin-right: -1rem !important;
    }

    .m-xxl-n20pt {
        margin: -1.25rem !important;
    }

    .mt-xxl-n20pt,
    .my-xxl-n20pt {
        margin-top: -1.25rem !important;
    }

    .mr-xxl-n20pt,
    .mx-xxl-n20pt {
        margin-right: -1.25rem !important;
    }

    [dir='rtl'] .mr-xxl-n20pt,
    [dir='rtl'] .mx-xxl-n20pt {
        margin-left: -1.25rem !important;
    }

    .mb-xxl-n20pt,
    .my-xxl-n20pt {
        margin-bottom: -1.25rem !important;
    }

    .ml-xxl-n20pt,
    .mx-xxl-n20pt {
        margin-left: -1.25rem !important;
    }

    [dir='rtl'] .ml-xxl-n20pt,
    [dir='rtl'] .mx-xxl-n20pt {
        margin-right: -1.25rem !important;
    }

    .m-xxl-n24pt {
        margin: -1.5rem !important;
    }

    .mt-xxl-n24pt,
    .my-xxl-n24pt {
        margin-top: -1.5rem !important;
    }

    .mr-xxl-n24pt,
    .mx-xxl-n24pt {
        margin-right: -1.5rem !important;
    }

    [dir='rtl'] .mr-xxl-n24pt,
    [dir='rtl'] .mx-xxl-n24pt {
        margin-left: -1.5rem !important;
    }

    .mb-xxl-n24pt,
    .my-xxl-n24pt {
        margin-bottom: -1.5rem !important;
    }

    .ml-xxl-n24pt,
    .mx-xxl-n24pt {
        margin-left: -1.5rem !important;
    }

    [dir='rtl'] .ml-xxl-n24pt,
    [dir='rtl'] .mx-xxl-n24pt {
        margin-right: -1.5rem !important;
    }

    .m-xxl-n32pt {
        margin: -2rem !important;
    }

    .mt-xxl-n32pt,
    .my-xxl-n32pt {
        margin-top: -2rem !important;
    }

    .mr-xxl-n32pt,
    .mx-xxl-n32pt {
        margin-right: -2rem !important;
    }

    [dir='rtl'] .mr-xxl-n32pt,
    [dir='rtl'] .mx-xxl-n32pt {
        margin-left: -2rem !important;
    }

    .mb-xxl-n32pt,
    .my-xxl-n32pt {
        margin-bottom: -2rem !important;
    }

    .ml-xxl-n32pt,
    .mx-xxl-n32pt {
        margin-left: -2rem !important;
    }

    [dir='rtl'] .ml-xxl-n32pt,
    [dir='rtl'] .mx-xxl-n32pt {
        margin-right: -2rem !important;
    }

    .m-xxl-n40pt {
        margin: -2.5rem !important;
    }

    .mt-xxl-n40pt,
    .my-xxl-n40pt {
        margin-top: -2.5rem !important;
    }

    .mr-xxl-n40pt,
    .mx-xxl-n40pt {
        margin-right: -2.5rem !important;
    }

    [dir='rtl'] .mr-xxl-n40pt,
    [dir='rtl'] .mx-xxl-n40pt {
        margin-left: -2.5rem !important;
    }

    .mb-xxl-n40pt,
    .my-xxl-n40pt {
        margin-bottom: -2.5rem !important;
    }

    .ml-xxl-n40pt,
    .mx-xxl-n40pt {
        margin-left: -2.5rem !important;
    }

    [dir='rtl'] .ml-xxl-n40pt,
    [dir='rtl'] .mx-xxl-n40pt {
        margin-right: -2.5rem !important;
    }

    .m-xxl-n44pt {
        margin: -2.75rem !important;
    }

    .mt-xxl-n44pt,
    .my-xxl-n44pt {
        margin-top: -2.75rem !important;
    }

    .mr-xxl-n44pt,
    .mx-xxl-n44pt {
        margin-right: -2.75rem !important;
    }

    [dir='rtl'] .mr-xxl-n44pt,
    [dir='rtl'] .mx-xxl-n44pt {
        margin-left: -2.75rem !important;
    }

    .mb-xxl-n44pt,
    .my-xxl-n44pt {
        margin-bottom: -2.75rem !important;
    }

    .ml-xxl-n44pt,
    .mx-xxl-n44pt {
        margin-left: -2.75rem !important;
    }

    [dir='rtl'] .ml-xxl-n44pt,
    [dir='rtl'] .mx-xxl-n44pt {
        margin-right: -2.75rem !important;
    }

    .m-xxl-n48pt {
        margin: -3rem !important;
    }

    .mt-xxl-n48pt,
    .my-xxl-n48pt {
        margin-top: -3rem !important;
    }

    .mr-xxl-n48pt,
    .mx-xxl-n48pt {
        margin-right: -3rem !important;
    }

    [dir='rtl'] .mr-xxl-n48pt,
    [dir='rtl'] .mx-xxl-n48pt {
        margin-left: -3rem !important;
    }

    .mb-xxl-n48pt,
    .my-xxl-n48pt {
        margin-bottom: -3rem !important;
    }

    .ml-xxl-n48pt,
    .mx-xxl-n48pt {
        margin-left: -3rem !important;
    }

    [dir='rtl'] .ml-xxl-n48pt,
    [dir='rtl'] .mx-xxl-n48pt {
        margin-right: -3rem !important;
    }

    .m-xxl-n64pt {
        margin: -4rem !important;
    }

    .mt-xxl-n64pt,
    .my-xxl-n64pt {
        margin-top: -4rem !important;
    }

    .mr-xxl-n64pt,
    .mx-xxl-n64pt {
        margin-right: -4rem !important;
    }

    [dir='rtl'] .mr-xxl-n64pt,
    [dir='rtl'] .mx-xxl-n64pt {
        margin-left: -4rem !important;
    }

    .mb-xxl-n64pt,
    .my-xxl-n64pt {
        margin-bottom: -4rem !important;
    }

    .ml-xxl-n64pt,
    .mx-xxl-n64pt {
        margin-left: -4rem !important;
    }

    [dir='rtl'] .ml-xxl-n64pt,
    [dir='rtl'] .mx-xxl-n64pt {
        margin-right: -4rem !important;
    }

    .m-xxl-n112pt {
        margin: -7rem !important;
    }

    .mt-xxl-n112pt,
    .my-xxl-n112pt {
        margin-top: -7rem !important;
    }

    .mr-xxl-n112pt,
    .mx-xxl-n112pt {
        margin-right: -7rem !important;
    }

    [dir='rtl'] .mr-xxl-n112pt,
    [dir='rtl'] .mx-xxl-n112pt {
        margin-left: -7rem !important;
    }

    .mb-xxl-n112pt,
    .my-xxl-n112pt {
        margin-bottom: -7rem !important;
    }

    .ml-xxl-n112pt,
    .mx-xxl-n112pt {
        margin-left: -7rem !important;
    }

    [dir='rtl'] .ml-xxl-n112pt,
    [dir='rtl'] .mx-xxl-n112pt {
        margin-right: -7rem !important;
    }

    .m-xxl-n128pt {
        margin: -8rem !important;
    }

    .mt-xxl-n128pt,
    .my-xxl-n128pt {
        margin-top: -8rem !important;
    }

    .mr-xxl-n128pt,
    .mx-xxl-n128pt {
        margin-right: -8rem !important;
    }

    [dir='rtl'] .mr-xxl-n128pt,
    [dir='rtl'] .mx-xxl-n128pt {
        margin-left: -8rem !important;
    }

    .mb-xxl-n128pt,
    .my-xxl-n128pt {
        margin-bottom: -8rem !important;
    }

    .ml-xxl-n128pt,
    .mx-xxl-n128pt {
        margin-left: -8rem !important;
    }

    [dir='rtl'] .ml-xxl-n128pt,
    [dir='rtl'] .mx-xxl-n128pt {
        margin-right: -8rem !important;
    }

    .m-xxl-auto {
        margin: auto !important;
    }

    .mt-xxl-auto,
    .my-xxl-auto {
        margin-top: auto !important;
    }

    .mr-xxl-auto,
    .mx-xxl-auto {
        margin-right: auto !important;
    }

    [dir='rtl'] .mr-xxl-auto,
    [dir='rtl'] .mx-xxl-auto {
        margin-left: auto !important;
    }

    .mb-xxl-auto,
    .my-xxl-auto {
        margin-bottom: auto !important;
    }

    .ml-xxl-auto,
    .mx-xxl-auto {
        margin-left: auto !important;
    }

    [dir='rtl'] .ml-xxl-auto,
    [dir='rtl'] .mx-xxl-auto {
        margin-right: auto !important;
    }
}

.stretched-link:after {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    pointer-events: auto;
    content: '';
}

.stretched-link:after {
    background-color: transparent;
}

.stretched-link:after,
[dir='rtl'] .stretched-link:after {
    right: 0;
    left: 0;
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important;
}

.text-justify {
    text-align: justify !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-left {
    text-align: left !important;
}

.text-right,
[dir='rtl'] .text-left {
    text-align: right !important;
}

[dir='rtl'] .text-right {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

@media (min-width: 374px) {
    .text-xs-md-left {
        text-align: left !important;
    }

    .text-xs-md-right,
    [dir='rtl'] .text-xs-md-left {
        text-align: right !important;
    }

    [dir='rtl'] .text-xs-md-right {
        text-align: left !important;
    }

    .text-xs-md-center {
        text-align: center !important;
    }
}

@media (min-width: 413px) {
    .text-xs-plus-left {
        text-align: left !important;
    }

    .text-xs-plus-right,
    [dir='rtl'] .text-xs-plus-left {
        text-align: right !important;
    }

    [dir='rtl'] .text-xs-plus-right {
        text-align: left !important;
    }

    .text-xs-plus-center {
        text-align: center !important;
    }
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right,
    [dir='rtl'] .text-sm-left {
        text-align: right !important;
    }

    [dir='rtl'] .text-sm-right {
        text-align: left !important;
    }

    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }

    .text-md-right,
    [dir='rtl'] .text-md-left {
        text-align: right !important;
    }

    [dir='rtl'] .text-md-right {
        text-align: left !important;
    }

    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-right,
    [dir='rtl'] .text-lg-left {
        text-align: right !important;
    }

    [dir='rtl'] .text-lg-right {
        text-align: left !important;
    }

    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left !important;
    }

    .text-xl-right,
    [dir='rtl'] .text-xl-left {
        text-align: right !important;
    }

    [dir='rtl'] .text-xl-right {
        text-align: left !important;
    }

    .text-xl-center {
        text-align: center !important;
    }
}

@media (min-width: 1366px) {
    .text-xxl-left {
        text-align: left !important;
    }

    .text-xxl-right,
    [dir='rtl'] .text-xxl-left {
        text-align: right !important;
    }

    [dir='rtl'] .text-xxl-right {
        text-align: left !important;
    }

    .text-xxl-center {
        text-align: center !important;
    }
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-lighter {
    font-weight: lighter !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: 500 !important;
}

.font-weight-bolder {
    font-weight: bolder !important;
}

.font-italic {
    font-style: italic !important;
}

.text-white {
    color: #fff !important;
}

.text-primary {
    color: var(--primary) !important;
}

a.text-primary:focus,
a.text-primary:hover {
    color: #0a6ebd !important;
}

.text-secondary {
    color: #6b757d !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
    color: #484e54 !important;
}

.text-success {
    color: #66bb6a !important;
}

a.text-success:focus,
a.text-success:hover {
    color: #419345 !important;
}

.text-info {
    color: #29b6f6 !important;
}

a.text-info:focus,
a.text-info:hover {
    color: #088eca !important;
}

.text-warning {
    color: #ffa726 !important;
}

a.text-warning:focus,
a.text-warning:hover {
    color: #d98100 !important;
}

.text-danger {
    color: #f44336 !important;
}

a.text-danger:focus,
a.text-danger:hover {
    color: #d2190b !important;
}

.text-light {
    color: #f8f9fa !important;
}

a.text-light:focus,
a.text-light:hover {
    color: #cbd3da !important;
}

.text-dark {
    color: #39444d !important;
}

a.text-dark:focus,
a.text-dark:hover {
    color: #181d21 !important;
}

.text-body {
    color: #383b3d !important;
}

.text-muted {
    color: #b8bdc2 !important;
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
}

.text-hide {
    font: 0/0 a;
    color: transparent;
}

.text-hide {
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.text-decoration-none {
    text-decoration: none !important;
}

.text-break {
    word-break: break-word !important;
    word-wrap: break-word !important;
}

.text-reset {
    color: inherit !important;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

@media print {

    *,
    :after,
    :before {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    abbr[title]:after {
        content: ' (' attr(title) ')';
    }

    pre {
        white-space: pre-wrap !important;
    }

    blockquote,
    pre {
        page-break-inside: avoid;
    }

    blockquote,
    pre {
        border: 1px solid #adb5bd;
    }

    thead {
        display: table-header-group;
    }

    img,
    tr {
        page-break-inside: avoid;
    }

    h2,
    h3,
    p {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    @page {
        size: a3;
    }

    .container,
    body {
        min-width: 992px !important;
    }

    .navbar {
        display: none;
    }

    .badge {
        border: 1px solid #000;
    }

    .table {
        border-collapse: collapse !important;
    }

    .table td,
    .table th {
        background-color: #fff !important;
    }

    .table-bordered td,
    .table-bordered th {
        border: 1px solid #dee2e6 !important;
    }

    .table-dark {
        color: inherit;
    }

    .table-dark tbody+tbody,
    .table-dark td,
    .table-dark th,
    .table-dark thead th {
        border-color: #f0f1f2;
    }

    .table .thead-dark th {
        color: inherit;
    }

    .table .thead-dark th {
        border-color: #f0f1f2;
    }
}

.mdk-header {
    position: relative;
    z-index: 1;
    height: 200px;
}

.mdk-header {
    margin-bottom: 1.25rem;
}

.mdk-header :last-child {
    margin-bottom: 0;
}

.mdk-header [data-fx-condenses] {
    opacity: 0;
}

.mdk-header [data-fx-condenses] {
    transform-origin: left top;
}

[dir='rtl'] .mdk-header [data-fx-condenses] {
    transform-origin: right top;
}

.mdk-header [data-fx-condenses][data-fx-id] {
    opacity: 1;
}

.mdk-header [data-primary] {
    z-index: 1;
}

.mdk-header__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: hidden;
}

.mdk-header__bg,
[dir='rtl'] .mdk-header__bg {
    right: 0;
    left: 0;
}

[data-effects*='parallax-background'] .mdk-header__bg {
    visibility: hidden;
}

.mdk-header__bg-front,
.mdk-header__bg-rear {
    position: absolute;
    top: 0;
    bottom: 0;
    pointer-events: none;
}

.mdk-header__bg-front,
.mdk-header__bg-rear {
    background-size: cover;
}

.mdk-header__bg-front,
.mdk-header__bg-rear,
[dir='rtl'] .mdk-header__bg-front,
[dir='rtl'] .mdk-header__bg-rear {
    right: 0;
    left: 0;
}

.mdk-header__bg-rear {
    opacity: 0;
}

.mdk-header__content {
    position: relative;
    width: 100%;
    height: 100%;
}

.mdk-header[data-condenses] .mdk-header__content {
    z-index: 0;
}

.mdk-header[data-condenses] [data-primary] {
    z-index: 1;
}

.mdk-header--shadow {
    transition: box-shadow 0.25s;
}

.mdk-header--shadow:after {
    position: absolute;
    bottom: -5px;
    width: 100%;
    height: 5px;
    content: '';
    pointer-events: none;
    opacity: 0;
    z-index: -1;
}

.mdk-header--shadow:after {
    box-shadow: inset 0 5px 6px -3px rgba(0, 0, 0, 0.4);
}

.mdk-header--shadow:after,
[dir='rtl'] .mdk-header--shadow:after {
    right: 0;
    left: 0;
}

.mdk-header--shadow-show:after {
    opacity: 1;
}

.mdk-header--fixed {
    position: fixed;
    top: 0;
    width: 100%;
}

.mdk-header--fixed {
    margin-bottom: 0;
}

.mdk-header--fixed {
    left: 0;
}

[dir='rtl'] .mdk-header--fixed {
    right: 0;
}

.mdk-header[data-reveals] [data-primary] {
    transition: transform 0.25s;
}

.mdk-header[data-reveals] [data-primary].mdk-header--shadow {
    transition: box-shadow 0.25s, transform;
}

.mdk-header[disabled],
.mdk-header[disabled] .mdk-header__bg-front,
.mdk-header[disabled] .mdk-header__bg-rear,
.mdk-header[disabled] .mdk-header__content>[data-primary],
.mdk-header[disabled]:after {
    transition: none !important;
}

.mdk-header-layout {
    height: 100%;
    position: relative;
    overflow: hidden;
    z-index: 0;
}

.mdk-header-layout--fullbleed {
    position: absolute;
    top: 0;
    bottom: 0;
}

.mdk-header-layout--fullbleed,
[dir='rtl'] .mdk-header-layout--fullbleed {
    left: 0;
    right: 0;
}

.mdk-header-layout>.mdk-header {
    position: fixed;
    top: 0;
    z-index: 1;
}

.mdk-header-layout>.mdk-header,
[dir='rtl'] .mdk-header-layout>.mdk-header {
    left: 0;
    right: 0;
}

.mdk-header-layout[data-fullbleed]>.mdk-header,
.mdk-header-layout[data-has-scrolling-region]>.mdk-header {
    position: absolute;
    top: 0;
}

.mdk-header-layout[data-fullbleed]>.mdk-header,
.mdk-header-layout[data-has-scrolling-region]>.mdk-header,
[dir='rtl'] .mdk-header-layout[data-fullbleed]>.mdk-header,
[dir='rtl'] .mdk-header-layout[data-has-scrolling-region]>.mdk-header {
    left: 0;
    right: 0;
}

.mdk-header-layout__content {
    position: relative;
    z-index: 0;
}

.mdk-header-layout__content--fullbleed {
    position: absolute;
    top: 0;
    bottom: 0;
}

.mdk-header-layout__content--fullbleed,
[dir='rtl'] .mdk-header-layout__content--fullbleed {
    left: 0;
    right: 0;
}

.mdk-header-layout__content--scrollable {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.mdk-box {
    position: relative;
}

.mdk-box {
    margin-bottom: 1.25rem;
}

.mdk-box__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: hidden;
    height: 100%;
}

.mdk-box__bg,
[dir='rtl'] .mdk-box__bg {
    right: 0;
    left: 0;
}

[data-effects*='parallax-background'] .mdk-box__bg {
    visibility: hidden;
}

.mdk-box__bg-front,
.mdk-box__bg-rear {
    position: absolute;
    top: 0;
    bottom: 0;
    pointer-events: none;
}

.mdk-box__bg-front,
.mdk-box__bg-rear {
    background-size: cover;
}

.mdk-box__bg-front,
.mdk-box__bg-rear,
[dir='rtl'] .mdk-box__bg-front,
[dir='rtl'] .mdk-box__bg-rear {
    right: 0;
    left: 0;
}

.mdk-box__bg-rear {
    opacity: 0;
}

.mdk-box__content {
    position: relative;
    width: 100%;
    height: 100%;
}

.mdk-box[disabled],
.mdk-box[disabled] .mdk-box__bg-front,
.mdk-box[disabled] .mdk-box__bg-rear,
.mdk-box[disabled] .mdk-box__content>[data-primary],
.mdk-box[disabled]:after {
    transition: none !important;
}

.mdk-drawer {
    position: relative;
    visibility: hidden;
}

.mdk-drawer[data-closing],
.mdk-drawer[data-opened] {
    visibility: visible;
}

.mdk-drawer[data-persistent] {
    width: 256px;
}

.mdk-drawer[data-persistent][data-position='left'] {
    right: auto;
}

.mdk-drawer[data-persistent][data-position='right'] {
    left: auto;
}

.mdk-drawer__content {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 256px;
    transition: transform 0.2s ease;
    background-color: #fff;
    left: 0;
    transform: translate3d(-100%, 0, 0);
}

[data-position='right']>.mdk-drawer__content {
    right: 0;
    left: auto;
    transform: translate3d(100%, 0, 0);
}

[data-opened]>.mdk-drawer__content {
    transform: translateZ(0);
}

.mdk-drawer__scrim {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: opacity 0.2s ease;
    transform: translateZ(0);
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

[data-opened]>.mdk-drawer__scrim {
    opacity: 1;
}

[data-persistent]>.mdk-drawer__scrim {
    visibility: hidden;
    opacity: 0;
}

.mdk-drawer-layout {
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
}

.mdk-drawer-layout--fullbleed {
    position: absolute;
    top: 0;
    bottom: 0;
}

.mdk-drawer-layout--fullbleed,
[dir='rtl'] .mdk-drawer-layout--fullbleed {
    left: 0;
    right: 0;
}

.mdk-drawer-layout .mdk-drawer {
    position: relative;
}

.mdk-drawer-layout .mdk-drawer[data-persistent] {
    width: 256px;
}

.mdk-drawer-layout .mdk-drawer[data-persistent][data-position='left'] {
    order: 0;
}

.mdk-drawer-layout .mdk-drawer[data-persistent][data-position='right'] {
    order: 2;
}

.mdk-drawer-layout__content {
    position: relative;
    height: 100%;
    transition: transform 0.2s;
    min-width: 320px;
    z-index: 0;
    order: 1;
    flex: 1 1 0%;
}

.mdk-drawer-layout__content--scrollable {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

[dir='rtl'] .mdk-drawer-layout .mdk-drawer[data-persistent][data-position='left'] {
    order: 2;
}

[dir='rtl'] .mdk-drawer-layout .mdk-drawer[data-persistent][data-position='right'] {
    order: 0;
}

.mdk-reveal {
    z-index: 0;
    position: relative;
    overflow: hidden;
    display: block;
}

.mdk-reveal--hover:hover .mdk-reveal__partial,
.mdk-reveal[data-opened] .mdk-reveal__partial {
    opacity: 0;
}

.mdk-reveal__content {
    transition: transform 0.2s;
    position: relative;
    z-index: 0;
}

.mdk-reveal__content {
    background-color: #fff;
}

.mdk-reveal__partial {
    position: absolute;
    top: 0;
    width: 100%;
    content: '';
    opacity: 1;
    z-index: 1;
    transition: opacity 0.2s;
    pointer-events: none;
}

.mdk-reveal__partial {
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 60%, hsla(0, 0%, 100%, 0.95) 80%, #fff);
}

.mdk-reveal__partial {
    left: 0;
}

[dir='rtl'] .mdk-reveal__partial {
    right: 0;
}

.mdk-carousel__content {
    transition: transform 0.4s;
    touch-action: pan-x;
}

.mdk-carousel__content {
    transform: translateZ(0);
}

.mdk-carousel__item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    width: 100%;
}

.mdk-carousel__item {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
}

.mdk-carousel__item {
    float: left;
}

[dir='rtl'] .mdk-carousel__item {
    float: right;
}

.mdk-tooltip {
    transition: transform 0.2s;
    will-change: transform;
    display: inline-block;
    color: #fff;
    font-size: 13px;
    z-index: 999;
    font-weight: 500;
    position: fixed;
    line-height: 1;
    max-width: 200px;
    pointer-events: none;
}

.mdk-tooltip {
    transform: scale(0);
    transform-origin: top center;
    background: rgba(0, 0, 0, 0.7);
    text-align: center;
    padding: 0.4rem;
    border-radius: 0.25rem;
}

.mdk-tooltip[data-opened] {
    transform: scale(1);
}

.sidebar-p-a {
    padding: 2rem 1.5rem;
}

.sidebar-p-x,
[dir='rtl'] .sidebar-p-x {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.sidebar-p-y {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.sidebar-p-t {
    padding-top: 2rem;
}

.sidebar-p-b {
    padding-bottom: 2rem;
}

.sidebar-p-l,
[dir='rtl'] .sidebar-p-l {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.sidebar-p-r {
    padding-right: 1.5rem;
}

[dir='rtl'] .sidebar-p-r {
    padding-left: 1.5rem;
}

.sidebar-m-a {
    margin: 2rem 1.5rem;
}

.sidebar-m-x,
[dir='rtl'] .sidebar-m-x {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.sidebar-m-y {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.sidebar-m-t {
    margin-top: 2rem;
}

.sidebar-m-b {
    margin-bottom: 2rem;
}

.sidebar-m-l {
    margin-left: 1.5rem;
}

.sidebar-m-r,
[dir='rtl'] .sidebar-m-l {
    margin-right: 1.5rem;
}

[dir='rtl'] .sidebar-m-r {
    margin-left: 1.5rem;
}

.sidebar-b-a {
    border: 1px solid transparent;
}

.sidebar-b-x,
[dir='rtl'] .sidebar-b-x {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
}

.sidebar-b-y {
    border-bottom: 1px solid transparent;
}

.sidebar-b-t,
.sidebar-b-y {
    border-top: 1px solid transparent;
}

.sidebar-b-b {
    border-bottom: 1px solid transparent;
}

.sidebar-block {
    margin-bottom: 2rem;
}

.sidebar-block,
[dir='rtl'] .sidebar-block {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.sidebar>.sidebar-text,
.sidebar>p {
    margin-bottom: 2rem;
}

.sidebar>.sidebar-text,
.sidebar>p,
[dir='rtl'] .sidebar>.sidebar-text,
[dir='rtl'] .sidebar>p {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.sidebar-brand {
    font-weight: 500;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
}

.sidebar-brand {
    margin-bottom: 1rem;
}

.sidebar-brand:hover {
    text-decoration: none;
}

.sidebar-brand-icon {
    margin-right: 0.75rem;
}

[dir='rtl'] .sidebar-brand-icon {
    margin-left: 0.75rem;
}

.sidebar-brand-header {
    height: 56px;
    line-height: 56px;
    width: 100%;
}

.sidebar-brand-header {
    margin-bottom: 2rem;
}

.sidebar-brand-header,
[dir='rtl'] .sidebar-brand-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.sidebar-brand-border {
    border-bottom: 1px solid transparent;
}

.sidebar-heading {
    font-weight: 500;
    font-size: 0.75rem;
    font-family: inherit;
    text-transform: uppercase;
    letter-spacing: normal;
    line-height: 1.5rem;
}

.sidebar-heading {
    margin-bottom: 2rem;
}

.sidebar-heading,
[dir='rtl'] .sidebar-heading {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.sidebar-badge {
    display: inline-block;
    font-size: 75%;
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;
    vertical-align: baseline;
}

.sidebar-badge {
    padding: 0.25em 0.4em;
    text-align: center;
    border-radius: 0.25rem;
}

.sidebar-dark {
    color: #dfe2e6;
}

.sidebar-dark {
    background: transparent;
}

.sidebar-dark.sidebar-left {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.sidebar-dark.sidebar-right,
.sidebar-dark.sidebar-left {
    border-left: 1px solid rgba(0, 0, 0, 0.15);
}

.sidebar-dark.sidebar-right {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.sidebar-dark .sidebar-link {
    color: #8a9299;
}

.sidebar-dark [class*='sidebar-b-'] {
    border-color: rgba(46, 49, 51, 0.9);
}

.sidebar-dark .sidebar-heading,
.sidebar-dark .sidebar-heading>a,
.sidebar-dark .sidebar-text,
.sidebar-dark p {
    color: #dfe2e6;
}

.sidebar-dark hr {
    border-color: rgba(46, 49, 51, 0.9);
}

.sidebar-dark .sidebar-brand {
    color: #fff;
}

.sidebar-dark .sidebar-brand-bg {
    background: rgba(0, 0, 0, 0.12);
}

.sidebar-dark .sidebar-brand-border {
    border-color: rgba(46, 49, 51, 0.9);
}

.sidebar-dark .sidebar-menu-button,
.sidebar-dark .sidebar-menu-toggle-icon {
    color: #8a9299;
}

.sidebar-dark .sidebar-menu-icon {
    color: hsla(0, 0%, 100%, 0.54);
}

.sidebar-dark .sidebar-menu-button:hover {
    color: #cfdbe6;
}

.sidebar-dark .sidebar-menu-button:hover {
    background: transparent;
}

.sidebar-dark .sidebar-menu-button:hover .sidebar-menu-icon,
.sidebar-dark .sidebar-menu-button:hover .sidebar-menu-toggle-icon {
    color: hsla(0, 0%, 100%, 0.54);
}

.sidebar-dark .open {
    background: rgba(38, 45, 51, 0.5);
}

.sidebar-dark .open>.sidebar-menu-button,
.sidebar-dark .open>.sidebar-menu-button .sidebar-menu-toggle-icon {
    color: #dfe2e6;
}

.sidebar-dark .open>.sidebar-menu-button {
    background: transparent;
}

.sidebar-dark .active>.sidebar-menu-button,
.sidebar-dark .open>.sidebar-menu-button .sidebar-menu-icon {
    color: #cfdbe6;
}

.sidebar-dark .active>.sidebar-menu-button .sidebar-menu-icon {
    color: var(--primary);
}

.sidebar-dark .sidebar-submenu .sidebar-menu-button,
.sidebar-dark .sidebar-submenu .sidebar-menu-icon {
    color: #8a9299;
}

.sidebar-dark .sidebar-submenu .active>.sidebar-menu-button,
.sidebar-dark .sidebar-submenu .active>.sidebar-menu-button .sidebar-menu-icon,
.sidebar-dark .sidebar-submenu .sidebar-menu-button:hover,
.sidebar-dark .sidebar-submenu .sidebar-menu-button:hover .sidebar-menu-icon {
    color: #cfdbe6;
}

.sidebar-dark .sm-bordered,
.sidebar-dark .sm-bordered.sidebar-submenu,
.sidebar-dark .sm-item-bordered.sidebar-submenu>.sidebar-menu-item,
.sidebar-dark .sm-item-bordered>.sidebar-menu-item {
    border-color: rgba(46, 49, 51, 0.9);
}

.sidebar-dark .sm-icons-block>.sidebar-menu-item>.sidebar-menu-button .sidebar-menu-icon {
    color: hsla(0, 0%, 100%, 0.54);
}

.sidebar-dark .sm-icons-block>.sidebar-menu-item>.sidebar-menu-button .sidebar-menu-icon {
    background: rgba(0, 0, 0, 0.12);
}

.sidebar-dark .sm-icons-block>.sidebar-menu-item.open>.sidebar-menu-button .sidebar-menu-icon {
    color: #cfdbe6;
}

.sidebar-dark .sm-icons-block>.sidebar-menu-item.open>.sidebar-menu-button .sidebar-menu-icon {
    background: rgba(0, 0, 0, 0.12);
}

.sidebar-dark .sm-icons-block>.sidebar-menu-item.active>.sidebar-menu-button .sidebar-menu-icon {
    color: var(--primary);
}

.sidebar-dark .sm-icons-block>.sidebar-menu-item.active>.sidebar-menu-button .sidebar-menu-icon {
    background: rgba(38, 45, 51, 0.5);
}

.sidebar-dark .sm-active-button-bg>.active>.sidebar-menu-button {
    color: #cfdbe6;
}

.sidebar-dark .sm-active-button-bg>.active>.sidebar-menu-button {
    background: rgba(38, 45, 51, 0.5);
}

.sidebar-dark .sm-active-button-bg>.active>.sidebar-menu-button .sidebar-menu-icon,
.sidebar-dark .sm-active-button-bg>.active>.sidebar-menu-button .sidebar-menu-toggle-icon {
    color: var(--primary);
}

.sidebar-dark .sm-icons-block.sm-active-button-bg>.active>.sidebar-menu-button .sidebar-menu-icon {
    background: rgba(38, 45, 51, 0.5);
}

.sidebar-menu {
    list-style: none;
}

.sidebar-menu {
    margin-bottom: 2rem;
    padding: 0;
}

.sidebar-menu-item {
    position: relative;
    overflow: hidden;
}

.sidebar-menu-button {
    display: flex;
    align-items: center;
    font-weight: 400;
    line-height: 42px;
    font-size: 15px;
}

.sidebar-menu-button {
    padding: 0 1.5rem;
}

.sidebar-menu-button,
.sidebar-menu-button:focus,
.sidebar-menu-button:hover {
    text-decoration: none;
}

.active>.sidebar-menu-button {
    font-weight: 400;
}

.sidebar-menu-toggle-icon {
    position: relative;
    font-size: 1.25rem;
}

.sidebar-menu-toggle-icon:before {
    font-family: Material Icons;
    content: '\E315';
    display: flex;
    align-items: center;
    font-size: inherit;
    color: inherit;
}

.open>.sidebar-menu-button .sidebar-menu-toggle-icon:before {
    content: '\E315';
}

.sidebar-menu-icon {
    vertical-align: middle;
    font-size: 24px;
    display: inline-block;
    line-height: normal;
    position: relative;
}

.sidebar-menu-icon--left {
    margin-right: 0.5rem;
}

.sidebar-menu-icon--right,
[dir='rtl'] .sidebar-menu-icon--left {
    margin-left: 0.5rem;
}

[dir='rtl'] .sidebar-menu-icon--right {
    margin-right: 0.5rem;
}

.sidebar-menu-badge {
    font-size: 0.75rem;
}

.sidebar-menu-badge {
    padding: 0.2rem 0.3rem;
}

.sidebar-menu-badge,
.sidebar-menu-toggle-icon {
    margin-left: 5px;
}

[dir='rtl'] .sidebar-menu-badge,
[dir='rtl'] .sidebar-menu-toggle-icon {
    margin-right: 5px;
}

.sidebar-submenu {
    display: none;
    list-style: none;
}

.sidebar-submenu {
    margin: 0;
    padding: 0;
}

.open>.sidebar-submenu {
    display: block;
}

.sidebar-submenu .sidebar-menu-button {
    line-height: 2rem;
    font-size: 15px;
}

.sidebar-submenu .sidebar-menu-icon {
    font-size: 24px;
}

.sm-icons-1 .sidebar-menu-icon {
    font-size: 1rem;
}

.sm-icons-small .sidebar-menu-icon {
    font-size: 0.875rem;
}

.sm-condensed>.sidebar-menu-item>.sidebar-menu-button {
    line-height: 31.5px;
}

.sm-condensed.sidebar-submenu>.sidebar-menu-item>.sidebar-menu-button {
    line-height: 1.5rem;
}

.sm-bordered,
.sm-item-bordered>.sidebar-menu-item {
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.sm-item-bordered .sidebar-submenu,
.sm-item-bordered>.sidebar-menu-item:last-of-type {
    border-bottom: none;
}

.sm-icons-block>.sidebar-menu-item>.sidebar-menu-button .sidebar-menu-icon {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    width: 30px;
}

.sm-icons-block>.sidebar-menu-item>.sidebar-menu-button .sidebar-menu-icon {
    text-align: center;
}

.sm-icons-block.sidebar-submenu>.sidebar-menu-item>.sidebar-menu-button .sidebar-menu-icon {
    height: 30px;
    line-height: 30px;
    width: 30px;
}

.sm-indent>.sidebar-menu-item .sidebar-menu-button {
    padding-left: 3rem;
}

[dir='rtl'] .sm-indent>.sidebar-menu-item .sidebar-menu-button {
    padding-right: 3rem;
}

.custom-checkbox-toggle {
    width: 3rem;
    height: 1.5rem;
}

.custom-checkbox-toggle {
    padding-left: 0;
}

[dir='rtl'] .custom-checkbox-toggle {
    padding-right: 0;
}

.custom-checkbox-toggle .custom-control-label {
    position: relative;
    width: 100%;
    height: 100%;
}

.custom-checkbox-toggle .custom-control-label:after,
.custom-checkbox-toggle .custom-control-label:before {
    position: absolute;
    top: 0;
    content: '';
    transition: all 0.2s ease;
}

.custom-checkbox-toggle .custom-control-label:after,
.custom-checkbox-toggle .custom-control-label:before {
    border-radius: 1.5rem;
}

.custom-checkbox-toggle .custom-control-label:after,
.custom-checkbox-toggle .custom-control-label:before {
    left: 0;
}

[dir='rtl'] .custom-checkbox-toggle .custom-control-label:after,
[dir='rtl'] .custom-checkbox-toggle .custom-control-label:before {
    right: 0;
}

.custom-checkbox-toggle .custom-control-label:before {
    width: 100%;
    height: 100%;
}

.custom-checkbox-toggle .custom-control-label:before {
    background-color: #e3ebf6;
}

.custom-checkbox-toggle .custom-control-label:after {
    width: 1.5rem;
    height: 100%;
}

.custom-checkbox-toggle .custom-control-label:after {
    transform: scale(0.8);
    background-color: #fff;
}

.custom-checkbox-toggle .custom-control-input:checked~.custom-control-label:after {
    background-color: #fff;
}

.custom-checkbox-toggle .custom-control-input:checked~.custom-control-label:after {
    right: 0;
    left: 1.5rem;
}

[dir='rtl'] .custom-checkbox-toggle .custom-control-input:checked~.custom-control-label:after {
    left: 0;
    right: 1.5rem;
}

.flatpickr-input[readonly] {
    background-color: #fff;
}

.flatpickr-wrapper {
    display: block;
}

.flatpickr-hidden-input {
    visibility: hidden;
    height: 0;
}

.flatpickr-calendar-right .flatpickr-calendar {
    right: -1.5rem;
}

[dir='rtl'] .flatpickr-calendar-right .flatpickr-calendar {
    left: -1.5rem;
}

@media (min-width: 576px) {
    .flatpickr-calendar-right .flatpickr-calendar {
        right: 0;
    }

    [dir='rtl'] .flatpickr-calendar-right .flatpickr-calendar {
        left: 0;
    }
}

.flatpickr-calendar.inline {
    box-shadow: none;
    border: none;
}

.flatpickr-calendar.inline:after,
.flatpickr-calendar.inline:before {
    display: none;
}

.flatpickr-calendar.inline,
.flatpickr-calendar.inline .dayContainer,
.flatpickr-calendar.inline .flatpickr-days {
    width: 100%;
    max-width: none;
}

.flatpickr-wrapper .flatpickr-input {
    height: 0;
    visibility: hidden;
}

.flatpickr-wrapper .flatpickr-input {
    padding: 0;
}

.chart {
    position: relative;
    height: 300px;
}

.chart-legend {
    display: flex;
    white-space: nowrap;
    justify-content: center;
}

.chart-legend {
    margin-top: 2.5rem;
}

.chart-legend-item {
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 0.75rem;
    color: rgba(56, 59, 61, 0.5);
}

.chart-legend-item {
    padding: 0.25rem 0.5rem;
    border: 1px solid #f0f1f2;
    border-radius: 0.2rem;
}

.chart-legend-indicator {
    display: block;
    width: 0.5rem;
    height: 0.5rem;
}

.chart-legend-indicator {
    border-radius: 50%;
}

.chart-legend-indicator {
    margin-right: 0.25rem;
}

[dir='rtl'] .chart-legend-indicator {
    margin-left: 0.25rem;
}

.chart-legend:not(.chart-legend--vertical) .chart-legend-item+.chart-legend-item {
    margin-left: 0.5rem;
}

[dir='rtl'] .chart-legend:not(.chart-legend--vertical) .chart-legend-item+.chart-legend-item {
    margin-right: 0.5rem;
}

.chart-legend--vertical {
    flex-direction: column;
}

.chart-legend--vertical .chart-legend-item+.chart-legend-item {
    margin-top: 0.5rem;
}

#chart-tooltip {
    z-index: 0;
    pointer-events: none;
}

#chart-tooltip .popover-header {
    border-bottom: 0;
}

#chart-tooltip .popover-body {
    text-transform: uppercase;
    font-size: 0.707rem;
    color: rgba(56, 59, 61, 0.7);
}

#chart-tooltip .popover-body+.popover-body {
    padding-top: 0;
}

#chart-tooltip .popover-body-label,
#chart-tooltip .popover-body-value {
    margin-left: 0.25rem;
}

#chart-tooltip .popover-body-indicator,
[dir='rtl'] #chart-tooltip .popover-body-label,
[dir='rtl'] #chart-tooltip .popover-body-value {
    margin-right: 0.25rem;
}

[dir='rtl'] #chart-tooltip .popover-body-indicator {
    margin-left: 0.25rem;
}

#chart-tooltip .arrow {
    top: 100%;
}

#chart-tooltip .arrow {
    left: 50%;
    transform: translateX(-50%) translateX(-0.5rem);
}

[dir='rtl'] #chart-tooltip .arrow {
    right: 50%;
    transform: translateX(50%) translateX(0.5rem);
}

#chart-tooltip .popover-body-indicator {
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
}

#chart-tooltip .popover-body-indicator {
    border-radius: 50%;
}

.avatar {
    font-size: 1rem;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    position: relative;
    z-index: 0;
}

.avatar-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.avatar-offline:after,
.avatar-offline:before,
.avatar-online:after,
.avatar-online:before {
    position: absolute;
    bottom: 5%;
    width: 20%;
    height: 20%;
    content: '';
}

.avatar-offline:after,
.avatar-offline:before,
.avatar-online:after,
.avatar-online:before {
    border-radius: 50%;
}

.avatar-offline:after,
.avatar-offline:before,
.avatar-online:after,
.avatar-online:before {
    right: 5%;
}

[dir='rtl'] .avatar-offline:after,
[dir='rtl'] .avatar-offline:before,
[dir='rtl'] .avatar-online:after,
[dir='rtl'] .avatar-online:before {
    left: 5%;
}

.avatar-offline:before,
.avatar-online:before {
    width: 22%;
    height: 22%;
    bottom: 4%;
}

.avatar-offline:before,
.avatar-online:before {
    background-color: #fff;
}

.avatar-offline:before,
.avatar-online:before {
    right: 4%;
}

[dir='rtl'] .avatar-offline:before,
[dir='rtl'] .avatar-online:before {
    left: 4%;
}

.avatar-online:after {
    background-color: #66bb6a;
}

.avatar-offline:after {
    background-color: #818a91;
}

.avatar-title {
    display: flex;
    width: 100%;
    height: 100%;
    color: #fff;
    align-items: center;
    justify-content: center;
}

.avatar-title {
    background-color: #818a91;
}

.avatar-xl,
.avatar-xxl {
    font-size: 1.70833rem;
    width: 5.125rem;
    height: 5.125rem;
}

.avatar-lg {
    font-size: 1.33333rem;
    width: 4rem;
    height: 4rem;
}

.avatar-sm {
    width: 2.5rem;
    height: 2.5rem;
}

.avatar-xs {
    font-size: 0.54167rem;
    width: 1.625rem;
    height: 1.625rem;
}

@media (min-width: 768px) {
    .avatar-xxl {
        font-size: 2.66667rem;
        width: 8rem;
        height: 8rem;
    }
}

.avatar.avatar-4by3 {
    width: 4rem;
}

.avatar-xxl.avatar-4by3 {
    width: 10.66667rem;
}

.avatar-xl.avatar-4by3 {
    width: 6.83333rem;
}

.avatar-lg.avatar-4by3 {
    width: 5.33333rem;
}

.avatar-group {
    display: inline-flex;
}

.avatar-group .avatar:hover {
    z-index: 1;
}

.avatar-group .avatar-img,
.avatar-group .avatar-title {
    border: 2px solid #fff;
}

.avatar-group .avatar+.avatar {
    margin-left: -0.75rem;
}

[dir='rtl'] .avatar-group .avatar+.avatar {
    margin-right: -0.75rem;
}

.avatar-group .avatar-lg+.avatar-lg {
    margin-left: -1rem;
}

[dir='rtl'] .avatar-group .avatar-lg+.avatar-lg {
    margin-right: -1rem;
}

.avatar-group .avatar-xl+.avatar-xl {
    margin-left: -1.28125rem;
}

[dir='rtl'] .avatar-group .avatar-xl+.avatar-xl {
    margin-right: -1.28125rem;
}

.avatar-group .avatar-xxl+.avatar-xxl {
    margin-left: -2rem;
}

[dir='rtl'] .avatar-group .avatar-xxl+.avatar-xxl {
    margin-right: -2rem;
}

.avatar-group .avatar-sm+.avatar-sm {
    margin-left: -0.625rem;
}

[dir='rtl'] .avatar-group .avatar-sm+.avatar-sm {
    margin-right: -0.625rem;
}

.avatar-group .avatar-xs+.avatar-xs {
    margin-left: -0.40625rem;
}

[dir='rtl'] .avatar-group .avatar-xs+.avatar-xs {
    margin-right: -0.40625rem;
}

.avatar-list>.avatar,
.avatar-list>.avatar-group {
    margin-bottom: 0.75rem;
}

.avatar-list>.avatar,
.avatar-list>.avatar-group {
    margin-left: 0.5rem;
}

[dir='rtl'] .avatar-list>.avatar,
[dir='rtl'] .avatar-list>.avatar-group {
    margin-right: 0.5rem;
}

.is-loading {
    position: relative;
    color: transparent !important;
}

.is-loading:after,
.loader {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
}

.is-loading:after,
.loader {
    border: 3px solid #383b3d;
    border-bottom-color: transparent;
    border-radius: 50%;
    background: transparent;
}

.is-loading:after,
.loader {
    -webkit-animation: is-loading-ltr 1s linear infinite;
    animation: is-loading-ltr 1s linear infinite;
}

[dir='rtl'] .is-loading:after,
[dir='rtl'] .loader {
    -webkit-animation: is-loading-rtl 1s linear infinite;
    animation: is-loading-rtl 1s linear infinite;
}

.is-loading-sm:after,
.loader-sm {
    width: 1rem;
    height: 1rem;
}

.is-loading-sm:after,
.loader-sm {
    border-width: 2px;
}

.is-loading-lg:after,
.loader-lg {
    width: 2rem;
    height: 2rem;
}

.is-loading-lg:after,
.loader-lg {
    border-width: 5px;
}

.is-loading>* {
    opacity: 0 !important;
}

.is-loading:after {
    position: absolute;
    top: calc(50% - 0.75rem);
    content: '';
}

.is-loading:after {
    left: calc(50% - 0.75rem);
}

[dir='rtl'] .is-loading:after {
    right: calc(50% - 0.75rem);
}

.is-loading-sm:after {
    top: calc(50% - 0.5rem);
}

.is-loading-sm:after {
    left: calc(50% - 0.5rem);
}

[dir='rtl'] .is-loading-sm:after {
    right: calc(50% - 0.5rem);
}

.is-loading-lg:after {
    top: calc(50% - 1rem);
}

.is-loading-lg:after {
    left: calc(50% - 1rem);
}

[dir='rtl'] .is-loading-lg:after {
    right: calc(50% - 1rem);
}

.btn-outline-primary.is-loading:after,
.is-loading-primary:after,
.loader-primary {
    border-color: var(--primary)var(--primary) transparent;
}

.btn-primary.is-loading:after {
    border-color: #fff #fff transparent;
}

.btn-outline-secondary.is-loading:after,
.is-loading-secondary:after,
.loader-secondary {
    border-color: #6b757d #6b757d transparent;
}

.btn-secondary.is-loading:after {
    border-color: #fff #fff transparent;
}

.btn-outline-success.is-loading:after,
.is-loading-success:after,
.loader-success {
    border-color: #66bb6a #66bb6a transparent;
}

.btn-success.is-loading:after {
    border-color: #212529 #212529 transparent;
}

.btn-outline-info.is-loading:after,
.is-loading-info:after,
.loader-info {
    border-color: #29b6f6 #29b6f6 transparent;
}

.btn-info.is-loading:after {
    border-color: #fff #fff transparent;
}

.btn-outline-warning.is-loading:after,
.is-loading-warning:after,
.loader-warning {
    border-color: #ffa726 #ffa726 transparent;
}

.btn-warning.is-loading:after {
    border-color: #212529 #212529 transparent;
}

.btn-outline-danger.is-loading:after,
.is-loading-danger:after,
.loader-danger {
    border-color: #f44336 #f44336 transparent;
}

.btn-danger.is-loading:after {
    border-color: #fff #fff transparent;
}

.btn-outline-light.is-loading:after,
.is-loading-light:after,
.loader-light {
    border-color: #f8f9fa #f8f9fa transparent;
}

.btn-light.is-loading:after {
    border-color: #212529 #212529 transparent;
}

.btn-outline-dark.is-loading:after,
.is-loading-dark:after,
.loader-dark {
    border-color: #39444d #39444d transparent;
}

.btn-dark.is-loading:after {
    border-color: #fff #fff transparent;
}

@-webkit-keyframes is-loading-ltr {
    0% {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(1turn);
    }
}

@keyframes is-loading-ltr {
    0% {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(1turn);
    }
}

@-webkit-keyframes is-loading-rtl {
    0% {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-1turn);
    }
}

@keyframes is-loading-rtl {
    0% {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-1turn);
    }
}

.loader-list {
    display: flex;
    align-items: center;
}

.loader-list>.loader {
    margin-bottom: 0.75rem;
}

.loader-list>.loader {
    margin-left: 0.5rem;
}

[dir='rtl'] .loader-list>.loader {
    margin-right: 0.5rem;
}

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

b,
strong {
    font-weight: 500;
}

.text-decoration-0:hover {
    text-decoration: none;
}

.font-size-16pt {
    font-size: 1rem !important;
}

.font-size-20pt {
    font-size: 1.25rem !important;
}

.font-size-24pt {
    font-size: 1.5rem !important;
}

.font-size-32pt {
    font-size: 2rem !important;
}

.font-size-48pt {
    font-size: 3rem !important;
}

.font-size-56pt {
    font-size: 3.5rem !important;
}

.font-size-64pt {
    font-size: 5rem !important;
}

.measure-paragraph {
    max-width: 456px;
}

.measure-lead,
.measure-paragraph-max {
    max-width: 536px;
}

.measure-lead-max {
    max-width: 616px;
}

.measure-hero-lead {
    max-width: 696px;
}

.text-black-20 {
    color: rgba(56, 59, 61, 0.2) !important;
}

.text-black-50 {
    color: rgba(56, 59, 61, 0.5) !important;
}

.text-black-70 {
    color: rgba(56, 59, 61, 0.7) !important;
}

.text-white-70 {
    color: hsla(0, 0%, 100%, 0.7) !important;
}

.text-white-50 {
    color: hsla(0, 0%, 100%, 0.5) !important;
}

.text-white-20 {
    color: hsla(0, 0%, 100%, 0.2) !important;
}

.mdk-header {
    height: auto;
}

.mdk-header-layout .mdk-drawer__content {
    top: 64px;
}

blockquote {
    font-size: 0.9rem;
}

blockquote {
    border-left: 2px solid #efefef;
    padding-left: 10px;
}

[dir='rtl'] blockquote {
    border-right: 2px solid #efefef;
    padding-right: 10px;
}

.breadcrumb {
    color: #b8bdc2;
    text-transform: uppercase;
    font-size: 0.707rem;
    font-weight: 500;
}

.breadcrumb {
    padding: 0;
    margin-bottom: 1.5rem;
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn:not(.btn-lg):not(.btn-sm) {
    font-size: 0.9375rem;
}

.button-list>.btn,
.button-list>.btn-group {
    margin-bottom: 0.75rem;
}

.button-list>.btn,
.button-list>.btn-group {
    margin-left: 0.5rem;
}

[dir='rtl'] .button-list>.btn,
[dir='rtl'] .button-list>.btn-group {
    margin-right: 0.5rem;
}

.btn-flush {
    line-height: 1;
    color: inherit;
}

.btn-flush {
    background: none;
    box-shadow: none;
    border: none;
    padding: 0;
}

.btn-default,
.btn-white {
    color: #212529;
}

.btn-default,
.btn-white {
    background-color: #fff;
    border-color: #f0f1f2;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-default:hover,
.btn-white:hover {
    color: #212529;
}

.btn-default:hover,
.btn-white:hover {
    background-color: #ececec;
    border-color: #d5d8da;
}

.btn-default:focus,
.btn-white.focus,
.btn-white:focus,
.focus.btn-default {
    color: #212529;
}

.btn-default:focus,
.btn-white.focus,
.btn-white:focus,
.focus.btn-default {
    background-color: #ececec;
    border-color: #d5d8da;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 0 0.2rem rgba(209, 210, 212, 0.5);
}

.btn-default:disabled,
.btn-white.disabled,
.btn-white:disabled,
.disabled.btn-default {
    color: #212529;
}

.btn-default:disabled,
.btn-white.disabled,
.btn-white:disabled,
.disabled.btn-default {
    background-color: #fff;
    border-color: #f0f1f2;
}

.btn-default:not(:disabled):not(.disabled).active,
.btn-default:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active,
.btn-white:not(:disabled):not(.disabled):active,
.show>.btn-white.dropdown-toggle,
.show>.dropdown-toggle.btn-default {
    color: #212529;
}

.btn-default:not(:disabled):not(.disabled).active,
.btn-default:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active,
.btn-white:not(:disabled):not(.disabled):active,
.show>.btn-white.dropdown-toggle,
.show>.dropdown-toggle.btn-default {
    background-color: #fff;
    border-color: #f0f1f2;
}

.btn-default:not(:disabled):not(.disabled).active:focus,
.btn-default:not(:disabled):not(.disabled):active:focus,
.btn-white:not(:disabled):not(.disabled).active:focus,
.btn-white:not(:disabled):not(.disabled):active:focus,
.show>.btn-white.dropdown-toggle:focus,
.show>.dropdown-toggle.btn-default:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(209, 210, 212, 0.5);
}

.btn-outline-white {
    color: #fff;
}

.btn-outline-white {
    border-color: #fff;
}

.btn-outline-white:hover {
    color: var(--primary);
}

.btn-outline-white:hover {
    background-color: #fff;
    border-color: #fff;
}

.btn-outline-white.focus,
.btn-outline-white:focus {
    box-shadow: 0 0 0 0.2rem hsla(0, 0%, 100%, 0.5);
}

.btn-outline-white.disabled,
.btn-outline-white:disabled {
    color: #fff;
}

.btn-outline-white.disabled,
.btn-outline-white:disabled {
    background-color: transparent;
}

.btn-outline-white:not(:disabled):not(.disabled).active,
.btn-outline-white:not(:disabled):not(.disabled):active,
.show>.btn-outline-white.dropdown-toggle {
    color: #212529;
}

.btn-outline-white:not(:disabled):not(.disabled).active,
.btn-outline-white:not(:disabled):not(.disabled):active,
.show>.btn-outline-white.dropdown-toggle {
    background-color: #fff;
    border-color: #fff;
}

.btn-outline-white:not(:disabled):not(.disabled).active:focus,
.btn-outline-white:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-white.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem hsla(0, 0%, 100%, 0.5);
}

.btn-success,
.btn-success:focus,
.btn-success:hover,
.btn-warning,
.btn-warning:focus,
.btn-warning:hover {
    color: #fff;
}

.btn.dropdown-toggle:after {
    align-self: center;
}

.btn .material-icons {
    font-size: inherit;
    line-height: inherit;
}

.btn-rounded {
    border-radius: 100px;
}

.btn-circle {
    width: calc(2.40625rem + 2px);
    height: calc(2.40625rem + 2px);
}

.btn-circle {
    border-radius: 100%;
}

.btn-circle.btn-sm,
.btn-group-sm>.btn-circle.btn {
    width: calc(1.73047rem + 2px);
    height: calc(1.73047rem + 2px);
}

.btn-circle.btn-lg,
.btn-group-lg>.btn-circle.btn {
    width: calc(2.75781rem + 2px);
    height: calc(2.75781rem + 2px);
}

.btn__icon--left {
    margin-right: 0.25rem;
}

.btn__icon--right,
[dir='rtl'] .btn__icon--left {
    margin-left: 0.25rem;
}

[dir='rtl'] .btn__icon--right {
    margin-right: 0.25rem;
}

.badge-success,
.badge-warning {
    color: #fff;
}

.btn .badge {
    top: 0;
}

.badge-notifications {
    line-height: 1rem;
    font-weight: 700;
}

.badge-notifications {
    border-radius: 100px;
    padding: 0 0.5rem;
}

.card {
    box-shadow: 0 2px 3px rgba(56, 59, 61, 0.05);
}

.form-row .card.form-row__card {
    margin-bottom: 10px;
}

.card-list>.card:last-child {
    margin-bottom: 0;
}

.card-title>a {
    color: rgba(57, 68, 77, 0.84);
}

.card-title>a:hover {
    color: var(--primary);
    text-decoration: none;
}

.card-title:last-child {
    margin-bottom: 0;
}

.card-subtitle {
    color: #b8bdc2;
    text-transform: uppercase;
    font-size: 0.707rem;
    font-weight: 500;
}

.card-subtitle {
    margin-top: -0.5rem;
}

.card,
.card-group {
    margin-bottom: 1.5rem;
}

.card-2by1 .card-body {
    padding: 0.625rem 1.25rem;
}

.card-sm .card-body {
    padding: 0.625rem;
}

[class*='card-img'] {
    position: relative;
    height: 200px;
    -o-object-fit: cover;
    object-fit: cover;
}

[class*='card-img']:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
}

[class*='card-img']:after {
    background-color: rgba(57, 68, 77, 0.125);
}

[class*='card-img']:after,
[dir='rtl'] [class*='card-img']:after {
    left: 0;
    right: 0;
}

@media (min-width: 576px) {
    .card-columns {
        -moz-column-count: 2;
        column-count: 2;
    }
}

@media (min-width: 992px) {
    .card-columns--3 {
        -moz-column-count: 3;
        column-count: 3;
    }
}

.card__options {
    position: absolute;
    top: 0.625rem;
}

.card__options {
    right: 1.25rem;
}

[dir='rtl'] .card__options {
    left: 1.25rem;
}

.card .media-left {
    padding-right: 0.625rem;
}

.card .media-right,
[dir='rtl'] .card .media-left {
    padding-left: 0.625rem;
}

[dir='rtl'] .card .media-right {
    padding-right: 0.625rem;
}

.dropdown-toggle:focus {
    outline: 0;
}

[data-caret='false']:after,
[data-caret='false']:before {
    display: none !important;
}

.dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s cubic-bezier(0.3, 0.5, 0.5, 1), margin-top 0.2s cubic-bezier(0.1, 0.3, 0.8, 1),
        visibility 0.3s ease;
}

.dropdown-menu {
    margin-top: 0 !important;
    background-clip: initial;
}

.dropdown-menu:after,
.dropdown-menu:before {
    content: '';
    height: 0;
    opacity: 0;
    transition: opacity 0.1s cubic-bezier(0.3, 0.5, 0.5, 1);
    position: absolute;
    top: -16px;
    width: 1px;
}

.dropdown-menu:after,
.dropdown-menu:before {
    border: 8px solid transparent;
    border-bottom-color: #fff;
}

.dropdown-menu:after,
.dropdown-menu:before {
    left: 10px;
}

[dir='rtl'] .dropdown-menu:after,
[dir='rtl'] .dropdown-menu:before {
    right: 10px;
}

.dropdown-menu:before {
    top: -17px;
}

.dropdown-menu:before {
    border-bottom-color: #f0f1f2;
}

.dropdown-menu-right:after,
.dropdown-menu-right:before {
    left: auto;
    right: 10px;
}

[dir='rtl'] .dropdown-menu-right:after,
[dir='rtl'] .dropdown-menu-right:before {
    right: auto;
    left: 10px;
}

.dropup .dropdown-menu:after,
.dropup .dropdown-menu:before {
    top: auto;
    bottom: -16px;
}

.dropup .dropdown-menu:after,
.dropup .dropdown-menu:before {
    border-bottom-color: transparent;
    border-top-color: #fff;
}

.dropup .dropdown-menu:before {
    bottom: -17px;
}

.dropup .dropdown-menu:before {
    border-top-color: #f0f1f2;
}

.dropdown-menu.show,
.show>.dropdown-menu {
    visibility: visible;
    opacity: 1;
}

.dropdown-menu.show,
.show>.dropdown-menu {
    margin-top: 0.5rem !important;
}

.dropdown-menu.show:after,
.dropdown-menu.show:before,
.show>.dropdown-menu:after,
.show>.dropdown-menu:before {
    opacity: 1;
}

.dropdown-menu-caret-center:after,
.dropdown-menu-caret-center:before {
    left: 50%;
    margin-left: -4px;
}

[dir='rtl'] .dropdown-menu-caret-center:after,
[dir='rtl'] .dropdown-menu-caret-center:before {
    right: 50%;
    margin-right: -4px;
}

@media (max-width: 373.98px) {
    .dropdown-menu-full {
        position: static;
    }

    .dropdown-menu-full .dropdown-menu,
    [dir='rtl'] .dropdown-menu-full .dropdown-menu {
        left: 0 !important;
        right: 0 !important;
    }
}

@media (max-width: 412.98px) {
    .dropdown-menu-xs-md-full {
        position: static;
    }

    .dropdown-menu-xs-md-full .dropdown-menu,
    [dir='rtl'] .dropdown-menu-xs-md-full .dropdown-menu {
        left: 0 !important;
        right: 0 !important;
    }
}

@media (max-width: 575.98px) {
    .dropdown-menu-xs-plus-full {
        position: static;
    }

    .dropdown-menu-xs-plus-full .dropdown-menu,
    [dir='rtl'] .dropdown-menu-xs-plus-full .dropdown-menu {
        left: 0 !important;
        right: 0 !important;
    }
}

@media (max-width: 767.98px) {
    .dropdown-menu-sm-full {
        position: static;
    }

    .dropdown-menu-sm-full .dropdown-menu,
    [dir='rtl'] .dropdown-menu-sm-full .dropdown-menu {
        left: 0 !important;
        right: 0 !important;
    }
}

@media (max-width: 991.98px) {
    .dropdown-menu-md-full {
        position: static;
    }

    .dropdown-menu-md-full .dropdown-menu,
    [dir='rtl'] .dropdown-menu-md-full .dropdown-menu {
        left: 0 !important;
        right: 0 !important;
    }
}

@media (max-width: 1199.98px) {
    .dropdown-menu-lg-full {
        position: static;
    }

    .dropdown-menu-lg-full .dropdown-menu,
    [dir='rtl'] .dropdown-menu-lg-full .dropdown-menu {
        left: 0 !important;
        right: 0 !important;
    }
}

@media (max-width: 1365.98px) {
    .dropdown-menu-xl-full {
        position: static;
    }

    .dropdown-menu-xl-full .dropdown-menu,
    [dir='rtl'] .dropdown-menu-xl-full .dropdown-menu {
        left: 0 !important;
        right: 0 !important;
    }
}

.dropdown-menu-xxl-full {
    position: static;
}

.dropdown-menu-xxl-full .dropdown-menu,
[dir='rtl'] .dropdown-menu-xxl-full .dropdown-menu {
    left: 0 !important;
    right: 0 !important;
}

.dropdown-menu .close {
    line-height: 0;
}

.dropdown-menu .close {
    padding: 0.5rem;
}

.dropdown-item {
    display: flex;
    align-items: center;
}

.dropdown-item>.material-icons {
    font-size: 18px;
}

.dropdown-item>.material-icons {
    margin-right: 5px;
}

[dir='rtl'] .dropdown-item>.material-icons {
    margin-left: 5px;
}

.dropdown-item.active,
.dropdown-item:active {
    font-weight: 500;
}

.dropdown-menu-dark.dropdown-menu {
    background-color: #39444d;
    border-color: rgba(46, 49, 51, 0.9);
}

.dropdown-menu-dark.dropdown-menu .dropdown-item {
    color: hsla(0, 0%, 100%, 0.5);
}

.dropdown-menu-dark.dropdown-menu .dropdown-item:focus,
.dropdown-menu-dark.dropdown-menu .dropdown-item:hover {
    color: hsla(0, 0%, 100%, 0.75);
}

.dropdown-menu-dark.dropdown-menu .dropdown-item.active,
.dropdown-menu-dark.dropdown-menu .dropdown-item:active {
    color: #fff;
}

.dropdown-menu-dark.dropdown-menu .dropdown-item.disabled,
.dropdown-menu-dark.dropdown-menu .dropdown-item:disabled {
    color: hsla(0, 0%, 100%, 0.25);
}

.dropdown-menu-dark.dropdown-menu:after,
.dropdown-menu-dark.dropdown-menu:before {
    border-bottom-color: #39444d;
}

.dropdown-menu-dark.dropdown-menu:before {
    border-bottom-color: rgba(46, 49, 51, 0.9);
}

.dropup .dropdown-menu-dark.dropdown-menu:after,
.dropup .dropdown-menu-dark.dropdown-menu:before {
    border-bottom-color: transparent;
    border-top-color: #39444d;
}

.dropup .dropdown-menu-dark.dropdown-menu:before {
    border-top-color: rgba(46, 49, 51, 0.9);
}

.footer {
    font-size: 0.9rem;
}

.footer {
    margin-bottom: 1.25rem;
}

.search-form {
    display: flex;
    align-items: center;
}

.search-form {
    background-color: #fff;
    border: 1px solid #f0f1f2;
    border-radius: 0.25rem;
}

.search-form .form-control {
    background-color: transparent;
}

.search-form .form-control,
.search-form .form-control:focus {
    border-color: transparent;
    box-shadow: none;
}

.search-form .btn {
    padding: 0 0.75rem;
    box-shadow: none;
    background-color: transparent;
}

.search-form .btn+.form-control {
    padding-left: 0;
}

[dir='rtl'] .search-form .btn+.form-control {
    padding-right: 0;
}

.search-form--light .btn {
    color: rgba(56, 59, 61, 0.4);
}

.search-form--light .form-control:focus+.btn {
    color: #495057;
}

.search-form--light .form-control:focus+.btn:hover {
    color: #383b3d;
}

.form-text {
    color: #b8bdc2;
}

.form-label {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
    color: rgba(56, 59, 61, 0.5);
}

.form-control:focus {
    box-shadow: none !important;
}

.form-control-rounded {
    border-radius: 20rem;
}

.form-control-flush {
    padding: 0;
    border-width: 0;
}

.form-control-flush,
.form-control-flush:focus {
    box-shadow: none;
    background-color: transparent;
}

.custom-file-naked {
    width: auto;
    height: auto;
}

.custom-file-naked {
    cursor: pointer;
}

.custom-file-naked .custom-file-input {
    width: auto;
    height: auto;
    line-height: 1;
}

.custom-file-naked .custom-file-input {
    cursor: pointer;
}

.custom-file-naked .custom-file-label {
    height: auto;
    line-height: 1;
}

.custom-file-naked .custom-file-label {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    box-shadow: none;
}

.custom-file-naked .custom-file-label:after {
    display: none;
}

.input-group-text .material-icons {
    font-size: inherit;
}

.input-group.input-group-merge .form-control {
    box-shadow: none;
}

.input-group.input-group-merge .form-control:focus~[class*='input-group'] .input-group-text {
    color: #9acffa;
}

.input-group.input-group-merge .form-control:focus~[class*='input-group'] .input-group-text {
    border-color: #9acffa;
}

.input-group.input-group-merge .form-control.is-valid~[class*='input-group'] .input-group-text,
.was-validated .input-group.input-group-merge .form-control:valid~[class*='input-group'] .input-group-text {
    color: #66bb6a;
}

.input-group.input-group-merge .form-control.is-valid~[class*='input-group'] .input-group-text,
.was-validated .input-group.input-group-merge .form-control:valid~[class*='input-group'] .input-group-text {
    border-color: #66bb6a;
}

.input-group.input-group-merge .form-control.is-invalid~[class*='input-group'] .input-group-text,
.was-validated .input-group.input-group-merge .form-control:invalid~[class*='input-group'] .input-group-text {
    color: #f44336;
}

.input-group.input-group-merge .form-control.is-invalid~[class*='input-group'] .input-group-text,
.was-validated .input-group.input-group-merge .form-control:invalid~[class*='input-group'] .input-group-text {
    border-color: #f44336;
}

.input-group.input-group-merge .form-control-prepended {
    padding-left: 0;
    border-left-width: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

[dir='rtl'] .input-group.input-group-merge .form-control-prepended {
    padding-right: 0;
    border-right-width: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.input-group.input-group-merge .form-control-appended {
    padding-right: 0;
    border-right-width: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

[dir='rtl'] .input-group.input-group-merge .form-control-appended {
    padding-left: 0;
    border-left-width: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group.input-group-merge .input-group-prepend {
    order: -1;
}

.input-group.input-group-merge .input-group-prepend>.input-group-text {
    border-right-width: 0 !important;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.input-group.input-group-merge .input-group-append>.input-group-text,
[dir='rtl'] .input-group.input-group-merge .input-group-prepend>.input-group-text {
    border-left-width: 0 !important;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

[dir='rtl'] .input-group.input-group-merge .input-group-append>.input-group-text {
    border-right-width: 0 !important;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

h1+.lead,
h2+.lead,
h3+.lead,
h4+.lead,
h5+.lead,
h6+.lead {
    margin-top: -5px;
    margin-bottom: 1.25rem;
}

.page-heading {
    padding: 1.25rem 0;
    margin-bottom: 0 !important;
}

.breadcrumb+.page-heading {
    padding-top: 0;
}

div.page-heading {
    position: relative;
    overflow: hidden;
}

.bg-transparent {
    background: transparent;
}

.bg-gradient-primary {
    background-image: linear-gradient(128deg, var(--primary-hover), var(--primary));
}

[dir='rtl'] .bg-gradient-primary {
    background-image: linear-gradient(-128deg, var(--primary-hover), var(--primary));
}

.border-style-dashed {
    border-style: dashed !important;
}

.border-1 {
    border: 1px solid #f0f1f2 !important;
}

.border-left-1 {
    border-left: 1px solid #f0f1f2 !important;
}

.border-right-1,
[dir='rtl'] .border-left-1 {
    border-right: 1px solid #f0f1f2 !important;
}

[dir='rtl'] .border-right-1 {
    border-left: 1px solid #f0f1f2 !important;
}

.border-top-1 {
    border-top: 1px solid #f0f1f2 !important;
}

.border-bottom-1 {
    border-bottom: 1px solid #f0f1f2 !important;
}

.border-2 {
    border: 2px solid #f0f1f2 !important;
}

.border-left-2 {
    border-left: 2px solid #f0f1f2 !important;
}

.border-right-2,
[dir='rtl'] .border-left-2 {
    border-right: 2px solid #f0f1f2 !important;
}

[dir='rtl'] .border-right-2 {
    border-left: 2px solid #f0f1f2 !important;
}

.border-top-2 {
    border-top: 2px solid #f0f1f2 !important;
}

.border-bottom-2 {
    border-bottom: 2px solid #f0f1f2 !important;
}

.border-3 {
    border: 3px solid #f0f1f2 !important;
}

.border-left-3 {
    border-left: 3px solid #f0f1f2 !important;
}

.border-right-3,
[dir='rtl'] .border-left-3 {
    border-right: 3px solid #f0f1f2 !important;
}

[dir='rtl'] .border-right-3 {
    border-left: 3px solid #f0f1f2 !important;
}

.border-top-3 {
    border-top: 3px solid #f0f1f2 !important;
}

.border-bottom-3 {
    border-bottom: 3px solid #f0f1f2 !important;
}

.border-primary {
    border-color: var(--primary) !important;
}

.border-left-primary {
    border-left-color: var(--primary) !important;
}

.border-right-primary,
[dir='rtl'] .border-left-primary {
    border-right-color: var(--primary) !important;
}

[dir='rtl'] .border-right-primary {
    border-left-color: var(--primary) !important;
}

.border-top-primary {
    border-top-color: var(--primary) !important;
}

.border-bottom-primary {
    border-bottom-color: var(--primary) !important;
}

.border-secondary {
    border-color: #6b757d !important;
}

.border-left-secondary {
    border-left-color: #6b757d !important;
}

.border-right-secondary,
[dir='rtl'] .border-left-secondary {
    border-right-color: #6b757d !important;
}

[dir='rtl'] .border-right-secondary {
    border-left-color: #6b757d !important;
}

.border-top-secondary {
    border-top-color: #6b757d !important;
}

.border-bottom-secondary {
    border-bottom-color: #6b757d !important;
}

.border-success {
    border-color: #66bb6a !important;
}

.border-left-success {
    border-left-color: #66bb6a !important;
}

.border-right-success,
[dir='rtl'] .border-left-success {
    border-right-color: #66bb6a !important;
}

[dir='rtl'] .border-right-success {
    border-left-color: #66bb6a !important;
}

.border-top-success {
    border-top-color: #66bb6a !important;
}

.border-bottom-success {
    border-bottom-color: #66bb6a !important;
}

.border-info {
    border-color: #29b6f6 !important;
}

.border-left-info {
    border-left-color: #29b6f6 !important;
}

.border-right-info,
[dir='rtl'] .border-left-info {
    border-right-color: #29b6f6 !important;
}

[dir='rtl'] .border-right-info {
    border-left-color: #29b6f6 !important;
}

.border-top-info {
    border-top-color: #29b6f6 !important;
}

.border-bottom-info {
    border-bottom-color: #29b6f6 !important;
}

.border-warning {
    border-color: #ffa726 !important;
}

.border-left-warning {
    border-left-color: #ffa726 !important;
}

.border-right-warning,
[dir='rtl'] .border-left-warning {
    border-right-color: #ffa726 !important;
}

[dir='rtl'] .border-right-warning {
    border-left-color: #ffa726 !important;
}

.border-top-warning {
    border-top-color: #ffa726 !important;
}

.border-bottom-warning {
    border-bottom-color: #ffa726 !important;
}

.border-danger {
    border-color: #f44336 !important;
}

.border-left-danger {
    border-left-color: #f44336 !important;
}

.border-right-danger,
[dir='rtl'] .border-left-danger {
    border-right-color: #f44336 !important;
}

[dir='rtl'] .border-right-danger {
    border-left-color: #f44336 !important;
}

.border-top-danger {
    border-top-color: #f44336 !important;
}

.border-bottom-danger {
    border-bottom-color: #f44336 !important;
}

.border-light {
    border-color: #f8f9fa !important;
}

.border-left-light {
    border-left-color: #f8f9fa !important;
}

.border-right-light,
[dir='rtl'] .border-left-light {
    border-right-color: #f8f9fa !important;
}

[dir='rtl'] .border-right-light {
    border-left-color: #f8f9fa !important;
}

.border-top-light {
    border-top-color: #f8f9fa !important;
}

.border-bottom-light {
    border-bottom-color: #f8f9fa !important;
}

.border-dark {
    border-color: #39444d !important;
}

.border-left-dark {
    border-left-color: #39444d !important;
}

.border-right-dark,
[dir='rtl'] .border-left-dark {
    border-right-color: #39444d !important;
}

[dir='rtl'] .border-right-dark {
    border-left-color: #39444d !important;
}

.border-top-dark {
    border-top-color: #39444d !important;
}

.border-bottom-dark {
    border-bottom-color: #39444d !important;
}

.border {
    border: 1px solid #f0f1f2 !important;
}

.border-top {
    border-top: 1px solid #f0f1f2 !important;
}

.border-right {
    border-right: 1px solid #f0f1f2 !important;
}

[dir='rtl'] .border-right {
    border-left: 1px solid #f0f1f2 !important;
}

.border-bottom {
    border-bottom: 1px solid #f0f1f2 !important;
}

.border-left {
    border-left: 1px solid #f0f1f2 !important;
}

[dir='rtl'] .border-left {
    border-right: 1px solid #f0f1f2 !important;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

[dir='rtl'] .border-right-0 {
    border-left: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

[dir='rtl'] .border-left-0 {
    border-right: 0 !important;
}

@media (min-width: 374px) {
    .border-xs-md {
        border: 1px solid #f0f1f2 !important;
    }

    .border-top-xs-md {
        border-top: 1px solid #f0f1f2 !important;
    }

    .border-right-xs-md {
        border-right: 1px solid #f0f1f2 !important;
    }

    [dir='rtl'] .border-right-xs-md {
        border-left: 1px solid #f0f1f2 !important;
    }

    .border-bottom-xs-md {
        border-bottom: 1px solid #f0f1f2 !important;
    }

    .border-left-xs-md {
        border-left: 1px solid #f0f1f2 !important;
    }

    [dir='rtl'] .border-left-xs-md {
        border-right: 1px solid #f0f1f2 !important;
    }

    .border-xs-md-0 {
        border: 0 !important;
    }

    .border-top-xs-md-0 {
        border-top: 0 !important;
    }

    .border-right-xs-md-0 {
        border-right: 0 !important;
    }

    [dir='rtl'] .border-right-xs-md-0 {
        border-left: 0 !important;
    }

    .border-bottom-xs-md-0 {
        border-bottom: 0 !important;
    }

    .border-left-xs-md-0 {
        border-left: 0 !important;
    }

    [dir='rtl'] .border-left-xs-md-0 {
        border-right: 0 !important;
    }
}

@media (min-width: 413px) {
    .border-xs-plus {
        border: 1px solid #f0f1f2 !important;
    }

    .border-top-xs-plus {
        border-top: 1px solid #f0f1f2 !important;
    }

    .border-right-xs-plus {
        border-right: 1px solid #f0f1f2 !important;
    }

    [dir='rtl'] .border-right-xs-plus {
        border-left: 1px solid #f0f1f2 !important;
    }

    .border-bottom-xs-plus {
        border-bottom: 1px solid #f0f1f2 !important;
    }

    .border-left-xs-plus {
        border-left: 1px solid #f0f1f2 !important;
    }

    [dir='rtl'] .border-left-xs-plus {
        border-right: 1px solid #f0f1f2 !important;
    }

    .border-xs-plus-0 {
        border: 0 !important;
    }

    .border-top-xs-plus-0 {
        border-top: 0 !important;
    }

    .border-right-xs-plus-0 {
        border-right: 0 !important;
    }

    [dir='rtl'] .border-right-xs-plus-0 {
        border-left: 0 !important;
    }

    .border-bottom-xs-plus-0 {
        border-bottom: 0 !important;
    }

    .border-left-xs-plus-0 {
        border-left: 0 !important;
    }

    [dir='rtl'] .border-left-xs-plus-0 {
        border-right: 0 !important;
    }
}

@media (min-width: 576px) {
    .border-sm {
        border: 1px solid #f0f1f2 !important;
    }

    .border-top-sm {
        border-top: 1px solid #f0f1f2 !important;
    }

    .border-right-sm {
        border-right: 1px solid #f0f1f2 !important;
    }

    [dir='rtl'] .border-right-sm {
        border-left: 1px solid #f0f1f2 !important;
    }

    .border-bottom-sm {
        border-bottom: 1px solid #f0f1f2 !important;
    }

    .border-left-sm {
        border-left: 1px solid #f0f1f2 !important;
    }

    [dir='rtl'] .border-left-sm {
        border-right: 1px solid #f0f1f2 !important;
    }

    .border-sm-0 {
        border: 0 !important;
    }

    .border-top-sm-0 {
        border-top: 0 !important;
    }

    .border-right-sm-0 {
        border-right: 0 !important;
    }

    [dir='rtl'] .border-right-sm-0 {
        border-left: 0 !important;
    }

    .border-bottom-sm-0 {
        border-bottom: 0 !important;
    }

    .border-left-sm-0 {
        border-left: 0 !important;
    }

    [dir='rtl'] .border-left-sm-0 {
        border-right: 0 !important;
    }
}

@media (min-width: 768px) {
    .border-md {
        border: 1px solid #f0f1f2 !important;
    }

    .border-top-md {
        border-top: 1px solid #f0f1f2 !important;
    }

    .border-right-md {
        border-right: 1px solid #f0f1f2 !important;
    }

    [dir='rtl'] .border-right-md {
        border-left: 1px solid #f0f1f2 !important;
    }

    .border-bottom-md {
        border-bottom: 1px solid #f0f1f2 !important;
    }

    .border-left-md {
        border-left: 1px solid #f0f1f2 !important;
    }

    [dir='rtl'] .border-left-md {
        border-right: 1px solid #f0f1f2 !important;
    }

    .border-md-0 {
        border: 0 !important;
    }

    .border-top-md-0 {
        border-top: 0 !important;
    }

    .border-right-md-0 {
        border-right: 0 !important;
    }

    [dir='rtl'] .border-right-md-0 {
        border-left: 0 !important;
    }

    .border-bottom-md-0 {
        border-bottom: 0 !important;
    }

    .border-left-md-0 {
        border-left: 0 !important;
    }

    [dir='rtl'] .border-left-md-0 {
        border-right: 0 !important;
    }
}

@media (min-width: 992px) {
    .border-lg {
        border: 1px solid #f0f1f2 !important;
    }

    .border-top-lg {
        border-top: 1px solid #f0f1f2 !important;
    }

    .border-right-lg {
        border-right: 1px solid #f0f1f2 !important;
    }

    [dir='rtl'] .border-right-lg {
        border-left: 1px solid #f0f1f2 !important;
    }

    .border-bottom-lg {
        border-bottom: 1px solid #f0f1f2 !important;
    }

    .border-left-lg {
        border-left: 1px solid #f0f1f2 !important;
    }

    [dir='rtl'] .border-left-lg {
        border-right: 1px solid #f0f1f2 !important;
    }

    .border-lg-0 {
        border: 0 !important;
    }

    .border-top-lg-0 {
        border-top: 0 !important;
    }

    .border-right-lg-0 {
        border-right: 0 !important;
    }

    [dir='rtl'] .border-right-lg-0 {
        border-left: 0 !important;
    }

    .border-bottom-lg-0 {
        border-bottom: 0 !important;
    }

    .border-left-lg-0 {
        border-left: 0 !important;
    }

    [dir='rtl'] .border-left-lg-0 {
        border-right: 0 !important;
    }
}

@media (min-width: 1200px) {
    .border-xl {
        border: 1px solid #f0f1f2 !important;
    }

    .border-top-xl {
        border-top: 1px solid #f0f1f2 !important;
    }

    .border-right-xl {
        border-right: 1px solid #f0f1f2 !important;
    }

    [dir='rtl'] .border-right-xl {
        border-left: 1px solid #f0f1f2 !important;
    }

    .border-bottom-xl {
        border-bottom: 1px solid #f0f1f2 !important;
    }

    .border-left-xl {
        border-left: 1px solid #f0f1f2 !important;
    }

    [dir='rtl'] .border-left-xl {
        border-right: 1px solid #f0f1f2 !important;
    }

    .border-xl-0 {
        border: 0 !important;
    }

    .border-top-xl-0 {
        border-top: 0 !important;
    }

    .border-right-xl-0 {
        border-right: 0 !important;
    }

    [dir='rtl'] .border-right-xl-0 {
        border-left: 0 !important;
    }

    .border-bottom-xl-0 {
        border-bottom: 0 !important;
    }

    .border-left-xl-0 {
        border-left: 0 !important;
    }

    [dir='rtl'] .border-left-xl-0 {
        border-right: 0 !important;
    }
}

@media (min-width: 1366px) {
    .border-xxl {
        border: 1px solid #f0f1f2 !important;
    }

    .border-top-xxl {
        border-top: 1px solid #f0f1f2 !important;
    }

    .border-right-xxl {
        border-right: 1px solid #f0f1f2 !important;
    }

    [dir='rtl'] .border-right-xxl {
        border-left: 1px solid #f0f1f2 !important;
    }

    .border-bottom-xxl {
        border-bottom: 1px solid #f0f1f2 !important;
    }

    .border-left-xxl {
        border-left: 1px solid #f0f1f2 !important;
    }

    [dir='rtl'] .border-left-xxl {
        border-right: 1px solid #f0f1f2 !important;
    }

    .border-xxl-0 {
        border: 0 !important;
    }

    .border-top-xxl-0 {
        border-top: 0 !important;
    }

    .border-right-xxl-0 {
        border-right: 0 !important;
    }

    [dir='rtl'] .border-right-xxl-0 {
        border-left: 0 !important;
    }

    .border-bottom-xxl-0 {
        border-bottom: 0 !important;
    }

    .border-left-xxl-0 {
        border-left: 0 !important;
    }

    [dir='rtl'] .border-left-xxl-0 {
        border-right: 0 !important;
    }
}

.text-base {
    font-family: Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol !important;
}

.text-muted-light {
    color: #b8bdc2 !important;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    white-space: nowrap;
}

.icon-block {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
}

.icon-block {
    background: #f3f3f3;
}

.mb-headings {
    margin-bottom: 1rem;
}

.mb-headings .h1,
.mb-headings .h2,
.mb-headings .h3,
.mb-headings .h4,
.mb-headings .h5,
.mb-headings .h6,
.mb-headings h1,
.mb-headings h2,
.mb-headings h3,
.mb-headings h4,
.mb-headings h5,
.mb-headings h6 {
    margin-bottom: 0;
}

.position-absolute-top {
    position: absolute;
    top: 0;
}

.position-absolute-top,
[dir='rtl'] .position-absolute-top {
    left: 0;
    right: 0;
}

.left-0 {
    left: 0 !important;
}

.right-0,
[dir='rtl'] .left-0 {
    right: 0 !important;
}

[dir='rtl'] .right-0 {
    left: 0 !important;
}

.top-0 {
    top: 0 !important;
}

.bottom-0 {
    bottom: 0 !important;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

.w-64 {
    width: 64px !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.h-64 {
    height: 64px !important;
}

@media (min-width: 374px) {
    .w-xs-md-25 {
        width: 25% !important;
    }

    .w-xs-md-50 {
        width: 50% !important;
    }

    .w-xs-md-75 {
        width: 75% !important;
    }

    .w-xs-md-100 {
        width: 100% !important;
    }

    .w-xs-md-auto {
        width: auto !important;
    }

    .w-xs-md-64 {
        width: 64px !important;
    }

    .h-xs-md-25 {
        height: 25% !important;
    }

    .h-xs-md-50 {
        height: 50% !important;
    }

    .h-xs-md-75 {
        height: 75% !important;
    }

    .h-xs-md-100 {
        height: 100% !important;
    }

    .h-xs-md-auto {
        height: auto !important;
    }

    .h-xs-md-64 {
        height: 64px !important;
    }
}

@media (min-width: 413px) {
    .w-xs-plus-25 {
        width: 25% !important;
    }

    .w-xs-plus-50 {
        width: 50% !important;
    }

    .w-xs-plus-75 {
        width: 75% !important;
    }

    .w-xs-plus-100 {
        width: 100% !important;
    }

    .w-xs-plus-auto {
        width: auto !important;
    }

    .w-xs-plus-64 {
        width: 64px !important;
    }

    .h-xs-plus-25 {
        height: 25% !important;
    }

    .h-xs-plus-50 {
        height: 50% !important;
    }

    .h-xs-plus-75 {
        height: 75% !important;
    }

    .h-xs-plus-100 {
        height: 100% !important;
    }

    .h-xs-plus-auto {
        height: auto !important;
    }

    .h-xs-plus-64 {
        height: 64px !important;
    }
}

@media (min-width: 576px) {
    .w-sm-25 {
        width: 25% !important;
    }

    .w-sm-50 {
        width: 50% !important;
    }

    .w-sm-75 {
        width: 75% !important;
    }

    .w-sm-100 {
        width: 100% !important;
    }

    .w-sm-auto {
        width: auto !important;
    }

    .w-sm-64 {
        width: 64px !important;
    }

    .h-sm-25 {
        height: 25% !important;
    }

    .h-sm-50 {
        height: 50% !important;
    }

    .h-sm-75 {
        height: 75% !important;
    }

    .h-sm-100 {
        height: 100% !important;
    }

    .h-sm-auto {
        height: auto !important;
    }

    .h-sm-64 {
        height: 64px !important;
    }
}

@media (min-width: 768px) {
    .w-md-25 {
        width: 25% !important;
    }

    .w-md-50 {
        width: 50% !important;
    }

    .w-md-75 {
        width: 75% !important;
    }

    .w-md-100 {
        width: 100% !important;
    }

    .w-md-auto {
        width: auto !important;
    }

    .w-md-64 {
        width: 64px !important;
    }

    .h-md-25 {
        height: 25% !important;
    }

    .h-md-50 {
        height: 50% !important;
    }

    .h-md-75 {
        height: 75% !important;
    }

    .h-md-100 {
        height: 100% !important;
    }

    .h-md-auto {
        height: auto !important;
    }

    .h-md-64 {
        height: 64px !important;
    }
}

@media (min-width: 992px) {
    .w-lg-25 {
        width: 25% !important;
    }

    .w-lg-50 {
        width: 50% !important;
    }

    .w-lg-75 {
        width: 75% !important;
    }

    .w-lg-100 {
        width: 100% !important;
    }

    .w-lg-auto {
        width: auto !important;
    }

    .w-lg-64 {
        width: 64px !important;
    }

    .h-lg-25 {
        height: 25% !important;
    }

    .h-lg-50 {
        height: 50% !important;
    }

    .h-lg-75 {
        height: 75% !important;
    }

    .h-lg-100 {
        height: 100% !important;
    }

    .h-lg-auto {
        height: auto !important;
    }

    .h-lg-64 {
        height: 64px !important;
    }
}

@media (min-width: 1200px) {
    .w-xl-25 {
        width: 25% !important;
    }

    .w-xl-50 {
        width: 50% !important;
    }

    .w-xl-75 {
        width: 75% !important;
    }

    .w-xl-100 {
        width: 100% !important;
    }

    .w-xl-auto {
        width: auto !important;
    }

    .w-xl-64 {
        width: 64px !important;
    }

    .h-xl-25 {
        height: 25% !important;
    }

    .h-xl-50 {
        height: 50% !important;
    }

    .h-xl-75 {
        height: 75% !important;
    }

    .h-xl-100 {
        height: 100% !important;
    }

    .h-xl-auto {
        height: auto !important;
    }

    .h-xl-64 {
        height: 64px !important;
    }
}

@media (min-width: 1366px) {
    .w-xxl-25 {
        width: 25% !important;
    }

    .w-xxl-50 {
        width: 50% !important;
    }

    .w-xxl-75 {
        width: 75% !important;
    }

    .w-xxl-100 {
        width: 100% !important;
    }

    .w-xxl-auto {
        width: auto !important;
    }

    .w-xxl-64 {
        width: 64px !important;
    }

    .h-xxl-25 {
        height: 25% !important;
    }

    .h-xxl-50 {
        height: 50% !important;
    }

    .h-xxl-75 {
        height: 75% !important;
    }

    .h-xxl-100 {
        height: 100% !important;
    }

    .h-xxl-auto {
        height: auto !important;
    }

    .h-xxl-64 {
        height: 64px !important;
    }
}

@media (max-width: 373.98px) {
    .w-xs-down-100 {
        width: 100% !important;
    }
}

@media (max-width: 412.98px) {
    .w-xs-md-down-100 {
        width: 100% !important;
    }
}

@media (max-width: 575.98px) {
    .w-xs-plus-down-100 {
        width: 100% !important;
    }
}

.z-0 {
    z-index: 0 !important;
}

.z-1 {
    z-index: 1 !important;
}

.z-2 {
    z-index: 2 !important;
}

.z-3 {
    z-index: 3 !important;
}

.flex {
    flex: 1 1 0%;
}

.flex-none {
    flex: none;
}

.jumbotron {
    padding: 1rem;
    border: 1px solid #f0f1f2;
    margin-bottom: 1.25rem;
}

.jumbotron> :last-child {
    margin-bottom: 0;
}

@media (min-width: 576px) {
    .jumbotron {
        padding: 2rem;
    }
}

body,
html {
    height: 100%;
    position: relative;
}

.mdk-header-layout,
.mdk-header-layout .mdk-drawer-layout,
.mdk-header-layout .mdk-drawer-layout__content {
    height: auto;
    min-height: 100%;
    overflow: visible;
}

.ls-top {
    padding-top: 1.25rem;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl,
[dir='rtl'] .container,
[dir='rtl'] .container-fluid,
[dir='rtl'] .container-lg,
[dir='rtl'] .container-md,
[dir='rtl'] .container-sm,
[dir='rtl'] .container-xl,
[dir='rtl'] .container-xxl {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
}

.page {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.page__header--shadow {
    box-shadow: 0 5px 15px 0 rgba(56, 59, 61, 0.05);
}

.layout-fluid .page__container {
    max-width: 944px;
}

.ui .page__container {
    max-width: 800px;
}

.layout-sticky-subnav .mdk-header-layout {
    overflow: initial;
}

.layout-sticky-subnav .page__header-nav {
    position: -webkit-sticky;
    position: sticky;
    top: 64px;
    z-index: 1;
}

.layout-sticky-subnav .page__header:first-child {
    margin-bottom: 0;
    box-shadow: none;
}

.layout-sticky-subnav .page__container,
.list-group {
    z-index: 0;
    position: relative;
}

.list-group {
    margin-bottom: 1.5rem;
}

.list-group-fit>.list-group-item,
[dir='rtl'] .list-group-fit>.list-group-item {
    border-left: none;
    border-right: none;
}

.list-group-fit>.list-group-item:last-of-type {
    border-bottom: none;
}

.list-group-fit>.list-group-item:first-of-type {
    border-top: none;
}

.card>.list-group:last-child {
    margin-bottom: 0;
}

.material-icons {
    vertical-align: sub;
}

.material-icons.md-18 {
    font-size: 18px;
}

.material-icons.md-24 {
    font-size: 24px;
}

.material-icons.md-36 {
    font-size: 36px;
}

.material-icons.md-48 {
    font-size: 48px;
}

.material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
}

.material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
}

.material-icons.md-light {
    color: #fff;
}

.material-icons.md-light.md-inactive {
    color: hsla(0, 0%, 100%, 0.3);
}

.media-right {
    padding-left: 1.25rem;
}

.media-left,
[dir='rtl'] .media-right {
    padding-right: 1.25rem;
}

[dir='rtl'] .media-left {
    padding-left: 1.25rem;
}

.media-body p:last-child {
    margin-bottom: 0;
}

.media {
    margin-top: 0;
}

.page-link {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    align-items: center;
    line-height: 1.25 !important;
}

.page-link {
    border-top: none;
    border-bottom: none;
}

.page-link {
    border-left: none;
}

[dir='rtl'] .page-link {
    border-right: none;
}

.page-link:focus,
.page-link:hover {
    text-decoration: none;
}

.page-item:last-of-type .page-link {
    border-right: none;
}

[dir='rtl'] .page-item:last-of-type .page-link {
    border-left: none;
}

.page-link .material-icons {
    line-height: 1rem;
}

.pagination {
    position: relative;
    z-index: 0;
}

.pagination:last-child {
    margin-bottom: 0;
}

.progress-primary {
    background-color: var(--primary);
}

.progress-gray {
    background-color: #818a91;
}

.table tbody td,
.table thead th {
    vertical-align: middle;
    line-height: 1.2;
}

.table-nowrap td,
.table-nowrap th {
    white-space: nowrap;
}

.table:not(.table-sm) th {
    padding: 0.35rem 0.75rem;
}

.table--elevated {
    box-shadow: 0 3px 3px -2px rgba(56, 59, 61, 0.2), 0 3px 4px 0 rgba(56, 59, 61, 0.14),
        0 1px 8px 0 rgba(56, 59, 61, 0.12);
}

.table thead th {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.5rem;
}

.table thead th {
    border-top: none;
}

.table .sort {
    color: inherit;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
}

.table .sort:after {
    content: url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='rgba(55, 77, 103, 0.54)'/></svg>");
}

.table .sort:after {
    margin-left: 0.5rem;
}

[dir='rtl'] .table .sort:after {
    margin-right: 0.5rem;
}

.table .sort.asc,
.table .sort.desc {
    color: #383b3d;
    text-decoration: underline;
}

.table .sort+.sort {
    margin-left: 1rem;
}

[dir='rtl'] .table .sort+.sort {
    margin-right: 1rem;
}

.tab-pane> :last-child {
    margin-bottom: 0;
}

.nav-pills .nav-link,
.nav-tabs .nav-link {
    color: #b8bdc2;
}

.nav-pills .nav-link:hover,
.nav-tabs .nav-link:hover {
    color: var(--primary);
}

.nav-tabs-card {
    background: #f5f6f7;
}

.nav-tabs-card .nav-link {
    color: #b8bdc2;
}

.nav-tabs-card .nav-link {
    border-radius: 0;
}

.nav-tabs-card .nav-link.active,
.nav-tabs-card .nav-link:hover {
    color: inherit;
}

.nav-tabs-card .nav-link.active,
.nav-tabs-card .nav-link:hover {
    border-color: transparent;
}

.nav-tabs-links {
    border-bottom: 3px solid #f0f1f2;
}

.nav-tabs-links .nav-item {
    margin-bottom: -3px;
}

.nav-tabs-links .nav-item+.nav-item {
    margin-left: 1rem;
}

[dir='rtl'] .nav-tabs-links .nav-item+.nav-item {
    margin-right: 1rem;
}

.nav-tabs-links .nav-link {
    color: rgba(56, 59, 61, 0.5);
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 500;
}

.nav-tabs-links .nav-link {
    padding: 0.5rem 0;
    border-bottom: 3px solid transparent;
}

.nav-tabs-links .nav-item.show .nav-link,
.nav-tabs-links .nav-link.active {
    color: #383b3d;
}

.nav-tabs-links .nav-item.show .nav-link,
.nav-tabs-links .nav-link.active {
    border-color: var(--primary);
}

.modal-header.bg-primary {
    color: #fff;
}

.page-nav {
    z-index: 0;
    position: relative;
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    max-height: calc(100vh - 4rem);
    overflow-y: auto;
    display: block;
    width: 240px;
}

.page-nav {
    padding: 0;
}

@media (max-width: 373.98px) {
    .page-nav {
        order: 1;
        position: relative;
        top: 0;
        max-height: none;
    }
}

.page-nav .page-nav__content {
    margin-left: 21px;
    padding-left: 17px;
}

[dir='rtl'] .page-nav .page-nav__content {
    margin-right: 21px;
    padding-right: 17px;
}

.page-nav:before {
    position: absolute;
    width: 2px;
    top: 0;
    bottom: 0;
    content: '';
    z-index: -1;
}

.page-nav:before {
    background-color: #f0f1f2;
}

.page-nav:before {
    left: 21px;
}

[dir='rtl'] .page-nav:before {
    right: 21px;
}

.page-nav .page-nav__menu {
    display: flex;
    flex-direction: column;
}

.page-nav .page-nav__menu {
    margin-bottom: 1rem;
}

.page-nav .page-nav__menu {
    margin-left: 21px;
}

[dir='rtl'] .page-nav .page-nav__menu {
    margin-right: 21px;
}

.page-nav .page-nav__menu a {
    color: rgba(56, 59, 61, 0.7);
    position: relative;
}

.page-nav .page-nav__menu a {
    margin-bottom: 0.5rem;
}

.page-nav .page-nav__menu a {
    padding: 0 0 0 17px;
}

[dir='rtl'] .page-nav .page-nav__menu a {
    padding: 0 17px 0 0;
}

.page-nav .page-nav__menu a:before {
    width: 12px;
    height: 12px;
    content: '';
    top: 6px;
    position: absolute;
}

.page-nav .page-nav__menu a:before {
    background-color: #d2d5d9;
    border-radius: 50%;
    border: 2px solid #f5f7fa;
}

.page-nav .page-nav__menu a:before {
    left: -5px;
}

[dir='rtl'] .page-nav .page-nav__menu a:before {
    right: -5px;
}

.page-nav .page-nav__menu a.active {
    font-weight: 700;
}

.page-nav .page-nav__menu a.active:before {
    background-color: var(--primary);
    border-color: #fff;
}

@media (min-width: 992px) {

    .hero .h1,
    .hero h1 {
        font-size: 3.998rem;
        font-weight: 700;
        line-height: 1.25;
    }

    .hero .lead,
    .hero__lead {
        font-size: 1.414rem;
        line-height: 1.414;
    }

    .hero .lead:not(:last-child),
    .hero__lead:not(:last-child) {
        margin-bottom: 2rem;
    }
}

.img-fluid {
    max-width: none;
    width: 100%;
}

:root {
    --sk-size: 64px;
    --sk-color: #fff;
}

.preloader {
    height: 100%;
    width: 100%;
    top: 0;
    position: fixed;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preloader {
    background: var(--primary);
}

.icon-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
}

.icon-holder {
    padding: 0;
}

.icon-holder[href]:hover {
    text-decoration: none;
}

.icon-holder--small {
    width: 30px;
    height: 30px;
}

.icon-holder--default {
    background-color: #e9ecef;
}

.icon-holder--primary {
    color: #fff;
}

.icon-holder--primary {
    background-image: none;
    background-color: var(--primary);
    border: 1px solid var(--primary);
}

.icon-holder--primary:hover {
    color: var(--primary);
}

.icon-holder--primary:hover {
    background-image: none;
    background-color: transparent;
    border: 1px solid var(--primary);
}

.icon-holder--primary:hover:hover {
    color: #fff;
}

.icon-holder--primary:hover:hover {
    background-color: var(--primary);
    border-color: var(--primary);
}

.icon-holder--outline-primary {
    color: var(--primary);
}

.icon-holder--outline-primary {
    background-image: none;
    background-color: transparent;
    border: 1px solid var(--primary);
}

.icon-holder--outline-primary:hover {
    color: #fff;
}

.icon-holder--outline-primary:hover {
    background-color: var(--primary);
    border-color: var(--primary);
}

.icon-holder--secondary {
    color: #fff;
}

.icon-holder--secondary {
    background-image: none;
    background-color: #6b757d;
    border: 1px solid #6b757d;
}

.icon-holder--secondary:hover {
    color: #6b757d;
}

.icon-holder--secondary:hover {
    background-image: none;
    background-color: transparent;
    border: 1px solid #6b757d;
}

.icon-holder--secondary:hover:hover {
    color: #fff;
}

.icon-holder--secondary:hover:hover {
    background-color: #6b757d;
    border-color: #6b757d;
}

.icon-holder--outline-secondary {
    color: #6b757d;
}

.icon-holder--outline-secondary {
    background-image: none;
    background-color: transparent;
    border: 1px solid #6b757d;
}

.icon-holder--outline-secondary:hover {
    color: #fff;
}

.icon-holder--outline-secondary:hover {
    background-color: #6b757d;
    border-color: #6b757d;
}

.icon-holder--success {
    color: #fff;
}

.icon-holder--success {
    background-image: none;
    background-color: #66bb6a;
    border: 1px solid #66bb6a;
}

.icon-holder--success:hover {
    color: #66bb6a;
}

.icon-holder--success:hover {
    background-image: none;
    background-color: transparent;
    border: 1px solid #66bb6a;
}

.icon-holder--success:hover:hover {
    color: #fff;
}

.icon-holder--success:hover:hover {
    background-color: #66bb6a;
    border-color: #66bb6a;
}

.icon-holder--outline-success {
    color: #66bb6a;
}

.icon-holder--outline-success {
    background-image: none;
    background-color: transparent;
    border: 1px solid #66bb6a;
}

.icon-holder--outline-success:hover {
    color: #fff;
}

.icon-holder--outline-success:hover {
    background-color: #66bb6a;
    border-color: #66bb6a;
}

.icon-holder--info {
    color: #fff;
}

.icon-holder--info {
    background-image: none;
    background-color: #29b6f6;
    border: 1px solid #29b6f6;
}

.icon-holder--info:hover {
    color: #29b6f6;
}

.icon-holder--info:hover {
    background-image: none;
    background-color: transparent;
    border: 1px solid #29b6f6;
}

.icon-holder--info:hover:hover {
    color: #fff;
}

.icon-holder--info:hover:hover {
    background-color: #29b6f6;
    border-color: #29b6f6;
}

.icon-holder--outline-info {
    color: #29b6f6;
}

.icon-holder--outline-info {
    background-image: none;
    background-color: transparent;
    border: 1px solid #29b6f6;
}

.icon-holder--outline-info:hover {
    color: #fff;
}

.icon-holder--outline-info:hover {
    background-color: #29b6f6;
    border-color: #29b6f6;
}

.icon-holder--warning {
    color: #fff;
}

.icon-holder--warning {
    background-image: none;
    background-color: #ffa726;
    border: 1px solid #ffa726;
}

.icon-holder--warning:hover {
    color: #ffa726;
}

.icon-holder--warning:hover {
    background-image: none;
    background-color: transparent;
    border: 1px solid #ffa726;
}

.icon-holder--warning:hover:hover {
    color: #fff;
}

.icon-holder--warning:hover:hover {
    background-color: #ffa726;
    border-color: #ffa726;
}

.icon-holder--outline-warning {
    color: #ffa726;
}

.icon-holder--outline-warning {
    background-image: none;
    background-color: transparent;
    border: 1px solid #ffa726;
}

.icon-holder--outline-warning:hover {
    color: #fff;
}

.icon-holder--outline-warning:hover {
    background-color: #ffa726;
    border-color: #ffa726;
}

.icon-holder--danger {
    color: #fff;
}

.icon-holder--danger {
    background-image: none;
    background-color: #f44336;
    border: 1px solid #f44336;
}

.icon-holder--danger:hover {
    color: #f44336;
}

.icon-holder--danger:hover {
    background-image: none;
    background-color: transparent;
    border: 1px solid #f44336;
}

.icon-holder--danger:hover:hover {
    color: #fff;
}

.icon-holder--danger:hover:hover {
    background-color: #f44336;
    border-color: #f44336;
}

.icon-holder--outline-danger {
    color: #f44336;
}

.icon-holder--outline-danger {
    background-image: none;
    background-color: transparent;
    border: 1px solid #f44336;
}

.icon-holder--outline-danger:hover {
    color: #fff;
}

.icon-holder--outline-danger:hover {
    background-color: #f44336;
    border-color: #f44336;
}

.icon-holder--light {
    color: #fff;
}

.icon-holder--light {
    background-image: none;
    background-color: #f8f9fa;
    border: 1px solid #f8f9fa;
}

.icon-holder--light:hover {
    color: #f8f9fa;
}

.icon-holder--light:hover {
    background-image: none;
    background-color: transparent;
    border: 1px solid #f8f9fa;
}

.icon-holder--light:hover:hover {
    color: #fff;
}

.icon-holder--light:hover:hover {
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.icon-holder--outline-light {
    color: #f8f9fa;
}

.icon-holder--outline-light {
    background-image: none;
    background-color: transparent;
    border: 1px solid #f8f9fa;
}

.icon-holder--outline-light:hover {
    color: #fff;
}

.icon-holder--outline-light:hover {
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.icon-holder--dark {
    color: #fff;
}

.icon-holder--dark {
    background-image: none;
    background-color: #39444d;
    border: 1px solid #39444d;
}

.icon-holder--dark:hover {
    color: #39444d;
}

.icon-holder--dark:hover {
    background-image: none;
    background-color: transparent;
    border: 1px solid #39444d;
}

.icon-holder--dark:hover:hover {
    color: #fff;
}

.icon-holder--dark:hover:hover {
    background-color: #39444d;
    border-color: #39444d;
}

.icon-holder--outline-dark {
    color: #39444d;
}

.icon-holder--outline-dark {
    background-image: none;
    background-color: transparent;
    border: 1px solid #39444d;
}

.icon-holder--outline-dark:hover {
    color: #fff;
}

.icon-holder--outline-dark:hover {
    background-color: #39444d;
    border-color: #39444d;
}

.icon-holder--outline-muted {
    color: #b8bdc2;
}

.icon-holder--outline-muted {
    background-image: none;
    background-color: transparent;
    border: 1px solid #b8bdc2;
}

.icon-holder--outline-muted:hover {
    color: #fff;
}

.icon-holder--outline-muted:hover {
    background-color: #b8bdc2;
    border-color: #b8bdc2;
}

.navbar {
    min-height: 64px;
    font-size: 1rem;
}

.navbar {
    padding-top: 0;
    padding-bottom: 0;
}

@media (max-width: 373.98px) {
    .navbar.navbar-expand-xs-md .navbar-collapse .nav-link {
        display: flex;
        flex: 1 1 0%;
    }

    .navbar.navbar-expand-xs-md .navbar-collapse .navbar-nav:last-child:not(:only-child) {
        margin-bottom: 1rem;
    }
}

@media (min-width: 374px) {
    .navbar.navbar-expand-xs-md .nav-item+.nav-item {
        margin-left: 1rem;
    }

    [dir='rtl'] .navbar.navbar-expand-xs-md .nav-item+.nav-item {
        margin-right: 1rem;
    }

    .navbar.navbar-expand-xs-md .navbar-nav+.navbar-nav {
        margin-left: 1rem;
    }

    [dir='rtl'] .navbar.navbar-expand-xs-md .navbar-nav+.navbar-nav {
        margin-right: 1rem;
    }

    .navbar.navbar-expand-xs-md .dropdown-menu,
    .navbar.navbar-expand-xs-md .dropdown-menu .ps {
        max-height: calc(100vh - 64px - 0.5rem);
    }
}

@media (max-width: 412.98px) {
    .navbar.navbar-expand-xs-plus .navbar-collapse .nav-link {
        display: flex;
        flex: 1 1 0%;
    }

    .navbar.navbar-expand-xs-plus .navbar-collapse .navbar-nav:last-child:not(:only-child) {
        margin-bottom: 1rem;
    }
}

@media (min-width: 413px) {
    .navbar.navbar-expand-xs-plus .nav-item+.nav-item {
        margin-left: 1rem;
    }

    [dir='rtl'] .navbar.navbar-expand-xs-plus .nav-item+.nav-item {
        margin-right: 1rem;
    }

    .navbar.navbar-expand-xs-plus .navbar-nav+.navbar-nav {
        margin-left: 1rem;
    }

    [dir='rtl'] .navbar.navbar-expand-xs-plus .navbar-nav+.navbar-nav {
        margin-right: 1rem;
    }

    .navbar.navbar-expand-xs-plus .dropdown-menu,
    .navbar.navbar-expand-xs-plus .dropdown-menu .ps {
        max-height: calc(100vh - 64px - 0.5rem);
    }
}

@media (max-width: 575.98px) {
    .navbar.navbar-expand-sm .navbar-collapse .nav-link {
        display: flex;
        flex: 1 1 0%;
    }

    .navbar.navbar-expand-sm .navbar-collapse .navbar-nav:last-child:not(:only-child) {
        margin-bottom: 1rem;
    }
}

@media (min-width: 576px) {
    .navbar.navbar-expand-sm .nav-item+.nav-item {
        margin-left: 1rem;
    }

    [dir='rtl'] .navbar.navbar-expand-sm .nav-item+.nav-item {
        margin-right: 1rem;
    }

    .navbar.navbar-expand-sm .navbar-nav+.navbar-nav {
        margin-left: 1rem;
    }

    [dir='rtl'] .navbar.navbar-expand-sm .navbar-nav+.navbar-nav {
        margin-right: 1rem;
    }

    .navbar.navbar-expand-sm .dropdown-menu,
    .navbar.navbar-expand-sm .dropdown-menu .ps {
        max-height: calc(100vh - 64px - 0.5rem);
    }
}

@media (max-width: 767.98px) {
    .navbar.navbar-expand-md .navbar-collapse .nav-link {
        display: flex;
        flex: 1 1 0%;
    }

    .navbar.navbar-expand-md .navbar-collapse .navbar-nav:last-child:not(:only-child) {
        margin-bottom: 1rem;
    }
}

@media (min-width: 768px) {
    .navbar.navbar-expand-md .nav-item+.nav-item {
        margin-left: 1rem;
    }

    [dir='rtl'] .navbar.navbar-expand-md .nav-item+.nav-item {
        margin-right: 1rem;
    }

    .navbar.navbar-expand-md .navbar-nav+.navbar-nav {
        margin-left: 1rem;
    }

    [dir='rtl'] .navbar.navbar-expand-md .navbar-nav+.navbar-nav {
        margin-right: 1rem;
    }

    .navbar.navbar-expand-md .dropdown-menu,
    .navbar.navbar-expand-md .dropdown-menu .ps {
        max-height: calc(100vh - 64px - 0.5rem);
    }
}

@media (max-width: 991.98px) {
    .navbar.navbar-expand-lg .navbar-collapse .nav-link {
        display: flex;
        flex: 1 1 0%;
    }

    .navbar.navbar-expand-lg .navbar-collapse .navbar-nav:last-child:not(:only-child) {
        margin-bottom: 1rem;
    }
}

@media (min-width: 992px) {
    .navbar.navbar-expand-lg .nav-item+.nav-item {
        margin-left: 1rem;
    }

    [dir='rtl'] .navbar.navbar-expand-lg .nav-item+.nav-item {
        margin-right: 1rem;
    }

    .navbar.navbar-expand-lg .navbar-nav+.navbar-nav {
        margin-left: 1rem;
    }

    [dir='rtl'] .navbar.navbar-expand-lg .navbar-nav+.navbar-nav {
        margin-right: 1rem;
    }

    .navbar.navbar-expand-lg .dropdown-menu,
    .navbar.navbar-expand-lg .dropdown-menu .ps {
        max-height: calc(100vh - 64px - 0.5rem);
    }
}

@media (max-width: 1199.98px) {
    .navbar.navbar-expand-xl .navbar-collapse .nav-link {
        display: flex;
        flex: 1 1 0%;
    }

    .navbar.navbar-expand-xl .navbar-collapse .navbar-nav:last-child:not(:only-child) {
        margin-bottom: 1rem;
    }
}

@media (min-width: 1200px) {
    .navbar.navbar-expand-xl .nav-item+.nav-item {
        margin-left: 1rem;
    }

    [dir='rtl'] .navbar.navbar-expand-xl .nav-item+.nav-item {
        margin-right: 1rem;
    }

    .navbar.navbar-expand-xl .navbar-nav+.navbar-nav {
        margin-left: 1rem;
    }

    [dir='rtl'] .navbar.navbar-expand-xl .navbar-nav+.navbar-nav {
        margin-right: 1rem;
    }

    .navbar.navbar-expand-xl .dropdown-menu,
    .navbar.navbar-expand-xl .dropdown-menu .ps {
        max-height: calc(100vh - 64px - 0.5rem);
    }
}

@media (max-width: 1365.98px) {
    .navbar.navbar-expand-xxl .navbar-collapse .nav-link {
        display: flex;
        flex: 1 1 0%;
    }

    .navbar.navbar-expand-xxl .navbar-collapse .navbar-nav:last-child:not(:only-child) {
        margin-bottom: 1rem;
    }
}

@media (min-width: 1366px) {
    .navbar.navbar-expand-xxl .nav-item+.nav-item {
        margin-left: 1rem;
    }

    [dir='rtl'] .navbar.navbar-expand-xxl .nav-item+.nav-item {
        margin-right: 1rem;
    }

    .navbar.navbar-expand-xxl .navbar-nav+.navbar-nav {
        margin-left: 1rem;
    }

    [dir='rtl'] .navbar.navbar-expand-xxl .navbar-nav+.navbar-nav {
        margin-right: 1rem;
    }

    .navbar.navbar-expand-xxl .dropdown-menu,
    .navbar.navbar-expand-xxl .dropdown-menu .ps {
        max-height: calc(100vh - 64px - 0.5rem);
    }
}

.navbar.navbar-expand .navbar-collapse .nav-link {
    display: flex;
    flex: 1 1 0%;
}

.navbar.navbar-expand .navbar-collapse .navbar-nav:last-child:not(:only-child) {
    margin-bottom: 1rem;
}

.navbar.navbar-expand .nav-item+.nav-item {
    margin-left: 1rem;
}

[dir='rtl'] .navbar.navbar-expand .nav-item+.nav-item {
    margin-right: 1rem;
}

.navbar.navbar-expand .navbar-nav+.navbar-nav {
    margin-left: 1rem;
}

[dir='rtl'] .navbar.navbar-expand .navbar-nav+.navbar-nav {
    margin-right: 1rem;
}

.navbar.navbar-expand .dropdown-menu,
.navbar.navbar-expand .dropdown-menu .ps {
    max-height: calc(100vh - 64px - 0.5rem);
}

.navbar-brand {
    font-size: 1.6rem;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
}

.navbar-brand .material-icons {
    font-size: 36px;
}

.navbar-brand .material-icons {
    margin-right: 0.5rem;
}

[dir='rtl'] .navbar-brand .material-icons {
    margin-left: 0.5rem;
}

.navbar-shadow {
    box-shadow: 0 5px 15px 0 hsla(0, 0%, 89%, 0.5);
}

.mdk-header--shadow:after {
    box-shadow: inset 0 5px 6px -3px rgba(0, 0, 0, 0.2);
}

.navbar-height {
    min-height: 64px;
}

.nav-item {
    display: flex;
    align-items: center;
}

.navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-toggler {
    border-color: transparent !important;
}

.navbar-toggler:not(:last-child) {
    margin-right: 1rem;
}

[dir='rtl'] .navbar-toggler:not(:last-child) {
    margin-left: 1rem;
}

.navbar .search-form .btn {
    padding: 0 0.5rem;
}

.navbar-nav-stats .nav-item+.nav-item {
    margin-left: 0.5rem !important;
}

[dir='rtl'] .navbar-nav-stats .nav-item+.nav-item {
    margin-right: 0.5rem !important;
}

.nav-stats {
    line-height: 1;
    height: 36px;
    font-weight: 700;
}

.nav-stats {
    text-align: center;
    border-radius: 3px;
    padding: 4px 12px;
}

.nav-stats small {
    display: block;
    font-size: 0.625rem;
}

.nav-stats small {
    margin-top: 2px;
}

.nav-item:not(:last-child) .nav-stats {
    margin-right: 5px;
}

[dir='rtl'] .nav-item:not(:last-child) .nav-stats {
    margin-left: 5px;
}

@media (min-width: 576px) {

    .fixed-layout .navbar-expand>.container,
    .fixed-layout .navbar-expand>.container-fluid,
    .fixed-layout .navbar-expand>.container-lg,
    .fixed-layout .navbar-expand>.container-md,
    .fixed-layout .navbar-expand>.container-sm,
    .fixed-layout .navbar-expand>.container-xl,
    .fixed-layout .navbar-expand>.container-xxl,
    [dir='rtl'] .fixed-layout .navbar-expand>.container,
    [dir='rtl'] .fixed-layout .navbar-expand>.container-fluid,
    [dir='rtl'] .fixed-layout .navbar-expand>.container-lg,
    [dir='rtl'] .fixed-layout .navbar-expand>.container-md,
    [dir='rtl'] .fixed-layout .navbar-expand>.container-sm,
    [dir='rtl'] .fixed-layout .navbar-expand>.container-xl,
    [dir='rtl'] .fixed-layout .navbar-expand>.container-xxl {
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important;
    }
}

@media (min-width: 576px) {

    .navbar-nav.nav-active-underline .nav-link.active:before,
    .navbar-nav.nav-active-underline>.active>.nav-link:before {
        position: absolute;
        width: 100%;
        height: 4px;
        content: ' ';
        bottom: 4px;
    }

    .navbar-nav.nav-active-underline .nav-link.active:before,
    .navbar-nav.nav-active-underline>.active>.nav-link:before {
        border-radius: 2px;
        background-color: var(--primary);
    }

    .navbar-nav.nav-active-underline .nav-link.active:before,
    .navbar-nav.nav-active-underline>.active>.nav-link:before {
        left: 0;
    }

    [dir='rtl'] .navbar-nav.nav-active-underline .nav-link.active:before,
    [dir='rtl'] .navbar-nav.nav-active-underline>.active>.nav-link:before {
        right: 0;
    }
}

.navbar-nav .nav-link {
    font-weight: 500;
    display: flex;
    align-items: center;
    position: relative;
}

.dropdown-notifications .dropdown-menu {
    min-width: 300px;
}

.dropdown-notifications .dropdown-menu {
    padding: 0;
}

.dropdown-notifications .dropdown-menu .list-group-item {
    display: flex;
    flex-direction: column;
}

.dropdown-notifications .dropdown-menu .list-group-item:hover,
.dropdown-notifications .dropdown-menu .unread {
    background: #f9f9f9;
}

.dropdown-notifications .dropdown-menu .unread-indicator {
    display: inline-block;
    content: '';
    width: 0.5rem;
    height: 0.5rem;
}

.dropdown-notifications .dropdown-menu .unread-indicator {
    border-radius: 100%;
}

.dropdown-notifications .dropdown-toggle {
    display: flex;
}

.dropdown-notifications .badge-notifications {
    position: relative;
    display: block;
}

.dropdown-notifications .badge-notifications {
    left: -0.5rem;
}

[dir='rtl'] .dropdown-notifications .badge-notifications {
    right: -0.5rem;
}

@media (max-width: 373.98px) {
    .navbar-dark.navbar-expand-xs-md .navbar-collapse .nav-item {
        border-top: 1px solid rgba(46, 49, 51, 0.9);
    }
}

@media (max-width: 412.98px) {
    .navbar-dark.navbar-expand-xs-plus .navbar-collapse .nav-item {
        border-top: 1px solid rgba(46, 49, 51, 0.9);
    }
}

@media (max-width: 575.98px) {
    .navbar-dark.navbar-expand-sm .navbar-collapse .nav-item {
        border-top: 1px solid rgba(46, 49, 51, 0.9);
    }
}

@media (max-width: 767.98px) {
    .navbar-dark.navbar-expand-md .navbar-collapse .nav-item {
        border-top: 1px solid rgba(46, 49, 51, 0.9);
    }
}

@media (max-width: 991.98px) {
    .navbar-dark.navbar-expand-lg .navbar-collapse .nav-item {
        border-top: 1px solid rgba(46, 49, 51, 0.9);
    }
}

@media (max-width: 1199.98px) {
    .navbar-dark.navbar-expand-xl .navbar-collapse .nav-item {
        border-top: 1px solid rgba(46, 49, 51, 0.9);
    }
}

@media (max-width: 1365.98px) {
    .navbar-dark.navbar-expand-xxl .navbar-collapse .nav-item {
        border-top: 1px solid rgba(46, 49, 51, 0.9);
    }
}

.navbar-dark.navbar-expand .navbar-collapse .nav-item {
    border-top: 1px solid rgba(46, 49, 51, 0.9);
}

.navbar-dark .badge:not([class*='badge-']) {
    background-color: rgba(38, 45, 51, 0.5);
}

.navbar-dark .search-form {
    color: hsla(0, 0%, 100%, 0.5);
}

.navbar-dark .search-form {
    background-color: rgba(0, 0, 0, 0.2);
    border-color: rgba(0, 0, 0, 0.05);
}

.navbar-dark .search-form .form-control::-moz-placeholder {
    color: inherit;
}

.navbar-dark .search-form .form-control:-ms-input-placeholder {
    color: inherit;
}

.navbar-dark .search-form .form-control,
.navbar-dark .search-form .form-control::placeholder {
    color: inherit;
}

.navbar-dark .search-form .btn {
    color: inherit;
}

.navbar-dark .search-form .btn {
    background-color: transparent;
}

.navbar-dark .nav-stats {
    color: #fff;
}

.navbar-dark .nav-stats {
    background: rgba(0, 0, 0, 0.2);
}

.navbar-dark .nav-stats small {
    opacity: 0.54;
}

.navbar-dark.bg-dark .navbar-brand {
    color: #dfe2e6;
}

.navbar-dark.bg-dark .navbar-brand:focus,
.navbar-dark.bg-dark .navbar-brand:hover {
    color: #cfdbe6;
}

.navbar-dark.bg-dark .navbar-brand .material-icons {
    color: var(--primary);
}

.navbar-dark.bg-dark .navbar-nav .nav-link {
    color: #8a9299;
}

.navbar-dark.bg-dark .navbar-nav .nav-link:focus,
.navbar-dark.bg-dark .navbar-nav .nav-link:hover {
    color: rgba(207, 219, 230, 0.75);
}

.navbar-dark.bg-dark .navbar-nav .nav-link.disabled {
    color: hsla(0, 0%, 100%, 0.25);
}

.navbar-dark.bg-dark .navbar-nav .active>.nav-link,
.navbar-dark.bg-dark .navbar-nav .nav-link.active,
.navbar-dark.bg-dark .navbar-nav .nav-link.show,
.navbar-dark.bg-dark .navbar-nav .show>.nav-link {
    color: #cfdbe6;
}

.navbar-dark.bg-dark .navbar-text,
.navbar-dark.bg-dark .navbar-toggler {
    color: #8a9299;
}

.navbar-dark.bg-dark .navbar-text a,
.navbar-dark.bg-dark .navbar-text a:focus,
.navbar-dark.bg-dark .navbar-text a:hover {
    color: #cfdbe6;
}

.navbar-light {
    color: #949799;
}

.navbar-light .navbar-brand {
    color: rgba(57, 68, 77, 0.84);
}

.navbar-light .navbar-brand .material-icons {
    color: var(--primary);
}

.navbar-light .search-form {
    background: rgba(0, 0, 0, 0.05);
    border-color: transparent;
}

.navbar-light .search-form .btn,
.navbar-light .search-form .form-control {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .nav-stats {
    background: rgba(0, 0, 0, 0.05);
}

.sidebar {
    position: relative;
    height: 100%;
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
}

.sidebar {
    text-align: initial;
}

.mdk-drawer__content {
    background: transparent;
}

.mdk-drawer__scrim {
    background-color: rgba(56, 59, 61, 0.5);
}

.sidebar .list-group-fit {
    border-top: 1px solid #f0f1f2;
    border-bottom: 1px solid #f0f1f2;
}

.sidebar .list-group-fit .active a,
.sidebar .list-group-fit .active a:hover {
    color: #fff;
}

.sidebar .list-group-fit a {
    color: #383b3d;
}

.sidebar .list-group-fit a:hover {
    text-decoration: none;
    color: var(--primary);
}

.sidebar-heading {
    margin-bottom: 0.5rem !important;
}

.sidebar-light .badge-default {
    color: #fff;
}

.sidebar-light .badge-default {
    background-color: rgba(0, 0, 0, 0.3);
}

.sidebar-menu-toggle-icon {
    transition: transform 0.15s;
}

.sidebar-menu-toggle-icon {
    transform: translate(0);
}

.open>.sidebar-menu-button .sidebar-menu-toggle-icon {
    transform: rotate(90deg);
}

[dir='rtl'] .open>.sidebar-menu-button .sidebar-menu-toggle-icon {
    transform: rotate(-90deg);
}

.sm-indent>.sidebar-menu-item .sidebar-menu-button {
    padding-left: 2.25rem;
}

[dir='rtl'] .sm-indent>.sidebar-menu-item .sidebar-menu-button {
    padding-right: 2.25rem;
}

.sidebar-submenu .sidebar-menu-item:last-child {
    margin-bottom: 1rem;
}

.sidebar-light .sidebar-submenu .sidebar-menu-text {
    border-left: 1px solid rgba(46, 49, 51, 0.1);
}

[dir='rtl'] .sidebar-light .sidebar-submenu .sidebar-menu-text {
    border-right: 1px solid rgba(46, 49, 51, 0.1);
}

.sidebar-dark .sidebar-submenu .sidebar-menu-text {
    border-left: 1px solid rgba(46, 49, 51, 0.9);
}

[dir='rtl'] .sidebar-dark .sidebar-submenu .sidebar-menu-text {
    border-right: 1px solid rgba(46, 49, 51, 0.9);
}

.sidebar-submenu .sidebar-menu-text {
    position: relative;
}

.sidebar-submenu .sidebar-menu-text {
    padding-left: 1.25rem;
}

[dir='rtl'] .sidebar-submenu .sidebar-menu-text {
    padding-right: 1.25rem;
}

.sidebar-submenu .sidebar-menu-text:after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    display: none;
    top: 12px;
}

.sidebar-submenu .sidebar-menu-text:after {
    background-color: var(--primary);
    border-radius: 100%;
}

.sidebar-submenu .sidebar-menu-text:after {
    left: -4px;
}

[dir='rtl'] .sidebar-submenu .sidebar-menu-text:after {
    right: -4px;
}

.active>.sidebar-menu-button .sidebar-menu-text:after {
    display: block;
}

@media (max-width: 373.98px) {
    .sidebar-transparent-xs {
        background-color: #f5f6f7 !important;
        border-color: transparent !important;
    }
}

.sidebar-transparent-xs-up {
    background-color: #f5f6f7 !important;
    border-color: transparent !important;
}

@media (max-width: 373.98px) {
    .sidebar-transparent-xs-down {
        background-color: #f5f6f7 !important;
        border-color: transparent !important;
    }
}

@media (min-width: 374px) and (max-width: 412.98px) {
    .sidebar-transparent-xs-md {
        background-color: #f5f6f7 !important;
        border-color: transparent !important;
    }
}

@media (min-width: 374px) {
    .sidebar-transparent-xs-md-up {
        background-color: #f5f6f7 !important;
        border-color: transparent !important;
    }
}

@media (max-width: 412.98px) {
    .sidebar-transparent-xs-md-down {
        background-color: #f5f6f7 !important;
        border-color: transparent !important;
    }
}

@media (min-width: 413px) and (max-width: 575.98px) {
    .sidebar-transparent-xs-plus {
        background-color: #f5f6f7 !important;
        border-color: transparent !important;
    }
}

@media (min-width: 413px) {
    .sidebar-transparent-xs-plus-up {
        background-color: #f5f6f7 !important;
        border-color: transparent !important;
    }
}

@media (max-width: 575.98px) {
    .sidebar-transparent-xs-plus-down {
        background-color: #f5f6f7 !important;
        border-color: transparent !important;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .sidebar-transparent-sm {
        background-color: #f5f6f7 !important;
        border-color: transparent !important;
    }
}

@media (min-width: 576px) {
    .sidebar-transparent-sm-up {
        background-color: #f5f6f7 !important;
        border-color: transparent !important;
    }
}

@media (max-width: 767.98px) {
    .sidebar-transparent-sm-down {
        background-color: #f5f6f7 !important;
        border-color: transparent !important;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .sidebar-transparent-md {
        background-color: #f5f6f7 !important;
        border-color: transparent !important;
    }
}

@media (min-width: 768px) {
    .sidebar-transparent-md-up {
        background-color: #f5f6f7 !important;
        border-color: transparent !important;
    }
}

@media (max-width: 991.98px) {
    .sidebar-transparent-md-down {
        background-color: #f5f6f7 !important;
        border-color: transparent !important;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .sidebar-transparent-lg {
        background-color: #f5f6f7 !important;
        border-color: transparent !important;
    }
}

@media (min-width: 992px) {
    .sidebar-transparent-lg-up {
        background-color: #f5f6f7 !important;
        border-color: transparent !important;
    }
}

@media (max-width: 1199.98px) {
    .sidebar-transparent-lg-down {
        background-color: #f5f6f7 !important;
        border-color: transparent !important;
    }
}

@media (min-width: 1200px) and (max-width: 1365.98px) {
    .sidebar-transparent-xl {
        background-color: #f5f6f7 !important;
        border-color: transparent !important;
    }
}

@media (min-width: 1200px) {
    .sidebar-transparent-xl-up {
        background-color: #f5f6f7 !important;
        border-color: transparent !important;
    }
}

@media (max-width: 1365.98px) {
    .sidebar-transparent-xl-down {
        background-color: #f5f6f7 !important;
        border-color: transparent !important;
    }
}

@media (min-width: 1366px) {

    .sidebar-transparent-xxl,
    .sidebar-transparent-xxl-up {
        background-color: #f5f6f7 !important;
        border-color: transparent !important;
    }
}

.sidebar-transparent-xxl-down {
    background-color: #f5f6f7 !important;
    border-color: transparent !important;
}

code.highlight {
    color: #fff;
}

code.django .hljs-template-tag {
    color: #ffa726;
}

.rating {
    line-height: 1;
}

.rating .material-icons {
    color: #ffa726;
    font-size: 18px;
}

.page-separator {
    position: relative;
    color: #b8bdc2;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
}

.page-separator {
    margin: 1rem 0;
}

.page-separator:before {
    content: '';
    height: 1px;
    width: 100%;
    top: 50%;
    position: absolute;
    z-index: -1;
}

.page-separator:before {
    background-color: #f0f1f2;
}

.page-separator:before {
    left: 0;
}

[dir='rtl'] .page-separator:before {
    right: 0;
}

.page-separator__text {
    display: inline-flex;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: rgba(56, 59, 61, 0.5);
    line-height: 0.9375rem;
    font-weight: 700;
    max-width: 90%;
}

.page-separator__text {
    padding: 0.25rem 1rem;
    background-color: #fff;
}

.page-separator__text:hover {
    text-decoration: none;
}

.app-messages {
    overflow: hidden;
}

.app-messages .mdk-drawer-layout,
.app-messages .mdk-drawer-layout__content,
.app-messages .mdk-header-layout,
.app-messages .mdk-header-layout__content {
    height: 100%;
}

.app-messages .page {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.app-messages .page {
    padding: 0;
}

.app-messages__container {
    position: relative;
    overflow: hidden;
    z-index: 0;
}

#messages-drawer .mdk-drawer__content,
#messages-drawer[data-persistent] {
    width: 280px;
}

.message__aside {
    margin-right: 1rem;
}

[dir='rtl'] .message__aside {
    margin-left: 1rem;
}

.message:nth-child(2n) .message__aside {
    order: 1;
}

.message:nth-child(2n) .message__aside {
    margin-right: 0;
    margin-left: 1rem;
}

[dir='rtl'] .message:nth-child(2n) .message__aside {
    margin-left: 0;
    margin-right: 1rem;
}

.message:nth-child(2n) .message__body {
    margin-left: auto;
}

[dir='rtl'] .message:nth-child(2n) .message__body {
    margin-right: auto;
}

.messages-toggle {
    position: absolute;
    top: 1rem;
}

.messages-toggle {
    right: -1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

[dir='rtl'] .messages-toggle {
    left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.forum-thread .media {
    overflow: visible;
}

.forum-icon-wrapper {
    position: relative;
}

.forum-thread-icon {
    width: 50px;
    height: 50px;
    color: #b8bdc2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.forum-thread-icon {
    border: 1px solid #f0f1f2;
    border-radius: 50%;
}

.forum-thread-icon:hover {
    text-decoration: none;
}

.forum-thread-user {
    position: absolute;
    bottom: -5px;
}

.forum-thread-user {
    right: 0;
}

[dir='rtl'] .forum-thread-user {
    left: 0;
}

.forum-thread-user img {
    border: 1px solid #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
}

.forum-thread-user:hover img {
    opacity: 0.7;
}